import React, { useState } from "react";
import { Button, Modal } from "antd";
// import mapSvg from "../../assets/svg/";

import ViewOnMap from "../../utils/ViewOnMap";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useQuery } from "react-query";
import highPriority1 from "../../assets/images/icon/high.png";
import mediumPriority1 from "../../assets/images/icon/medium.png";
import lowPriority1 from "../../assets/images/icon/low.png";
import Completed from "../../assets/images/icon/completed.png";
import PendingIcon from "../../assets/images/icon/pending.png";
import AssignedIcon from "../../assets/images/icon/assigned.png";
import Unassigned from "../../assets/images/icon/Unassigned.png";
import Inprogess from "../../assets/images/icon/inpgress.png";

import useAxiosInstance from "../../lib/useAxiosInstance";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import AssignDetails from "./AssignedDetails";
import AssignTo from "./AssignedTo";
import { useTranslation } from "react-i18next";
import formatRole from "../../utils/formatRole";

const TaskDetails = () => {
  const { t } = useTranslation();
  const { taskId } = useParams();

  //   console.log("id", taskId);

  const axiosInstance = useAxiosInstance();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  //fetch Task By Id-->

  const fetchTasksById = async () => {
    return axiosInstance.get(`api/task/getTask/${taskId}`);
  };
  const singleTaskData = useQuery("single-task", fetchTasksById);
  //   console.log("std", singleTaskData);

  // <--
  const priority = singleTaskData?.data?.data?.data?.priority;
  const status = singleTaskData?.data?.data?.data?.status;

  const grievanceTitle = singleTaskData?.data?.data?.data?.title;

  const taskStatus = singleTaskData?.data?.data?.data?.status;

  // const priority = singleTaskData?.priority;

  function getPriorityIcon(priority) {
    switch (priority) {
      case "high":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#E84C3B", border: "0.5px solid #E84C3B" }}
          >
            <img src={highPriority1} alt='priority icon' />
            <span>{t("High")}</span>
          </div>
        );
      case "medium":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#E67E22", border: "0.5px solid #E67E22" }}
          >
            <img src={mediumPriority1} alt='priority icon' />
            <span>{t("Medium")}</span>
          </div>
        );
      case "low":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#3BBEE8", border: "0.5px solid #3BBEE8" }}
          >
            <img src={lowPriority1} alt='priority icon' />
            <span>{t("Low")}</span>
          </div>
        );
      default:
        return null;
    }
  }

  function getStatusIcon(status) {
    switch (status) {
      case "unassigned":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#E84C3B", border: "0.5px solid #E84C3B" }}
          >
            <img src={Unassigned} alt='unassigned icon' />
            <span>{t("unassigned")}</span>
          </div>
        );
      case "assigned":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#E67E22", border: "0.5px solid #E67E22" }}
          >
            <img src={AssignedIcon} alt='assigned icon' />
            <span>{t("assigned")}</span>
          </div>
        );
      case "inProgress":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#3BBEE8", border: "0.5px solid #3BBEE8" }}
          >
            <img src={Inprogess} alt='inprogress icon' />
            <span>{t("inProgress")}</span>
          </div>
        );
      case "pendingApproval":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#your-color", border: "0.5px solid #your-color" }}
          >
            <img src={PendingIcon} alt='pending Approval icon' />
            <span>{t("approvalPending")}</span>
          </div>
        );
      case "complete":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#your-color", border: "0.5px solid #your-color" }}
          >
            <img src={Completed} alt='complete icon' />
            <span>{t("completed")}</span>
          </div>
        );
      default:
        return null;
    }
  }

  const createdAt = moment(singleTaskData?.data?.data?.data?.createdAt);
  const formattedDate = createdAt.format("DD-MM-YYYY");

  // Check if singleTaskData is undefined
  if (!singleTaskData) {
    return <div>Loading...</div>;
  }

  const mapLocation = {
    lat: singleTaskData?.data?.data?.data?.location?.lat,
    lng: singleTaskData?.data?.data?.data?.location?.lng
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedImage("");
  };

  return (
    <>
      <div className='assign-details-wrapper'>
        <div className='breadcrumbs'>
          <h1 className='flex'>
            <p>
              {" "}
              {t("Task Management System")}
              <MdKeyboardArrowRight style={{ marginTop: "4px" }} />{" "}
              <span>{t("Task Details")}</span>{" "}
            </p>
          </h1>
        </div>
        <div className=' grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-6 '>
          <div className='gms-all-details  col-span-2'>
            <div className='issue-details  '>
              <div className='details-heading flex'>
                {/* <img src={getPriorityIcon(priority)} alt="" /> */}
                <span>{getPriorityIcon(priority)}</span>
                <span>{getStatusIcon(status)}</span>
                <h4 className='pr-4'>{grievanceTitle}</h4>
              </div>
              <div className='details-form-wrapper'>
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 '>
                  <div className='gms-container col-span-2 '>
                    <p>
                      <span>{t("Description")} </span>
                      <span style={{ fontWeight: 700 }}>
                        {singleTaskData?.data?.data?.data?.description}
                      </span>
                    </p>
                    <h2>{t("Created by")}</h2>
                    <p>
                      <span> {t("Name")} </span>
                      <span>
                        {singleTaskData?.data?.data?.data?.createdByUserName}
                      </span>
                    </p>
                    <p>
                      <span>{t("Role")} </span>
                      <span>
                        {formatRole(
                          singleTaskData?.data?.data?.data?.createdByUserRole
                        )}
                      </span>
                    </p>
                    <p>
                      <span>{t("Creation Date")} </span>
                      <span>{formattedDate}</span>
                    </p>
                    <h2>{t("Photos")}</h2>
                    <div className='gms-details-img gap-4'>
                      {/* {singleTaskData?.data?.data?.data?.uploadImages?.map(
                        (image, index) => (
                          <img
                            src={image?.publicUrl}
                            alt={image?.name}
                            key={index}
                          />
                        )
                      )} */}
                      {singleTaskData?.data?.data?.data?.uploadImages?.map(
                        (image, index) => (
                          <img
                            key={index}
                            src={image?.publicUrl}
                            alt={`image?.name ${index + 1}`}
                            onClick={() => handleImageClick(image)}
                            style={{ cursor: "pointer", marginRight: 10 }}
                            className="image-preview"
                          />
                        )
                      )}
                    </div>
                  </div>
                  <div className='m-2 h-64'>
                    <ViewOnMap mapLocation={mapLocation}></ViewOnMap>
                    <div className='mt-3 flex justify-center'>
                      <Button>
                        <a
                          href={`https://maps.google.com/?q=${parseFloat(
                            mapLocation?.lat
                          )},${parseFloat(mapLocation?.lng)}`}
                          target='_blank'
                        >
                          <div className='flex'>
                            {/* <img src={mapSvg} alt="Map Icon" /> */}
                            <span className='pl-2 pt-1'>
                              {t("View On Map")}
                            </span>
                          </div>
                        </a>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ASSIGN TASK */}
          <div className='col-span-1'>
            <div className='details-heading'>
              <h4>{t("Assign Task")}</h4>
            </div>

            <div className='details-form-wrapper'>
              {taskStatus === "unassigned" ? (
                <AssignTo taskId={singleTaskData?.data?.data?.data?._id} />
              ) : (
                <AssignDetails
                  singleTaskData={singleTaskData?.data?.data?.data}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="50%"
        className="image-modal"
      >
        <img src={selectedImage?.publicUrl} alt="Selected" style={{ width: "100%" }} className="p-10" />
      </Modal>
    </>
  );
};
export default TaskDetails;
