import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const GenderPie = ({ genderData = [] }) => {
  const { t } = useTranslation();
  // Extracting values from the data
  const data = [
    {
      gender: "F",
      value: 994,
      percentage: "9.87"
    },
    {
      gender: "M",
      value: 6916,
      percentage: "68.67"
    },
    {
      gender: "U",
      value: 2162,
      percentage: "21.47"
    }
  ];
  const maleCount = genderData.find((item) => item.gender === "M")?.value || 0;
  const femaleCount =
    genderData.find((item) => item.gender === "F")?.value || 0;
  const unknownCount =
    genderData.find((item) => item.gender === "U")?.value || 0;

  const optionsDonut = {
    chart: {
      type: "donut"
    },
    states: {
      hover: {
        filter: {
          type: "none"
        }
      },
      active: {
        filter: {
          type: "none"
        }
      }
    },
    labels: [t("Male"), t("Female"), t("Unknown")],
    colors: ["#004877", "#C5DEFF", "#CCCCCC"],
    legend: {
      show: true,
      position: "bottom"
    }
  };

  const seriesDonut = [maleCount, femaleCount, unknownCount];

  return (
    <div>
      <div className='flex justify-between'>
        <h1 className='text-lg font-semibold'>{t("Gender Distribution")}</h1>
        <div className='flex flex-col'>
          <span>
            {t("Male")}: {maleCount}
          </span>
          <span>
            {t("Female")}: {femaleCount}
          </span>
          <span>
            {t("Unknown")}: {unknownCount}
          </span>
        </div>
      </div>
      <Chart
        options={optionsDonut}
        series={seriesDonut}
        type='donut'
        width='100%'
        height='300px'
      />
    </div>
  );
};

export default GenderPie;
