// import { Button } from "antd";
import React from "react";
import ProfilePic from "../../assets/images/icon/placeholder-icon.png";
import { useQuery } from "react-query";
import useAxiosInstance from "../../lib/useAxiosInstance";
import formatDate from "../../utils/formatDate";
import formatRole from "../../utils/formatRole";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import ApprovalRejection from "./ApprovalRejection";
import { ToastContainer } from "react-toastify";

const AssignDetails = ({ singleTaskData }) => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  const userId = singleTaskData?.assignedToUserId;
  //fetch client user by Id-->
  const fetchClientUser = async () => {
    return axiosInstance.get(`/api/clients/clientUser/${userId}`);
  };
  const fetchClientUserProfileImage = async () => {
    return axiosInstance.get(`/api/clients/info/getProfileImage/${userId}`);
  };
  const clientUserDataById = useQuery(
    ["client-user-by-id", userId],
    fetchClientUser,
    {
      refetchOnWindowFocus: false,
      enabled: !!userId
    }
  );

  const clientUserProfileImage = useQuery(
    ["client-user-profile-image", userId],
    fetchClientUserProfileImage,
    {
      refetchOnWindowFocus: false,
      enabled: !!userId
    }
  );

  const UserProfilePic =
    clientUserProfileImage?.data?.data?.data?.profileImageLink;

  return (
    <>
      <ToastContainer position='top-center' />
      <div className=''>
        <div className=''>
          <div className='issue-details mt2'>
            <div className='profile-pic'>
              <img
                className=''
                src={UserProfilePic?.publicUrl || ProfilePic}
                alt={"No Profile Picture"}
              />
            </div>

            <p>
              <span>{t("Assign To")} </span>{" "}
              <span>{singleTaskData?.assignedToUserName}</span>{" "}
            </p>
            <p>
              <span>{t("Role")} </span>{" "}
              <span>
                {formatRole(clientUserDataById?.data?.data?.data?.role)}
              </span>{" "}
            </p>
            <p>
              <span>{t("dueDate")} </span>
              <span>
                {" "}
                {singleTaskData?.dueDate
                  ? formatDate(singleTaskData?.dueDate)
                  : "-"}{" "}
              </span>
            </p>
            <p>
              <span>{t("Email")} </span>
              <span>{clientUserDataById?.data?.data?.data?.email}</span>
            </p>
            <p>
              <span> {t("Phone")} </span>{" "}
              <span>{clientUserDataById?.data?.data?.data?.phone}</span>
            </p>

            <h2 style={{ color: "#004877", fontSize: "16px" }}>
              {t("Submitted Photos")}
            </h2>

            {singleTaskData?.postCompletionImages?.length > 0 ? (
              <Row className='gms-details-img pb-5'>
                {singleTaskData?.postCompletionImages?.map((image, index) => (
                  <img
                    className='mt-1'
                    src={image?.publicUrl}
                    alt={image?.name}
                    key={index}
                  />
                ))}
              </Row>
            ) : (
              <span>{t("No Images Found")}</span>
            )}

            <ApprovalRejection
              tmsId={singleTaskData?._id}
              taskStatus={singleTaskData?.status}
              singleTaskData={singleTaskData}
            />
          </div>
        </div>
        {/* <div className='gms-details-btn m-5'>
                    <Button className='mr-5' style={{backgroundColor:"transparent", color:"#000"}}>Reject</Button>
                    <Button>Approve</Button>
                </div> */}
      </div>
    </>
  );
};
export default AssignDetails;
