import React from "react";
import { priorityIcons } from "../../../utils/priorityIcons";
import cdn from "../../../assets/images/icon/location.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MarkerInfoContent = ({ activeMarker }) => {
  const { t } = useTranslation();
  return (
    <div className='info-window-content'>
      <span className='flex'>
        <img
          src={priorityIcons[activeMarker?.properties?.priority]}
          alt='priority icon'
          style={{ maxWidth: "18px", maxHeight: "18px", marginRight: "5px" }}
        />
        <span className='pl-1' style={{ fontSize: "18px", fontWeight: "bold" }}>
          {activeMarker?.properties?.title}
        </span>
      </span>
      <span className='flex-container'>
        <img src={cdn} alt='' className='cdn-icon' />
        <h5 className='bold-text'>
          {t("address")}: {activeMarker?.properties?.address || "NA"}
        </h5>
      </span>
      <h5 className='bold-text'>
        {t("assignedTo")}: {activeMarker?.properties?.assigned_to}
      </h5>
      <h5 className='bold-text'>
        {t("reportedBy")}: {activeMarker?.properties?.reported_by}
      </h5>
      <h5>
        <b>{t("status")}</b>:
        {activeMarker?.properties?.status
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/\b\w/g, (match) => match.toUpperCase())}
      </h5>
      <Link
        to={`/tms/taskdetails/${activeMarker?.properties?.taskId}`}
        target='_blank'
      >
        <div className='button-link'>{t("moreDetails")}</div>
      </Link>
    </div>
  );
};

export default MarkerInfoContent;
