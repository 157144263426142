import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/logo/PS-Logo 3.png";
import LoginPageVideo from "../../assets/videos/login-page-video1.mp4"
import LazyLoad from 'react-lazyload';
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAxiosInstance from "../../lib/useAxiosInstance";
import ErrorMessage from '../Forms/ErrorMessage';
import { Input, Spin } from "antd";
import checkLogo from "../../assets/svg/check-decagram.svg";

const ResetPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  console.log("TOKEN", token)
  const id = searchParams.get('id');
  const axiosInstance = useAxiosInstance();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const FormSchema = Yup.object().shape({
    password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const handleSubmit = async (values, onSubmitProps) => {
    setIsLoading(true);
    axiosInstance.post(`api/clients/auth/passwordReset?token=${token}&id=${id}`, values)
      .then((response) => {
        setIsSubmitted(true);
      })  
      .catch((error) => {
        setIsSubmitted(false);
        onSubmitProps.setFieldError('confirmPassword', 'Something went wrong. Please try again.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <div className="video-background">
        <LazyLoad height={200} once>
          <video id="video-bg" autoPlay muted loop >
            <source src={LoginPageVideo} type="video/mp4" />
          </video>
        </LazyLoad>

      </div>
      <div className='signup-page grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2'>
       
        {isSubmitted ? (
          <div className="get-in">
            <div className="block mx-auto">
              <img src={checkLogo} alt="" />
            </div>
            <div className="sign-in-para text-center p-1">
              <p>
                Your password has been reset successfully.
              </p>
            </div>
            <div className='my-2 text-center'>
                <p>Click here to <Link to='/' className='forgot-pass-text'>Login</Link></p>
            </div>
          </div>
        ) : (
          <div className='get-in'>
            <div className='logo'>
              <img className="block mx-auto" src={Logo} alt='Logo' />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div className='text-content'>
                  <h2>{t("Reset Password")}</h2>
                </div>
                <div className='sign-in-para'>
                  <p>{t("choosePasswordBelow")}</p>
                </div>

                <div className='flex flex-wrap'>
                  <div className='p-2 w-full'>
                    <div className='mt-7'>
                      <label className='leading-7 text-lg text-gray-600'>
                        {t("New Password")}
                      </label>
                      {/* <input
                        type='password'
                        placeholder={t("Choose new password")}
                        id='password'
                        name='password'
                        className='w-full rounded-lg border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 mt-2 leading-8 transition-colors duration-200 ease-in-out'
                      // value={email}
                      /> */}
                      <Input.Password type='password' placeholder={t("Choose new password")} name='password'
                        className={`w-full rounded-lg text-base py-2 px-3 mt-2`}
                        onChange={formik.handleChange} value={formik.values.password}
                      />
                      <ErrorMessage hasError={Boolean(formik.errors.password && formik.touched.password)} message={formik.errors.password} />
                    </div>
                  </div>
                  <div className='p-2 w-full'>
                    <div className='mt-7'>
                      <label className='leading-7 text-lg text-gray-600'>
                        {t(" New Password")}
                      </label>
                      {/* <input
                        type='password'
                        placeholder={t("Retype new password")}
                        id='confirmPassword'
                        name='confirmPassword'
                        className='w-full rounded-lg border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 mt-2 leading-8 transition-colors duration-200 ease-in-out'
                      // value={email}
                      /> */}
                      <Input.Password type='password' placeholder={t("Retype new password")} name='confirmPassword'
                        className={`w-full rounded-lg text-base py-2 px-3 mt-2`}
                        onChange={formik.handleChange} value={formik.values.confirmPassword}
                      />
                      <ErrorMessage hasError={Boolean(formik.errors.confirmPassword && formik.touched.confirmPassword)} message={formik.errors.confirmPassword} />
                    </div>
                  </div>
                  <div className='p-2 w-full'>
                    <div className='my-4'>
                      {/* <button
                        className='flex mx-auto w-full text-white bg-sky-900 border-0 py-3 justify-center focus:outline-none  rounded-lg text-lg'
                      // onClick={handleSubmit}
                      >
                        {t("Submit")}
                      </button> */}
                      <button className="flex mx-auto w-full text-white bg-sky-900 border-0 py-3 justify-center focus:outline-none  rounded-lg text-lg"
                        type="submit" disabled={isLoading}
                      >
                        {isLoading ? <Spin /> : t("Submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
