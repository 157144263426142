import React, { useContext, useEffect } from "react";
import GmsTmsGraphs from "../../Components/overview/GmsTmsComGraphs";
import GmsTmsRecordsGraphs from "../../Components/overview/GmsTmsRecordsGraphs";
import Stats from "../../Components/Stats";
import overviewGms from "../../assets/images/icon/gms-entries-widgets.svg";
import overviewTasks from "../../assets/images/icon/active-tasks-widgets.svg";
import overviewSurvay from "../../assets/images/icon/survey-widgets.svg";
import openGMs from "../../assets/images/icon/open.png";
import progressGMS from "../../assets/images/icon/progress.png";
import delayedGMS from "../../assets/images/icon/gmsDelayed.png";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useQuery } from "react-query";
import { AppContext } from "../../context/AppContextProvider";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import AOS from "aos";
import html2canvas from "html2canvas";
import { Margin, usePDF } from "react-to-pdf";
import "aos/dist/aos.css";

import { DotChartOutlined } from "@ant-design/icons";

import { Skeleton } from "antd";
import LazyLoad from "react-lazyload";

const Overview = () => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();
  const { toPDF, targetRef } = usePDF({
    filename: "Overview.pdf",
    page: { margin: Margin.SMALL }
  });

  // Function to update user data in localStorage
  const updateLocalStorageUser = (newUserData) => {
    localStorage.setItem(
      "user",
      JSON.stringify({ ...newUserData, prefix: user?.prefix })
    );
  };

  // Fetch overview widgets by client Id
  const fetchOverviewWidget = async () => {
    const response = await axiosInstance.get(
      `/api/widget/getOverview/${user?.organisationId}`
    );
    return response.data;
  };

  const overviewWidgetData = useQuery("overview-widgets", fetchOverviewWidget);

  // Update localStorage if user data has changed
  if (
    JSON.stringify(user) !==
      JSON.stringify(overviewWidgetData?.data?.data?.clientUser) ||
    !overviewWidgetData?.data?.data?.clientUser
  ) {
    updateLocalStorageUser(overviewWidgetData?.data?.data?.clientUser || user);
  }
  console.log(user);
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom" // defines which position of the element regarding to window should trigger the animation
    });
  });
  const handleDownloadImage = async (format) => {
    try {
      // console.log("I am here");
      const element = targetRef.current;
      // console.log("I am element", targetRef);
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL(`image/${format}`);
      const link = document.createElement("a");
      link.href = data;
      link.download = `Overview.${format}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  const handleDownload = (selectedFormat) => {
    selectedFormat === "pdf" ? toPDF() : handleDownloadImage(selectedFormat);
  };

  return (
    <>
      <div className='overview-container px-7 pb-4'>
        <div className='px-4 flex flex-row justify-between items-center'>
          <div className='overview-username' data-aos='fade-right'>
            {t("hello")}{" "}
            <span className='username'>
              {" "}
              {user?.prefix} {user?.firstName}
            </span>
          </div>

          <div className=' lang-selector flex pr-3'>
            <Select
              style={{ width: 150, marginTop: 0 }}
              className='mb-2'
              placeholder={t("Export as")}
              onChange={handleDownload}
            >
              <Select.Option value='pdf'>PDF</Select.Option>
              <Select.Option value='jpg'>JPG</Select.Option>
              <Select.Option value='png'>PNG</Select.Option>
            </Select>
          </div>
        </div>
        <div ref={targetRef}>
          <div
            className='w-full grid grid-cols-12 gap-10 grid-parent overview-top p-5'
            data-aos='fade-up'
          >
            <div className='col-span-12 sm:col-span-4'>
              <Stats
                icon={overviewGms}
                title={t("gmsEntriesPreviousDay")}
                stat={
                  overviewWidgetData?.data?.data?.overviewData?.newGMSEntries ||
                  0
                }
              />
            </div>
            <div className='col-span-12 sm:col-span-4'>
              <Stats
                icon={overviewTasks}
                title={t("totalActiveTasks")}
                stat={
                  overviewWidgetData?.data?.data?.overviewData
                    ?.totalActiveTask || 0
                }
              />
            </div>
            <div className='col-span-12 sm:col-span-4'>
              <Stats
                icon={overviewSurvay}
                title={t("totalSurveysReports")}
                stat={
                  overviewWidgetData?.data?.data?.overviewData
                    ?.totalSurveyReports || 0
                }
              />
            </div>
          </div>

          <div className='w-full grid grid-cols-12 gap-x-6 gap-y-0 grid-parent '>
            <div
              className='col-span-6 overview-second-row-map'
              data-aos='fade-right'
            >
              <LazyLoad height={200} once>
                <div className='mx-auto flex justify-center items-center h-full'>
                  <div className='w-full pa-4'>
                    {overviewWidgetData.isLoading ? (
                      <div className=' flex flex-col justify-center items-center gap-2'>
                        <Skeleton.Node active={true}>
                          <DotChartOutlined
                            style={{
                              fontSize: 40,
                              color: "#bfbfbf"
                            }}
                          />
                        </Skeleton.Node>
                        <h1 style={{ color: "#bfbfbf" }}>{t("loading")}</h1>
                      </div>
                    ) : overviewWidgetData.isError ? (
                      <div>{t("errorLoadingData")}</div>
                    ) : (
                      <GmsTmsGraphs
                        gmsTmsData={
                          overviewWidgetData.data?.data?.overviewData
                            ?.GMSTMSCompletionRate
                        }
                      />
                    )}
                  </div>
                </div>
              </LazyLoad>
            </div>

            <div
              className='col-span-6 overview-second-row-map'
              data-aos='fade-left'
            >
              <LazyLoad height={200} once>
                <div className='mx-auto flex justify-center items-center h-full'>
                  <div className='w-full pa-4'>
                    {overviewWidgetData.isLoading ? (
                      <div className=' flex flex-col justify-center items-center gap-2'>
                        <Skeleton.Node active={true}>
                          <DotChartOutlined
                            style={{
                              fontSize: 40,
                              color: "#bfbfbf"
                            }}
                          />
                        </Skeleton.Node>
                        <h1 style={{ color: "#bfbfbf" }}>{t("loading")}</h1>
                      </div>
                    ) : overviewWidgetData.isError ? (
                      <div>{t("errorLoadingData")}</div>
                    ) : (
                      <GmsTmsRecordsGraphs
                        gmsTmsData={
                          overviewWidgetData.data?.data?.overviewData
                            ?.GMSTMSCompletionRate
                        }
                      />
                    )}
                  </div>
                </div>
              </LazyLoad>
            </div>
          </div>

          <div className='w-full grid grid-cols-12 gap-10 grid-parent overview-top p-5'>
            <div className='col-span-12 sm:col-span-4'>
              <Stats
                icon={openGMs}
                title={t("openGrievances")}
                stat={
                  overviewWidgetData?.data?.data?.overviewData
                    ?.grievanceIsInOpenState || 0
                }
              />
            </div>
            <div className='col-span-12 sm:col-span-4'>
              <Stats
                icon={progressGMS}
                title={t("grievancesInProgress")}
                stat={
                  overviewWidgetData?.data?.data?.overviewData
                    ?.grievanceIsInProgress || 0
                }
              />
            </div>
            <div className='col-span-12 sm:col-span-4'>
              <Stats
                icon={delayedGMS}
                title={t("grievancesDelayed")}
                stat={
                  overviewWidgetData?.data?.data?.overviewData
                    ?.grievanceIsDelayed || 0
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
