import formatAgeNoOfPeopleValues from "./FormattedValues/formattedAgeNoOfPeopleValues";
import formatGenderValues from "./FormattedValues/formattedGenderValue";

import formatCommunityValues from "./FormattedValues/formatCommunityValues";
import formatGenderCommunityValues from "./FormattedValues/formattedGenderCommunityValues";
import formatAgeGenderValues from "./FormattedValues/formattedAgeGenderValues";
import formatAgeCommunityValues from "./FormattedValues/formattedAgeCommunityValues";
export const getGraphData = (selectedOption, Data, fetchedData = {}) => {
  switch (selectedOption) {
    case "age":
      return formatAgeNoOfPeopleValues(
        fetchedData?.demographyData?.normalDemoGraphy?.age || []
      );

    case "gender":
      return formatGenderValues(
        fetchedData?.demographyData?.normalDemoGraphy?.gender || []
      );
    case "community":
      return formatCommunityValues(
        fetchedData?.demographyData?.normalDemoGraphy?.community || []
      );

    case "ageGender":
      return formatAgeGenderValues(
        fetchedData?.demographyData?.ageVsGender || []
      );
    case "ageCommunity":
      return formatAgeCommunityValues(
        Data?.demographyData.ageVsCommunity || []
        // fetchedData?.demographyData?.ageVsCommunity
      );
    case "ganderCommunity":
      return formatGenderCommunityValues(
        Data?.demographyData?.genderVsCommunity || []
        // fetchedData?.demographyData?.genderVsCommunity
      );

    default:
      return [];
  }
};
