import React, { useEffect, useContext } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Pagination,
  message,
  Spin
} from "antd";
import "../../Styles/user.css";
import PageHeading from "../../Components/PageHeading";
import SearchFilter from "../../Components/SearchFilter";
// import axios from "axios";
import UserTabs from "../../Components/assetsManagement/UserTabs";
import { userContext } from "../../context/UserContextProvider";
// import OfficeDataTable from "../../Components/assetsManagement/OfficeDataTable";
// import { AppContext } from "../../context/AppContextProvider";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

const Users = () => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();

  const {
    inputValue,
    setInputValue,
    showModal,
    setShowModal,
    addNewOffice,
    setAddNewOffice,
    openModal,
    handleCancel,
    currentPage,
    setCurrentPage,
    limit,
    setLimit,
    errors,
    setErrors,
    isLoading,
    setIsLoading
  } = useContext(userContext);

  const getOfficeData = () => {
    axiosInstance
      .get(`/api/clients/office/info?limit=10&page=1`)
      .then((response) => {
        console.log("response office inex--<", response);
        setAddNewOffice(response.data.data.results.reverse());
      });
  };

  // ADD NEW USER
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (isLoading) {
    //   return;
    // }
    setIsLoading(true);
    const formValid = validateForm(inputValue);
    if (!formValid) {
      return;
    }
    const newUserData = { ...inputValue, id: new Date().getTime().toString() };
    setTimeout(() => {
      axiosInstance
        .post("api/clients/office", newUserData)
        .then((response) => {
          setAddNewOffice([newUserData, ...addNewOffice]);

          // console.log("userData", response.data.data);
          setInputValue({
            officeName: "",
            officeLocation: ""
          });
          setShowModal(false);
          message.success("New office added successfully!");
          setErrors({});
          setIsLoading(false);
          // console.log("created", response);
        })
        .catch((error) => {
          // message.error("An error occurred. Please try again.");
          // setIsLoading(false);
          // console.error(error);
        });
      setShowModal(false);
    }, 1000);
  };
  const validateForm = (formData) => {
    const errors = {};
    let formValid = true;

    // Check required fields
    if (!formData.officeName && !formData.officeLocation) {
      message.error("Office name and location are required");
      formValid = false;
    } else {
      if (!formData.officeName) {
        message.error("Office name is required");
        formValid = false;
      }
      if (!formData.officeLocation) {
        message.error("Office location is required");
        formValid = false;
      }
    }
    setErrors(errors);
    return formValid;
  };

  const inputHandler = (e) => {
    const name = e.target.name;
    const value = name === "phone" ? parseInt(e.target.value) : e.target.value;
    setInputValue({ ...inputValue, [name]: value });
  };

  useEffect(() => {
    getOfficeData();
    // eslint-disable-next-line
  }, [currentPage, limit]);

  return (
    <>
      <div className='user-section m-5'>
        <PageHeading
          // pageTitle="Users"
          pageModalTitle='+ Add New Office'
          pageModalOpen={openModal}
        />

        {showModal && (
          <Modal
            className='user-modal-wrapper'
            open={showModal}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            width={1040}
            footer={[
              <Button
                key='modalFooterButton'
                type='button'
                className='modal-footer-button'
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Add New Office
              </Button>
            ]}
          >
            <Form>
              {isLoading && <Spin spinning={true} className='overlay' />}

              <div className='user-info-modal'>
                <div className='user-modal-header'>
                  <h3>Add New Office</h3>
                </div>
                <div className='user-modal-body'>
                  <h4>Office Details</h4>
                  <Row className='user-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Name</label>
                      <Input
                        type='text'
                        placeholder='Jhon'
                        value={inputValue.officeName}
                        name='officeName'
                        onChange={inputHandler}
                        required
                      />
                      {errors.officeName && (
                        <div className='text-red-500 text-sm'>
                          {errors.officeName}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className='user-modal-row'>
                    <Col span={16}>
                      <label htmlFor=''>Address</label>
                      <TextArea
                        rows={3}
                        name='officeLocation'
                        value={inputValue.officeLocation}
                        onChange={inputHandler}
                      />
                      {errors.officeLocation && (
                        <div className='text-red-500 text-sm'>
                          {errors.officeLocation}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          </Modal>
        )}

        <div className='user-table-wrapper'>
          <UserTabs />
          <SearchFilter />
          {/* <OfficeDataTable /> */}
        </div>
        <div className='client-pagination mx-5 '>
          <div>
            <Select
              value={limit.toString()}
              style={{ width: 56 }}
              onChange={(value) => setLimit(parseInt(value))}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "50", label: "50" },
                { value: "100", label: "100" }
              ]}
            />{" "}
            {t("Entries per pages")}
          </div>
          <div>
            <Pagination
              defaultCurrent={1}
              defaultPageSize={10}
              total={addNewOffice.length}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setLimit(pageSize);
              }}
              className='pagination'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
