import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
const useConstituencyData=(id) =>{
  const [constituencyData, setConstituencyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("client-token");
       const c_id = JSON.parse(localStorage.getItem("user")).constituencyId;

        const config = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}api/constituency/getConstituencyById/${c_id}`,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        };

        const response = await axios(config);
        const data = response.data.data;
        setConstituencyData(data);
        setLoading(false);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setError(error);
        setLoading(false);
      }
    };

    if (id && id !== 0) {
      fetchData();
    }
  }, [id]);
  return { loading, error, constituencyData };
}
export default useConstituencyData;