const formatAgeGenderValues = (ageVsGenderData) => {
  return Object.entries(ageVsGenderData).map(([ageGroup, values]) => {
    const totalPeople = values.reduce(
      (sum, { noOfPeople }) => sum + noOfPeople,
      0
    );

    let ageLabel;

    if (ageGroup === "65+") {
      ageLabel = "65 & above";
    } else if (ageGroup === "Unknown") {
      ageLabel = "Missing";
    } else {
      const age = ageGroup.split("-").map(Number);
      ageLabel = `${age[0]}-${age[1]}`;
    }

    const dataEntry = { age: ageLabel, Female: 0, Male: 0, Others: 0 };

    values.forEach(({ name, noOfPeople }) => {
      const percentage = ((noOfPeople / totalPeople) * 100).toFixed(2);
      dataEntry[name] = parseFloat(percentage);
    });

    return dataEntry;
  });
};

export default formatAgeGenderValues;
