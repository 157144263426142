import React from "react";
import Chart from "react-apexcharts";
import NoDataIcon from "../../../../assets/svg/no-data.svg";
import engagementHelper from "../../utils/engagementHelper";
import { useTranslation } from "react-i18next";

const colors = ["#004877", "#C5DEFF"];

const EngagementSection = ({ engagedData, platform }) => {
  const { t } = useTranslation();
  const engagedDataSocial = engagementHelper(engagedData, platform);

  // Extracting values with fallback to 0 if undefined or null
  const followersCount = +engagedDataSocial?.followerEngagement || 0;
  const nonFollowersCount = +engagedDataSocial?.nonFollowerEngagement || 0;
  const totalLikes = engagedDataSocial?.totalLikes || 0;
  const totalEngagedUsers = engagedDataSocial?.totalEngagedUsers || 0;

  const optionsDonut = {
    chart: {
      type: "donut"
    },
    states: {
      hover: {
        filter: {
          type: "none"
        }
      },
      active: {
        filter: {
          type: "none"
        }
      }
    },
    labels: [t("Followers"), t("Non-followers")],
    colors: colors,
    legend: {
      show: true,
      position: "bottom"
    }
  };

  const seriesDonut = [followersCount, nonFollowersCount];

  return (
    <div className='engagement-section'>
      <div className='donut-chart-labels flex justify-around items-center mb-4'>
        <div className='overview-heading'>
          <h1 className='overview-text text-[#004877] text-lg font-bold'>
            {t("Engagement")}
          </h1>
        </div>
        <div className='text-center'>
          <span className='donut-chart-label' style={{ color: colors[0] }}>
            {t("Followers Rate")}:
          </span>{" "}
          {followersCount}
        </div>
        <div className='text-center'>
          <span className='donut-chart-label' style={{ color: colors[0] }}>
            {t("Non-followers Rate")}:
          </span>{" "}
          {nonFollowersCount}
        </div>
      </div>
      {followersCount === 0 && nonFollowersCount === 0 ? (
        <div className='flex justify-center items-center h-64'>
          <img src={NoDataIcon} alt='No data' className='w-32 h-32' />
          <p className='text-center text-gray-500'>
            {t("No engagement data available")}.
          </p>
        </div>
      ) : (
        <Chart
          options={optionsDonut}
          series={seriesDonut}
          type='donut'
          width='100%'
          height='300'
        />
      )}

      <hr className='mt-4' />
      <div className='flex justify-between items-center mt-4'>
        <div className='text-center'>
          <h2 className='font-semibold'>{t("Post Interaction")}</h2>
        </div>
        <div className='text-center'>
          <h1 className='text-xl font-bold'>{totalLikes}</h1>
          <h2 className='text-gray-600'>{t("Likes")}</h2>
        </div>
        <div className='text-center'>
          <h1 className='text-xl font-bold'>{totalEngagedUsers}</h1>
          <h2 className='text-gray-600'>{t("Total Engaged Users")}</h2>
        </div>
      </div>
    </div>
  );
};

export default EngagementSection;
