import React from "react";
import { Row, Col, Select, Button, Divider } from "antd";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const FilterContent = ({ t, newGrievanceData, setNewGrievanceData }) => {
  const DurationOptions = [
    { value: "All", label: t("All") },
    { value: "Past Week", label: t("Past Week") },
    { value: "Past Month", label: t("Past Month") },
    { value: "Past Quarter", label: t("Past Quarter") },
    { value: "Past Year", label: t("Past Year") }
  ];
  return (
    <>
      <div className='filter-container-body'>
        <div className='mb2'>
          <Row>
            <Col span={24}>
              <label htmlFor=''>{t("Duration")}</label>
              <br />
              <Select
                style={{
                  height: "40px",
                  marginTop: "5px"
                  //   width: 300
                }}
                name='duration'
                value={newGrievanceData?.duration}
                disabled={newGrievanceData?.dateRange}
                onChange={(value) => {
                  setNewGrievanceData({
                    ...newGrievanceData,
                    ["duration"]: value
                  });
                }}
                placeholder={t("select")}
              >
                {DurationOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Divider>{t("OR")}</Divider>
          <Row>
            <Col span={24}>
              <label htmlFor=''>{t("Date Range")}</label>
              <br />

              <RangePicker
                name='dateRange'
                style={{
                  width: 316,
                  height: "40px",
                  marginTop: "5px"
                }}
                value={newGrievanceData?.dateRangeToShow}
                disabled={!!newGrievanceData?.duration}
                format={dateFormat}
                onChange={(date, dateString) => {
                  setNewGrievanceData((prevState) => ({
                    ...prevState,
                    dateRangeToShow: date
                  }));
                  setNewGrievanceData((prevState) => ({
                    ...prevState,
                    dateRange: dateString
                  }));
                }}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className='filter-container-footer flex justify-end '>
        <Button
          className='search-by'
          style={{ width: "auto", background: "#004877", color: "white" }}
          onClick={() => {
            setNewGrievanceData({});
          }}
        >
          {t("Reset")}
        </Button>
      </div>
    </>
  );
};

export default FilterContent;
