import React from "react";
import HighPriority from "../../assets/images/icon/high.png";
import MediumPriority from "../../assets/images/icon/medium.png";
import LowPriority from "../../assets/images/icon/low.png";
import Completed from "../../assets/images/icon/completed.png";
import PendingIcon from "../../assets/images/icon/pending.png";
import AssignedIcon from "../../assets/images/icon/assigned.png";
import Unassigned from "../../assets/images/icon/Unassigned.png";
import Inprogess from "../../assets/images/icon/inpgress.png";
import { useQuery } from "react-query";
import "../../Styles/tms.css";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useTranslation } from "react-i18next";
const GmsStats = ({
  setStatusClick = () => {},
  statusClick,
  setPriorityClick = () => {},
  priorityClick
}) => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();

  const fetchGmsSummary = async () => {
    const response = await axiosInstance.get(
      `api/grievances/getGrievanceSummary`
    );
    const gmsSummary = response?.data?.data;
    return gmsSummary;
  };
  const gmsPriorityData = useQuery(["gmsSummary"], fetchGmsSummary, {
    refetchOnWindowFocus: false
  });
  // Access the priority counts from the API response based on _id
  const priorityCounts = {};
  gmsPriorityData?.data?.forEach((priority) => {
    const priorityId = priority?._id;
    const count = priority?.count;
    priorityCounts[priorityId] = count;
  });

  // gms status summary

  const fetchGmsStatusSummary = async () => {
    const response = await axiosInstance.get(
      `api/grievances/getGrievanceStatusSummary`
    );
    const gmsStatus = response?.data?.data;
    return gmsStatus;
  };
  const gmsSummaryData = useQuery(["gmsStatus"], fetchGmsStatusSummary, {
    refetchOnWindowFocus: false
  });

  const statusCounts = {
    unassigned: 0,
    assigned: 0,
    inProgress: 0,
    pendingApproval: 0,
    complete: 0
  };

  gmsSummaryData?.data?.forEach((status) => {
    const statusId = status?._id;
    const statusCount = status?.count;
    statusCounts[statusId] = statusCount;
  });
  return (
    <>
      <div className='container-fluid w-full grid grid-cols-12 gap-10 grid-parent'>
        <div className='col-span-12 sm:col-span-5 tms-stats-col'>
          <div className='grid grid-cols-12 grid-child gap-8'>
            <button
              type='button'
              className={`col-span-4 grid-child-item priority-stats ${
        priorityClick === "high" ? "bg-active-card" : "bg-white"
      }`}              onClick={() =>
                priorityClick === "high"
                  ? setPriorityClick(null)
                  : setPriorityClick("high")
              }
            >
              <img src={HighPriority} alt='priority icon' />
              <h6>{priorityCounts["high"] || 0}</h6>
              <div
                className={`di ${
                  priorityClick === "high"
                    ? "text-blue-500"
                    : "text-rgba(0, 0, 0, 0.5)"
                }`}
              >
                {t("High")}
              </div>
            </button>

            <button
       className={`col-span-4 grid-child-item priority-stats ${
        priorityClick === "medium" ?"bg-active-card" : "bg-white"
      }`}  
                  onClick={() =>
                priorityClick === "medium"
                  ? setPriorityClick(null)
                  : setPriorityClick("medium")
              }
            >
              <img src={MediumPriority} alt='priority icon' />
              <h6>{priorityCounts["medium"] || 0}</h6>
              <div
                className={`di ${
                  priorityClick === "medium"
                    ? "text-blue-500"
                    : "text-rgba(0, 0, 0, 0.5)"
                }`}
              >
                {t("Medium")}
              </div>
            </button>
            <button
       className={`col-span-4 grid-child-item priority-stats ${
        priorityClick === "low" ?"bg-active-card" : "bg-white"
      }`}              onClick={() =>
                priorityClick === "low"
                  ? setPriorityClick(null)
                  : setPriorityClick("low")
              }
            >
              <img src={LowPriority} alt='priority icon' />
              <h6>{priorityCounts["low"] || 0}</h6>
              <div
                className={`di ${
                  priorityClick === "low"
                    ? "text-blue-500"
                    : "text-rgba(0, 0, 0, 0.5)"
                }`}
              >
                {t("Low")}
              </div>
            </button>
          </div>
        </div>

        <div className='col-span-12 sm:col-span-7 tms-stats-col'>
          <div className='grid grid-cols-5 md:grid-cols-8 grid-child status-stats'>
            <button
              type='button'
              className={`col-span-1 md:col-span-2  grid-child-item status-item1
               ${statusClick === "unassigned" ? "bg-active-card" : ""}`}  
                onClick={() =>
                statusClick === "unassigned"
                  ? setStatusClick(null)
                  : setStatusClick("unassigned")
              }
            >
              <img src={Unassigned} alt='unassigned icon' />
              <h6>{statusCounts["unassigned"]}</h6>
              <div
                className={`di ${
                  statusClick === "unassigned"
                    ? "text-blue-500"
                    : "text-rgba(0, 0, 0, 0.5)"
                }`}
              >
                {t("unassigned")}
              </div>
            </button>

            <button
              type='button'
              className={`col-span-1 md:col-span-2  grid-child-item status-item2
               ${statusClick === "assigned" ? "bg-active-card" : ""}`}        
                     onClick={() =>
                statusClick === "assigned"
                  ? setStatusClick(null)
                  : setStatusClick("assigned")
              }
            >
              <img src={AssignedIcon} alt='assigned icon' style={{filter:"var(--assigned-status)"}}/>
              <h6>{statusCounts["assigned"]}</h6>
              <div
                className={`di ${
                  statusClick === "assigned"
                    ? "text-blue-500"
                    : "text-rgba(0, 0, 0, 0.5)"
                }`}
              >
                {t("assigned")}
              </div>
            </button>

            <button
              type='button'
              className={`col-span-1 md:col-span-2  grid-child-item status-item3
               ${statusClick === "inProgress" ? "bg-active-card" : " "}`}    
                onClick={() =>
                statusClick === "inProgress"
                  ? setStatusClick(null)
                  : setStatusClick("inProgress")
              }
            >
              <img src={Inprogess} alt='inprogress icon' />
              <h6>{statusCounts["inProgress"]}</h6>
              <div
                className={`di ${
                  statusClick === "inProgress"
                    ? "text-blue-500"
                    : "text-rgba(0, 0, 0, 0.5)"
                }`}
              >
                {t("inProgress")}
              </div>
            </button>

            {/* <button
              type='button'
              className={`col-span-1 md:col-span-2  grid-child-item status-item4
               ${statusClick === "pendingApproval" ? "bg-active-card" : " "}`}   
                           onClick={() =>
                statusClick === "pendingApproval"
                  ? setStatusClick(null)
                  : setStatusClick("pendingApproval")
              }
            >
              <img src={PendingIcon} alt='pending Approval icon' />
              <h6>{statusCounts["pendingApproval"]}</h6>
              <div
                className={`di ${
                  statusClick === "pendingApproval"
                    ? "text-blue-500"
                    : "text-rgba(0, 0, 0, 0.5)"
                }`}
              >
                {t("approvalPending")}
              </div>
            </button> */}

            <button
              type='button'
              className={`col-span-1 md:col-span-2  grid-child-item status-item5
               ${statusClick === "complete" ? "bg-active-card" : " "}`}   
                onClick={() =>
                statusClick === "complete"
                  ? setStatusClick(null)
                  : setStatusClick("complete")
              }
            >
              <img src={Completed} alt='complete icon' />
              <h6>{statusCounts["complete"]}</h6>
              <div
                className={`di ${
                  statusClick === "complete"
                    ? "text-blue-500"
                    : "text-rgba(0, 0, 0, 0.5)"
                }`}
              >
                {t("completed")}
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default GmsStats;
