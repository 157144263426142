




import React, { useState, useEffect } from "react";
import { ResponsivePie } from '@nivo/pie';
// import Icon1 from "../../../assets/svg/userinfo1.svg";
// import Icon2 from "../../../assets/svg/userinfo2.svg";
// import { useQuery } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";


const SummaryPieChart = ({ userId }) => {
    const [chartData, setChartData] = useState([]);
    const axiosInstance = useAxiosInstance();

    const fetchTaskSummaryByUserId = async () => {
        try {
            const response = await axiosInstance.get(`api/task/getTasksStatusSummaryByUserId/${userId.userId}`);
            const apiData = response.data.data;
            const statusShow = ['assigned', 'inProgress', 'complete'];

            const formattedData = statusShow.map((status) => {
                const item = apiData.find((item) => item._id === status);

                let label = " "; // Custom label
                if (status === "assigned") {
                    label = "Task Not Started";
                }
                else if (status === "inProgress") {

                    label = "Task In-progress";
                }

                // else if (status === "pendingApproval") {
                //   label = "Task pending Approval";
                //  } 

                else if (status === "complete") {

                    label = "Task Completed";
                }
                const count = item ? item.count : 0;
                return {
                    id: status,
                    label: `${label} (${count})`,
                    value: count,
                };
            });

            setChartData(formattedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchTaskSummaryByUserId();  
      // eslint-disable-next-line
    }, []);

    // Calculate the ratio
    const assignedCount = chartData.find((data) => data.id === "assigned")?.value || 0;
    const completeCount = chartData.find((data) => data.id === "complete")?.value || 0;
    const ratio = completeCount !== 0 ? assignedCount / completeCount : 0;
   
    // console.log("ratio", ratio);

    const data = [

        {
            "id": "Not Started",
            "label": "Grievance Not Started (0) ",
            "value": 0,
            // "color": "#004877"
        },
        {
            "id": "In-progress",
            "label": "Grievance In-progress (0)",
            "value": 0,
            // "color": " #E67E22"
        },
        {
            "id": "Completed",
            "label": "Grievance Completed (0)",
            "value": 0,
            // "color": "#27AE60"
        },

    ]
    const colors = ["#E5E5E5", "#06CCF3", "#004877"];
    return (
        <>
            <div className="container-fluid w-full grid grid-cols-12 gap-8 grid-parent userinfo-charts">
                <div className="col-span-12 sm:col-span-6 tms-stats-col charts">
                    <div className="flex chart-title">
                        <div className="img-wrapper">
                        {/* <img src={Icon1} alt="img" /> */}
                        </div>
                        <div>
                            <h6>0:0</h6>
                            <p>Grievance assigned to completion ratio</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 grid-child">
                        <div style={{ height: "220px",marginTop:"30px" }}>
                            <ResponsivePie
                                data={data}
                                colors={colors}
                                margin={{ top: 20, right: 90, bottom: 80, left: 10 }}
                                innerRadius={0.75}
                                padAngle={0.7}
                                activeOuterRadiusOffset={8}
                                // borderWidth={1}
                                // borderColor={{
                                //     from: 'color',
                                //     modifiers: [['darker', 0.2]]
                                // }}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="none"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={null}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                    from: 'color',
                                    modifiers: [['darker', 2]]
                                }}
                                fill={(datum) => datum.data.color}

                                legends={[
                                    {
                                        anchor: 'right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 0,
                                        translateY: 56,
                                        itemsSpacing: 8,
                                        itemWidth: 100,
                                        itemHeight: 18,
                                        itemTextColor: '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 10,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-span-12 sm:col-span-6 tms-stats-col charts">
                    <div className="flex chart-title">
                        <div className="img-wrapper">
                        {/* <img src={Icon2} alt="img" /> */}
                        </div>
                        <div>
                            <h6>{`${assignedCount}:${completeCount}`}</h6>
                            <p>Task assigned to completion ratio</p>
                        </div>
                    </div>

                    {/* task summary */}
                    <div className="grid grid-cols-1 grid-child">
                        <div style={{ height: "220px", marginTop:"30px" }} >

                            <ResponsivePie
                                data={chartData}
                                colors={colors}
                                margin={{ top: 20, right: 90, bottom: 80, left: 10 }}
                                innerRadius={0.75}
                                padAngle={0.7}
                                activeOuterRadiusOffset={8}
                                // borderWidth={1}
                                // borderColor={{
                                //     from: 'color',
                                //     modifiers: [['darker', 0.2]]
                                // }}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="none"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={null}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                    from: 'color',
                                    modifiers: [['darker', 2]]
                                }}
                                fill={(datum) => datum.color}
                                legends={[
                                    {
                                        anchor: 'right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 0,
                                        translateY: 56,
                                        itemsSpacing: 8,
                                        itemWidth: 100,
                                        itemHeight: 18,
                                        itemTextColor: '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 10,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SummaryPieChart;

