import React, { useState, useEffect } from 'react'
import useAxiosInstance from '../../lib/useAxiosInstance';
import { useQuery, useQueryClient } from 'react-query';
import SearchFilter from '../SearchFilter';
import CustSupportDataTable from "../CustomerSupport/CustSupportDataTable";
import { Pagination, Select } from 'antd';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';



const CustSupportList = ({ activeTab,  setSelectedTicket, customerSupportData ,total,limit,setLimit,currentPage,setCurrentPage}) => {
    const { t } = useTranslation();
    const axiosInstance = useAxiosInstance();
    const queryClient = useQueryClient();
    const [searchKeyword, setSearchKeyword] = useState("");

    // GET ALL CUSTOMER SUPPORT DATA
    // const fetchCustomerData = async (keyword) => {
    //     const response = await axiosInstance.get(
    //         `api/tickets/filter?field=ticketStatus&keyword=${keyword}page=${currentPage}&limit=${limit}`,
    //     );
    //     setTotal(response.data.totalpages)
    //     return response.data
    // };
    // const customerSupportData = useQuery(["customerData", selectedTicket, currentPage, limit], () =>
    //     fetchCustomerData(selectedTicket, currentPage, limit)
    // );

    // console.log('DATA', customerSupportData)

    // SEARCH USER
    const fetchSearchTickets = async () => {
        let response;
        if (searchKeyword) {
            response = await axiosInstance.get(
                `api/tickets/search?keyword=${searchKeyword}`
            );
        }
        return response;
    };

    const ticketSearchData = useQuery(['allSearchTickets', searchKeyword],
        () => fetchSearchTickets(),

    );


    const SearchUser = (value) => {
        setCurrentPage(1);
        setSearchKeyword(value);
    };

    useEffect(() => {
        if (searchKeyword) {
            queryClient.invalidateQueries(['allSearchTickets', searchKeyword]);
        }
    }, [queryClient, searchKeyword]);

    // DELETE USER
    const handleDelete = async (record) => {
        try {
            const response = await axiosInstance.delete(`api/tickets/${record._id}`);
            const message = response?.data?.message;
            toast.success(`${message}`);
            queryClient.invalidateQueries("customerData");
            queryClient.invalidateQueries(["allTickets"]);
            queryClient.invalidateQueries(["unassignedTickets"]);
            queryClient.invalidateQueries(["pendingTickets"]);
            queryClient.invalidateQueries(["resolvedTickets"]);

        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Something went wrong.");
            }
        }
    };


    // FOR PAGINATION
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(1);
        setLimit(pageSize);
    };

    const pagination = {
        total: total * limit,
        pageSize: limit,
        current: currentPage,
        onChange: handlePageChange,
    };


    return (
        <div>


            <div className="user-table-wrapper cust-table pt-4">
                <SearchFilter handleSearch={SearchUser} />

                <CustSupportDataTable
                    customerSupportData={customerSupportData}
                    ticketSearchData={ticketSearchData?.data}
                    pagination={pagination}
                    // selectedTicket={selectedTicket}
                    handleDelete={handleDelete}
                    activeTab={activeTab}
                    setSelectedTicket={setSelectedTicket}
                />

                <div className="client-pagination mx-5">
                    <div className="pagination-text">
                        {t("Showing")}
                        <Select className='mx-2' value={limit.toString()} style={{ width: 56 }}
                            onChange={(value) => {
                                setLimit(parseInt(value));
                                setCurrentPage(1);
                            }}

                            options={[
                                { value: "10", label: "10" },
                                { value: "20", label: "20" },
                                { value: "50", label: "50" },
                                { value: "100", label: "100" },
                            ]}
                        />
                        <span> {t("Entries per pages")}</span>
                    </div>

                    <div>
                        <Pagination className="pagination" current={currentPage}
                            pageSize={limit}
                            total={total * limit}
                            onChange={(page, pageSize) => {
                                setCurrentPage(page);
                                setLimit(pageSize);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustSupportList