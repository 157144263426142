import React from "react";
import { ResponsiveBar } from "@nivo/bar";

import NoDataIcon from "../../../../assets/svg/no-data.svg";
import { useTranslation } from "react-i18next";

const GenderChart = ({ isDataAvailable, graphData }) => {
  const { t } = useTranslation();
  const sanitizedGraphData = graphData.map((item) => ({
    ...item,
    "No of People": isNaN(item["No of People"]) ? 0 : item["No of People"]
  }));

  const theme = {
    axis: {
      ticks: {
        text: {
          fontSize: 11,
          fill: `var(--stat-text)`,
          fontWeight: 400
        }
      },
      legend: {
        text: {
          fontSize: 11,
          fill: `var(--stat-text)`,
          fontWeight: 400
        }
      }
    }
  };
  return (
    <div className='revenue-map-chart' style={{ height: "300px" }}>
      {isDataAvailable ? (
        <ResponsiveBar
          data={sanitizedGraphData}
          keys={["No of People"]}
          indexBy='gender'
          margin={{ top: 10, right: 5, bottom: 40, left: 80 }}
          padding={0.3}
          layout='horizontal'
          colors={["#3BBEE8"]} // Adjust color as needed
          borderColor={{ from: "color", modifiers: [["darker", "1.6"]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 0,
            tickRotation: 0,
            legend: "No. of People (%)",
            legendPosition: "middle",
            legendOffset: 30,
            format: (value) => `${value}%`
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Gender",
            legendPosition: "middle",
            legendOffset: -70
          }}
          theme={theme}
          enableGridY={false}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[]}
          tooltip={({ id, value }) => (
            <strong>{`${id}: ${value.toFixed(2)}%`}</strong>
          )}
        />
      ) : (
        <div className='no-data-icon'>
          <img src={NoDataIcon} alt='' />
          <h1>{t("No Data Available")}</h1>
        </div>
      )}
    </div>
  );
};

export default GenderChart;
