import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const availableLanguages = ["marathi", "en-US", "chi", "hi"];
// const option = {
//   order: ["navigator", "htmlTag", "path", "subdomail"],
//   checkWhitelist: true
// };

// Retrieve selected language from local storage or fallback to "en"
const storedLanguage = localStorage.getItem("selectedLanguage") || "en-US";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: storedLanguage, // Use storedLanguage as fallback
    debug: true,
    whitelist: availableLanguages,
    // detection: option
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
