import highPriority from "../assets/images/icon/high-priority.png";
import mediumPriority from "../assets/images/icon/medium-priority.png";
import lowPriority from "../assets/images/icon/low-priority.png";
import unAssigned from "../assets/images/icon/statusIcon/unAssigned.svg";
import assigned from "../assets/images/icon/statusIcon/assigned.svg";
import inProgress from "../assets/images/icon/statusIcon/inProgress.svg";
import pendingApproval from "../assets/images/icon/statusIcon/pendingApproval.svg";

const statusIcons = {
  unassigned: unAssigned,
  assigned: assigned,
  inProgress: inProgress,
  pendingApproval: pendingApproval
};
export { statusIcons };
