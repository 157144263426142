import axios from "axios";

const geocodeAddress = async (address) => {
  const response = await axios.get(
    "https://maps.googleapis.com/maps/api/geocode/json",
    {
      params: {
        address: address,
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      }
    }
  );

  if (response.data.status === "OK") {
    const { lat, lng } = response?.data?.results[0]?.geometry?.location;
    return { lat, lng };
  } else {
    console.error(`Geocoding error: ${response?.data?.status}`);
    return null;
  }
};
export default geocodeAddress;
