import React, { useContext, useEffect } from "react";
import { Modal, Input, Select, Row, Col } from "antd";
import "../../../Styles/user.css";
import { useQuery, useMutation, useQueryClient } from "react-query";
import OfficeDropDown from "./OfficeDropDown";
import ConstituencyDropDown from "./ConstituencyDropDown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../../context/AppContextProvider";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import AssetsManagementContext from "../../../context/assetsManagementContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../Forms/ErrorMessage";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const ClientUser = ({ isOpen, isAddUserMode }) => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();
  const assetsContext = useContext(AssetsManagementContext);
  const queryClient = useQueryClient();

  const FormSchema = Yup.object().shape({
    prefix:Yup.string().required("Name prefix is required"),
    firstName: Yup.string()
      .trim()
      .required("First Name is required")
      .min(2, "First Name is minimum 2 character")
      .max(50, "First Name is maximum 50 character"),
    lastName: Yup.string()
      .trim()
      .required("Last Name is required")
      .min(2, "Last Name is minimum 2 character")
      .max(50, "Last Name is maximum 50 character"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Valid email is required")
      .matches(
        "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
        "Valid email is required"
      )
      .min(10, "Email is minimum 10 character")
      .max(100, "Email is maximum 100 character"),
    phone: Yup.string()
      .required("Phone is required")
      .min(10, "Phone must be 10 digit")
      .max(10, "Phone must be 10 digit"),
    password: Yup.string()
      .trim()
      .required("Password is required")
      .min(8, "Password is minimum 8 characters"),
    confirmPassword: Yup.string()
      .trim()
      .required("Please confirm password")
      .oneOf([Yup.ref("password"), null], "Password must match"),
    role: Yup.string().required("Role is required"),
    // constituencyId: Yup.string().required('Constituency is required'),
    // officeId: Yup.string().required('Office Name is required')
    reportsTo: Yup.string().required("Please select user")
  });

  const formik = useFormik({
    initialValues: {
      prefix: isAddUserMode ? "" : assetsContext.editUserData.prefix,
      firstName: isAddUserMode ? "" : assetsContext.editUserData.firstName,
      lastName: isAddUserMode ? "" : assetsContext.editUserData.lastName,
      email: isAddUserMode ? "" : assetsContext.editUserData.email,
      phone: isAddUserMode ? null : Number(assetsContext.editUserData.phone),
      role: isAddUserMode ? null : assetsContext.editUserData.role,
      password: isAddUserMode
        ? ""
        : assetsContext.editUserData.clientPassword || "",
      confirmPassword: isAddUserMode
        ? ""
        : assetsContext.editUserData.clientPassword || "",
      constituencyId: isAddUserMode
        ? null
        : assetsContext.editUserData.constituencyId,
      officeId: isAddUserMode ? null : assetsContext.editUserData.officeId,
      // status:isAddUserMode ? "" : assetsContext.editUserData.status,
      reportsTo: isAddUserMode ? "" : assetsContext.editUserData.reportsTo
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  // ADD NEW USER MODAL
  // const openModal = () => {
  //     assetsContext.updateOpenModal(true);
  // };
  const closeModal = () => {
    assetsContext.updateOpenModal(false);
    assetsContext.updateAddUserMode(true);
    formik.resetForm();
  };

  //fetch roles-->
  const fetchRoles = async () => {
    return axiosInstance.get("api/clients/getclientroles");
  };
  const rolesData = useQuery("client-roles", fetchRoles);
  // <--

  // Get Organizational Client Users List
  // const fetchClientList = async () => {
  //   return axiosInstance.get(`api/clients/info/organizationalUsersList`);
  // };
  // const reportsToList = useQuery("client-user-list", fetchClientList);

  const fetchClientList = async (role) => {
    return axiosInstance.get(
      `api/clients/info/organizationalUsersList?role=${role}`
    );
  };
  const reportsToList = useQuery("client-user-list", () =>
    fetchClientList(formik.values.role)
  );

  useEffect(() => {
    if (formik.values.role) {
      reportsToList.refetch();
    }
  }, [formik.values.role]);

  const handleSubmit = async (value) => {
    const data = {
      ...value,
      organisationId: user?.organisationId
      // status: "active",
    };
    if (isAddUserMode) {
      handleCreateUser.mutate(data);
    } else {
      handleUpdateUser.mutate(data);
    }
  };
  const handleCreateUser = useMutation(
    (formData) =>
      axiosInstance
        .post(`/api/clients/`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );
  const handleUpdateUser = useMutation(
    (formData) =>
      axiosInstance
        .put(
          `api/clients/clientUser/${assetsContext.editUserData._id}`,
          formData
        )
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );
  const handleSuccess = (data) => {
    queryClient.invalidateQueries("client-users");
    queryClient.invalidateQueries("allSearchUserData");
    closeModal();
    toast.success(`${data?.message}`);
  };
  const handleError = (error) => {
    if (error?.response?.data?.message) {
      let msg = error.response.data.message;
      if (msg.includes("email")) {
        formik.setFieldError("email", msg);
      } else if (msg.includes("phone")) {
        formik.setFieldError("phone", msg);
      } else {
        toast.error(error.response.data.message);
      }
    } else {
      toast.error("Something went wrong.");
    }
  };

  return (
    <React.Fragment>
      {/* <Form className="user-section"> */}

      {isOpen && (
        <Modal
          className='user-modal-wrapper'
          open={isOpen}
          onCancel={closeModal}
          width={960}
          maskClosable={false}
          footer={[
            <button
              key='modalButton'
              form='useradd-form'
              type='submit'
              className='modal-footer-button'
            >
              {isAddUserMode ? t("Add New User") : t("Save Changes")}
            </button>
          ]}
        >
          <form id='useradd-form' onSubmit={formik.handleSubmit}>
            <div className='user-info-modal'>
              <div className='modal-header'>
                <h3>{isAddUserMode ? t("Add New User") : t("Edit User")}</h3>
              </div>
              <div className='user-modal-body-wrapper'>
                <div className='user-modal-body'>
                  <h4>{t("User Information")}</h4>
                  <Row className='user-modal-row'>
                    <Col span={9} className='mr-6'>
                      <label htmlFor=''>{t("First Name")}</label>
                      <Input.Group compact className='client-first-name'>
                        <Select
                          placeholder="Mr."
                          style={{ width: '25%' }}
                          onChange={(value) => formik.setFieldValue('prefix', value)}
                          value={formik.values.prefix || undefined}
                        >
                          <Select.Option value="Mr.">Mr.</Select.Option>
                          <Select.Option value="Mrs.">Mrs.</Select.Option>
                          <Select.Option value="Ms.">Ms.</Select.Option>
                        </Select>
                        <Input
                          type='text'
                          name='firstName'
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                        />
                      </Input.Group>
                      <ErrorMessage
                        hasError={Boolean(
                          (formik.errors.firstName && formik.touched.firstName) || (formik.errors.prefix && formik.touched.prefix)
                        )}
                        message={formik.errors.firstName || formik.errors.prefix}
                      />
                    </Col>
                    <Col span={9}>
                      <label htmlFor=''>{t("Last Name")}</label>
                      <Input
                        type='text'
                        name='lastName'
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.lastName && formik.touched.lastName
                        )}
                        message={formik.errors.lastName}
                      />
                    </Col>
                  </Row>
                  <Row className='user-modal-row'>
                    <Col span={9} className='mr-6'>
                      <label htmlFor=''>{t("Email")}</label>
                      <Input
                        type='text'
                        name='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.email && formik.touched.email
                        )}
                        message={formik.errors.email}
                      />
                    </Col>
                    <Col span={9}>
                      <label htmlFor=''>{t("Phone")}</label>
                      <Input
                        type='number'
                        addonBefore='+91'
                        name='phone'
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.phone && formik.touched.phone
                        )}
                        message={formik.errors.phone}
                      />
                    </Col>
                  </Row>
                  <Row className='user-modal-row'>
                    <Col span={9} className='mr-6'>
                      <label htmfor=''>{t("Password")}</label>
                      <Input.Password
                        className='password'
                        type='password'
                        name='password'
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.password && formik.touched.password
                        )}
                        message={formik.errors.password}
                      />
                    </Col>
                    <Col span={9} className='mr-6'>
                      <label htmfor=''>{t("Confirm Password")}</label>
                      <Input.Password
                        className='password'
                        type='password'
                        name='confirmPassword'
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.confirmPassword &&
                          formik.touched.confirmPassword
                        )}
                        message={formik.errors.confirmPassword}
                      />
                    </Col>
                  </Row>
                  <Row className='user-modal-row'>
                    {/* use here switch statement */}
                    <Col span={9} className='mr-6'>
                      <label htmfor=''>{t("Role")}</label>
                      <Select
                        name='role'
                        value={formik.values.role}
                        onChange={(value) =>
                          formik.setFieldValue("role", value)
                        }
                        options={rolesData?.data?.data?.data?.map((e) => ({
                          value: e.name,
                          label: e.label
                        }))}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.role && formik.touched.role
                        )}
                        message={formik.errors.role}
                      />
                    </Col>
                    {(() => {
                      switch (formik.values.role) {
                        case "constituency_manager":
                          return <ConstituencyDropDown formik={formik} />;
                        case "client":
                          return null;
                        case "office_manager":
                          return <OfficeDropDown formik={formik} />;
                        case "cadre":
                          return <OfficeDropDown formik={formik} />;
                        case "data_entry_operator":
                          return <OfficeDropDown formik={formik} />;
                        case "chief_of_staff":
                          return null;
                        default:
                          return <OfficeDropDown formik={formik} />;
                      }
                    })()}

                    <Col span={9} className='mr-6'>
                      <label htmfor=''>{t("Reports To")}</label>
                      <Select
                        className='capitalize'
                        name='reportsTo'
                        value={formik.values.reportsTo}
                        onChange={(value) =>
                          formik.setFieldValue("reportsTo", value)
                        }
                      // options={reportsToList?.data?.data?.data?.map((e) => ({
                      //   value: `${e.firstName} ${e.lastName}`,
                      //   label: `${e.firstName} ${e.lastName}`
                      // }))}
                      >
                        {reportsToList?.data?.data?.data?.map((userList) => (
                          <Select.Option
                            key={userList._id}
                            value={userList._id}
                            className='capitalize'
                          >
                            {`${userList.firstName} ${userList.lastName}`}
                          </Select.Option>
                        ))}
                      </Select>
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.reportsTo && formik.touched.reportsTo
                        )}
                        message={formik.errors.reportsTo}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ClientUser;
