import React, { useState } from 'react'

const AssetsManagementContext = React.createContext({
    isOpenModal: false,
    updateOpenModal: () => {
    },

    isStatusModal: false,
    updateStatusModal: () => {
    },

    isAddMode: true,
    updateAddMode: () => {
    },

    editData: {},
    updateEditData: () => {
    },
    isAddUserMode: true,
    updateAddUserMode: () => {
    },
    editUserData: {},
    updateEditUserData: () => {
    },
});
export const AssetsContextProvider = ({ children }) => {

    const [isOpenModalState, setIsOpenModalState] = useState(false);
    const [isStatusModalState, setIsStatusModalState] = useState(false);
    const [isAddModeState, setIsAddModeState] = useState(true);
    const [editDataState, setEditDataState] = useState({});

    const contextValue = {
        isOpenModal: isOpenModalState,
        updateOpenModal: setIsOpenModalState,

        isStatusModal: isStatusModalState,
        updateStatusModal: setIsStatusModalState,

        isAddMode: isAddModeState,
        updateAddMode: setIsAddModeState,

        editData: editDataState,
        updateEditData: setEditDataState,

        isAddUserMode: isAddModeState,
        updateAddUserMode: setIsAddModeState,

        editUserData: editDataState,
        updateEditUserData: setEditDataState,

    };

    return <AssetsManagementContext.Provider value={contextValue}>{children}</AssetsManagementContext.Provider>;
}

export default AssetsManagementContext