import React, { useContext, useEffect, useState, useRef } from "react";
import { Space, Table } from "antd";
import Column from "antd/es/table/Column";
import { EllipsisOutlined } from "@ant-design/icons";
import DeleteModal from "../DeleteModal";
import CustSupportContext from "../../context/custSupportContext";
import { useTranslation } from "react-i18next";

const CustSupportDataTable = ({
  ticketSearchData,
  customerSupportData,
  pagination,
  handleDelete,
  activeTab
}) => {
  const { t } = useTranslation();

  const custSupportContext = useContext(CustSupportContext);

  const [openDropdown, setOpenDropdown] = useState([]);

  const dropdownRefs = useRef([]);

  const handleClick = (index) => {
    setOpenDropdown((prevOpenDropdown) => {
      const updatedOpenDropdown = [...prevOpenDropdown];
      updatedOpenDropdown[index] = !updatedOpenDropdown[index];
      return updatedOpenDropdown;
    });
  };

  const handleOutsideClick = (event) => {
    for (let i = 0; i < dropdownRefs.current.length; i++) {
      if (
        dropdownRefs.current[i] &&
        !dropdownRefs.current[i].contains(event.target)
      ) {
        setOpenDropdown((prevOpenDropdown) => {
          const updatedOpenDropdown = [...prevOpenDropdown];
          updatedOpenDropdown[i] = false;
          return updatedOpenDropdown;
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleEdit = (record) => {
    custSupportContext.updateEditData(record);
    custSupportContext.updateAddMode(false);
    custSupportContext.updateOpenModal(true);
  };

  // DELETE MODAL
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const showModalDelete = (record) => {
    setSelectedRecord(record);
    setDeleteModal(!isDeleteModal);
  };

  // Filter data based on the active tab
  const filteredData = () => {
    switch (activeTab) {
      case "all-tickets":
        return customerSupportData;
      case "open-tickets":
        return customerSupportData;
      case "pending-tickets":
        return customerSupportData;
      case "resolved-tickets":
        return customerSupportData;
      default:
        return [];
    }
  };

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        pagination={pagination}
        // dataSource={
        //     ticketSearchData ? ticketSearchData.data?.data?.results : selectedTicket ? selectedTicket : customerSupportData
        // }
        dataSource={
          ticketSearchData
            ? ticketSearchData.data?.data?.results
            : filteredData()
        }
      >
        <Column title={t("Ticket ID")} dataIndex='_id' />
        <Column title={t("Issue Name")} dataIndex='issueName' />
        <Column title={t("Description")} dataIndex='comment' />
        <Column title={t("Category")} dataIndex='category' />
        <Column title={t("Status")} dataIndex='ticketStatus' />
        <Column
          title={t("Date")}
          dataIndex='date'
          render={(text, record) => (
            <span>
              {new Date(record.createdAt).toLocaleDateString("en-GB")}
            </span>
          )}
        />

        <Column
          title={t("ACTION")}
          key='action'
          render={(text, record, index) => (
            <Space className='select-option' size='small'>
              <div
                className='dropdown'
                ref={(el) => (dropdownRefs.current[index] = el)}
              >
                <span onClick={() => handleClick(index)}>
                  <EllipsisOutlined />
                </span>
                <div
                  className='dropdown-content'
                  style={{ display: openDropdown[index] ? "block" : "none" }}
                >
                  <div onClick={() => handleEdit(record)}>{t("Edit")}</div>

                  <div onClick={() => showModalDelete(record)}>
                    {t("Delete")}
                  </div>
                </div>
              </div>
            </Space>
          )}
        />
      </Table>

      {isDeleteModal && (
        <DeleteModal
          textheading={t("Ticket")}
          deleteTitle={t("Ticket")}
          deleteBtn={t("Delete Ticket")}
          handleDelete={() => handleDelete(selectedRecord)}
          isDeleteModal={isDeleteModal}
          showModalDelete={() => setDeleteModal(false)}
        />
      )}
    </>
  );
};

export default CustSupportDataTable;
