import React, { useContext, useState } from "react";
import "../../Styles/settings.css";
import "../../Styles/CustomerSupport.css";
import CustSupportList from "./CustSupportList";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import useAxiosInstance from "../../lib/useAxiosInstance";
import PageHeading from "../../Components/PageHeading";
import CustSupportContext from "../../context/custSupportContext";
import assigned from "../../assets/images/cust/assigned.svg";
import pending from "../../assets/images/cust/pending.svg";
import resolved from "../../assets/images/cust/resolved.svg";
import allTickets from "../../assets/images/cust/alltickets.svg";
import { useTranslation } from "react-i18next";

const CustTabs = ({ activeTab, onTabChange }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const axiosInstance = useAxiosInstance();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState("DES");
  const [selectedTicket, setSelectedTicket] = useState("all");

  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    onTabChange(tab); // the tab change
    navigate(`../${tab}`); // sibling path
  };

  const custSupportContext = useContext(CustSupportContext);

  const handleModal = () => {
    custSupportContext.updateOpenModal(true);
  };

  // TICKET SUMMARY
  const fetchTicketSummary = async (keyword) => {
    const response = await axiosInstance.get(
      `api/tickets/filter?field=ticketStatus&keyword=${keyword}&size=${limit}&page=${currentPage}&sort=${sort}`
    );
    queryClient.invalidateQueries("allSearchTickets");
    setTotal(response.data.totalpages);
    return response.data;
  };
  const customerSupportData = useQuery(
    ["customerData", selectedTicket, currentPage, limit,sort],
    () => fetchTicketSummary(selectedTicket, currentPage, limit,sort)
  );

  const allTicketsData = useQuery(["allTickets"], () =>
    fetchTicketSummary("all")
  );
  // const assignedTicketsData = useQuery(["assignedTickets"], () => fetchTicketSummary("assigned"));
  const unassignedTicketsData = useQuery(["unassignedTickets"], () =>
    fetchTicketSummary("open")
  );
  const pendingTicketsData = useQuery(["pendingTickets"], () =>
    fetchTicketSummary("pending")
  );
  const resolvedTicketsData = useQuery(["resolvedTickets"], () =>
    fetchTicketSummary("resolved")
  );

  const allTicket = allTicketsData?.data?.TicketCount || 0;
  // const assignedTickets = assignedTicketsData?.data?.TicketCount || 0;
  const unassignedTickets = unassignedTicketsData?.data?.TicketCount || 0;
  const pendingTickets = pendingTicketsData?.data?.TicketCount || 0;
  const resolvedTickets = resolvedTicketsData?.data?.TicketCount || 0;

  return (
    <>
      <div>
        <PageHeading
          pageTitle={t("Customer Support")}
          pageModalTitle={t("+Create New Ticket")}
          pageModalOpen={handleModal}
          className='mb4'
        />
        <div>
          <div className='cust-supprt-section'>
            <div className='customer-support-stats'>
              <div className='w-full grid grid-cols-8 gap-12 grid-parent cust-suppr-btn pb-6'>
                <div className='col-span-12 sm:col-span-2'>
                  <div
                    className='p-3 cust-supprt-tabs flex flex-col items-center'
                    onClick={() => {
                      handleTabChange("all-tickets");
                      setSelectedTicket("all");
                      setCurrentPage(1);
                    }}
                  >
                    <div
                      className='cust-img-wrapper pl-2'
                      style={{ background: "#3BBEE8" }}
                    >
                      <img src={allTickets} alt='stat' />
                    </div>
                    <h2 className='mt-2'>{allTicket}</h2>
                    <p>{t("All Tickets")}</p>
                  </div>
                </div>

                <div className='col-span-12 sm:col-span-2'>
                  <div
                    className='p-3  cust-supprt-tabs flex flex-col items-center'
                    onClick={() => {
                      handleTabChange("open-tickets");
                      setSelectedTicket("open");
                      setCurrentPage(1);
                    }}
                  >
                    <div
                      className='cust-img-wrapper'
                      style={{ background: "var(--open-tickets)" }}
                    >
                      <img src={assigned} alt='stat' />
                    </div>

                    <h2 className='mt-2'>{unassignedTickets}</h2>
                    <p>{t("Open Tickets")}</p>
                  </div>
                </div>
                <div className='col-span-12 sm:col-span-2'>
                  <div
                    className='bg-white p-3 cust-supprt-tabs flex flex-col items-center'
                    onClick={() => {
                      handleTabChange("pending-tickets");
                      setSelectedTicket("pending");
                      setCurrentPage(1);
                    }}
                  >
                    <div
                      className='cust-img-wrapper'
                      style={{ background: "#E67E22" }}
                    >
                      <img src={pending} alt='stat' />
                    </div>
                    <h2 className='mt-2'>{pendingTickets}</h2>
                    <p>{t("Pending Tickets")}</p>
                  </div>
                </div>
                <div className='col-span-12 sm:col-span-2'>
                  <div
                    className='bg-white p-3 cust-supprt-tabs flex flex-col items-center'
                    onClick={() => {
                      handleTabChange("resolved-tickets");
                      setSelectedTicket("resolved");
                      setCurrentPage(1);
                    }}
                  >
                    <div
                      className='cust-img-wrapper'
                      style={{ background: "#27AE60" }}
                    >
                      <img src={resolved} alt='stat' />
                    </div>
                    <h2 className='mt-2'>{resolvedTickets}</h2>
                    <p>{t("Resolved Tickets")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='user-table-wrapper'>
          <div className='tab-content'>
            <div className='report-page-section'>
              <div className='report-table-wrapper'>
                {activeTab === "all-tickets" ||
                activeTab === "open-tickets" ||
                activeTab === "pending-tickets" ||
                activeTab === "resolved-tickets" ? (
                  <CustSupportList
                    activeTab={activeTab}
                    setSelectedTicket={setSelectedTicket}
                    customerSupportData={customerSupportData?.data?.data}
                    total={total} limit={limit} setLimit={setLimit}
                    currentPage={currentPage} setCurrentPage={setCurrentPage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustTabs;
