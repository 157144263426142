// processGeoData.js
import { isEmptyArray } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";

const useProcessGeoData = (polyData, isLoaded, setPolygonPaths) => {
  useEffect(() => {
    if (isLoaded) {
      if (isEmptyArray(polyData)) {
        // toast.error("data not available");
      }

      if (polyData[0]?.boundaries?.type === "FeatureCollection") {
        const featureGeoJson = polyData[0]?.boundaries?.features.map(
          (feature) => {
            let featureCoordinates = [];
            if (feature.geometry?.type === "MultiPolygon") {
              featureCoordinates = feature.geometry?.coordinates.flat(2);
            } else if (feature.geometry?.type === "Polygon") {
              featureCoordinates = feature.geometry?.coordinates[0];
            }

            return {
              // constituencyId: polyData[0]?._id || "",
              coordinate: featureCoordinates
                ? featureCoordinates.map((coord) => ({
                    lat: coord[1],
                    lng: coord[0]
                  }))
                : [],
              // name: feature?.properties?.WARD_NAME || "",
              // state: polyData[0]?.state,
              // district: polyData[0]?.name
            };
          }
        );
        setPolygonPaths(featureGeoJson);
      } else {
        const geoJSON = (Array.isArray(polyData) ? polyData : []).map(
          (item) => {
            let coordinates = [];
            const boundaries = item?.boundaries;
            if (boundaries?.type === "Polygon") {
              coordinates = boundaries?.coordinates[0];
            } else if (boundaries?.type === "MultiPolygon") {
              coordinates = boundaries?.coordinates.flat(2);
            } else if (boundaries?.type === "FeatureCollection") {
              coordinates = boundaries?.features.flatMap((feature) => {
                if (feature.geometry?.type === "MultiPolygon") {
                  return feature.geometry?.coordinates.flat(2);
                } else if (feature.geometry?.type === "Polygon") {
                  return feature.geometry?.coordinates[0];
                }
                return [];
              });
            }

            return {
              // constituencyId: item?._id || "",
              coordinate: coordinates
                ? coordinates.map((coord) => ({
                    lat: coord[1],
                    lng: coord[0]
                  }))
                : []
              // name: item?.name,
              // state: item?.state,
              // district: item?.district
            };
          }
        );
        setPolygonPaths(geoJSON);
      }
    }
  }, [polyData, isLoaded]);
};

export default useProcessGeoData;
