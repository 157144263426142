import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../Components/PageHeading";
import TmsStats from "./TmsStats";
import TmsList from "./TmsList";
import "../../Styles/tms.css";
import AddNewTask from "./AddNewTask";
import { Button } from "antd";
import { Popover } from "antd";

import { ToastContainer } from "react-toastify";
import TmsMapBox from "./MapBox";
import { useTranslation } from "react-i18next";
import FilterContent from "./FilterContent";

const Tms = () => {
  const { t } = useTranslation();
  const [priorityClick, setPriorityClick] = useState();
  const [statusClick, setStatusClick] = useState();
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [newGrievanceData, setNewGrievanceData] = useState({});
  const showAddTaskModal = () => {
    setAddTaskModal(true);
  };

  // get Task data

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("list");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    navigate(`/tms/${tabName}`);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "list":
        return (
          <TmsList
            setPriorityClick={setPriorityClick}
            priorityClick={priorityClick}
            statusClick={statusClick}
            setStatusClick={setStatusClick}
            newGrievanceData={newGrievanceData}
          />
        );
      case "map":
        return (
          <TmsMapBox
            priorityClick={priorityClick}
            statusClick={statusClick}
            newGrievanceData={newGrievanceData}
          />
        );
      default:
        return (
          <TmsList
            setPriorityClick={setPriorityClick}
            priorityClick={priorityClick}
            statusClick={statusClick}
            setStatusClick={setStatusClick}
          />
        );
    }
  };
  const renderFilter = () => {
    return (
      <FilterContent
        t={t}
        newGrievanceData={newGrievanceData}
        setNewGrievanceData={setNewGrievanceData}
      />
    );
  };

  return (
    <div className='gms-page-tabs m-5'>
      <PageHeading
        pageTitle={t("Task Management")}
        pageModalTitle={t("+ Add New Task")}
        pageModalOpen={showAddTaskModal}
        className='mb4'
      />
      <ToastContainer />
      <TmsStats
        setPriorityClick={setPriorityClick}
        priorityClick={priorityClick}
        statusClick={statusClick}
        setStatusClick={setStatusClick}
      />
      <div className='user-table-wrapper mt-5'>
        <div className='tabs-wrapper'>
          <nav className='tms-tabs'>
            <button
              className={activeTab === "list" ? "active" : ""}
              onClick={() => handleTabClick("list")}
            >
              {/* <BiListUl className="tms-icon" /> */}
              {t("List View")}
            </button>
            <button
              className={activeTab === "map" ? "active" : ""}
              onClick={() => handleTabClick("map")}
            >
              {/* <MdMap className="tms-icon" /> */}
              {t("Map View")}
            </button>
          </nav>

          <Popover
            content={renderFilter()}
            trigger='click'
            placement='bottomLeft'
            className='filter-button'
          >
            <Button
              key='add-constituency'
              form='constituency-form'
              type='submit'
              className='search-by mr-8'
              style={{
                width: "auto",
                color: "var(--primary-color)",
                border: "1px solid var(--primary-color)"
              }}
            >
              {t("Filter By")}
            </Button>
          </Popover>
        </div>

        <div className='tms-tab-content'>{renderTabContent()}</div>
      </div>

      {addTaskModal && (
        <AddNewTask
          showAddTaskModal={showAddTaskModal}
          addTaskModal={addTaskModal}
          setAddTaskModal={setAddTaskModal}
          setActiveTab={setActiveTab}
        />
      )}
    </div>
  );
};

export default Tms;
