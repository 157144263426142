import React, { useState } from "react";
import "../../Styles/forgotpassword.css";
import Logo from "../../assets/images/logo/PS-Logo 3.png";
import checkLogo from "../../assets/svg/check-decagram.svg";
import { useTranslation } from "react-i18next";
import LoginPageVideo from "../../assets/videos/login-page-video1.mp4"
import { useFormik } from "formik";
import * as Yup from "yup";
import useAxiosInstance from "../../lib/useAxiosInstance";
import ErrorMessage from '../Forms/ErrorMessage';
import { ToastContainer, toast } from "react-toastify";
import LazyLoad from "react-lazyload";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Valid email is required")
      .matches(
        "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
        "Valid email is required"
      )
      .min(10, "Email is minimum 10 character")
      .max(100, "Email is maximum 100 character")
  });

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const handleSubmit = async (value, onSubmitProps) => {
    setIsLoading(true);
    axiosInstance
      .post("api/clients/auth/requestResetPassword", value)
      .then((response) => {
        setIsSubmitted(true);
      })
      .catch((error) => {
        onSubmitProps.setFieldError("email", "Something went wrong. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleResendLink = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("api/clients/auth/requestResetPassword", {
        email: formik.values.email
      });
      // Handle success, show success message or navigate to a success page
      setIsSubmitted(true);
      const message = response.data.message;
      toast.success(message, {
        position: toast.POSITION.BOTTOM_LEFT
      })
    } catch (error) {
      // Handle error, show error message
      toast.error('Something went wrong. Please try again.', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="video-background">
        <LazyLoad height={200} once>
          <video id="video-bg" autoPlay muted loop >
            <source src={LoginPageVideo} type="video/mp4" />
          </video>
        </LazyLoad>
      </div>
      <div className='signup-page grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2'>
        <div className='logo forgotPage-logo'>
          <img className="block mx-auto" src={Logo} alt='Logo' />
        </div>
        <ToastContainer />
        {isSubmitted ? (
          <div className='get-in'>
            <div className="block mx-auto mb-3">
              <img src={checkLogo} alt='' />
            </div>
            <div className='sign-in-para text-center'>
              <p>{t("resetEmailSent")}</p>
            </div>
            <div className='sign-in-para text-center pt-10'>
              <p>
                {t("receivedMail?")} <span className="resend-link" onClick={handleResendLink} style={{ cursor: 'pointer' }}>{t("Resend")}</span> {t("Link")}
              </p>
            </div>
          </div>
        ) : (
          <div className='get-in'>
            <div className='text-content'>
              <h2>{t("Forgot Password")}</h2>
            </div>
            <div className='sign-in-para py-2'>
              <p>{t("enterEmail")}</p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className='flex flex-wrap'>
                <div className='py-2 w-full'>
                  <div className='mt-7'>
                    <label className='leading-7 text-lg text-gray-600'>
                      {t("Email")}
                    </label>
                    <input
                      type='email'
                      placeholder='E-mail'
                      id='email'
                      name='email'
                      className={`w-full rounded-lg text-base py-2 px-3 mt-2`}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.email && formik.touched.email
                      )}
                      message={formik.errors.email}
                    />
                  </div>
                </div>

                <div className='py-2 w-full'>
                  <div className='my-4'>
                    <button type="submit" className='flex mx-auto w-full text-white bg-sky-900 border-0 py-3 justify-center focus:outline-none  rounded-lg text-lg' disabled={isLoading}>
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
