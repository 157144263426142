import { Modal } from "antd";
import { t } from "i18next";
import React, { useContext } from "react";
import ConstituencyContext from "../../context/constituencyContext";
function formatYearFromDate(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();

  return year;
}
const ConstituencyInfo = ({
  isOpen,
  constituencyData,
  constituencyDataList
}) => {

  const constituencyContext = useContext(ConstituencyContext);
  const closeModal = () => {
    constituencyContext.updateOpenModal(false);
  };
  return (
    <>
      {isOpen && (
        <Modal
          className='reports-modal-wrapper'
          open={isOpen}
          width={1040}
          onCancel={closeModal}
          footer={false}
        >
          <div className='constituency-info'>
            <div className='reports-info-modal'>
              <div className='reports-modal-header'>
                <h3>{t("constituencyInformation")}</h3>
              </div>
            </div>
            <div className='reports-modal-body'>
              <div className='request-report'>
                <div className='basicInfoDiv'>
                  <div className='firstname-field'>
                    <p className='mb-6 ml-1'>
                      <span>{t("constituencyID")}</span>
                      <span style={{ textTransform: "capitalize" }}>
                        {constituencyData?._id}
                      </span>
                    </p>
                    <p className='mb-6 ml-1'>
                      <span>{t("state")}</span>
                      <span>{constituencyData?.state}</span>
                    </p>
                  </div>
                </div>
                <div className='basicInfoDiv'>
                  <div className='firstname-field'>
                    <p className='mb-6 ml-1'>
                      <span>{t("district")}</span>
                      <span>{constituencyData?.district}</span>
                    </p>
                    <p className='mb-6 ml-1'>
                      <span>{t("establishedYear")}:</span>
                      <span>
                        {formatYearFromDate(
                          constituencyData?.establishedYear
                        ) || "-"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className='basicInfoDiv'>
                  <div className='firstname-field'>
                    <p className='mb-6 ml-1'>
                      <span>{t("numberOfVillages")}</span>
                      <span>
                        {constituencyDataList?.totalVillageCount || "-"}
                      </span>
                    </p>
                    <p className='mb-6 ml-1'>
                      <span>{t("numberOfPollingStations")}</span>
                      <span>{constituencyDataList?.totalPSCount || "-"}</span>
                    </p>
                  </div>
                </div>
                <div className='basicInfoDiv'>
                  <div className='firstname-field'>
                    <p className='flex ml-1'>
                      <span>{t("description")}</span>
                      <span className='inline-block w-3/5'>-</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ConstituencyInfo;
