
import React from "react";
import AppContextProvider from "./context/AppContextProvider";

import RouteApp from "./routes/RouteApp";



function App() {

  return (
    <AppContextProvider>
    {/* all routes in RouteApp component */}
      <RouteApp />
    </AppContextProvider>
  );
}

export default App;