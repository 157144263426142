import React from "react";
import { Select } from "antd";

const LanguageOption = (props) => {
  const storedLanguage = localStorage.getItem("selectedLanguage") || "en-US";
  return (
    <div className="lang-selector">
    <Select
      defaultValue='🇮🇳 Language'
      value={storedLanguage}
      onChange={props.onChange}
      style={{ width: 150, marginTop: 0 }}
      size='small'
    >
      <Select.Option value={"en-US"}>🇮🇳 English</Select.Option>
      <Select.Option value={"hi"}>🇮🇳 हिंदी (Hindi)</Select.Option>
      <Select.Option value={"marathi"}>🇮🇳 मराठी (Marathi)</Select.Option>
      <Select.Option value={"telegu"}>🇮🇳 తెలుగు (Telegu)</Select.Option>
    </Select>
    </div>
  );
};

export default LanguageOption;
