const formatAgeNoOfPeopleValues = (normalDemoGraphyData) => {
  const totalPeople = normalDemoGraphyData.reduce(
    (sum, { noOfPeople }) => sum + noOfPeople,
    0
  );

  return normalDemoGraphyData.map(({ name, noOfPeople }) => {
    let ageLabel;

    if (name === "Unknown") {
      ageLabel = "Missing";
    } else {
      ageLabel = name;
    }

    return {
      age: ageLabel,
      "No of People": parseFloat(((noOfPeople / totalPeople) * 100).toFixed(2))
    };
  });
};

export default formatAgeNoOfPeopleValues;
