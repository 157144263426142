import React, { useState } from "react";
import { Select } from "antd";


import GraphModal from "../GraphModal";
import ExpandIcon from "../../../assets/svg/expandIcon.svg";
import ConstituencyDataTable from "./constituencyDataTable";
// import AgeChart from "./ageChart";
import sampleData from "./sampleData.json";
// import AgeVsCommunityChart from "../utils/Charts/ageVsCommunityChart";
// import AgeChart from "../utils/Charts/ageChart";
// import GenderChart from "../utils/Charts/genderChart";
// import CommunityChart from "../utils/Charts/communityChart";
// import GenderVsCommunityChart from "../utils/Charts/genderVsCommunityChart";
import { getGraphData } from "../utils/getFromattedValue";
// import AgeVsGenderChart from "../utils/Charts/ageVsGenderChart";
import { renderCharts } from "../utils/renderCharts";
import { useTranslation } from "react-i18next";

const ConstituencyInformation = ({
  summaryData,
  constituencyDataList,
  isDataAvailable,
  isLoading
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("age");
  // const isDataAvailable = true;
  // const isDataAvailable =
  //   ["YES", "Yes"].includes(summaryData["Form 20"]) &&
  //   ["YES", "Yes"].includes(summaryData["Electoral Roll"]);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  //graphData for age VS Gender
  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };
  // const graphData = getGraphData(selectedOption, sampleData);
  const graphData = getGraphData(
    selectedOption,
    sampleData,
    constituencyDataList
  );

  return (
    <>
      <div className='constituency-data mt-5'>
        <h4 className='flex justify-center mb-2 mt-4'>
          {t("constituencyData")}
        </h4>
        <div className='w-full grid grid-cols-12 gap-4'>
          <div className='col-span-12 sm:col-span-7 constituenct-data-table'>
            <h1> {t("Constituency")} {t("Form 20 Data")}</h1>
            <ConstituencyDataTable
              isDataAvailable={isDataAvailable}
              data={constituencyDataList?.electoralData}
              constituencyLoading={isLoading}
            />
          </div>
          <div className='col-span-12 sm:col-span-5 constituenct-data-table'>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <h1 className='mt-2 ml-6'>{t("Constituency Electoral Data")}</h1>
              <div className='flex-grow' />
              <div className='ml-auto pr-1'>
                <Select
                  className='mr-2'
                  placeholder='Select'
                  value={selectedOption}
                  onChange={handleSelectChange}
                  disabled={!isDataAvailable}
                >
                  <Select.Option value='age'>{t("age")}</Select.Option>
                  <Select.Option value='gender'>{t("gender")}</Select.Option>
                  {/* <Select.Option value='community'>
                    {t("community")}
                  </Select.Option> */}
                  <Select.Option value='ageGender'>
                    {t("ageVsGender")}
                  </Select.Option>
                  {/* <Select.Option value='ageCommunity'>
                    {t("ageVsCommunity")}
                  </Select.Option>
                  <Select.Option value='ganderCommunity'>
                    {t("genderVsCommunity")}
                  </Select.Option> */}
                </Select>
              </div>
              <img
                className='cursor-pointer'
                src={ExpandIcon}
                alt=''
                onClick={openModal}
              />
            </div>

            {renderCharts(
              selectedOption,
              isDataAvailable,
              graphData,
              isLoading
            )}
          </div>
        </div>
      </div>
      <GraphModal
        isDataAvailable={isDataAvailable}
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        graphData={graphData}
        selectedOption={selectedOption}
      />
    </>
  );
};

export default ConstituencyInformation;
