const formatTimeDifference = (timeString) => {
  // Parse the time string into a Date object
  const time = new Date(timeString);

  // Check if the parsed time is valid
  if (isNaN(time.getTime())) {
    return "";
  }

  // Calculate the time difference
  const currentTime = new Date();
  const timeDifference = currentTime - time;

  // Convert milliseconds to different units
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const monthsDifference = Math.floor(daysDifference / 30); // Rough approximation
  const yearsDifference = Math.floor(daysDifference / 365); // Rough approximation

  // Format the time difference
  if (yearsDifference >= 1) {
    return `${yearsDifference} yr${yearsDifference > 1 ? "s" : ""}`;
  } else if (monthsDifference >= 1) {
    return `${monthsDifference} mo${monthsDifference > 1 ? "s" : ""}`;
  } else if (daysDifference >= 1) {
    return `${daysDifference} day${daysDifference > 1 ? "s" : ""}`;
  } else if (hoursDifference >= 1) {
    return `${hoursDifference} hr${hoursDifference > 1 ? "s" : ""}`;
  } else {
    return `${minutesDifference} min${minutesDifference > 1 ? "s" : ""}`;
  }
};

export default formatTimeDifference;
