import React, { useEffect, useState } from "react";
import { Table, Space, Tooltip, message, Button } from "antd";
import { EllipsisOutlined, CopyOutlined } from "@ant-design/icons";
import "../../../Styles/user.css";
import { Link } from "react-router-dom";
import { userContext } from "../../../context/UserContextProvider";
import { useContext } from "react";
import AssetsManagementContext from "../../../context/assetsManagementContext";
import DeleteModal from "../../DeleteModal";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import DeactivateClientUser from "./DeactivateClientUser";
import { useTranslation } from "react-i18next";

const { Column } = Table;

const UserDataTable = ({ usersData, handleDelete, userSearchData }) => {
  const { t } = useTranslation();
  const { pagination } = useContext(userContext);
  const assetsContext = useContext(AssetsManagementContext);
  const axiosInstance = useAxiosInstance();

  const userRole = localStorage.getItem("user");
  const users = JSON.parse(userRole);

  //  DROPDOWN
  const [openActionDropdown, setOpenActionDropdown] = useState(null);
  const [openContactDropdown, setOpenContactDropdown] = useState(null);

  const handleActionClick = (index) => {
    setOpenActionDropdown(openActionDropdown === index ? null : index);
    setOpenContactDropdown(null);
  };

  const handleContactClick = (index) => {
    setOpenContactDropdown(openContactDropdown === index ? null : index);
    setOpenActionDropdown(null);
  };

  const isActionDropdownOpen = (index) => {
    return openActionDropdown === index;
  };

  const isContactDropdownOpen = (index) => {
    return openContactDropdown === index;
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setOpenActionDropdown(null);
      setOpenContactDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // DELETE MODAL
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const showModalDelete = (record) => {
    setSelectedRecord(record);
    setDeleteModal(!isDeleteModal);
  };
  // FOR  EDIT MODAL
  // const handleEdit = (record) => {
  //   assetsContext.updateEditUserData(record);
  //   assetsContext.updateAddUserMode(false);
  //   assetsContext.updateOpenModal(true);
  // };
  const handleEdit = async (record, type) => {
    try {
      const response = await axiosInstance.get(
        `api/clients/clientUser/${record._id}`
      );
      const clientData = response.data.data;
      assetsContext.updateEditUserData(clientData);
      if (type === "edit") {
        assetsContext.updateAddUserMode(false);
        assetsContext.updateOpenModal(true);
      } else if (type === "status") {
        assetsContext.updateStatusModal(true);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching client data:", error);
    }
  };

  const convertRole = {
    // // Split the string by underscores
    // const words = role.split("_");

    // // Capitalize the first letter of each word
    // const capitalizedWords = words.map((word) => {
    //   return word.charAt(0).toUpperCase() + word.slice(1);
    // });

    // // Join the words back together with spaces
    // const convertedRole = capitalizedWords.join(" ");

    // return convertedRole;
    data_entry_operator: "Data Entry Operator",
    chief_of_staff: "Chief of Staff",
    constituency_manager: "Constituency Manager",
    office_manager: "Office Manager",
    client: "Client",
    cadre: "Cadre"
  };

  const [copiedIndex, setCopiedIndex] = useState(null);
  const [copiedField, setCopiedField] = useState(null);
  const copyToClipboard = (text, index, field) => {
    navigator.clipboard.writeText(text);
    setCopiedIndex(index);
    setCopiedField(field);
    setTimeout(() => {
      setCopiedIndex(null);
      setCopiedField(null);
    }, 1000);
  };
  // const copyToClipboard = (title) => {
  //   navigator.clipboard.writeText(title);
  //   message.success("Copied to clipboard!");
  // };
  return (
    <>
      <Table
        dataSource={userSearchData?.data?.data || usersData}
        className='user-table-inner'
        pagination={pagination}
        rowKey={(record) => record._id}
      >
        <>
          <Column title={t("USER ID")} dataIndex='_id' />
          <Column
            className='font-semibold w-64'
            title={t("NAME")}
            dataIndex='name'
            render={(text, record) => (
              <span>{`${record.firstName} ${record.lastName}`}</span>
            )}
          />
          <Column
            title={t("OFFICE")}
            dataIndex='office'
            className='w-56'
            render={(text, record) =>
              record.officeDetails?.length > 0 ? (
                <span>{`${record.officeDetails?.[0]?.officeName}`}</span>
              ) : (
                <span>{t("No Data")}</span>
              )
            }
          />
          <Column
            title={t("ROLE")}
            dataIndex='role'
            className='w-52'
            // render={(text) => <span>{convertRole(text)}</span>}
            render={(text) => convertRole[text]}
          />
          <Column
            title={t("STATUS")}
            dataIndex='status'
            className='user-status-text w-44'
            render={(text) => (
              <span
                style={{
                  color: text === "active" ? "#27AE60" : "#E84C3B",
                  fontWeight: "700"
                }}
              >
                {/* {text === "active" ? "Active" : "Inactive"} */}
                {text}
              </span>
            )}
          />

          <Column
            className='contact-info cursor-pointer w-32'
            title=''
            key='contact'
            render={(text, record, index) => (
              <Space className='select-option' size='small' style={{}}>
                <div
                  className='dropdown'
                  onClick={() => handleContactClick(index)}
                >
                  <div className=']'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C10.9993 5.71733 10.9033 5.48 10.712 5.288C10.5207 5.096 10.2833 5 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C8.99933 6.28267 9.09533 6.52033 9.288 6.713C9.48067 6.90567 9.718 7.00133 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88334 18.6867 3.825 17.9743 2.925 17.075C2.025 16.1757 1.31267 15.1173 0.788001 13.9C0.263335 12.6827 0.000667933 11.3827 1.26582e-06 10C-0.000665401 8.61733 0.262001 7.31733 0.788001 6.1C1.314 4.88267 2.02633 3.82433 2.925 2.925C3.82367 2.02567 4.882 1.31333 6.1 0.788C7.318 0.262667 8.618 0 10 0C11.382 0 12.682 0.262667 13.9 0.788C15.118 1.31333 16.1763 2.02567 17.075 2.925C17.9737 3.82433 18.6863 4.88267 19.213 6.1C19.7397 7.31733 20.002 8.61733 20 10C19.998 11.3827 19.7353 12.6827 19.212 13.9C18.6887 15.1173 17.9763 16.1757 17.075 17.075C16.1737 17.9743 15.1153 18.687 13.9 19.213C12.6847 19.739 11.3847 20.0013 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" />
                    </svg>
                  </div>
                  {isContactDropdownOpen(index) && (
                    <div className='dropdown-content'>
                      {/* <div onClick={(e) => {window.location.href =`mailto:${record.email}`;}}>{record.email}</div>
                      <div>{record.phone}</div> */}
                      {/* <Tooltip title="Click to copy">
                        <div onClick={() => copyToClipboard(record.phone)}>
                          <div onClick={() => window.location.href = `mailto:${record.email}`}
                            style={{ cursor: "pointer" }}
                          >
                            {record.email}
                          </div>
                        </div>
                      </Tooltip>
                      <Tooltip title="Click to copy">
                        <div
                          onClick={(e) => { e.stopPropagation();copyToClipboard(record.phone)}}
                          style={{ cursor: "pointer", marginTop: 8 }}
                        >
                          {record.phone}
                        </div>
                      </Tooltip> */}

                      <Tooltip
                        title={
                          copiedIndex === index && copiedField === "email"
                            ? "Copied!"
                            : "Click to copy"
                        }
                      >
                        <div
                          onClick={(e) =>
                            {window.location.href =`mailto:${record.email}`;copyToClipboard(record.email, index, "email")}
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {record.email}
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={
                          copiedIndex === index && copiedField === "phone"
                            ? "Copied!"
                            : "Click to copy"
                        }
                      >
                        <div
                          onClick={(e) =>
                            // copyToClipboard(record.phone, index, "phone")
                             {e.stopPropagation();copyToClipboard(record.phone, index, "phone")}
                          }
                          style={{ cursor: "pointer", marginTop: 8 }}
                        >
                          {record.phone}
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </Space>
            )}
          />

          <Column
            title=''
            key='action'
            className='w-20'
            render={(text, record, index) =>
              record.role !== 'client' ? (
                <Space className='select-option' size='small'>
                  <div
                    className='dropdown'
                    onClick={() => handleActionClick(index)}
                  >
                    <span>
                      <EllipsisOutlined />
                    </span>
                    {isActionDropdownOpen(index) && (
                      <div className='dropdown-content'>
                        {record.status === "active" && (
                          <Link to={`/assets-management/user/${record._id}`}>
                            <div>{t("User Info")}</div>
                          </Link>
                        )}
                        <div
                          onClick={() => handleEdit(record, 'status')}
                        >
                          {record.status === "active"
                            ? t("Deactivate")
                            : t("Activate")}{" "}
                          {t("User")}
                        </div>
                        {record.status === "active" && (
                          <div onClick={() => handleEdit(record, "edit")}>
                            {t("Edit")}
                          </div>
                        )}
                        <div onClick={() => showModalDelete(record)}>
                          {t("Delete")}
                        </div>
                      </div>
                    )}
                  </div>
                </Space>
              ) : null
            }
          />
        </>
      </Table>
      {isDeleteModal && (
        <DeleteModal
          textheading={t("User")}
          deleteTitle={t("User")}
          deleteBtn={t("Delete User")}
          handleDelete={() => handleDelete(selectedRecord)}
          isDeleteModal={isDeleteModal}
          showModalDelete={() => setDeleteModal(false)}
        />
      )}
      {assetsContext.isStatusModal && (
        <DeactivateClientUser
          isStatusModal={assetsContext.isStatusModal}
          editUserData={assetsContext.editUserData}
        />
      )}
    </>
  );
};

export default UserDataTable;
