import React, { useEffect } from "react";
import "../Styles/overView.css";

const Stats = (props) => {
  useEffect(() => {
    const element = document.getElementById(props.title);
    if (element) {
      animateValue(element, 0, parseInt(props.stat), 600);
    }
  }, [props.stat, props.title]);

  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.textContent = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  return (
    <>
      <div className='overview-stats flex flex-row '>
        <div className='stats-icon'>
          <img src={props.icon} alt='' />
        </div>
        <div className='stats-wrapper'>
          <div className='stats-content'>
            <div className='float-child'>
              <p className='stats-stat' id={props.title}>
                {props.stat}
              </p>
            </div>

            <p className='stats-title'>{props.title}</p>

            {/* <div className='float-container'>
              <div className='float-child-arrow'>
                <div className='change-icon' >
                  <i>{props.changeIcon}</i>
                </div>
              </div>
              <div className='float-child-arrow'>
                <p className='stats-percent'>{props.percent}</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
