import React, { useState, useMemo, Fragment, useEffect, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";
import { Combobox, Transition } from "@headlessui/react";
import { BsCheck2, BsChevronExpand } from "react-icons/bs";
import { lib, indiaCenter, initialZoom } from "../../utils/mapConstants";
import { mapStyles } from "../../utils/mapStyles";
const indiaBounds = {
  north: 40, // Northernmost point
  south: 5, // Southernmost point
  west: 62, // Westernmost point
  east: 105 // Easternmost point
};

export default function Places({ initialLocation, setLocationData }) {
  const mapRef = useRef(null);
  const [locationValue, setLocationValue] = useState("");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: lib
  });

  useEffect(() => {
    setLocationData(locationValue);
  }, [locationValue, setLocationData]);

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Map
      initialLocation={initialLocation}
      setLocationValue={setLocationValue}
      isLoaded={isLoaded}
      mapRef={mapRef}
    />
  );
}

//lat: 23.521491901285618, lng: 80.32050376508397 (center of india).
function Map(props) {
  const { initialLocation, setLocationValue, mapRef, isLoaded } = props;
  const initialMarker = useMemo(() => {
    const lat = parseFloat(initialLocation?.lat);
    const lng = parseFloat(initialLocation?.lng);

    // Check if lat and lng are valid numbers
    if (!isNaN(lat) && !isNaN(lng)) {
      return {
        lat: lat,
        lng: lng,
        address: initialLocation?.address || ""
      };
    } else {
      return null;
    }
  }, [initialLocation]);

  const [selected, setSelected] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);

  //func. to handle address and lat,lng
  const handleSelect = async (coOrdinate) => {
    const results = await getGeocode(coOrdinate);
    const address = results[0]?.formatted_address;
    //removing these type of prefix code ("HHPJ+W35" ) from address
    const addressWithoutCode = address.split(" ").slice(1).join(" ");
    setSelected({
      lat: coOrdinate?.location.lat,
      lng: coOrdinate?.location.lng,
      address: addressWithoutCode
    });
  };

  //function to handle marker infoWindow
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) return;
    setActiveMarker(marker);
  };

  useEffect(() => {
    setSelected(initialMarker);
  }, [initialMarker]);

  useEffect(() => {
    setLocationValue(selected);
  }, [selected, setLocationValue]);

  return (
    <>
      <div className='places-container rounded-lg mt-2'>
        <PlacesAutocomplete setSelected={setSelected} mapRef={mapRef} />
      </div>

      <GoogleMap
        google={props.google}
        zoom={selected ? 15 : initialZoom}
        center={selected || indiaCenter}
        mapContainerClassName='map-box'
        // onClick={(ev) => {
        //   let lat = ev.latLng.lat();
        //   let lng = ev.latLng.lng();
        //   let obj = { lat: lat, lng: lng };
        //   handleSelect({ location: obj });
        // }}
        onLoad={(map) => {
          mapRef.current = map;
        }}
        options={{
          restriction: {
            latLngBounds: indiaBounds,
            strictBounds: true
          },
          styles: mapStyles
        }}
      >
        <React.Fragment>
          {selected && isLoaded && (
            <Marker
              position={selected}
              onClick={() => handleActiveMarker(selected?.lat)}
            >
              {activeMarker === selected?.lat ? (
                <InfoWindow
                  onCloseClick={() => setActiveMarker(null)}
                  value={selected?.address}
                >
                  <div>{selected?.address}</div>
                </InfoWindow>
              ) : null}
            </Marker>
          )}
        </React.Fragment>
      </GoogleMap>
    </>
  );
}

const PlacesAutocomplete = ({ setSelected, mapRef }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng, address });
    if (mapRef.current) {
      const targetZoom = 15;
      const currentZoom = mapRef.current.getZoom();
      const numSteps = 20;
      const step = (targetZoom - currentZoom) / numSteps;

      const markerLatLng = new window.google.maps.LatLng(lat, lng);

      // Smoothly zoom in only if the current zoom is greater than the target zoom
      if (currentZoom < targetZoom) {
        for (let i = 1; i <= numSteps; i++) {
          setTimeout(() => {
            const newZoom = currentZoom + step * i;
            mapRef.current.setZoom(newZoom);
            mapRef.current.panTo(markerLatLng);
          }, 80 * i);
        }
      }
    }
  };
  const preventFormSubmissionOnEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };

  return (
    <div>
      <Combobox onChange={handleSelect}>
        <div className='relative mt-1'>
          <div className='relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'>
            <Combobox.Input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={!ready}
              className=' w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 outline-none'
              placeholder='Search an address'
              onKeyPress={preventFormSubmissionOnEnter}
            />
            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
              <BsChevronExpand
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Combobox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {status === "OK" &&
                data.map(({ place_id, description }) => (
                  <Combobox.Option
                    key={place_id}
                    value={description}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {description}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <BsCheck2 className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
