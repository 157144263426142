import React, { useState, useMemo } from "react";

import Chart from "react-apexcharts";
import NoDataIcon from "../../../../assets/svg/no-data.svg";
import formatDate from "../../../../utils/formatDate";
import durationDataFormatter from "../../utils/durationDataFormatter";
import formateDate from "../../../../utils/formatDate";
import { Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const periodMap = {
  monthly: "month",
  weekly: "week",
  daily: "day"
};

const platformColorMap = {
  twitter: "var(--dark-color)",
  facebook: "#1877F2",
  instagram: "#C13584"
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

const FollowerGrowth = ({ data = [] }) => {
  const { t } = useTranslation();
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");

  if (isEmptyObject(data))
    return (
      <div className='flex items-center justify-center h-32'>
        <img src={NoDataIcon} alt={t("No data available")} />
      </div>
    );

  const socialData = data
    .map((itm) => {
      // if (itm?.social === "facebook" || itm?.extractedData?.length === 0) {
      //   return null;
      // }

      return {
        id: itm?.platform,
        data: itm?.platformData?.data
          ? itm?.platformData?.data.map((point) => {
              return {
                x: formatDate(new Date(point?.date)),
                y: point?.followers || point?.likes
              };
            })
          : []
      };
    })
    .filter(Boolean); // Filter out the null values

  if (isEmptyObject(socialData))
    return (
      <div className='flex items-center justify-center h-32'>
        <img src={NoDataIcon} alt={t("No data available")} />
      </div>
    );

  const chartData = socialData.map((platform) => ({
    name: platform?.id,
    data: platform?.data.map((point) => ({ x: point?.x, y: point?.y }))
  }));
  const dataToUse = durationDataFormatter(chartData, selectedPeriod);
  const colors = chartData.map((platform) => platformColorMap[platform.name]);

  const formatYAxis = (value) => {
    if (value === null) return "";
    if (value >= 1000 && value <= 100000)
      return `${(value / 1000).toFixed(2)}K`;
    if (value >= 100000) return `${(value / 100000).toFixed(2)}L`;
    return value.toFixed(2);
  };

  const options = {
    chart: {
      height: "auto",
      type: "line"
    },
    grid: {
      line: {
        stroke: "lightgray",
        strokeWidth: 0.6,
        strokeDasharray: "5 5"
      }
    },
    crosshairs: {
      stroke: {
        color: "#7CC5F4",
        width: 2,
        dashArray: 3
      }
    },
    fill: {
      type: "gradient"
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: colors
    },
    xaxis: {
      type: "datetime",
      categories: [],
      labels: {
        style: {
          colors: "var(--stat-text)"
        }
      }
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "var(--stat-text)"
        },
        formatter: formatYAxis
      }
    },
    toolbar: {
      show: false
    },
    tooltip: {
      marker: {
        fillColors: colors
      },
      x: {
        format: "dd/MM/yy",
        formatter: function (value, index) {
          return `  ${formatDate(new Date(value))}`;
        }
      },
      y: {
        formatter: function (val, opts) {
          const data =
            opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
          const count = data && data.count ? data.count.toLocaleString() : "0";
          const formattedCount = count.replace(
            /(\d)(?=(\d{3})+(?!\d))/g,
            "$1,"
          );

          // Determine the arrow and color based on the value
          const arrow = val < 0 ? "&#9660;" : "&#9650;"; // Downward arrow for negative growth
          const color = val < 0 ? "red" : "green";

          // Return the tooltip content
          return `<div class="custom-chart-toolip">
          <p><span style="padding-left:16px">${t("Followers")}: ${formattedCount}</span></p>    
          <p><span style="color: ${color};">${arrow}</span> Change: ${Math.abs(
            val
          ).toLocaleString("en-IN")}</p>
              </div>`;
        }
      }
    },
    markers: {
      colors: colors
    },
    legend: {
      labels: {
        colors: ["var(--stat-text)", "var(--stat-text)"]
      },
      markers: {
        fillColors: colors
      }
    },
    fixed: {
      enabled: false,
      position: "topRight",
      offsetX: 0,
      offsetY: -10
    }
  };

  const handleChange = (value) => {
    setSelectedPeriod(value);
  };

  return (
    <div className='social-media-traffic-graph'>
      <div className='overview-heading flex flex-row justify-between items-center'>
        <div className='flex gap-2'>
          <h4 className='overview-text'>{t("Follower Growth")}</h4>
          <Tooltip
            title={`Follower Change from the last ${periodMap[selectedPeriod]}`}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div className='flex'>
          <Select
            style={{ width: "120px" }}
            className='mb-2'
            placeholder='Select period'
            onChange={handleChange}
            value={selectedPeriod}
          >
            <Select.Option value='daily'>{t("Daily")}</Select.Option>
            <Select.Option value='weekly'>{t("Weekly")}</Select.Option>
            <Select.Option value='monthly'>{t("Monthly")}</Select.Option>
          </Select>
        </div>
      </div>

      <div className='social-media-traffic-chart h-72'>
        {dataToUse.length ? (
          <Chart
            options={options}
            series={dataToUse}
            width='95%'
            height='95%'
          />
        ) : (
          <div className='flex flex-col justify-center items-center no-data-icon'>
            <img src={NoDataIcon} alt='No data' />
            <span>{this("No data")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FollowerGrowth;
