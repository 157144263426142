import React, { useEffect, useState } from "react";

import "../../Styles/settings.css";
import General from "./General";

import Profile from "./profile";
import Billing from "./Billing";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../Components/PageHeading";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useTranslation } from "react-i18next";

const Tabsettings = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [activeTab, setActiveTab] = useState("general");
  const [profileData, setProfileData] = useState({});

  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();

  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/clients/clientUser/info/${user?._id}`
      );
      setProfileData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, [isProfileUpdated]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`../${tab}`); // sibling path
  };

  let tabContent;
  if (activeTab === "general") {
    tabContent = (
      <General
        profileData={profileData}
        setIsProfileUpdated={setIsProfileUpdated}
        setActiveTab={setActiveTab}
      />
    );
  } else if (activeTab === "profile") {
    tabContent = (
      <Profile
        profileData={profileData}
        setIsProfileUpdated={setIsProfileUpdated}
        setActiveTab={setActiveTab}
      />
    );
  } else if (activeTab === "billing") {
    tabContent = <Billing user={user} />;
  }

  return (
    <div className='settings-page-section'>
      <div className='settings-page'>
        <PageHeading pageTitle='Settings' />
        <div className='tabs'>
          <div className='setting-body'>
            <button
              className={`tab-button  ${
                activeTab === "general" ? "active" : ""
              }`}
              onClick={() => handleTabChange("general")}
            >
              {t("General")}
            </button>
            <button
              className={`tab-button ${
                activeTab === "profile" ? "active" : ""
              }`}
              onClick={() => handleTabChange("profile")}
            >
              {t("Profile")}
            </button>

            {!["data_entry_operator", "office_manager"].includes(
              user?.role
            ) && (
              <button
                className={`tab-button ${
                  activeTab === "billing" ? "active" : ""
                }`}
                onClick={() => handleTabChange("billing")}
              >
                {t("Billing Details")}
              </button>
            )}
          </div>
        </div>
        <div className='tab-content'>{tabContent}</div>
      </div>
    </div>
  );
};

export default Tabsettings;
