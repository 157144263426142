import useAxiosInstance from "../../../lib/useAxiosInstance";
const useGrievanceApi = () => {
  const axiosInstance = useAxiosInstance();
  const fetchGMS = async (currentPage, limit) => {
    const response = await axiosInstance.get(
      `api/grievances/getAllGrievanceByUser?page=${currentPage}&limit=${limit}&sort=${"DES"}`
    );
    return response?.data?.message;
  };

  const fetchByStatus = async (currentPage, limit, pendingApproval) => {
    let response;
    if (pendingApproval) {
      response = await axiosInstance.get(
        `api/grievances/getAllGrievanceByStatus/?page=${currentPage}&limit=${limit}&sort=${"DES"}`
      );
    }
    return response?.data?.message;
  };

  const fetchSearch = async (searchKeyword, currentPage, limit) => {
    let response;
    if (searchKeyword) {
      response = await axiosInstance.get(
        `api/grievances/searchGrievanceByKeyword?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
      );
    }
    return response?.data?.message;
  };
  return { fetchGMS, fetchByStatus, fetchSearch };
};
export default useGrievanceApi;
