import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import "../../Styles/_color_variables.css";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import NoDataIcon from "../../assets/svg/no-data.svg";

const lineColors = ["#2697F4", "#7CC5F4"]; // Define the line colors for TMS and GMS

const GmsTmsRecordsGraphs = ({ gmsTmsData }) => {
  const { t } = useTranslation();

  // State to track the selected period
  const [selectedPeriod, setSelectedPeriod] = useState("yearly");

  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (gmsTmsData && gmsTmsData.length > 0) {
      const gms = gmsTmsData[0]?.gms?.getAddedGms[selectedPeriod] || [];
      const tms = gmsTmsData[0]?.tms?.getAddedGms[selectedPeriod] || [];

      const newData = [
        {
          id: "TMS",
          data: tms.map((item) => ({ x: item?.date, y: item?.count }))
        },
        {
          id: "GMS",
          data: gms.map((item) => ({ x: item?.date, y: item?.count }))
        }
      ];
      setGraphData(newData);
    }
  }, [gmsTmsData, selectedPeriod]);

  const handleChange = (value) => {
    setSelectedPeriod(value);
  };

  const options = {
    chart: {
      type: "area",
      height: "auto"
    },
    grid: {
      line: {
        stroke: "lightgray",
        strokeWidth: 0.6,
        strokeDasharray: "5 5"
      },
      borderColor: 'lightgray',
      strokeDashArray: 4,
    },
    crosshairs: {
      stroke: {
        color: "#7CC5F4",
        width: 1,
        dashArray: 3
      }
    },
    fill: {
      type: "gradient"
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return ` ${val}`;
      },
      background: {
        opacity: 0.5
      },
      padding: "5px"
    },
    stroke: {
      curve: "smooth",
      width: 3
    },
    tooltip: {
      x: {
        format: "dd/MM/yy"
      },
      y: {
        title: t("Entries"),
        formatter: function (val) {
          return `${t("Entries")}: ${val}`;
        }
      }
    },
    xaxis: {
      type: "datetime",
      categories: [],
      labels: {
        style: {
          colors: "var(--stat-text)"
        }
      }
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "var(--stat-text)"
        }
      }
    },
    toolbar: {
      show: false
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      labels: {
        colors: ["var(--stat-text)","var(--stat-text)"] 
      }
    }
  };

  return (
    <div className='overview-user-section overview-section'>
      <div className='overview-heading flex flex-row justify-between items-center'>
        <h4 className='overview-text'>{t("GMS & TMS Entries")}</h4>
        <div className='flex'>
          <Select
            style={{ width: "120px" }}
            className='mb-2'
            placeholder={t("Select")}
            onChange={handleChange}
            value={selectedPeriod}
          >
            <Select.Option value='weekly'>{t("weekly")}</Select.Option>
            <Select.Option value='monthly'>{t("monthly")}</Select.Option>
            <Select.Option value='quarterly'>{t("quarterly")}</Select.Option>
            <Select.Option value='halfYearly'>{t("halfYearly")}</Select.Option>
            <Select.Option value='yearly'>{t("yearly")}</Select.Option>
          </Select>
        </div>
      </div>
      <div
        style={{
          height: "300px",
          width: "100%",
          padding: "10px 10px",
          marginTop: "20px"
        }}
      >
        {graphData.length > 0 ? (
          <Chart
            height='100%'
            options={{
              ...options,
              xaxis: {
                ...options.xaxis,
                categories: graphData[0]?.data.map((item) => item?.x)
              }
            }}
            series={graphData.map((item, index) => ({
              name: item?.id,
              data: item?.data.map((dataItem) => dataItem?.y),
              color: lineColors[index]
            }))}
            type='area'
          />
        ) : (
          <div className='flex flex-col justify-center items-center overview-error'>
            <img src={NoDataIcon} alt={t("No Data")} />
            <span>{t("noDataAvailable")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default GmsTmsRecordsGraphs;
