import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Styles/gms.css";
import OrganizationalHierarchy from "./organizationalHierarchy/OrganizationalHierarchy";
import OfficeDataTable from "./office/OfficeDataTable";
import UserDataList from "./user/UserDataList";
import AssetsManagementContext from "../../context/assetsManagementContext";
import AddOffice from "./office/AddOffice";
import ClientUser from "./user/ClientUser";
import PageHeading from "../PageHeading";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const UserTabs = () => {
  const { t } = useTranslation();
  const assetsContext = useContext(AssetsManagementContext);
  const [activeTab, setActiveTab] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("office")) {
      setActiveTab("office");
    } else if (path.includes("user")) {
      setActiveTab("user");
    } else if (path.includes("organizational-hierarchy")) {
      setActiveTab("organizational-hierarchy");
    }
  }, [location.pathname]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`../${tab}`);
  };
  const handleModal = (tab) => {
    assetsContext.updateOpenModal(true);
  };
  return (
    <>
      {activeTab === "office" && (
        <PageHeading
          pageTitle={t("Asset Management")}
          pageModalTitle={t("+ Add New Office")}
          pageModalOpen={handleModal}
        />
      )}

      {activeTab === "user" && (
        <PageHeading
          pageTitle={t("Users")}
          pageModalTitle={t("+ Add New User")}
          pageModalOpen={handleModal}
        />
      )}
      {activeTab === "organizational-hierarchy" && (
        <PageHeading pageTitle={t("Asset Management")} activeTab={activeTab} />
      )}
      <div className='user-table-wrapper'>
        <div className='tabs1'>
          <div className='user-table-tabs'>
            <button
              className={`tab-button tab-btn1 ${
                activeTab === "office" ? "active" : ""
              }`}
              onClick={() => handleTabChange("office")}
            >
              {t("Office")}
            </button>
            <button
              className={`tab-button ${
                activeTab === "user" ? "active" : ""
              } ml-5`}
              onClick={() => handleTabChange("user")}
            >
              {t("Users")}
            </button>
            <button
              className={`tab-button ${
                activeTab === "organizational-hierarchy" ? "active" : ""
              } ml-5`}
              onClick={() => handleTabChange("organizational-hierarchy")}
            >
              {t("Organizational Hierarchy")}
            </button>
          </div>
        </div>
        <div className='tab-content'>
          {activeTab === "office" && <OfficeDataTable />}
          {activeTab === "user" && <UserDataList />}
          {activeTab === "organizational-hierarchy" && (
            <OrganizationalHierarchy />
          )}
        </div>
      </div>
      {activeTab === "office" && (
        <AddOffice
          isOpen={assetsContext.isOpenModal}
          isAddMode={assetsContext.isAddMode}
        />
      )}
      {activeTab === "user" && (
        <ClientUser
          isOpen={assetsContext.isOpenModal}
          isAddUserMode={assetsContext.isAddUserMode}
        />
      )}
      {/* {activeTab === 'organizational-hierarchy' && <OrganizationalHierarchy />} */}
    </>
  );
};

export default UserTabs;
