import React from 'react'
import UserTabs from './UserTabs'
import { ToastContainer } from 'react-toastify'

const Result = () => {
    return (
        <>
            <ToastContainer />
            <div className="user-section">
                <UserTabs />
            </div>
        </>
    )
}

export default Result