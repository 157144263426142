import React, { useState } from "react";
import { Table, Space, Pagination, Select } from "antd";
import { useQuery } from "react-query";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { EllipsisOutlined } from "@ant-design/icons";
import SearchFilter from "../SearchFilter";
import ShareSurvey from "./ShareSurvey";
import { useTranslation } from "react-i18next";
import SurveyDownload from "./SurveyDownload";
import { useNavigate } from "react-router-dom";
import pdfImg from "../../assets/images/icon/pdf.png";

const Column = Table;

const SurveyData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState("DES");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  // DROPDOWN
  const [hoveredDropdown, setHoveredDropdown] = useState(null);

  const handleClick = (index) => {
    setHoveredDropdown(index);
  };

  const handleMouseLeave = () => {
    setHoveredDropdown(null);
  };

  const isDropdownOpen = (index) => {
    return hoveredDropdown === index;
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };

  //fetch all Surveys-->
  const allSurvyesData = async () => {
    const response = await axiosInstance.get(
      `api/survey/client/getAllSurveys?page=${currentPage}&limit=${limit}&sort=${sort}`
    );
    setTotal(response.data.totalpages);
    return response;
  };
  const surveysData = useQuery(
    ["SurveysClient", currentPage, limit, sort],
    () => allSurvyesData(currentPage, limit, sort)
  );

  // GET ALL SEARCH SURVEY DATA
  const fetchSearchSurvey = async () => {
    let response;
    if (searchKeyword) {
      response = await axiosInstance.get(
        `api/survey/client/getAllSurveys?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
      );
      setTotal(response?.data?.totalpages);
    } else {
      response = await axiosInstance.get(
        `api/survey/client/getAllSurveys?page=${currentPage}&limit=${limit}&sort=${sort}`
      );
      setTotal(response?.data?.totalpages);
    }
    return response;
  };

  const surveySearchData = useQuery(
    ["search-survey", searchKeyword, currentPage, limit],
    () => fetchSearchSurvey(),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const SearchSurveys = (value) => {
    setCurrentPage(1);
    setSearchKeyword(value);
  };

  // DOWNLOAD SURVEY FILE
  const handleView = (record) => {
    if (record.status === "close") {
      axiosInstance
        .get(`api/survey/client/viewRequestedSurvey/${record._id}`, {
          headers: {
            subscription: "advanced"
          }
        })
        .then((res) => {
          const publicUrl = res.data.data.publicUrl;
          const fileName = getFileNameFromUrl(publicUrl);
          const link = document.createElement("a");
          link.href = publicUrl;
          link.setAttribute("download", fileName);
          link.setAttribute("target", "_blank");
          link.click();
        });
    } else {
      axiosInstance
        .get(`api/survey/${record._id}`, {
          headers: {
            subscription: "advanced"
          }
        })
        .then((res) => {
          const publicUrl = res.data.data.resourceLocation[0].publicUrl;
          const fileName = getFileNameFromUrl(publicUrl);
          const link = document.createElement("a");
          link.href = publicUrl;
          link.setAttribute("download", fileName);
          link.click();
        });
    }
  };

  const getFileNameFromUrl = (url) => {
    const urlParts = url.split("/");
    const fileName = urlParts[urlParts.length - 1];
    const fileExtension = fileName.split(".").pop();
    return fileExtension;
  };

  // VIEW MORE DETAILS
  const [moreDetails, setMoreDetails] = useState(false);

  // const viewDetails = (record) => {
  //   setMoreDetails(true);
  // };

  // Function to handle the click on surveyName
  const handleSurveyNameClick = (record) => {
    // Assuming record contains the ID and state value

    const { _id } = record;
    navigate(`../uploadedSurvey/${_id}`, { state: record, target: "_blank" });

    // setSelectedSurvey(record);
  };
  return (
    <>
      <div className='report-filter flex justify-between'>
        <SearchFilter className='mt-5' handleSearch={SearchSurveys} />
        {/* <div className='report-filter-select flex flex-col pr-4'>
          <span>{t("Filter")}</span>
          <Select className='' placeholder='Select'>
            <Select.Option value='all'>{t("All")}</Select.Option>
            <Select.Option value='free'>{t("Free")}</Select.Option>
            <Select.Option value='paid'>{t("Paid")}</Select.Option>
          </Select>
        </div> */}
      </div>
      <div className='report-data-modal'>
        <Table
          rowKey={(record) => record._id}
          dataSource={
            surveySearchData?.data?.data?.data || surveysData?.data?.data?.data
          }
          pagination={pagination}
        >
          <Column
            title={t("UPLOAD FILE")}
            className='report-type w-40'
            render={(text, record) => (
              <div className='px-4'>
                <img
                  src={record?.resourceLocation?.publicUrl || pdfImg}
                  alt='Uploaded File'
                  style={{ width: "50px", height: "auto" }}
                  onError={(e) => {
                    e.target.src = pdfImg;
                  }}
                />
              </div>
            )}
          />
          <Column
            title={t("SURVEY NAME")}
            dataIndex='surveyName'
            className='font-semibold cursor-pointer'
            render={(text, record) => (
              <span
                onClick={() => {
                  handleSurveyNameClick(record);
                }}
                style={{ textDecoration: "underline" }}
              >
                {record?.surveyName}
              </span>
            )}
          />
          <Column
            title={t("DESCRIPTION")}
            render={(text, record) => {
              const description =
                record.surveyDescription || record.description;
              return <span>{description}</span>;
            }}
          />
          <Column
            title={t("UPLOAD DATE")}
            dataIndex='createdAt'
            sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
            defaultSortOrder='descend'
            render={(text, record) => (
              <span>
                {new Date(record.createdAt).toLocaleDateString("en-GB")}
              </span>
            )}
          />
          <Column
            className='report-action'
            title={t("ACTION")}
            key='action'
            render={(text, record, index) => (
              <Space className='select-option' size='small' style={{}}>
                <div
                  className='dropdown'
                  onClick={() => handleClick(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <span>
                    <EllipsisOutlined />
                  </span>
                  {isDropdownOpen(index) && (
                    <div className='dropdown-content -ml-24'>
                      <div onClick={() => handleView(record)}>{t("view")}</div>
                      {/* <div onClick={() => viewDetails(record)}>
                        {t("More Details")}
                      </div> */}
                    </div>
                  )}
                </div>
              </Space>
            )}
          />
        </Table>
        {selectedSurvey && (
          <SurveyDownload
            selectedSurvey={selectedSurvey}
            setSelectedSurvey={setSelectedSurvey}
          />
        )}
        <ShareSurvey
          moreDetails={moreDetails}
          setMoreDetails={setMoreDetails}
        />
      </div>
      <div className='client-pagination '>
        <div className='pagination-text '>
          {t("showing")}
          <Select
            className='mx-2'
            value={limit.toString()}
            style={{ width: 56 }}
            onChange={(value) => {
              setLimit(parseInt(value));
              setCurrentPage(1);
            }}
            options={[
              // { value: "5", label: "5" },
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "50", label: "50" },
              { value: "100", label: "100" }
            ]}
          />
          <span className='pl-1'>{t("entriesPerPage")}</span>
        </div>
        <div>
          <Pagination
            current={currentPage}
            pageSize={limit}
            total={total * limit}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setLimit(pageSize);
            }}
            className='pagination'
          />
        </div>
      </div>
    </>
  );
};

export default SurveyData;
