import React, { useEffect, useState, useMemo } from "react";
import StatCard from "./StatCard";
import { ToastContainer } from "react-toastify";
import SocialMediaNewVisitors from "./SocialMediaNewVisitors";
import fbIcon from "../../assets/svg/fbSocial.svg";
import instaIcon from "../../assets/svg/instaSocial.svg";
import twitterIcon from "../../assets/svg/twitterSocial.svg";
import AddSocialModal from "./AddSocialModal";
import PageHeading from "../../Components/PageHeading";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useQuery } from "react-query";
import FollowerGrowth from "./Growth/FollowerGrowth";
import FollowingGrowth from "./Growth/FollowingGrowth";
import PostGrowth from "./Growth/PostGrowth";
import TotalPost from "./TotalPost";
import HeatMap from "./HeatMap";
import { useTranslation } from "react-i18next";

import "../../Styles/socialMedia.css";

const socialIconMapping = {
  facebook: fbIcon,
  instagram: instaIcon,
  twitter: twitterIcon
};
const SocialMedia = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [usernameData, setUsernameData] = useState([]);
  const axiosInstance = useAxiosInstance();

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const fetchUsernames = async () => {
    const response = await axiosInstance.get("api/users/linked");
    return response?.data;
  };

  const { data = {}, isLoading } = useQuery(
    ["socialMedia"],
    async () => {
      try {
        const response = await axiosInstance.get("api/users/social_data");
        return response?.data;
      } catch (error) {
        console.error("Error fetching social data:", error);
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const fetchData = async () => {
    try {
      const data = await fetchUsernames();
      setUsernameData(data);
    } catch (error) {
      console.error("Error fetching usernames:", error);
    }
  };
  const {} = useQuery("fetchUsernames", fetchData);

  const basicData = useMemo(() => {
    if (Array.isArray(data)) {
      return data.reduce((acc, itm) => {
        acc[itm?.platform] = {
          basicInfo: itm?.platformData?.BasicInfo || {},
          growth: itm?.platformData?.ProfileGrowth || {},
          followerGrowth30Day:
            itm?.platformData?.data[0]?.likes -
              itm?.platformData?.data[29]?.likes ||
            itm?.platformData?.data[0]?.followers -
              itm?.platformData?.data[29]?.followers ||
            0
        };
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [data]);

  const followerDemographics = useMemo(() => {
    if (Array.isArray(data)) {
      return data.reduce((acc, itm) => {
        acc[itm?.platform] = itm?.apiData?.followerDemographics;
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [data]);

  const reachedAudienceDemographics = useMemo(() => {
    if (Array.isArray(data)) {
      return data.reduce((acc, itm) => {
        acc[itm?.platform] = itm?.apiData?.reachedAudienceDemographics;
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [data]);
  const engagedAudienceDemographics = useMemo(() => {
    if (Array.isArray(data)) {
      return data.reduce((acc, itm) => {
        acc[itm?.platform] = itm?.apiData?.engagedAudienceDemographics;
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [data]);

  return (
    <div>
      <ToastContainer />
      <PageHeading
        pageTitle={t("Social Media")}
        pageModalTitle={t('Connect Social Media')}
        pageModalOpen={handleModal}
      />

      {data ? (
        <>
          <div className='grid grid-cols-3 gap-4 m-2'>
            {["facebook", "instagram", "twitter"]?.map((platform) => {
              return (
                <div key={platform}>
                  <StatCard
                    icon={socialIconMapping[platform]}
                    platform={platform}
                    data={basicData[platform]}
                  />
                </div>
              );
            })}
          </div>
          <div className='grid grid-cols-2 gap-4 m-2'>
            <div className='col-span-1  media-wrapper p-4 rounded-xl shadow'>
              {" "}
              <FollowerGrowth data={data} />
            </div>
            <div className='col-span-1  media-wrapper p-4 rounded-xl shadow'>
              {" "}
              <PostGrowth data={data} />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-4 m-2'>
            <div className='col-span-1  media-wrapper p-4 rounded-xl shadow'>
              {" "}
              <HeatMap apiData={followerDemographics} title={t('Followers')} />
            </div>
            <div className='col-span-1  media-wrapper p-4 rounded-xl shadow'>
              {" "}
              <HeatMap
                apiData={engagedAudienceDemographics}
                title={t('Engaged Audience')}
              />
            </div>
          </div>

          <div className='grid grid-cols-4 gap-4 m-2'>
            <div className='col-span-1  media-wrapper p-4 rounded-xl shadow'>
              {" "}
              <SocialMediaNewVisitors data={basicData} />
            </div>
            <div className='col-span-3 media-wrapper p-4 rounded-xl shadow'>
              <div className='overview-heading'>
                <h4 className='overview-text'>{t("Other Statistics")}</h4>
              </div>
              <div className='grid grid-cols-3 gap-4 m-4'>
                {["facebook", "instagram", "twitter"].map((platform) => {
                  return (
                    <div key={platform}>
                      <TotalPost
                        icon={socialIconMapping[platform]}
                        data={basicData[platform]}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}

      <AddSocialModal
        showModal={showModal}
        handleModal={handleModal}
        usernameData={usernameData}
      />
    </div>
  );
};
export default SocialMedia;
