import { Select, Tooltip } from "antd";
import React, { useState } from "react";
import Chart from "react-apexcharts";
import formatDate from "../../../../../../utils/formatDate";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const colors = ["#4CAF50", "#F44336"];
const periodMap = {
  monthly: "month",
  weekly: "week",
  daily: "day"
};
const FollowerGrowth = ({
  data = [],
  platfrom,
  selectedFollowerPeriod,
  setSelectedFollowerPeriod = () => {}
}) => {
  const { t } = useTranslation();
  const seriesLine = [
    {
      name: "Growth",
      type: "line",
      data: data.map((point) => ({
        x: new Date(point?.x).toISOString(),
        y: point?.y,
        count: point?.count
      }))
    }
  ];
  const formatYAxis = (value) => {
    if (value === null) return "";
    if (value >= 1000 && value <= 100000)
      return `${(value / 1000).toFixed(2)}K`;
    if (value >= 100000) return `${(value / 100000).toFixed(2)}L`;
    return value.toFixed(2);
  };

  const optionsLine = {
    chart: {
      height: "auto",
      type: "line"
    },
    line: {
      stroke: "lightgray",
      strokeWidth: 0.6,
      strokeDasharray: "5 5"
    },

    stroke: {
      color: "#7CC5F4",
      width: 3,
      curve: "smooth"
    },

    fill: {
      type: "gradient"
    },

    xaxis: {
      type: "datetime",
      categories: [],
      labels: {
        style: {
          colors: "var(--stat-text)"
        }
      }
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "var(--stat-text)"
        },
        formatter: formatYAxis
      }
    },
    toolbar: {
      show: false
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
        formatter: function (value, index) {
          return `  ${formatDate(new Date(value))}`;
        }
      },
      y: {
        formatter: function (val, opts) {
          const data =
            opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
          const count = data && data.count ? data.count.toLocaleString() : "0";
          const formattedCount = count.replace(
            /(\d)(?=(\d{3})+(?!\d))/g,
            "$1,"
          );

          // Determine the arrow and color based on the value
          const arrow = val < 0 ? "&#9660;" : "&#9650;"; // Downward arrow for negative growth
          const color = val < 0 ? "red" : "green";

          // Determine the platform (assuming platform info is in `data`)
          const platform = data && data.platform ? data.platform : "";

          // Return the tooltip content
          return `<div style="padding: 5px; border-radius: 3px;">
            <span style="color: ${color};">${arrow}</span> ${t(
            "Change"
          )}: ${Math.abs(val)}
          
            ${
              platfrom === "facebook" ? t("Page Likes") : t("Followers")
            }: ${formattedCount}
        </div>`;
        }
      },

      legend: {
        labels: {
          colors: ["var(--stat-text)", "var(--stat-text)"]
        }
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: -10
      }
    }
  };
  const handleChange = (value) => {
    setSelectedFollowerPeriod(value);
  };
  return (
    <div>
      <div className='overview-heading flex flex-row justify-between items-center'>
        <div className='flex gap-2'>
          <h1 className='text-lg font-semibold text-[--dark-color]'>
            {platfrom === "facebook" ? t("Total Likes") : t("Follower Growth")}
          </h1>
          <Tooltip
            title={`${t("Followers change from the last")} ${
              periodMap[selectedFollowerPeriod]
            }`}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>

        <div className='flex'>
          <Select
            style={{ width: "120px" }}
            className='mb-2'
            placeholder='Select period'
            onChange={handleChange}
            value={selectedFollowerPeriod}
          >
            <Select.Option value='daily'>{t("Daily")}</Select.Option>
            <Select.Option value='weekly'>{t("Weekly")}</Select.Option>
            <Select.Option value='monthly'>{t("Monthly")}</Select.Option>
            {/* <Select.Option value='quarterly'>Quarterly</Select.Option> */}
            {/* <Select.Option value='halfYearly'>halfYearly</Select.Option> */}
            {/* <Select.Option value='yearly'>yearly</Select.Option> */}
          </Select>
        </div>
      </div>
      <Chart
        options={optionsLine}
        series={seriesLine}
        type='line'
        height='250'
      />
    </div>
  );
};

export default FollowerGrowth;
