const formatCommunityValues = (communityData) => {
  const totalPeople = communityData.reduce(
    (sum, { noOfPeople }) => sum + noOfPeople,
    0
  );

  return communityData.map(({ name, noOfPeople }) => ({
    community: name,
    "No of People": parseFloat(((noOfPeople / totalPeople) * 100).toFixed(2))
  }));
};

export default formatCommunityValues;
