import React, { useContext } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import Overview from "../Pages/overview";
import ConstituencyManagement from "../Pages/cdm";
import Users from "../Pages/users";
import Reports from "../Pages/reports";
import Insights from "../Pages/insights";
import HelpCenter from "../Pages/HelpCenter";
import PlatfromDetails from "../Pages/socialMedia/PlatfromDetails";
import Survey from "../Pages/survey";
import CustomerSupport from "../Pages/customerSupport";
import Tabsettings from "../Pages/settings/Tabsettings";
import Notifications from "../Pages/notifications/Notifications";
import Login from "../Components/services/Login";
import ForgotPassword from "../Components/services/ForgotPassword";
import { AppContext } from "../context/AppContextProvider";
import ClientUser from "../Components/assetsManagement/user/ClientUser";
import GmsTab from "../Pages/gms";
import AssetsManagement from "../Pages/assetsManagement";
import Tms from "../Pages/tms";
import TmsList from "../Pages/tms/TmsList";
import TmsMapBox from "../Pages/tms/MapBox";
import UserInfo from "../Components/assetsManagement/userInfo/userInfo";
// import IssueDetails from "../Pages/gms/IssueDetails";
import IssueDetails from "../Pages/gms/IssueDetails";
import TmsDetails from "../Pages/tms/TmsDetails";
import SubscriptionMessage from "../utils/SubscriptionMessage";
import GrievanceUserInfo from "../Components/assetsManagement/userInfo/GrievanceUserInfo";
import TaskUserInfo from "../Components/assetsManagement/userInfo/TaskUserInfo";
import SurveyDownload from "../Components/survey/SurveyDownload";
import ReportDownload from "../Components/reports/ReportDownload";
import ResetPassword from "../Components/services/ResetPassword";
import SocialMedia from "../Pages/socialMedia";
// import CustomerSupport from "../Pages/customerSupport"
// import OrganizationalHierarchy from "../Components/assetsManagement/organizationalHierarchy/OrganizationalHierarchy";

const RouteApp = () => {
  const { isLoggedIn, user } = useContext(AppContext);
  const subscriptions = user?.subscription?.map((item) => item?.name) || [];
  console.log({ subscriptions });

  return (
    <Router>
      {isLoggedIn ? (
        <>
          <Navbar />
          <div className='grid-container'>
            <div className='grid-child'>
              <Sidebar role={user?.role} />
            </div>

            <div className='grid-child'>
              <Routes>
                {["data_entry_operator", "office_manager"].includes(
                  user?.role
                ) ? (
                  <Route path='*' element={<Navigate to='/gms' replace />} />
                ) : (
                  <Route
                    path='*'
                    element={<Navigate to='/dashboard' replace />}
                  />
                )}
                <Route exact path='/dashboard' element={<Overview />} />
                <Route path='/helpcenter' element={<HelpCenter />} />
                <Route path='/users' element={<Users />} />
                <Route path='/clientuser' element={<ClientUser />} />
                {/* <Route path='/cadremanagment' element={<Cadre />} /> */}
                <Route
                  path='/cdm'
                  element={
                    subscriptions.includes("CDM") ? (
                      <ConstituencyManagement />
                    ) : (
                      <SubscriptionMessage />
                    )
                  }
                />
                {subscriptions.includes("GMS") ? (
                  <Route path='/gms'>
                    <Route index element={<Navigate to='gms' replace />} />
                    <Route path=':source' element={<GmsTab />} />

                    <Route
                      path='issuedetails/:grievanceId'
                      element={<IssueDetails />}
                    />
                  </Route>
                ) : (
                  <Route path='/gms' element={<SubscriptionMessage />} />
                )}
                {/* <Route path='/gms/issuedetails/:grievanceId' element={<IssueDetails />} /> */}
                {subscriptions.includes("TMS") ? (
                  <>
                    <Route path='/tms' element={<Tms />}>
                      <Route path='list' element={<TmsList />} />
                      <Route path='map' element={<TmsMapBox />} />
                    </Route>
                    <Route
                      path='/tms/taskdetails/:taskId'
                      element={<TmsDetails />}
                    />
                  </>
                ) : (
                  <Route path='/tms' element={<SubscriptionMessage />} />
                )}
                {/* <Route
                  path='/reports'
                  element={
                    subscriptions.includes("Reports") ? (
                      <Reports />
                    ) : (
                      <SubscriptionMessage />
                    )
                  }
                /> */}
                {subscriptions.includes("Reports") ? (
                  <>
                    <Route path='/reports'>
                      <Route
                        index
                        element={<Navigate to='report-database' replace />}
                      />
                      <Route path=':source' element={<Reports />} />
                      <Route
                        path='uploadedReport/:id'
                        element={<ReportDownload />}
                      />
                    </Route>
                  </>
                ) : (
                  <Route path='/reports' element={<SubscriptionMessage />} />
                )}
                {subscriptions.includes("Survey") ? (
                  <>
                    <Route path='/survey'>
                      <Route
                        index
                        element={<Navigate to='report-database' replace />}
                      />
                      <Route path=':source' element={<Survey />} />

                      <Route
                        path='uploadedSurvey/:id'
                        element={<SurveyDownload />}
                      />
                    </Route>
                  </>
                ) : (
                  <Route path='/survey' element={<SubscriptionMessage />} />
                )}
                <Route path='/insights' element={<Insights />} />

                {subscriptions.includes("Social Media") ? (
                  <Route path='/socialmedia'>
                    <Route
                      index
                      element={<Navigate to='socialmedia' replace />}
                    />
                    <Route path=':source' element={<SocialMedia />} />

                    <Route
                      path='details/:platfrom'
                      element={<PlatfromDetails />}
                    />
                  </Route>
                ) : (
                  <Route
                    path='/socialmedia'
                    element={<SubscriptionMessage />}
                  />
                )}
                {/* <Route path="/customersupport" element={<CustomerSupport />} /> */}
                <Route path='/notifications' element={<Notifications />} />
                <Route
                  path='*'
                  element={<Navigate to='/dashboard' replace />}
                />
                <Route exact path='/dashboard' element={<Overview />} />
                <Route path='/helpcenter' element={<HelpCenter />} />

                {/* <Route path="/users" element={<Users />} />
                <Route
                  path="/assets-management"
                  element={<AssetsManagement />}
                />
                <Route path="/assetsManagement/user" element={<ClientUser />} /> */}
                <Route path='/assets-management'>
                  <Route index element={<Navigate to='office' replace />} />
                  <Route path=':source' element={<AssetsManagement />} />
                </Route>
                <Route path='/assets-management/user/:userId'>
                  <Route index element={<Navigate to='grievances' replace />} />
                  <Route path=':source' element={<UserInfo />} />
                </Route>
                <Route
                  path='/assets-management/user/userInfo/grievanceuserinfo/:gmsUserId'
                  element={<GrievanceUserInfo />}
                />
                <Route
                  path='/assets-management/user/userInfo/taskuserinfo/:tmsUserId'
                  element={<TaskUserInfo />}
                />
                {/* <Route path="/assetsManagement/organizatinal-hierarchy" element={<OrganizationalHierarchy />} /> */}
                {/* <Route path='/cadremanagment' element={<Cadre />} /> */}
                <Route path='/reports' element={<Reports />} />
                <Route path='/insights' element={<Insights />} />
                <Route path='/survey' element={<Survey />} />
                {/* <Route path="/customersupport" element={<CustomerSupport />} /> */}
                <Route path='/customersupport'>
                  <Route
                    index
                    element={<Navigate to='all-tickets' replace />}
                  />
                  <Route path=':source' element={<CustomerSupport />} />
                </Route>
                <Route path='/notifications' element={<Notifications />} />
                {/* <Route path='/gms'>
                  <Route index element={<Navigate to='gms' replace />} />
                  <Route path=':source' element={<GmsTab />} />

                  <Route
                    index
                    element={<Navigate to='all-tickets' replace />}
                  />
                  <Route path=':source' element={<CustomerSupport />} />
                </Route> */}
                <Route path='/notifications' element={<Notifications />} />
                <Route path='/settings'>
                  <Route index element={<Navigate to='general' replace />} />
                  <Route path=':source' element={<Tabsettings />} />
                </Route>
              </Routes>
            </div>
          </div>
        </>
      ) : (
        <Routes>
          <Route path='/login/forgot-password' element={<ForgotPassword />} />
          <Route
            path='/api/clients/auth/passwordReset'
            element={<ResetPassword />}
          />
          <Route path='/' element={<Login />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      )}
    </Router>
  );
};

export default RouteApp;
