import React, { useContext, useState } from "react";
import { Table, Space, Pagination, Select } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import ViewOffices from "./ViewOffices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import AssetsManagementContext from "../../../context/assetsManagementContext";
import SearchFilter from "../../SearchFilter";
import DeleteModal from "../../DeleteModal";
import { useTranslation } from "react-i18next";
const { Column } = Table;

const OfficeDataTable = () => {
  const { t } = useTranslation();
  const assetsContext = useContext(AssetsManagementContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [sort, setSort] = useState("DES");
  const [searchKeyword, setSearchKeyword] = useState("");

  // FOR PAGINATION
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange,
  };

  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const [openDropdown, setOpenDropdown] = useState(null);
  const handleClick = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
    }
  };

  const handleMouseLeave = () => {
    setOpenDropdown(null);
  };

  const isDropdownOpen = (index) => {
    return openDropdown === index;
  };

  const [viewOffice, setViewOffice] = useState(false);
  const [viewDetails, setViewDetails] = useState("");
  const [viewOfficeName, setViewOfficeName] = useState("");

  //fetch offices-->
  // const fetchOffices = async () => {
  //     const response = await axiosInstance.get(`/api/clients/office/info?page=${currentPage}&limit=${limit}&sort=${sort}`);
  //     setTotal(response.data.data.totalPages);
  //     return response;
  // };

  // const officesData = useQuery(["client-offices", currentPage, limit, sort], () => fetchOffices());
  // <--

  const officeSummary = (officeId) => {
    axiosInstance
      .get(`api/clients/office/summary/${officeId}`)
      .then((response) => {
        setViewDetails(response.data.data);
        setViewOffice(true);
      })
      .catch((error) => {
        setViewDetails({
          count: {
            office_manager: 0,
            data_entry_operator: 0,
            cadre: 0
          },
          users: []
        });
        setViewOffice(true);
      });
  };

  const handleEdit = (record) => {
    assetsContext.updateEditData(record);
    assetsContext.updateAddMode(false);
    assetsContext.updateOpenModal(true);
  };
  // SERACH OFFICE
  // const [searchKeyword, setSearchKeyword] = useState("");
  const SearchOffice = (value) => {
    setSearchKeyword(value);
    setCurrentPage(1);
  };

  const fetchSearchOffice = async () => {
    let response;
    if (searchKeyword) {
      response = await axiosInstance.get(
        `api/clients/office/search?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
      );
      setTotal(response?.data?.totalpages);
    } else {
      response = await axiosInstance.get(
        `/api/clients/office/info?page=${currentPage}&limit=${limit}&sort=${sort}`
      );
      setTotal(response?.data?.data?.totalPages);
    }
    return response;
  };

  const officeSearchData = useQuery(
    ["allSearchOffices", searchKeyword, currentPage, limit, sort],
    () => fetchSearchOffice()
  );
  const dataToUse = searchKeyword
    ? officeSearchData?.data?.data?.data
    : officeSearchData?.data?.data?.data?.results;

  // DELETE OFFICE
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const showModalDelete = (record) => {
    setSelectedRecord(record);
    setDeleteModal(!isDeleteModal);
  };
  const handleDelete = async (record) => {
    try {
      const response = await axiosInstance.delete(
        `api/clients/office/${record._id}`
      );
      const message = response?.data?.message;
      toast.success(`${message}`);
      queryClient.invalidateQueries("client-offices");
      queryClient.invalidateQueries("allSearchOffices");
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };
  return (
    <>
      {/* <ToastContainer /> */}
      <SearchFilter handleSearch={SearchOffice} />
      <div className='user-table-wrapper'>
        <Table
          // dataSource={officeSearchData?.data?.data?.data || officesData?.data?.data?.data?.results}
          dataSource={dataToUse}
          className='office-table-inner'
          pagination={pagination}
          rowKey={(record) => record._id}
        >
          <>
            <Column key='officeid' title={t("NAME")} dataIndex='officeName' />
            <Column title={t("ADDRESS")} dataIndex='officeLocation' />
            {/* <Column key="city" title="CITY" dataIndex="city" /> */}
            <Column
              key='constituency'
              title={t("CONSTITUENCY")}
              dataIndex='constituencyName'
            />
            <Column
              key='usersCount'
              title={t("assignedNo")}
              dataIndex='assigned_users_count'
            />
            <Column
              title={t("ACTION")}
              key='action'
              render={(text, record, index) => (
                <Space className='select-option' size='small' style={{}}>
                  <div
                    className='dropdown'
                    onClick={() => handleClick(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span>
                      <EllipsisOutlined />
                    </span>
                    {isDropdownOpen(index) && (
                      <div className='dropdown-content'>
                        <div
                          onClick={() => {
                            officeSummary(record._id);
                            setViewOfficeName(record.officeName);
                          }}
                        >
                          {t("View All Users")}
                        </div>

                        {/*condition for head office not editable */}
                        {record.constituencyID && (
                          <>
                            <div onClick={() => handleEdit(record)}>
                              {t("Edit")}
                            </div>
                            <div onClick={() => showModalDelete(record)}>
                              {t("Delete")}
                            </div>
                          </>)
                        }

                      </div>
                    )}
                  </div>
                </Space>
              )}
            />
          </>
        </Table>
        <div className='client-pagination '>
          <div className='pagination-text'>
            {t("Showing")}
            <Select
              className='mx-2'
              value={limit.toString()}
              style={{ width: 56 }}
              onChange={(value) => {
                setLimit(parseInt(value));
                setCurrentPage(1);
              }}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "50", label: "50" },
                { value: "100", label: "100" }
              ]}
            />
            {t("Entries per pages")}
          </div>
          <div>
            <Pagination
              current={currentPage}
              pageSize={limit}
              total={total * limit}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setLimit(pageSize);
              }}
              className='pagination'
            />
          </div>
        </div>
      </div>

      <ViewOffices
        viewOffice={viewOffice}
        setViewOffice={setViewOffice}
        viewDetails={viewDetails}
        viewOfficeName={viewOfficeName}
      />
      {isDeleteModal && (
        <DeleteModal
          textheading={t("Office")}
          deleteTitle={t("Office")}
          deleteBtn={t("Delete Office")}
          handleDelete={() => handleDelete(selectedRecord)}
          isDeleteModal={isDeleteModal}
          showModalDelete={() => setDeleteModal(false)}
        />
      )}
    </>
  );
};

export default OfficeDataTable;
