import React, { useState } from "react";
import PageHeading from "../../Components/PageHeading.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MapBox from "./MapBox";
import GmsStats from "./GmsStats.js";
import AddNewGrievance from "./AddNewGrievance.js";
import { useNavigate } from "react-router-dom";
import GmsListView from "./GMSListView/index.js";
import { useTranslation } from "react-i18next";

import { Button } from "antd";
import { Popover } from "antd";
import FilterContainer from "../../Components/FilterContainer";
import useAxiosInstance from "../../lib/useAxiosInstance.js";
import { useQuery } from "react-query";

import FilterContent from "./FilterContent";

const GmsTab = ({ elements }) => {
  const axiosInstance = useAxiosInstance();
  const [isSearch, setIsSearch] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [newGrievanceData, setNewGrievanceData] = useState({});

  const { t } = useTranslation(); // add modal
  const [addGrievanceModal, setAddGrievanceModal] = useState(false);
  const [priorityClick, setPriorityClick] = useState();
  const [statusClick, setStatusClick] = useState();
  const userData = JSON.parse(localStorage.getItem("user"));
  const showAddModal = () => {
    setAddGrievanceModal(true);
  };

  //Fetch Category List-->
  const fetchCategoryList = async () => {
    const response = await axiosInstance.get(
      `api/category/main?size=${100}&page=${1}&sort=${"DESC"}`
    );
    return response;
  };
  const categoryList = useQuery("Category-List", fetchCategoryList, {
    refetchOnWindowFocus: false,
    retry: 1
  });

  //Fetch Sub-Category List-->
  const fetchSubCategoryList = async (parentId) => {
    const response = await axiosInstance.get(
      `api/category/sub?size=${100}&page=${1}&sort=${"DESC"}&parentId=${parentId}`
    );
    return response;
  };

  const subCategoryList = useQuery(
    ["Sub-Category-List", parentId],
    () => fetchSubCategoryList(parentId),
    { refetchOnWindowFocus: false, retry: 1 }
  );

  // <--
  //populate subcategory data-->
  const handleSubCat = (id) => {
    setParentId(id);
  };

  const renderFilter = () => {
    return (
      <FilterContent
        t={t}
        categoryList={categoryList}
        subCategoryList={subCategoryList}
        newGrievanceData={newGrievanceData}
        parentId={parentId}
        setNewGrievanceData={setNewGrievanceData}
        setParentId={setParentId}
        handleSubCat={handleSubCat}
      />
    );
  };
  // gms tabs
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("list");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    navigate(`/gms/${tabName}`);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "list":
        return (
          <GmsListView
            setPriorityClick={setPriorityClick}
            priorityClick={priorityClick}
            statusClick={statusClick}
            setStatusClick={setStatusClick}
            newGrievanceData={newGrievanceData}
          />
        );
      case "map":
        return (
          <MapBox
            setPriorityClick={setPriorityClick}
            priorityClick={priorityClick}
            statusClick={statusClick}
            setStatusClick={setStatusClick}
            newGrievanceData={newGrievanceData}
          />
        );
      default:
        return (
          <GmsListView
            setPriorityClick={setPriorityClick}
            priorityClick={priorityClick}
            statusClick={statusClick}
            setStatusClick={setStatusClick}
            newGrievanceData={newGrievanceData}
          />
        );
    }
  };

  return (
    <>
      <div className='gms-page-tabs m-5'>
        <ToastContainer />

        <PageHeading
          pageTitle={t("gmsPageTitle")}
          pageModalTitle={t("addNewGrievanceModalTitle")}
          pageModalOpen={showAddModal}
          className='mb4'
        />
        {userData?.role !== "data_entry_operator" && (
          <GmsStats
            setPriorityClick={setPriorityClick}
            priorityClick={priorityClick}
            statusClick={statusClick}
            setStatusClick={setStatusClick}
          />
        )}
        <div className='user-table-wrapper mt-5'>
          <div className='tabs-wrapper'>
            <nav className='tms-tabs'>
              <button
                className={activeTab === "list" ? "active" : ""}
                onClick={() => handleTabClick("list")}
              >
                {t("listView")}
              </button>
              <button
                className={activeTab === "map" ? "active" : ""}
                onClick={() => handleTabClick("map")}
              >
                {t("mapView")}
              </button>
            </nav>

            <Popover
              content={renderFilter()}
              trigger='click'
              placement='bottomLeft'
              className='filter-button mb-2'
            >
              <Button
                key='add-constituency'
                form='constituency-form'
                type='submit'
                className='search-by mr-8'
                style={{
                  width: "auto",
                  color: "var(--primary-color)",
                  border: "1px solid var(--primary-color)"
                }}
              >
                {t("Filter By")}
              </Button>
            </Popover>
          </div>
          <div className='tms-tab-content'>{renderTabContent()}</div>
        </div>
        {addGrievanceModal && (
          <AddNewGrievance
            addGrievanceModal={addGrievanceModal}
            setAddGrievanceModal={setAddGrievanceModal}
            setActiveTab={setActiveTab}
            userData={userData}
          />
        )}
        {isSearch && (
          <FilterContainer isSearch={isSearch} setIsSearchOpen={setIsSearch} />
        )}
      </div>
    </>
  );
};

export default GmsTab;
