import React from "react";
import { useQuery } from "react-query";
import { Col, Select } from "antd";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import ErrorMessage from "../../Forms/ErrorMessage";
import { useTranslation } from "react-i18next";

const ConstituencyDropDown = ({ formik }) => {
  const { t } = useTranslation();

  const axiosInstance = useAxiosInstance();
  //Fetch Category List-->
  // const fetchConstituencyByState = async () => {
  //   return axiosInstance.get(
  //     `/api/constituency/getAllConstituencyByType/state`
  //   );
  // };
  // const constituencyByState = useQuery(
  //   "client-constituency-ByState",
  //   fetchConstituencyByState,
  //   { retry: 1 }
  // );
  // // <--

  // //Fetch Category List-->
  // const fetchConstituencyByCenter = async () => {
  //   return axiosInstance.get(
  //     `/api/constituency/getAllConstituencyByType/central`
  //   );
  // };
  // const constituencyByCenter = useQuery(
  //   "client-constituency-ByCenter",
  //   fetchConstituencyByCenter,
  //   { retry: 1 }
  // );
  // <--

   //Fetch All Constituency List-->
   const fetchAllConstituency = async () => {
    return axiosInstance.get(
      `api/constituency/getAllConstituency`
    );
  };
  const allConstituency = useQuery(
    "all-constituency",
    fetchAllConstituency,
    { retry: 1 }
  );
  return (
    <React.Fragment>
      <Col span={8} className='mr-6'>
        <label htmfor=''>{t("Constituency")}</label>
        <div className='custom-select'>
          <Select
            placeholder='Select Constituency'
            name='constituencyId'
            value={formik.values.constituencyId}
            onChange={(value) => formik.setFieldValue("constituencyId", value)}
          >
            {allConstituency?.data?.data?.data?.map((constituency) => (
                <Select.Option key={constituency._id} value={constituency._id}>
                  {constituency.name}
                </Select.Option>
              ))}
            {/* <Select.OptGroup>
              {constituencyByCenter?.data?.data?.data?.map((constituency) => (
                <Select.Option key={constituency._id} value={constituency._id}>
                  {constituency.name}
                </Select.Option>
              ))}
            </Select.OptGroup>
            <Select.OptGroup>
              {constituencyByState?.data?.data?.data?.map((constituency) => (
                <Select.Option key={constituency._id} value={constituency._id}>
                  {constituency.name}
                </Select.Option>
              ))}
            </Select.OptGroup> */}
          </Select>
          <ErrorMessage
            hasError={Boolean(
              formik.errors.constituencyId && formik.touched.constituencyId
            )}
            message={formik.errors.constituencyId}
          />
        </div>
      </Col>
    </React.Fragment>
  );
};

export default ConstituencyDropDown;
