// import React from 'react';
// import { useQuery } from "react-query";
// import { Select, Col } from "antd";
// import useAxiosInstance from '../../lib/useAxiosInstance';

// const OfficeDropDown = ({ addNewUserData, setAddNewUserData }) => {

//     const axiosInstance = useAxiosInstance();
//     //Fetch Category List-->
//     const fetchOfficeList = async () => {
//         return axiosInstance.get(
//             `/api/clients/office/info`,);
//     };
//     const officeList = useQuery("client-office-List", fetchOfficeList, { retry: 1 });
//     // <--

//     return (
//         <React.Fragment>
//             <Col span={8} className="mr-6">
//                 <label htmfor="">Office</label>
//                 <Select
//                     placeholder="Select office"
//                     name="officeId"
//                     onChange={(value) => {
//                         setAddNewUserData({ ...addNewUserData, ["officeId"]: value });
//                     }}
//                 >
//                     {officeList?.data?.data?.data?.results?.map(
//                         (office) => (
//                             <Select.Option
//                                 key={office._id}
//                                 value={office._id}
//                             >
//                                 {office.officeName}
//                             </Select.Option>
//                         )
//                     )}
//                 </Select>
//             </Col>
//         </React.Fragment>
//     )
// }

// export default OfficeDropDown

import React from "react";
import { useQuery } from "react-query";
import { Select, Col } from "antd";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import ErrorMessage from "../../Forms/ErrorMessage";
import { useTranslation } from "react-i18next";

const OfficeDropDown = ({ formik }) => {
  const { t } = useTranslation();

  const axiosInstance = useAxiosInstance();
  //Fetch Category List-->
  const fetchOfficeList = async () => {
    return axiosInstance.get(`/api/clients/office/info`);
  };
  const officeList = useQuery("client-office-List", fetchOfficeList, {
    retry: 1
  });
  // <--

  return (
    <React.Fragment>
      <Col span={9} className='mr-6'>
        <label htmfor=''>{t("Office")}</label>
        <Select
          placeholder='Select office'
          name='officeId'
          // onChange={(value) => {
          //     setAddNewUserData({ ...addNewUserData, ["officeId"]: value });
          // }}
          value={formik.values.officeId}
          onChange={(value) => formik.setFieldValue("officeId", value)}
        >
          {officeList?.data?.data?.data?.results?.map((office) => (
            <Select.Option key={office._id} value={office._id}>
              {office.officeName}
            </Select.Option>
          ))}
        </Select>
        <ErrorMessage
          hasError={Boolean(formik.errors.officeId && formik.touched.officeId)}
          message={formik.errors.officeId}
        />
      </Col>
    </React.Fragment>
  );
};

export default OfficeDropDown;
