import React from "react";
import icon from "../assets/images/icon/Unauthorized.png";

const SubscriptionMessage = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh"
  };

  const iconStyle = {
    width: "100px",
    height: "auto",
    marginBottom: "20px"
  };

  return (
    <div style={containerStyle}>
      <img src={icon} alt='Unauthorized' style={iconStyle} />
      <p style={{ color: "#2B9FFF", fontSize: "24px" }}>
        You have not subscribed to this feature
      </p>

      <p>Kindly contact the Polstrat team for activation.</p>
    </div>
  );
};

export default SubscriptionMessage;
