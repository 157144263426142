import React, { useContext } from "react";
import { Modal, Row, Col, Input, Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ErrorMessage from "../Forms/ErrorMessage";
import ReportContext from "../../context/reportContext";
import { useMutation, useQueryClient } from "react-query";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useTranslation } from "react-i18next";


const RequestReportForm = ({ isOpen }) => {
  const { t } = useTranslation();
  const reportContext = useContext(ReportContext);
  const queryClient = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const FormSchema = Yup.object().shape({
    reportName: Yup.string()
      .trim()
      .required("Report Name is required")
      .min(3, "First Name is minimum 3 character")
      .max(50, "First Name is maximum 50 character"),
    description: Yup.string()
      .trim()
      .required("Description is required")
      .min(3, "Last Name is minimum 3 character")
      .max(200, "Description is maximum 200 character")
  });

  const formik = useFormik({
    initialValues: {
      reportName: "",
      description: "",
      quickCall: false
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const closeModal = () => {
    reportContext.updateOpenModal(false);
    reportContext.updateAddMode(true);
    formik.resetForm();
  };

  // <--
  //handle submit event
  const handleSubmit = async (value) => {
    const data = {
      ...value,
      quickCall: value.quickCall.toString()
    };
    handleCreateReportRequest.mutate(data);
  };

  const handleCreateReportRequest = useMutation(
    (formData) =>
      axiosInstance
        .post(`api/reports/requestReport`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );
  const handleSuccess = (data) => {
    queryClient.invalidateQueries("report-requested-list");
    queryClient.invalidateQueries("search-report-requested");
    closeModal();
    toast.success(`${data?.message}`);
  };

  const handleError = (error) => {
    toast.error("Something went wrong.");
    closeModal();
  };

  return (
    <>
      <div className='reports-section'>
        {isOpen && (
          <Modal
            className='reports-modal-wrapper'
            open={isOpen}
            onSubmit={handleSubmit}
            onCancel={closeModal}
            width={960}
            maskClosable={false}
            footer={[
              <button
                key='request-report'
                form='request-report-form'
                type='submit'
                className='modal-footer-button'
              >
                {t("requestReport")}
              </button>
            ]}
          >
            <form id='request-report-form' onSubmit={formik.handleSubmit}>
              <div className='reports-info-modal'>
                <div className='reports-modal-header'>
                  <h3>{t("requestNewReport")}</h3>
                </div>
                <div className='reports-modal-body'>
                  <div className='request-report'>
                    <h5>{t("reportInformation")}</h5>
                    <Row className='reports-modal-row mb-8'>
                      <Col span={10} className='mr-9'>
                        <label>{t("reportName")}</label>
                        <Input
                          type='text'
                          placeholder=''
                          name='reportName'
                          value={formik.values.reportName}
                          onChange={formik.handleChange}
                          showCount
                          maxLength={50}
                        />
                        <ErrorMessage
                          hasError={Boolean(
                            formik.errors.reportName &&
                              formik.touched.reportName
                          )}
                          message={formik.errors.reportName}
                        />
                      </Col>
                    </Row>
                    <Row className='reports-modal-row'>
                      <Col span={22}>
                        <label> {t("description")}</label>
                        <TextArea
                          rows={6}
                          placeholder='Description'
                          showCount
                          maxLength={200}
                          name='description'
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        />
                        <ErrorMessage
                          hasError={Boolean(
                            formik.errors.description &&
                              formik.touched.description
                          )}
                          message={formik.errors.description}
                        />
                      </Col>
                      <Checkbox
                        className='req-callback ml-1'
                        name='quickCall'
                        checked={formik.values.quickCall}
                        onChange={formik.handleChange}
                      >
                        {t("requestCallback")}
                      </Checkbox>
                    </Row>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </div>
    </>
  );
};

export default RequestReportForm;
