import { useState, useEffect } from "react";

import useAxiosInstance from "../../../lib/useAxiosInstance";

const useGetGrievances = (
  isLoaded,
  statusClick,
  priorityClick,
  newGrievanceData
) => {
  const axiosInstance = useAxiosInstance();
  const [grievances, setGrievances] = useState([]);
  const [loading, setLoading] = useState(true);
  const limit = 10000;
  const currentPage = 1;
  const categoryName = newGrievanceData?.categoryName || "";
  const subCategoryName = newGrievanceData?.subCategoryName || "";
  const duration = newGrievanceData?.duration || "";
  const startDate = newGrievanceData?.dateRange?.[0] || "";
  const endDate = newGrievanceData?.dateRange?.[1] || "";

  useEffect(() => {
    if (isLoaded) {
      const fetchData = async () => {
        try {
          const response = await axiosInstance.post(
            `api/grievances/filterGrievance?page=${currentPage}&limit=${limit}&sort=DES`,
            {
              priority: statusClick,
              status: priorityClick,
              categoryName: categoryName,
              duration: duration,
              startDate: startDate,
              subCategoryName: subCategoryName,
              endDate: endDate
            }
          );
          const data = response?.data?.message?.data;
          const filteredGrievances = data.map((item) => {
            const coordinates = item?.location
              ? [parseFloat(item?.location.lng), parseFloat(item?.location.lat)]
              : [
                  parseFloat(item?.locationCoordinates?.lng),
                  parseFloat(item?.locationCoordinates?.lat)
                ];
            return {
              type: "Feature",
              properties: {
                title: item?.grievanceTitle,
                color: item?.location
                  ? item.location.color
                  : item.locationCoordinates?.color || "green",
                cat_name: item?.categoryName || item?.grievanceTitle,
                sub_cat_name: item?.subCategoryName,
                st_name:
                  item?.locationCoordinates?.Name ||
                  item?.locationCoordinates?.address ||
                  item?.location?.address ||
                  "",
                status: item?.status || "",
                reported_by: item?.createdByUserName || "",
                assigned_to: item?.assignedToUserName || "",
                priority: item?.priority || "",
                g_id: item?._id || ""
              },
              geometry: {
                coordinates,
                type: "Point"
              }
            };
          });
          setGrievances(filteredGrievances);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [
    isLoaded,
    currentPage,
    limit,
    priorityClick,
    statusClick,
    categoryName,
    subCategoryName,
    duration,
    startDate,
    endDate
  ]);

  return { grievances, loading };
};

export default useGetGrievances;
