import React from 'react'
import '../../Styles/reports.css'
import { ReportContextProvider } from '../../context/reportContext';
import Result from '../../Components/reports/Result';



const Reports = () => {
  return (
    <>
      <ReportContextProvider>
        <Result />
      </ReportContextProvider>

    </>
  )
}

export default Reports