import { useState } from "react";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { toast } from "react-toastify";
const ApprovalRejection = ({ tmsId, taskStatus, singleTaskData }) => {
  const { t } = useTranslation();
  const assignedToUserId = singleTaskData?.assignedToUserId;
  const axiosInstance = useAxiosInstance();
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("");

  const approveTask = async () => {
    const status = action === "approve" ? "complete" : "assigned";
    return axiosInstance.put(
      `api/task/updateTaskStatus/${tmsId}?userId=${assignedToUserId}`,
      {
        status: status
      }
    );
  };
  const handleApprove = () => {
    setAction("approve");
    setShowModal(true);
  };

  const handleReject = () => {
    setAction("reject");
    setShowModal(true);
  };

  const handleConfirm = () => {
    approveTask()
      .then((res) => {
        // console.log("res", res);
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        console.error("Error approving task:", error);
        toast.error(error?.response?.data?.message);
      });
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div className='flex justify-end mt-8'>
      {/* <h2 style={{ color: "#004877", fontSize: "16px", marginBottom: "10px" }}>
        {t("Approval")}
      </h2> */}
      <div>
        <Button
          onClick={handleApprove}
          style={{
            color: " #ffffff",
            borderRadius: "8px",
            height: "40px",
            background: "#004877"

          }}
          className='mr-2 '
          disabled={taskStatus !== "pendingApproval"}
        >
          <span style={{
            color: taskStatus !== "pendingApproval" ? "gray" : "#ffffff"
          }}> {t("Approve")} </span>
        </Button>
        <Button
          onClick={handleReject}
          // type='primary'
          style={{
            height: "40px",
            borderRadius: "8px"
          }}
          disabled={taskStatus !== "pendingApproval"}
        >
          {t("Reject")}
        </Button>
      </div>
      <Modal
        open={showModal}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <div>
          <div className='delete-modal-header'>
            <h3>{t("Confirmation")}</h3>
          </div>
          <div className='delete-modal-body'>
            <h6>
              {t("Are you sure you want to")} {action}?
            </h6>
          </div>

          <div className='modal-footer p-5'>
            <div className='flex justify-end'>
              <Button className='delete-btn' onClick={handleConfirm}>
                {t("Confirm")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ApprovalRejection;
