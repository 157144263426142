import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Table,
  Select,
  Pagination,
  Space,
  Checkbox,
  Input,
  Tooltip,
  DatePicker,
  Skeleton,
  Empty
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Column from "antd/es/table/Column";
import ViewTmsDetails from "./ViewTmsDetails";
import EditTask from "./EditTask";
import DeleteModal from "../../Components/DeleteModal";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { EllipsisOutlined } from "@ant-design/icons";
import pinIcon from "../../assets/images/icon/pinIcon.png";
import highPriority from "../../assets/images/icon/high-priority.png";
import mediumPriority from "../../assets/images/icon/medium-priority.png";
import lowPriority from "../../assets/images/icon/low-priority.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { Link } from "react-router-dom";
import SearchLine from "../../assets/svg/search-line.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";

const TmsList = ({
  inputArr,
  setInputArr = () => {},
  priorityClick,
  statusClick,
  newGrievanceData
}) => {
  const { t } = useTranslation();

  // const categoryName = newGrievanceData?.categoryName || "";
  // const subCategoryName = newGrievanceData?.subCategoryName || "";
  const duration = newGrievanceData?.duration || "";
  const startDate = newGrievanceData?.dateRange?.[0] || "";
  const endDate = newGrievanceData?.dateRange?.[1] || "";
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const [taskStatus, setTaskStatus] = useState(null);
  const [assignToData, setAssignToData] = useState(null);
  const [taskId, setTaskId] = useState(null);
  // selected task id >>
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pendingApproval, setPendingApproval] = useState(false);
  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: (page, pageSize) => {
      setCurrentPage(page);
      setLimit(pageSize);
    }
  };

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const showModalDelete = (record) => {
    setSelectedRecord(record);
    setIsDeleteModal(!isDeleteModal);
  };

  // view Modal
  const [viewModal, setViewModal] = useState(false);
  const showViewModal = () => {
    setViewModal(!viewModal);
  };

  const DeleteData = async () => {
    try {
      await axiosInstance.delete(`/api/task/${selectedRecord._id}`);

      const newUserData = inputArr.filter(
        (item) => item._id !== selectedRecord._id
      );
      setInputArr(newUserData);
    } catch (error) {
      // console.error(error);
    }
  };

  const mutateDeleteTask = useMutation(DeleteData, {
    onSuccess: (data) => {
      toast.success("Deleted Successfully");
      //invalidate cache and refresh
      queryClient.invalidateQueries("tasklist");
      queryClient.invalidateQueries("taskPriorrityCount");
      queryClient.invalidateQueries("taskSummary");
      setIsDeleteModal(false);
    },
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });

  const handleDelete = (e) => {
    mutateDeleteTask.mutateAsync();
  };

  //Assign task to user -->
  const assignToUser = async () => {
    return axiosInstance.put(`api/task/updateTask/${taskId}`, assignToData);
  };

  const mutateAssignUser = useMutation(assignToUser, {
    onSuccess: (data) => {
      toast.success(`${data?.data?.message}`);
      //invalidate cache and refresh
      queryClient.invalidateQueries("tasklist");
    },
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });

  //fetch all users-->
  const fetchUsers = async () => {
    return axiosInstance.get("/api/clients/info?page=1&limit=1000");
  };
  const usersData = useQuery("client-users-list", fetchUsers);
  // <--

  // handle assigned to user
  const handleAssignTo = (user, status) => {
    setAssignToData({
      assignedToUserId: user?._id,
      assignedToUserName: user?.firstName + " " + user?.lastName,
      assignedByUserRole: user?.role,
      status: status
    });
  };
  const handleDueDateChange = (dueDate) => {
    setAssignToData({
      dueDate: dueDate
    });
    queryClient.invalidateQueries("GMS-List_Data");
  };

  useEffect(() => {
    if (assignToData != null) {
      mutateAssignUser.mutateAsync();
    }
  }, [assignToData]);

  // edit Modal
  const [editModal, setEditModal] = useState(false);

  const showEditModal = (record) => {
    setSelectedRecord(record);
    setEditModal(!editModal);
  };

  // dropdown menu
  const [openDropdown, setOpenDropdown] = useState([]);

  const dropdownRefs = useRef([]);

  const handleClick = (index) => {
    setOpenDropdown((prevOpenDropdown) => {
      const updatedOpenDropdown = [...prevOpenDropdown];
      updatedOpenDropdown[index] = !updatedOpenDropdown[index];
      return updatedOpenDropdown;
    });
  };

  const handleOutsideClick = (event) => {
    for (let i = 0; i < dropdownRefs.current.length; i++) {
      if (
        dropdownRefs.current[i] &&
        !dropdownRefs.current[i].contains(event.target)
      ) {
        setOpenDropdown((prevOpenDropdown) => {
          const updatedOpenDropdown = [...prevOpenDropdown];
          updatedOpenDropdown[i] = false;
          return updatedOpenDropdown;
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  function getPriorityIcon(priority) {
    switch (priority) {
      case "high":
        return <img src={highPriority} alt='priority icon' />;
      case "medium":
        return <img src={mediumPriority} alt='priority icon' />;
      case "low":
        return <img src={lowPriority} alt='priority icon' />;
      default:
        return null;
    }
  }

  const onStatusChange = () => {
    setPendingApproval(!pendingApproval);
  };
  const SearchCategory = (value) => {
    setSearchKeyword(value);
    setCurrentPage(1);
  };

  const fetchTaskList = async () => {
    const response = await axiosInstance.get(
      `api/task/getAllTasks?page=${currentPage}&limit=${limit}&sort=${"DES"}`
    );
    return response?.data;
  };
  const taskData = useQuery(["tasklist", currentPage, limit], fetchTaskList, {
    refetchOnWindowFocus: false
  });

  const fetchByStatus = async () => {
    try {
      let response;
      if (pendingApproval) {
        response = await axiosInstance.get(
          `api/task/getAllTasksByStatus?page=${currentPage}&limit=${limit}&sort=${"DES"}`
        );
      }
      return response?.data?.message;
    } catch (error) {
      return null;
    }
  };

  const fetchByFilter = async () => {
    try {
      const response = await axiosInstance.post(
        `api/task/filterTask?page=${currentPage}&limit=${limit}&sort=${"DES"}`,
        {
          priority: priorityClick,
          status: statusClick,
          duration: duration,
          startDate: startDate,
          endDate: endDate
        }
      );
      return response?.data?.message;
    } catch (error) {
      return null;
    }
  };

  const taskDataByFilter = useQuery(
    [
      "tasklist",
      priorityClick,
      statusClick,
      currentPage,
      limit,
      duration,
      startDate,
      endDate
    ],
    fetchByFilter,
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const tmsDataByStatus = useQuery(
    ["tasklist", pendingApproval, currentPage, limit],
    fetchByStatus
  );

  const fetchSearch = async () => {
    try {
      if (searchKeyword) {
        const response = await axiosInstance.get(
          `api/task/searchTaskByKeyword?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
        );
        return response?.data?.message;
      }
    } catch (error) {
      return null;
    }
  };

  const taskDataByKeyword = useQuery(
    ["tasklist", searchKeyword, currentPage, limit],
    fetchSearch,
    {
      onError: (error) => {}
    }
  );

  const listToUse = useMemo(() => {
    if (pendingApproval) {
      return tmsDataByStatus;
    } else if (searchKeyword) {
      return taskDataByKeyword;
    } else if (
      statusClick ||
      priorityClick ||
      duration ||
      startDate ||
      endDate
    ) {
      return taskDataByFilter;
    } else {
      return taskData;
    }
  }, [
    pendingApproval,
    searchKeyword,
    taskData,
    taskDataByKeyword,
    tmsDataByStatus
  ]);
  function calculateTotalPages(
    listToUse,
    pendingApproval,
    searchKeyword,
    statusClick,
    priorityClick,
    duration,
    startDate,
    endDate
  ) {
    if (pendingApproval) {
      return listToUse?.data?.data?.length || 0;
    } else if (searchKeyword) {
      return listToUse?.data?.totalTask || 0;
    } else if (
      statusClick ||
      priorityClick ||
      duration ||
      startDate ||
      endDate
    ) {
      return listToUse?.data?.totalTask || 0;
    } else {
      return listToUse?.data?.taskCount || 0;
    }
  }

  const approveTask = async (record) => {
    return axiosInstance.put(`api/task/updateTaskStatus/${record._id}`, {
      status: "complete"
    });
  };

  const mutateUpdateGrievance = useMutation(approveTask, {
    onSuccess: (data) => {
      toast.success(
        `${
          data?.data?.message.charAt(0).toUpperCase() +
          data?.data?.message.slice(1)
        }`
      );
      queryClient.invalidateQueries("tasklist");
      queryClient.invalidateQueries("taskPriorrityCount");
      queryClient.invalidateQueries("taskSummary");
      //  window.location.reload();
    },
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });

  const handleApprove = (record) => {
    mutateUpdateGrievance.mutateAsync(record);
  };

  const handleRowClick = (record) => {
    window.location.href = `#/tms/taskdetails/${record._id}`;
  };

  const handleCellClick = (e) => {
    e.stopPropagation(); // Prevent row click from being triggered
  };
  useEffect(() => {
    const calculatedTotalPages = calculateTotalPages(
      listToUse,
      pendingApproval,
      searchKeyword,
      statusClick,
      priorityClick,
      duration,
      startDate,
      endDate
    );
    setTotal(calculatedTotalPages);
  }, [listToUse, pendingApproval, searchKeyword, statusClick, priorityClick]);
  const isLoading = listToUse?.isLoading;

  useEffect(() => {
    setCurrentPage(1);
  }, [priorityClick, statusClick]);

  return (
    <>
      <div className='search-filter'>
        <div className='search-option'>
          <Input
            size='small'
            placeholder={t("search")}
            disabled={pendingApproval || statusClick || priorityClick}
            onChange={(e) => SearchCategory(e.target.value)}
          />
          <img src={SearchLine} alt='' />
        </div>
        {/* <Checkbox
          onChange={onStatusChange}
          className='checkbox'
          disabled={searchKeyword}
        >
          {t("Pending Approval")}
        </Checkbox> */}
      </div>
      <ToastContainer />

      <div className=''>
        <div className='gms-table'>
          <Table
            className='tms-list'
            rowKey={(record) => record._id}
            dataSource={listToUse?.data?.data || []}
            style={{ border: "none !important" }}
            pagination={pagination}
            locale={{
              emptyText: isLoading ? (
                <div
                  style={{
                    marginLeft: "20px",
                    width: "95%"
                  }}
                >
                  {" "}
                  {/* Container with 90% width */}
                  <Skeleton
                    title={false}
                    active
                    paragraph={{
                      rows: 6,
                      width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                    }}
                  />
                </div>
              ) : (
                <Empty />
              )
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record)
            })}
          >
            <Column
              title={t("Track Id")}
              dataIndex='_id'
              render={(title, record) => (
                <>
                  <div className='flex'>
                    <span className='pinIcon'>
                      {record &&
                      record.uploadImages &&
                      record.uploadImages.length ? (
                        <img
                          src={pinIcon}
                          alt='pin icon'
                          className='mr-4'
                          height='20px'
                          width='10px'
                        />
                      ) : null}
                    </span>
                    <Tooltip title={title}>
                      <span>{title.slice(0, 10)}...</span>
                    </Tooltip>
                  </div>
                </>
              )}
            />
            <Column
              title={t("Task Name")}
              dataIndex='title'
              render={(title, record) => (
                <>
                  <span className='flex'>
                    <span className='priorityIcon'>
                      {getPriorityIcon(record.priority)}
                    </span>
                    <span className='task_name_column'> {title}</span>
                  </span>
                </>
              )}
            />
            <Column
              title={t("dueDate")}
              dataIndex='dueDate'
              render={(text, record) => (
                <span onClick={handleCellClick}>
                  {record?.dueDate ? (
                    <div
                      className={`${
                        record?.status === "complete"
                          ? "completed-date"
                          : "date-assign"
                      }`}
                    >
                      <span>
                        {new Date(record?.dueDate).toLocaleDateString()}
                      </span>

                      {record?.status !== "complete" && (
                        <CloseOutlined
                          style={{ marginLeft: "8px", cursor: "pointer" }}
                          onClick={() => {
                            setTaskId(record?._id);
                            handleDueDateChange(null); // Pass null to handle clearing the date
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <DatePicker
                      placeholder='DD/MM/YY'
                      name='dueDate'
                      style={{ width: "120px" }}
                      value={record?.dueDate ? moment(record?.dueDate) : null}
                      onClick={() => setTaskId(record?._id)}
                      onChange={(date) => handleDueDateChange(date)}
                      disabledDate={(current) =>
                        current && current < moment().startOf("day")
                      }
                    />
                  )}
                </span>
              )}
            />
            <Column
              title={t("Assigned To")}
              dataIndex='assignedToCadre'
              render={(text, record) => (
                <span onClick={handleCellClick}>
                  {record.status && record.status !== "unassigned" ? (
                    <div
                      className={`${
                        record?.status === "assigned"
                          ? "date-assign"
                          : "completed-date"
                      }`}
                    >
                      <span>{record.assignedToUserName}</span>
                      {record?.status === "assigned" && (
                        <CloseOutlined
                          style={{ marginLeft: "8px", cursor: "pointer" }}
                          onClick={() => {
                            setTaskId(record?._id);
                            handleAssignTo({}, "unassigned");
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <Select
                      className='gms-select'
                      style={{ width: "120px", height: "32px!important" }}
                      value={
                        record.assignedToUserName ||
                        record.status === "unassigned"
                          ? "unassigned"
                          : record.assignedToUserName
                      }
                      showSearch
                      filterOption={(input, option) =>
                        option?.children?.props?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onClick={() => setTaskId(record?._id)}
                      onChange={(value, option) =>
                        handleAssignTo(option.data.user, "assigned")
                      }
                    >
                      {usersData?.data?.data?.data?.results?.map((user) => (
                        <Select.Option
                          key={user?._id}
                          value={user?._id}
                          data={{ user }}
                        >
                          {user?.firstName + " " + user?.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </span>
              )}
            />
            <Column
              title={t("Status")}
              const
              dataIndex='status'
              render={(status) => {
                if (status === "pendingApproval") {
                  return "Pending Approval";
                } else if (status === "inProgress") {
                  return "In Progress";
                } else if (status === "complete") {
                  return "Completed";
                } else {
                  return status;
                }
              }}
            />
            <Column
              title={t("ACTION")}
              key='action'
              render={(text, record, index) => (
                <div onClick={handleCellClick}>
                  <Space
                    size='middle'
                    onClick={() => {
                      setSelectedTaskId(record?._id);
                      setTaskStatus(record?.status);
                    }}
                  >
                    <div
                      className='dropdown'
                      ref={(el) => (dropdownRefs.current[index] = el)}
                    >
                      <span onClick={() => handleClick(index)}>
                        <EllipsisOutlined />
                      </span>
                      <div
                        className='dropdown-content'
                        style={{
                          display: openDropdown[index] ? "block" : "none"
                        }}
                      >
                        <Link to={`/tms/taskdetails/${record._id}`}>
                          <div>{t("Task details")}</div>
                        </Link>
                        {record?.status === "pendingApproval" && (
                          <div onClick={() => handleApprove(record)}>
                            {t("Approve")}
                          </div>
                        )}
                        <div onClick={() => showEditModal(record)}>
                          {t("Edit")}
                        </div>
                        <div onClick={() => showModalDelete(record)}>
                          {t("Delete")}
                        </div>

                        {(record?.status === "assigned" ||
                          record?.status === "inprogress") && (
                          <div
                            onClick={() => {
                              setTaskId(record?._id);
                              handleAssignTo({}, "unassigned");
                            }}
                          >
                            {t("Unassigned")}
                          </div>
                        )}
                      </div>
                    </div>
                  </Space>
                </div>
              )}
            />
          </Table>

          <div className='client-pagination pb-5 '>
            <div className='pagination-text'>
              {t("Showing")}{" "}
              <span className='px-2'>
                {" "}
                <Select
                  value={limit.toString()}
                  style={{ width: 56 }}
                  onChange={(value) => setLimit(parseInt(value))}
                  options={[
                    { value: "5", label: "5" },
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "50", label: "50" },
                    { value: "100", label: "100" }
                  ]}
                />{" "}
              </span>
              <span className='pl-1'>{t("entriesPerPage")}</span>
            </div>
            <div className='gms-selection'>
              <Pagination
                current={currentPage}
                pageSize={limit}
                total={total}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setLimit(pageSize);
                }}
                className='pagination'
              />
            </div>
          </div>
        </div>
      </div>
      {isDeleteModal && (
        <DeleteModal
          deleteBtn={t("Delete TMS")}
          deleteTitle={t("Delete Tms")}
          isDeleteModal={isDeleteModal}
          showModalDelete={showModalDelete}
          record={selectedRecord}
          handleDelete={handleDelete}
        />
      )}

      {viewModal && (
        <ViewTmsDetails
          viewModal={viewModal}
          showViewModal={showViewModal}
          selectedTaskId={selectedTaskId}
          taskStatus={taskStatus}
        />
      )}

      {editModal && (
        <EditTask
          selectedRecord={selectedRecord}
          editModal={editModal}
          setEditModal={setEditModal}
        />
      )}
    </>
  );
};

export default TmsList;
