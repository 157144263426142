import { useState, useEffect } from "react";

import useAxiosInstance from "../../../lib/useAxiosInstance";

const useGetAllTask = (
  isLoaded,
  statusClick,
  priorityClick,
  newGrievanceData
) => {
  const axiosInstance = useAxiosInstance();

  const duration = newGrievanceData?.duration || "";
  const startDate = newGrievanceData?.dateRange?.[0] || "";
  const endDate = newGrievanceData?.dateRange?.[1] || "";

  const [geojsonData, setGeojsonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const limit = 10000;
  const currentPage = 1;

  const fetchTaskData = async () => {
    try {
      // const token = localStorage.getItem("client-token");
      const response = await axiosInstance.post(
        `api/task/filterTask?page=${currentPage}&limit=${limit}&sort=DES`,
        {
          priority: statusClick,
          status: priorityClick,
          duration: duration,
          startDate: startDate,
          endDate: endDate
        }
      );

      const newGeojsonData = response?.data?.message?.data.map((task) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(task?.location?.lng),
              parseFloat(task?.location?.lat)
            ]
          },
          properties: {
            reported_by: task?.createdByUserName || "NA",
            assigned_to: task?.assignedToUserName || "NA",
            title: task?.title || "NA",
            status: task?.status || "NA",
            address: task?.location?.address || "NA",
            priority: task?.priority || "NA",
            description: task?.description || "",
            taskId: task?._id || ""
          }
        };
      });

      setGeojsonData(newGeojsonData);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLoaded) {
      fetchTaskData();
    }
  }, [
    isLoaded,
    currentPage,
    limit,
    priorityClick,
    statusClick,
    duration,
    startDate,
    endDate
  ]);

  return { geojsonData, loading, error };
};

export default useGetAllTask;
