import React, {  useState } from 'react'

const ConstituencyContext = React.createContext({
    isOpenModal: false,
    updateOpenModal: () => { },

    isOpenGraphModal: false,
    updateOpenGraphModal: () => { }
})
export const ConstituencyContextProvider = ({ children }) => {
    const [isOpenModalState, setIsOpenModalState] = useState(false)
    const [isOpenGraphModalState, setIsOpenGraphModalState] = useState(false)

    const contextValue = {
        isOpenModal: isOpenModalState,
        updateOpenModal: setIsOpenModalState,

        isOpenGraphModal: isOpenGraphModalState,
        updateOpenGraphModal: setIsOpenGraphModalState
    }
    return <ConstituencyContext.Provider value={contextValue}>{children}</ConstituencyContext.Provider>
}
export default ConstituencyContext