import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  Select,
  Radio,
  Form,
  Upload,
  InputNumber,
  message,
  DatePicker
} from "antd";
import dayjs from "dayjs";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiUploadCloud } from "react-icons/fi";
import Places from "./Places";
import "../../../src/index.css";
import { AppContext } from "../../context/AppContextProvider";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { t } from "i18next";
import moment from "moment";

const { TextArea } = Input;

const EditModal = ({
  selectedRecord = {},
  editModal,
  setEditModal = () => {}
}) => {
  const [form] = Form.useForm();
  const axiosInstance = useAxiosInstance();
  const { user } = useContext(AppContext);
  const queryClient = useQueryClient();

  const [currentStep, setCurrentStep] = useState(1);

  const [uploadImageData, setUploadImageData] = useState(
    selectedRecord?.uploadImages
  );
  const [locationData, setLocationData] = useState({});

  const [newGrievanceData, setNewGrievanceData] = useState({
    constituencyId: user?.constituencyId,
    officeId: selectedRecord?.officeId || null,
    visitorType: selectedRecord?.visitorType || null,
    numberOfPeople: selectedRecord?.numberOfPeople || null,
    firstName: selectedRecord?.firstName || "",
    lastName: selectedRecord?.lastName || " ",
    gender: selectedRecord?.gender || "",
    email: selectedRecord?.email || "",
    phone: selectedRecord?.phone || "",
    categoryId: selectedRecord?.categoryId || null,
    categoryName: selectedRecord?.categoryName || "",
    subCategoryId: selectedRecord?.subCategoryId || null,
    subCategoryName: selectedRecord?.subCategoryName || "",
    grievanceTitle: selectedRecord?.grievanceTitle || "NA",
    location: selectedRecord?.location || {},
    uploadImages: uploadImageData || [],
    priority: selectedRecord?.priority || "",
    description: selectedRecord?.description || "",
    dueDate: selectedRecord?.dueDate
      ? dayjs(
          moment(selectedRecord?.dueDate).format("DD/MM/YYYY"),
          "DD/MM/YYYY"
        )
      : null
  });
  const [parentId, setParentId] = useState();

  const [assignToData, setAssignToData] = useState(null);
  const grievanceStatus = selectedRecord?.status;

  const handleAssignTo = (user) => {
    setAssignToData({
      assignedToUserId: user?._id,
      assignedToUserName: `${user?.firstName} ${user?.lastName}`,
      assignedByUserRole: user?.role,
      status: "assigned"
    });
  };

  //Assign to user in grievance -->
  const assignToUser = async () => {
    return axiosInstance.put(
      `/api/grievances/updateGrievance/${selectedRecord?._id}`,
      assignToData
    );
  };
  const mutateAssignUser = useMutation(assignToUser, {
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });
  // useEffect(() => {
  //   if (assignToData != null) {
  //     mutateAssignUser.mutateAsync();
  //   }
  // }, [assignToData]);

  const onInputChange = (e) => {
    setNewGrievanceData({
      ...newGrievanceData,
      [e.target.name]: e.target.value
    });
  };

  //fetch all users-->
  const fetchUsers = async () => {
    return axiosInstance.get("/api/clients/info?page=1&limit=1000");
  };
  const usersData = useQuery("client-users", fetchUsers, {
    refetchOnWindowFocus: false
  });
  //Fetch Category List-->
  const fetchCategoryList = async () => {
    const response = await axiosInstance.get(
      `api/category/main?size=${100}&page=${1}&sort=${"DESC"}`
    );
    return response;
  };
  const categoryList = useQuery("Category-List", fetchCategoryList, {
    refetchOnWindowFocus: false,
    retry: 1
  });

  //Fetch Sub-Category List-->
  const fetchSubCategoryList = async (parentId) => {
    const response = await axiosInstance.get(
      `api/category/sub?size=${100}&page=${1}&sort=${"DESC"}&parentId=${parentId}`
    );
    return response;
  };
  const subCategoryList = useQuery(
    "Sub-Category-List",
    () => fetchSubCategoryList(parentId),
    { refetchOnWindowFocus: false, retry: 1 }
  );
  //populate subcategory data-->
  const handleSubCat = (id) => {
    setParentId(id);
  };
  // <--

  //add new Grievance-->
  const addGrievance = async () => {
    return axiosInstance.put(
      `api/grievances/updateGrievance/${selectedRecord?._id}`,
      newGrievanceData
    );
  };

  const mutateAddGrievance = useMutation(addGrievance, {
    onSuccess: (data) => {
      toast.success(
        `${
          data?.data?.message.charAt(0).toUpperCase() +
          data?.data?.message.slice(1)
        }`
      );

      //invalidate cache and refresh
      queryClient.invalidateQueries("GMS-List_Data");
      queryClient.invalidateQueries("gmsSummary");
      queryClient.invalidateQueries("gmsStatus");
      setEditModal(false);
    },
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });
  // <--

  //upload images-->
  const uploadImagesFunc = async (resourceLocation) => {
    const formData = new FormData();
    formData.append("grievanceImages", resourceLocation);
    return axiosInstance
      .post("api/grievances/uploadGrievanceImages", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then((res) => {
        setUploadImageData((prevState) => {
          if (Array.isArray(prevState)) {
            return [...prevState, res?.data?.data?.[0]];
          } else {
            return [res?.data?.data?.[0]];
          }
        });
        message.success(`${resourceLocation?.name} file uploaded successfully`);
      })
      .catch((err) =>
        message.error(
          `${resourceLocation?.name} file upload failed: ${err?.message}`
        )
      );
  };
  // <--

  const handleSubmit = (e) => {
    if (!mutateAddGrievance.isLoading) {
      mutateAddGrievance.mutateAsync();
    }

    if (assignToData != null) {
      mutateAssignUser.mutateAsync();
    }
  };
  const handleUploadImages = async (e) => {
    await uploadImagesFunc(e?.file);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = uploadImageData.filter(
      (image, imageIndex) => imageIndex !== index
    );
    setUploadImageData(updatedImages);
  };

  //modal functions-->
  const handleCancel = () => {
    setEditModal(false);
  };
  const handleNext = (e) => {
    setCurrentStep(currentStep + 1);
  };
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  // <--
  const preventFormSubmissionOnEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };
  // updating "newGrievanceData" state
  useEffect(() => {
    setNewGrievanceData((prevState) => ({
      ...prevState,
      uploadImages: uploadImageData
    }));
  }, [uploadImageData]);

  //updating "locationData" state
  useEffect(() => {
    setNewGrievanceData((prevState) => ({
      ...prevState,
      location: locationData
    }));
  }, [locationData]);

  useEffect(() => {
    if (parentId) {
      subCategoryList.refetch();
    }
  }, [parentId]);

  // multistep render form
  const renderStepOne = () => (
    <Form
      form={form}
      onFinish={handleNext}
      initialValues={{
        ...newGrievanceData,
        assignedToUserName: selectedRecord?.assignedToUserName
      }}
    >
      <ToastContainer />
      <div className='gms-modal'>
        <div className='modal-header'>
          <p>{t("editGrievance")}</p>
        </div>
        <div className='modal-body-container'>
          <div className='modal-body'>
            <h3>{t("issueDetails")}</h3>
            <Row className='row'>
              <Col span={8}>
                <label htmlFor=''>
                  {t("visitorType")}
                  <span className='required-field'>*</span>
                </label>
                <br></br>
                <Form.Item
                  name='visitorType'
                  rules={[
                    { required: true, message: t("pleaseSelectVisitor") }
                  ]}
                >
                  <Select
                    placeholder={t("selectVisitorType")}
                    size='large'
                    style={{ marginTop: "10px" }}
                    name='visitorType'
                    required
                    onChange={(value) =>
                      setNewGrievanceData({
                        ...newGrievanceData,
                        visitorType: value
                      })
                    }
                    options={[
                      {
                        value: "citizen",
                        label: t("citizen")
                      },
                      {
                        value: "vip",
                        label: t("vip")
                      },
                      {
                        value: "cadre",
                        label: t("cadre")
                      },
                      {
                        value: "other",
                        label: t("other")
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <label htmlFor=''>{t("numberOfPeople")}</label>
                <Form.Item
                  name='numberOfPeople'
                  onChange={(e) => onInputChange(e)}
                >
                  <InputNumber
                    name='numberOfPeople'
                    placeholder={t("numberOfPeople")}
                    style={{ marginTop: "10px", width: "100%" }}
                    min={0}
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className='row'>
              <Col span={8}>
                <label htmlFor=''>
                  {t("firstName")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='firstName'
                  rules={[
                    { required: true, message: t("pleaseEnterFirstName") }
                  ]}
                >
                  <Input
                    name='firstName'
                    style={{ marginTop: "8px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <label htmlFor=''>
                  {t("lastName")}
                  {/* <span className='required-field'>*</span> */}
                </label>
                <Form.Item
                  name='lastName'
                  // rules={[{ required: true, message: t("pleaseEnterLastName") }]}
                >
                  <Input
                    name='lastName'
                    style={{ marginTop: "8px" }}
                    onChange={(e) => onInputChange(e)}
                    // required
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className='row'>
              <Col span={8}>
                <label htmlFor=''>
                  {t("gender")}
                  <span className='required-field'>*</span>
                </label>
                <br></br>
                <Form.Item
                  name='gender'
                  rules={[{ required: true, message: t("pleaseSelectGender") }]}
                >
                  <Select
                    placeholder={t("gender")}
                    required
                    size='large'
                    style={{ marginTop: "10px" }}
                    name='gender'
                    onChange={(value) =>
                      setNewGrievanceData({
                        ...newGrievanceData,
                        gender: value
                      })
                    }
                    options={[
                      {
                        value: "male",
                        label: t("male")
                      },
                      {
                        value: "female",
                        label: t("female")
                      },
                      {
                        value: "others",
                        label: t("others")
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <label htmlFor=''>{t("email")}</label>
                <Form.Item
                  name='email'
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address"
                    }
                  ]}
                >
                  <Input
                    id='email'
                    name='email'
                    placeholder='john.smith@abc.com'
                    className='mt2'
                    style={{ marginTop: "10px" }}
                    onChange={(e) => onInputChange(e)}
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='row'>
              <Col span={8}>
                <label htmlFor=''>
                  {t("phone")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='phone'
                  rules={[
                    { required: true, message: "Please Enter the Phone No." },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number"
                    }
                  ]}
                >
                  <Input
                    type='tel'
                    required
                    addonBefore='+91'
                    id='phone'
                    name='phone'
                    placeholder='9876543210'
                    className='mt2 '
                    style={{ marginTop: "10px" }}
                    onChange={(e) => onInputChange(e)}
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>

              <Col span={8} offset={1}>
                <label htmlFor=''>{t("assignedto")}</label>
                <Form.Item name='assignedToUserName'>
                  <Select
                    style={{ marginTop: "10px" }}
                    placeholder='Select Assignee'
                    // showSearch
                    // filterOption={(inputValue, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(inputValue.toLowerCase()) >= 0
                    // }
                    // onClick={() => setGrievanceId(selectedRecord?._id)}
                    onChange={(value, option) =>
                      handleAssignTo(option.data.user)
                    }
                    disabled={grievanceStatus === "complete"}
                  >
                    {usersData?.data?.data?.data?.results?.map((user) => (
                      <Select.Option
                        key={user?._id}
                        value={user?._id}
                        data={{ user }}
                      >
                        {user?.firstName} {user?.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <label htmlFor=''>{t("dueDate")}</label>
                <Form.Item name='dueDate'>
                  <DatePicker
                    name='dueDate'
                    id='dueDate'
                    placeholder='DD/MM/YY'
                    className='mt2'
                    style={{ marginTop: "10px" }}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                    onChange={(date, dateString) => {
                      setNewGrievanceData((prev) => ({
                        ...prev,
                        dueDate: dateString
                      }));
                    }}
                    disabled={grievanceStatus === "complete"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        <div className='btn-flex'>
          <Button
            type='button'
            className='primary-btn mr3 mt3 mb3'
            htmlType='submit'
          >
            {t("next")}
          </Button>
        </div>
      </div>
    </Form>
  );

  const renderStepTwo = () => (
    <Form form={form} onFinish={handleNext} initialValues={newGrievanceData}>
      <div className='gms-modal'>
        <div className='modal-header'>
          <p>{t("editGrievance")}</p>
        </div>
        <div className='modal-body-container'>
          <div className='modal-body'>
            <h3>{t("issueDetails")}</h3>
            <Row className='row'>
              <Col span={8}>
                <label htmlFor=''>
                  {t("category")}
                  <span className='required-field'>*</span>
                </label>
                <br></br>
                <Form.Item
                  name='categoryName'
                  value=''
                  rules={[
                    {
                      required: true,
                      message: "Please Select the Category Name"
                    }
                  ]}
                >
                  <Select
                    placeholder='Select Category'
                    size='large'
                    style={{ marginTop: "10px" }}
                    name='categoryName'
                    onChange={(value, key) => {
                      setNewGrievanceData({
                        ...newGrievanceData,
                        categoryName: value
                      });
                      setNewGrievanceData((prevState) => ({
                        ...prevState,
                        categoryId: key.key
                      }));
                      handleSubCat(key?.key);
                    }}
                  >
                    {categoryList?.data?.data?.data?.map((category) => (
                      <Select.Option key={category._id} value={category.name}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <label htmlFor=''>
                  {t("subCategory")}
                  <span className='required-field'>*</span>
                </label>
                <br></br>
                <Form.Item
                  name='subCategoryName'
                  rules={[
                    {
                      required: true,
                      message: "Please Select the Sub-Category Name"
                    }
                  ]}
                >
                  {parentId ? (
                    <Select
                      placeholder='Select Sub-Category'
                      size='large'
                      style={{ marginTop: "10px" }}
                      name='subCategoryName'
                      onChange={(value, key) => {
                        setNewGrievanceData({
                          ...newGrievanceData,
                          subCategoryName: value
                        });
                        setNewGrievanceData((prevState) => ({
                          ...prevState,
                          subCategoryId: key?.key
                        }));
                      }}
                    >
                      {subCategoryList?.data?.data?.data?.map((subCategory) => (
                        <Select.Option
                          key={subCategory._id}
                          value={subCategory.name}
                        >
                          {subCategory.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      disabled
                      placeholder='Select Category first'
                      style={{ marginTop: "10px" }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row className='row'>
              <Col span={8}>
                <label htmlFor=''>{t("Title")}</label>
                <Form.Item name='grievanceTitle'>
                  <Input
                    name='grievanceTitle'
                    style={{ marginTop: "8px" }}
                    onChange={(e) => onInputChange(e)}
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <label htmlFor=''>
                  {t("priority")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='priority'
                  rules={[
                    {
                      required: true,
                      message: "Please Select the Priority"
                    }
                  ]}
                >
                  <Radio.Group
                    style={{ display: "block", marginTop: "25px" }}
                    name='priority'
                    onChange={(e) => onInputChange(e)}
                    required
                  >
                    <Radio value='low'>{t("low")}</Radio>
                    <Radio value='medium'>{t("medium")}</Radio>
                    <Radio value='high'>{t("high")}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row className='row'>
              <Col span={18}>
                <label htmlFor=''>
                  {t("description")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='description'
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the Description"
                    }
                  ]}
                >
                  <TextArea
                    rows={2}
                    required
                    id='description'
                    name='description'
                    onChange={(e) => onInputChange(e)}
                    style={{ marginTop: "8px", width: "97%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='gms-img-upload'>
              <Col span={18}>
                <label htmlFor=''> {t("uploadImages")}</label>
                <Form.Item name='uploadImages'>
                  <Upload.Dragger
                    beforeUpload={() => false}
                    onChange={handleUploadImages}
                    className='custom-img-upload'
                    style={{ marginTop: "8px" }}
                    type='file'
                    fileList={[]}
                  >
                    <>
                      <FiUploadCloud
                        style={{ color: "#004877", fontSize: "50px" }}
                      />
                      <p className='ant-upload-text'>
                        {t("selectFile")}
                        <br />
                        <span>{t("fileSizeInfo")}</span>{" "}
                      </p>
                    </>
                    <div className='ImgUpload'> {t("selectFileButton")} </div>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
            </Row>

            <Row className='gms-uploaded-images-div'>
              {newGrievanceData?.uploadImages?.map((image, index) => (
                <Col span={4} key={index}>
                  <div className='image-box'>
                    <div
                      className='delete-icon'
                      onClick={() => handleDeleteImage(index)}
                    >
                      <i className='fas fa-trash-alt'></i>
                    </div>
                    <img
                      src={image?.publicUrl}
                      alt={image?.name}
                      className='uploaded-image_item'
                      onClick={() => window.open(image?.publicUrl, "_blank")}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>

      <div className='btn-flex1'>
        <Button
          type='primary'
          className='prev-button ml3 mt3 mb3'
          onClick={handleBack}
        >
          {t("previous")}
        </Button>
        <Button
          type='button'
          className='primary-btn mr3 mt3 mb3'
          htmlType='submit'
        >
          {t("next")}
        </Button>
      </div>
    </Form>
  );

  const renderStepThree = () => (
    <Form form={form} onFinish={handleSubmit}>
      <div className='gms-modal'>
        <div className='modal-header'>
          <p>{t("editGrievance")}</p>
        </div>
        <div className='modal-body'>
          <h3>
            {t("location")}
            <span className='required-field'>*</span>
          </h3>
          <Places
            initialLocation={selectedRecord?.location}
            setLocationData={setLocationData}
          />
        </div>
      </div>
      <div className='btn-space'>
        <Button
          type='primary'
          className='prev-button ml3 mt3 mb3'
          onClick={handleBack}
        >
          {t("previous")}
        </Button>
        <Button
          type='button'
          className='primary-btn mr3 mt3 mb3'
          htmlType='submit'
        >
          {t("editGrievance")}
        </Button>
      </div>
    </Form>
  );
  return (
    <Modal
      className='modal-container'
      footer={null}
      open={editModal}
      onCancel={handleCancel}
      width={1070}
      maskClosable={false}
    >
      <div className='gms-modal'>
        {currentStep === 1
          ? renderStepOne()
          : currentStep === 2
          ? renderStepTwo()
          : renderStepThree()}
      </div>
    </Modal>
  );
};

export default EditModal;
