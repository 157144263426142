import React from "react";
import "../../../Styles/organizationalHierarchy.css";
import { Tree, TreeNode } from "react-organizational-chart";
import defaultImg from "../../../assets/images/placeholder-icon.png";
import { useQuery } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
const roleMapping = {
  data_entry_operator: "Data Entry Operator",
  chief_of_staff: "Chief of Staff",
  constituency_manager: "Constituency Manager",
  office_manager: "Office Manager",
  client: "Client",
  cadre: "Cadre"
};
const OrganizationalHierarchy = () => {
  const axiosInstance = useAxiosInstance();

  // GET ORGANIZATIONAL CLIENT LIST
  const fetchOrgnizationalList = async () => {
    const response = await axiosInstance.get(`api/clients/info/organizationalHierarchy`);
    return response.data.data;
  };
  const orgList = useQuery("orgnizational-list", fetchOrgnizationalList);

  const renderHierarchy = (nodes, level = 0) => {
    return nodes?.flatMap((node) => {
      return node?._id ? (
        <TreeNode
          key={`office-${node._id}`}
          label={
            node?._id ? (
              <div className={`styled-node level-${level + 1}`}>
                <div className='org-img'>
                  <img
                    src={node.profileImageLink?.publicUrl || defaultImg}
                    alt={node?.firstName}
                  />
                </div>
                <div className='flex flex-col hierarchy-info'>
                  <span className='capitalize'>
                    {node?.firstName} {node?.lastName}
                  </span>
                  <span className=''>
                    {roleMapping[node?.role] || node?.role}
                  </span>
                </div>
              </div>
            ) : null
          }
        >
          {Array.isArray(node?.worksUnder) &&
            node?.worksUnder.length > 0 &&
            renderHierarchy(node?.worksUnder, level + 1)}
        </TreeNode>
      ) : (
        nodes?.flatMap((node) => {
          return Object.entries(node)
            .filter(([key, value]) => Array.isArray(value) && key !== 'worksUnder')
            .map(([officeName, offices]) => (
              <TreeNode
                key={`office-${officeName}`}
                label={
                  <div className='office-name'>
                    <span>Office: {officeName}</span>
                  </div>
                }
                className='office-node'
              >
                {offices?.map((office) => (
                  <TreeNode
                    key={`office-${office._id}`}
                    label={
                      <div className={`styled-node level-${level + 1} `}>
                        <div className='org-img'>
                          <img
                            src={office.profileImageLink?.publicUrl || defaultImg}
                            alt={office?.firstName}
                          />
                        </div>
                        <div className='flex flex-col hierarchy-info'>
                          <span className='capitalize'>
                            {office?.firstName} {office?.lastName}
                          </span>
                          <span>{roleMapping[office?.role]}</span>
                        </div>
                      </div>
                    }
                  >
                    {Array.isArray(office?.worksUnder) &&
                      office?.worksUnder.length > 0 &&
                      renderHierarchy(office?.worksUnder, level + 1)}
                  </TreeNode>
                ))}
              </TreeNode>
            ));
        })
      );
    });
  };
  return (
    <>
      <div className='hierarchy' >
        <Tree 
          lineWidth={"2px"}
          lineColor={"#D4D4D4B2"}
          lineBorderRadius={"0"}
          label={
            <div className='styled-node'>
              <div className='org-img'>
                <img
                  src={orgList?.data?.profileImageLink?.publicUrl || defaultImg}
                  alt={orgList?.data?.firstName}
                />
              </div>
              <div className='flex flex-col hierarchy-info'>
                <span className='capitalize'>
                  {orgList?.data?.firstName} {orgList?.data?.lastName}
                </span>
                <span>{roleMapping[orgList?.data?.role]}</span>
              </div>
            </div>
          }
        >
          {renderHierarchy(orgList?.data?.worksUnder)}
        </Tree>
      </div>
    </>
  );
};

export default OrganizationalHierarchy;

