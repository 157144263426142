function formatDate(inputDate) {
  const date = new Date(inputDate);
  const options = {
    day: "numeric",
    month: "2-digit",
    year: "numeric"
  };

  return date.toLocaleString("en-US", options) || "";
}
export default formatDate;
