import React from "react";
import { Col, Input, Modal, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import CustSupportContext from "../../context/custSupportContext";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useMutation, useQueryClient } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../Forms/ErrorMessage";
import { useTranslation } from "react-i18next";

const CustSupportForm = ({ isOpen, isAddMode }) => {
  const { t } = useTranslation();
  const custSupportContext = useContext(CustSupportContext);
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const FormSchema = Yup.object().shape({
    loggedBy: Yup.string().required("Your Name required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Valid email is required")
      .matches(
        "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
        "Valid email is required"
      )
      .min(10, "Email is minimum 10 character")
      .max(100, "Email is maximum 100 character"),
    phone: Yup.string()
      .required("Phone is required")
      .min(10, "Phone must be 10 digit")
      .max(10, "Phone must be 10 digit"),

    comment: Yup.string().required("Description is required")
  });

  const formik = useFormik({
    initialValues: {
      loggedBy: isAddMode ? "" : custSupportContext.editData.loggedBy,
      issueName: isAddMode ? "" : custSupportContext.editData.issueName,
      email: isAddMode ? "" : custSupportContext.editData.email,
      phone: isAddMode ? "" : custSupportContext.editData.phone,
      category: isAddMode ? null : custSupportContext.editData.category,
      comment: isAddMode ? "" : custSupportContext.editData.comment,
      ticketStatus: isAddMode
        ? "open"
        : custSupportContext.editData.ticketStatus,
      date: isAddMode ? "" : custSupportContext.editData.date
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const handleSubmit = async (value) => {
    // console.log("handleSubmit called"); // Add this line

    if (isAddMode) {
      const date = new Date();
      handleCreateTicket.mutate(
        { ...value, date },
        {
          onSuccess: () => {
            // Invalidate the relevant queries after the mutation is successful
            queryClient.invalidateQueries(["allTickets"]);
            queryClient.invalidateQueries(["unassignedTickets"]);
            queryClient.invalidateQueries(["pendingTickets"]);
            queryClient.invalidateQueries(["resolvedTickets"]);
          }
        }
      );
    } else {
      handleUpdateTicket.mutate(value, {
        onSuccess: () => {
          // Invalidate the relevant queries after the mutation is successful
          queryClient.invalidateQueries(["allTickets"]);
          queryClient.invalidateQueries(["unassignedTickets"]);
          queryClient.invalidateQueries(["pendingTickets"]);
          queryClient.invalidateQueries(["resolvedTickets"]);
        }
      });
    }
  };

  const handleCreateTicket = useMutation(
    (formData) =>
      axiosInstance
        .post(`api/tickets`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );

  const handleUpdateTicket = useMutation(
    (formData) =>
      axiosInstance
        .put(`api/tickets/${custSupportContext.editData._id}`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );

  const handleSuccess = (data) => {
    queryClient.invalidateQueries("customerData");
    closeModal();
    toast.success(`${data?.message}`);
  };

  const handleError = (error) => {
    if (error?.response?.data?.message) {
      let msg = error.response.data.message;
      if (msg.includes("email")) {
        formik.setFieldError("email", msg);
      } else {
        toast.error(error.response.data.message);
        closeModal();
      }
    } else {
      toast.error("Something went wrong.");
      closeModal();
    }
  };

  const closeModal = () => {
    custSupportContext.updateOpenModal(false);
    custSupportContext.updateAddMode(true);
    formik.resetForm();
  };

  return (
    <div>
      {isOpen && (
        <Modal
          className='user-modal-wrapper'
          maskClosable={false}
          open={isOpen}
          width={960}
          onCancel={closeModal}
          footer={[
            <button
              key='add-cust'
              form='cust-form'
              type='submit'
              className='modal-footer-button'
            >
              {isAddMode ? t("Submit Ticket") : t("Save Changes")}
            </button>
          ]}
        >
          <form id='cust-form' onSubmit={formik.handleSubmit}>
            <div className='user-info-modal'>
              <div className='modal-header'>
                <h3>{isAddMode ? t("Create New Ticket") : t("Edit Ticket")}</h3>
              </div>
              <div className='user-modal-body-wrapper'>
                <div className='user-modal-body'>
                  <h4>{t("Ticket Details")}</h4>
                  <Row className='user-modal-row'>
                    <Col span={9} className='mr-6'>
                      <label htmlFor=''>{t("Your Name")}</label>
                      <Input
                        type='text'
                        name='loggedBy'
                        onChange={formik.handleChange}
                        value={formik.values.loggedBy}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.loggedBy && formik.touched.loggedBy
                        )}
                        message={formik.errors.loggedBy}
                      />
                    </Col>
                  </Row>
                  <Row className='user-modal-row'>
                    <Col span={9} className='mr-6'>
                      <label htmlFor=''>{t("Email")}</label>
                      <Input
                        type='email'
                        name='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.email && formik.touched.email
                        )}
                        message={formik.errors.email}
                      />
                    </Col>
                    <Col span={9}>
                      <label htmlFor=''>{t("Phone")}</label>
                      <Input
                        type='tel'
                        addonBefore='+91'
                        name='phone'
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.phone && formik.touched.phone
                        )}
                        message={formik.errors.phone}
                      />
                    </Col>
                  </Row>
                  <Row className='user-modal-row'>
                    <Col span={9} className='mr-6'>
                      <label htmlFor=''>{t("Category")}</label>
                      <Select
                        placeholder='Please Select'
                        name='category'
                        value={formik.values.category}
                        onChange={(value) => {
                          formik.handleChange("category")(value);
                        }}
                        options={[
                          {
                            value: "GMS"
                          },
                          {
                            value: "TMS"
                          },
                          {
                            value: "Tasks"
                          },
                          {
                            value: "Report"
                          },
                          {
                            value: "Survey"
                          },
                          {
                            value: "Users"
                          },
                          {
                            value: "Other"
                          }
                        ]}
                      />
                    </Col>
                    <Col span={9}>
                      <label htmlFor=''>{t("Issue")}</label>

                      <Input
                        type='text'
                        placeholder='Enter Issue'
                        name='issueName'
                        onChange={formik.handleChange}
                        value={formik.values.issueName}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.issueName && formik.touched.issueName
                        )}
                        message={formik.errors.issueName}
                      />
                    </Col>
                  </Row>
                  <Row className='user-modal-row mb-6'>
                    <Col span={19}>
                    <label htmlFor=''>{t("Comments")}</label>
                      <TextArea
                        className='pt-4 pl-4 pb-6'
                        onChange={formik.handleChange}
                        rows={2}
                        name='comment'
                        value={formik.values.comment}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.comment && formik.touched.comment
                        )}
                        message={formik.errors.comment}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default CustSupportForm;
