import React from "react";
import "../../Styles/helpcenter.css";
import Balu from "../../assets/videos/movie.mp4";
// import HelpcenterInnerpage from './helpcenter-innerpage'
import Assismanagement from "../../assets/images/icon/Assignment.png";
import icon1 from "../../assets/images/icon/rocket.png";
import icon2 from "../../assets/images/icon/icon2.png";
import icon3 from "../../assets/images/icon/icon3.png";
import icon4 from "../../assets/images/icon/ballot.png";
import { RiSlideshow3Fill } from "react-icons/ri";
import { BsQuestionCircleFill } from "react-icons/bs";
import PageHeading from "../../Components/PageHeading";
import { useTranslation } from "react-i18next";

const HelpCenter = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='helpcenter-heading'>
        <PageHeading pageTitle={t("Help Center")} />{" "}
      </div>

      <div className='helpcenter-common'>
        <h3 className='pb-5 flex '>
          <span className='icon-clr'>
            <BsQuestionCircleFill className='di mr-3 ' />
          </span>{" "}
          {t("Help Topics")}
        </h3>
        <div className='grid grid-cols-3 gap-8'>
          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'>
                {" "}
                <img src={icon1} alt='icon' />
              </div>
              <h6 className='pt-7 leading-4'>{t("Getting Started")}</h6>
            </div>

            <p className='help-subpara px-4'>
              {t(
                "This topic will help you to get you started quickly and help you get familiar with the application."
              )}
            </p>
          </div>

          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'>
                {" "}
                <img src={Assismanagement} alt='icon' />
              </div>

              <h6 className='pt-7 leading-4'>{t("Grievance Management")}</h6>
            </div>

            <p className='help-subpara px-4'>
              {t(
                "This topic will help you to get you started quickly and help you get familiar with the application."
              )}
            </p>
          </div>
          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'>
                {" "}
                <img src={icon2} alt='icon' />
              </div>
              <h6 className='pt-7'>{t("Constituency Map")}</h6>
            </div>
            <p className='help-subpara px-4'>
              {t(
                "This topic will help you to get you started quickly and help you get familiar with the application."
              )}
            </p>
          </div>
          {/* <div className='help-topic'>
                        <div className='flex'>
                            <img className='helptopic-innerbox'></img>
                            <p className='pt-7 font-bold text-lg leading-4'>Cadre Management</p>
                        </div>

                        <p className='help-subpara px-4'>{t("This topic will help you to get you started
                            quickly and help you get familiar with the
                            application.")}</p>

                    </div> */}

          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'>
                {" "}
                <img src={Assismanagement} alt='icon' />
              </div>

              <h6 className='pt-7'>{t("Reports")}</h6>
            </div>

            <p className='help-subpara px-4'>
              {t(
                "This topic will help you to get you started quickly and help you get familiar with the application."
              )}
            </p>
          </div>

          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'>
                {" "}
                <img src={icon3} alt='icon' />
              </div>
              <h6 className='pt-7'>{t("Users")}</h6>
            </div>

            <p className='help-subpara px-4'>
              {t(
                "This topic will help you to get you started quickly and help you get familiar with the application."
              )}
            </p>
          </div>
          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'>
                {" "}
                <img src={icon4} alt='icon' />
              </div>
              <h6 className='pt-7'>{t("Surveys")}</h6>
            </div>

            <p className='help-subpara px-4'>
              {t(
                "This topic will help you to get you started quickly and help you get familiar with the application."
              )}
            </p>
          </div>
          {/* <div className='help-topic'>
                        <div className='flex'>
                            <img className='helptopic-innerbox'></img>
                            <p className='pt-7 font-bold text-lg leading-4'>Troubleshooting & FAQs</p>
                        </div>

                        <p className='help-subpara px-4'>{t("This topic will help you to get you started
                            quickly and help you get familiar with the
                            application.")}</p>
                    </div> */}
        </div>

        {/* usefull Videos */}

        <h3 className=' pb-5 pt-10 mt-15 flex'>
          <span>
            <RiSlideshow3Fill className='di mr-3 icon-clr' />{" "}
          </span>
          {t("Useful Videos")}
        </h3>
        <div className='grid grid-cols-3 gap-8'>
          <div>
            <video
              className='rounded-lg'
              width='100%'
              height='200'
              autoPlay
              muted
            >
              <source src={Balu} type='video/mp4' />
              {t("Your browser does not support the video tag.")}
            </video>
            <div>
              <h6 className='pt-4'>{t("Getting Started")}</h6>
              <p className='help-subpara pt-4'>
                {t("Watch this video for a walkthrough of the application.")}
              </p>
            </div>
          </div>

          <div>
            <video
              className='rounded-lg'
              width='100%'
              height='200'
              autoPlay
              muted
            >
              <source src={Balu} type='video/mp4' />
              {t("Your browser does not support the video tag.")}
            </video>
            <div>
              <h6 className='pt-4'>{t("Adding New Grievance")}</h6>
              <p className='help-subpara pt-4'>
                {t("Watch this video for a walkthrough of the application.")}
              </p>
            </div>
          </div>

          <div>
            <video
              className='rounded-lg'
              width='100%'
              height='200'
              autoPlay
              muted
            >
              <source src={Balu} type='video/mp4' />
              {t("Your browser does not support the video tag.")}
            </video>
            <div>
              <h6 className='pt-4'>{t("Assigning Task to Cadre")}</h6>
              <p className='help-subpara pt-4'>
                {t("Watch this video for a walkthrough of the application.")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenter;
