const durationDataFormatter = (data = [], type) => {
  if (!data || data.length === 0) {
    console.error("Data is undefined or empty.");
    return [];
  }
  // Helper function to format dates
  const formatDate = (date) => {
    const d = new Date(date);
    const month = "" + (d.getMonth() + 1);
    const day = "" + d.getDate();
    const year = d.getFullYear();
    return [month.padStart(2, "0"), day.padStart(2, "0"), year].join("/");
  };

  // Helper function to group data by month
  const groupByMonth = (data = []) => {
    const monthlyData = [];
    const months = new Set();

    for (let item of data) {
      const date = new Date(item.x);
      const month = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;
      if (!months.has(month)) {
        months.add(month);
        monthlyData.push(item);
      }
      if (monthlyData.length === 12) break;
    }

    return monthlyData;
  };

  // Helper function to group data by week
  const groupByWeek = (data) => {
    const weeklyData = [];
    for (let i = 0; i < data.length; i += 7) {
      weeklyData.push(data[i]);
      if (weeklyData.length === 52) break;
    }
    return weeklyData;
  };

  // Process data based on type
  const processData = (data) => {
    let processedData;

    if (type === "daily") {
      processedData = data.map((item, index, array) => ({
        x: formatDate(item.x),
        count: parseInt(item.y, 10),
        y:
          index < array.length - 1
            ? parseInt(item.y, 10) - parseInt(array[index + 1].y, 10)
            : 0
      }));
    } else if (type === "monthly") {
      const groupedData = groupByMonth(data);
      processedData = groupedData.map((item, index, array) => ({
        x: formatDate(item.x),
        count: parseInt(item.y, 10),
        y:
          index < array.length - 1
            ? parseInt(item.y, 10) - parseInt(array[index + 1].y, 10)
            : 0
      }));
    } else if (type === "weekly") {
      const groupedData = groupByWeek(data);
      processedData = groupedData.map((item, index, array) => ({
        x: formatDate(item.x),
        count: parseInt(item.y, 10),
        y:
          index < array.length - 1
            ? parseInt(item.y, 10) - parseInt(array[index + 1].y, 10)
            : 0
      }));
    }

    return processedData;
  };

  return data.map((item) => ({
    name: item?.name.charAt(0).toUpperCase() + item?.name.slice(1),
    data: processData(item?.data)
  }));
};

export default durationDataFormatter;
