import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const SocialMediaNewVisitors = ({ data }) => {
  const { t } = useTranslation();
  const platforms = ["Facebook", "Instagram", "Twitter"];
  const defaultDifference = 0; // Default value if a platform is not in the data
  const rawData = platforms.map((platform) => {
    const platformKey = platform.toLowerCase();
    return data[platformKey]?.followerGrowth30Day || defaultDifference;
  });

  // Find the absolute maximum value in the rawData for normalization
  const maxAbsValue = Math.max(...rawData.map(Math.abs));

  // Normalize the data to a range of -100 to 100
  const newVisitorData = rawData.map((value) => (value / maxAbsValue) * 100);

  const options = {
    series: newVisitorData,
    chart: {
      height: 180,
      type: "radialBar"
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 8,
          size: "20%",
          background: "transparent",
          image: undefined
        },
        track: {
          background: "var(--track-bg)", // Color for the remaining space
          strokeWidth: "90%",
          margin: 9, // margin is in pixels
          dropShadow: {
            enabled: false
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: true,
            formatter: function (value) {
              return Math.round((value / 100) * maxAbsValue);
            }
          }
        },
        barLabels: {
          enabled: true,
          useSeriesColors: true,
          margin: 10,
          fontSize: "11px",
          formatter: function (seriesName, opts) {
            return `${platforms[opts.seriesIndex]}: ${
              rawData[opts.seriesIndex]
            }`;
          }
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#1877F2", "#FFA44B", "#000"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    colors: ["#1877F2", "#C837AB", "#000"],
    labels: platforms,
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }
    ]
  };

  return (
    <div className='social-media-traffic-graph'>
      <div className='overview-heading'>
        <h4 className='overview-text'>{t("New followers (Last 30 Days)")}</h4>
      </div>
      <div className='social-media-traffic-chart h-48 mt-2 flex justify-center items-center'>
        <Chart options={options} series={options.series} type='radialBar' />
      </div>
    </div>
  );
};

export default SocialMediaNewVisitors;
