import React, { useContext } from "react";
import { Modal, Input, Row, Col } from "antd";
import "../../../Styles/office.css";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ErrorMessage from "../../Forms/ErrorMessage";
import AssetsManagementContext from "../../../context/assetsManagementContext";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;

const AddOffice = ({ isOpen, isAddMode }) => {
  const { t } = useTranslation();

  const assetsContext = useContext(AssetsManagementContext);
  const queryClient = useQueryClient();
  const axiosInstance = useAxiosInstance();

  //fetch offices-->
  const fetchOffices = async () => {
    const response = await axiosInstance.get(`/api/clients/office/info`);
    // setTotal(response.data.data.totalPages);
    return response;
  };

  const officesData = useQuery(["client-offices"], () => fetchOffices());
  // <--

  const FormSchema = Yup.object().shape({
    officeName: Yup.string()
      .trim()
      .required("Office Name is required")
      .min(3, "First Name is minimum 3 character")
      .max(50, "First Name is maximum 50 character"),
    officeLocation: Yup.string()
      .trim()
      .required("Office Location is required")
      .min(3, "Last Name is minimum 3 character")
      .max(50, "Last Name is maximum 50 character")
    // constituencyName: Yup.string().required('Constituency is required')
  });

  const formik = useFormik({
    initialValues: {
      officeName: isAddMode ? "" : assetsContext.editData.officeName,
      officeLocation: isAddMode ? "" : assetsContext.editData.officeLocation,
      constituencyName:
        officesData?.data?.data?.data?.results[0]?.constituencyName
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const closeModal = () => {
    assetsContext.updateOpenModal(false);
    assetsContext.updateAddMode(true);
    formik.resetForm();
  };

  // <--
  //handle submit event
  const handleSubmit = async (value) => {
    if (isAddMode) {
      // mutateAddOffice.mutateAsync();
      handleCreateOffice.mutate(value);
    } else {
      handleUpdateOffice.mutate(value);
    }
  };

  const handleCreateOffice = useMutation(
    (formData) =>
      axiosInstance
        .post(`/api/clients/office/`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );
  const handleUpdateOffice = useMutation(
    (formData) =>
      axiosInstance
        .put(`/api/clients/office/${assetsContext.editData._id}`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );
  const handleSuccess = (data) => {
    queryClient.invalidateQueries("allSearchOffices");
    closeModal();
    toast.success(`${data?.message}`);
  };
  const handleError = (error) => {
    toast.error("Something went wrong.");
    closeModal();
  };
  return (
    <React.Fragment>
      {isOpen && (
        <Modal
          className='user-modal-wrapper'
          open={isOpen}
          onCancel={closeModal}
          width={960}
          maskClosable={false}
          footer={[
            <button
              key='modalFooterButton'
              form='office-form'
              type='submit'
              className='modal-footer-button'
            >
              {isAddMode ? t("Add New Office") : t("Save Changes")}
            </button>
          ]}
        >
          <form id='office-form' onSubmit={formik.handleSubmit}>
            <div className='user-info-modal'>
              <div className='modal-header'>
                <h3>
                  {isAddMode ? t("Add New Office") : t("Edit New Office")}
                </h3>
              </div>
              <div className='user-modal-body-wrapper'>
                <div className='user-modal-body'>
                  <h4>{t("Office Details")}</h4>
                  <Row className='user-modal-row'>
                    <Col span={9} className='mr-4'>
                      <label htmlFor=''>{t("Name")}</label>
                      <Input
                        type='text'
                        placeholder='Jhon'
                        name='officeName'
                        value={formik.values.officeName}
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.officeName && formik.touched.officeName
                        )}
                        message={formik.errors.officeName}
                      />
                    </Col>
                    <Col span={9} className='ml-6'>
                      <label htmlFor=''>{t("Constituency")}</label>
                      <Input
                        type='text'
                        name='constituencyName'
                        value={formik.values.constituencyName}
                        disabled
                      />
                      {/* <ErrorMessage
                                                hasError={Boolean(formik.errors.constituencyName && formik.touched.constituencyName)}
                                                message={formik.errors.constituencyName}
                                            /> */}
                    </Col>
                  </Row>
                  <Row className='user-modal-row'>
                    <Col span={19}>
                      <label htmlFor=''>{t("Address")}</label>
                      <TextArea
                        rows={3}
                        name='officeLocation'
                        value={formik.values.officeLocation}
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.officeLocation &&
                            formik.touched.officeLocation
                        )}
                        message={formik.errors.officeLocation}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </React.Fragment>
  );
};
export default AddOffice;
