import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  Radio,
  Form,
  Upload,
  message,
  DatePicker
} from "antd";
import { FiUploadCloud } from "react-icons/fi";
import Places from "./Places";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const { TextArea } = Input;
const user = JSON.parse(localStorage.getItem("user"));

const AddNewTask = ({
  addTaskModal,
  setAddTaskModal,
  setActiveTab = () => { }
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const [currentStep, setCurrentStep] = useState(1);
  const [locationData, setLocationData] = useState("");
  const [uploadImageData, setUploadImageData] = useState([]);

  const [newTaskData, setNewTaskData] = useState({
    title: "",
    status: "unassigned",
    assignedToUserId: null,
    assignedToUserName: "",
    assignedToUserRole: "",
    location: {
      latitude: "",
      longitude: "",
      c_id: 2,
      color: "",
      Name: "",
      st_name: ""
    },
    uploadImages: [
      {
        guid: "",
        name: "",
        key: "",
        publicUrl: ""
      }
    ],
    typeStatus: "",
    priority: " ",
    description: " ",
    officeId: user?.officeId?.toString() || null
  });

  const onInputChange = (e) => {
    setNewTaskData({ ...newTaskData, [e.target.name]: e.target.value });
  };

  // Add New Task Post
  const addNewTask = async () => {
    return axiosInstance.post(`api/task/createTask`, newTaskData);
  };

  const mutateAddTask = useMutation(addNewTask, {
    onSuccess: (data) => {
      toast.success(`${data?.data?.message}`);
      //invalidate cache and refresh
      queryClient.invalidateQueries("tasklist");
      queryClient.invalidateQueries("taskPriorrityCount");
      queryClient.invalidateQueries("taskSummary");

      setAddTaskModal(false);
      setActiveTab("list");
      // fetchSummary();
    },
    onError: (error) =>
      toast.error(
        `something went wrong: ${error?.response?.data?.error?.message}`
      )
  });

  //upload images-->
  const uploadImages = async (uploadImages) => {
    const formData = new FormData();
    formData.append("taskImages", uploadImages);
    return axiosInstance
      .post(`api/task/uploadTaskImages`, formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then((res) => {
        setUploadImageData((prevState) => [...prevState, res?.data?.data?.[0]]);
        message.success(`${uploadImages?.name} file uploaded successfully`);
      })
      .catch((err) =>
        message.error(
          `${uploadImages?.name} file upload failed: ${err?.message}`
        )
      );
  };

  // <--
  // const handleSubmit = (e) => {
  //   // e.preventDefault();
  //   mutateAddTask.mutateAsync();
  // }

  const handleSubmit = () => {
    if (!mutateAddTask.isLoading) {
      mutateAddTask.mutateAsync();
    }
  };
  const preventFormSubmissionOnEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };

  const handleUploadImages = async (e) => {
    await uploadImages(e?.file);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = uploadImageData.filter(
      (image, imageIndex) => imageIndex !== index
    );
    setUploadImageData(updatedImages);
  };

  //updating "newGrievanceData" state
  useEffect(() => {
    setNewTaskData((prevState) => ({
      ...prevState,
      uploadImages: uploadImageData
    }));
  }, [uploadImageData]);

  // //updating "locationData" state
  useEffect(() => {
    if (!locationData) {
      setNewTaskData((prevState) => ({
        ...prevState,
        location: {
          latitude: "",
          longitude: "",
          c_id: 2,
          color: "",
          Name: "",
          st_name: ""
        }
      }));
    } else {
      setNewTaskData((prevState) => ({
        ...prevState,
        location: locationData
      }));
    }
  }, [locationData]);

  //modal functions-->
  const handleCancel = () => {
    setAddTaskModal(false);
  };
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };
  const handleBack = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };

  const renderStepOne = () => (
    <Form form={form} onFinish={handleNext}>
      <div className='gms-modal'>
        <div className='modal-header'>
          <p>{t("Add New Task")}</p>
        </div>
        <div className="modal-body-container">
          <div className='modal-body'>
            <h3>{t("Task Details")}</h3>
            <Row className='row'>
              <Col span={8}>
                <label htmlFor='title'>
                  {t("Task Name")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='title'
                  rules={[
                    { required: true, message: "Please enter the task name" }
                  ]}
                >
                  <Input
                    name='title'
                    style={{ marginTop: "8px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                    onKeyPress={preventFormSubmissionOnEnter}
                  />
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <label htmlFor=''>{t("dueDate")}</label>
                <Form.Item name='dueDate'>
                  <DatePicker
                    name='dueDate'
                    id='dueDate'
                    placeholder='DD/MM/YY'
                    className='mt2'
                    style={{ marginTop: "10px" }}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                    onChange={(date, dateString) => {
                      setNewTaskData((prev) => ({
                        ...prev,
                        dueDate: dateString
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <label htmlFor='priority'>
                  {"Priority"}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='priority'
                  rules={[
                    { required: true, message: "Please select the priority" }
                  ]}
                >
                  <Radio.Group
                    style={{ display: "block", marginTop: "25px" }}
                    name='priority'
                    required
                    onChange={(e) => onInputChange(e)}
                  >
                    <Radio value='low'>{t("Low")}</Radio>
                    <Radio value='medium'>{t("Medium")}</Radio>
                    <Radio value='high'>{t("High")}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row className='row'>
              <Col span={19}>
                <label htmlFor='description'>
                  {t("Description")}
                  <span className='required-field'>*</span>
                </label>
                <Form.Item
                  name='description'
                  rules={[
                    { required: true, message: "Please enter the description" }
                  ]}
                >
                  <TextArea
                    rows={2}
                    id='description'
                    name='description'
                    required
                    onChange={(e) => onInputChange(e)}
                    style={{ marginTop: "8px", width: "97%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='gms-img-upload'>
              <Col span={19}>
                <label htmlFor='uploadImages'>{t("Upload Images")}</label>
                <Form.Item name='uploadImages'>
                  <Upload.Dragger
                    name='uploadImages'
                    beforeUpload={() => false}
                    onChange={handleUploadImages}
                    className='custom-img-upload'
                    accept='.jpg, .png, .pdf'
                    maxFileSize={10 * 1024 * 1024}
                    style={{ marginTop: "8px" }}
                    type='file'
                    fileList={[]}
                  >
                    <FiUploadCloud
                      style={{ color: "#004877", fontSize: "50px" }}
                    />
                    <p className='ant-upload-text'>
                      {t("Select a file or drag and drop here")}
                      <br />
                      <span>
                        {t("JPG, PNG or PDF, file size no more than 10MB")}
                      </span>{" "}
                    </p>
                    <div className='ImgUpload'> {t("Select File")}</div>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
            </Row>
            <Row className='gms-uploaded-images-div'>
              {newTaskData?.uploadImages?.map((image, index) => (
                <Col span={4} key={index}>
                  <div className='image-box'>
                    <div
                      className='delete-icon'
                      onClick={() => handleDeleteImage(index)}
                    >
                      <i className='fas fa-trash-alt'></i>
                    </div>
                    <img
                      src={image?.publicUrl}
                      alt={image?.name}
                      className='uploaded-image_item'
                      onClick={() => window.open(image?.publicUrl, "_blank")}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
      <div className='btn-flex'>
        <Button
          type='button'
          className='primary-btn mr3 mt3 mb3'
          htmlType='submit'
        >
          {t("Next")}
        </Button>
      </div>
    </Form>
  );

  const renderStepTwo = () => (
    <Form form={form} onFinish={handleSubmit}>
      <div className='gms-modal'>
        <div className='modal-header'>
          <p>{t("Add New Task")}</p>
        </div>
        <div className="modal-body-container">
          <div className='modal-body'>
            <h3>{t("Location (Not Mandatory)")}</h3>
            <Places setLocationData={setLocationData} />
          </div>
        </div>
      </div>
      <div className='btn-space'>
        <Button
          type='primary'
          className='prev-button ml3 mt3 mb3'
          onClick={handleBack}
        >
          {t("Previous")}
        </Button>
        <Button
          type='button'
          className='primary-btn mr3 mt3 mb3'
          htmlType='submit'
        // onClick={() => handleSubmit()}
        >
          {t("Add New Task")}
        </Button>
      </div>
    </Form>
  );

  return (
    <>
      <Modal className="modal-container"
        footer={null}
        open={addTaskModal}
        onCancel={handleCancel}
        width={1000}
        maskClosable={false}
      >
        <div className='gms-modal'>
          {currentStep === 1 ? renderStepOne() : renderStepTwo()}
        </div>
      </Modal>
    </>
  );
};

export default AddNewTask;
