import React from 'react'
import { useState } from 'react'

const ReportContext = React.createContext({
    isOpenModal: false,
    updateOpenModal: () => { },

    isAddMode: true,
    updateAddMode: () => { },

})
export const ReportContextProvider = ({ children }) => {
    const [isOpenModalState, setIsOpenModalState] = useState(false)
    const [isAddModeState, setIsAddModeState] = useState(true)

    const contextValue = {
        isOpenModal: isOpenModalState,
        updateOpenModal: setIsOpenModalState,

        isAddMode: isAddModeState,
        updateAddMode: setIsAddModeState,
    }
    return <ReportContext.Provider value={contextValue}>{children}</ReportContext.Provider>;
}
export default ReportContext