import React from "react";
import "../../Styles/user.css";

import { AssetsContextProvider } from "../../context/assetsManagementContext";
import Result from "../../Components/assetsManagement/Result";

const AssetsManagement = () => {
  // const {  addNewOffice,  setCurrentPage, limit, setLimit } = useContext(userContext)

  // const getOfficeData = () => {
  //   axiosInstance
  //     .get(`api/clients/office/info`, {
  //       params: {
  //         page: currentPage,
  //         limit: limit,
  //       },
  //     })
  //     .then((response) => {
  //       setAddNewOffice(response.data.data.results.reverse());
  //     });
  // };

  // useEffect(() => {
  //   getOfficeData();
  //   // eslint-disable-next-line
  // }, [currentPage, limit]);

  return (
    <>
      {/* <div className="user-section">
        <AddOffice/>
        <div className="user-table-wrapper">
          <UserTabs />
          <SearchFilter />
          <OfficeDataTable />
        </div>
      </div> */}
      <AssetsContextProvider>
        <Result />
      </AssetsContextProvider>
    </>
  );
};

export default AssetsManagement;
