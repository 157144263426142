function formatRole(role) {
  if (role) {
    return role
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return null;
  }
}
export default formatRole;
