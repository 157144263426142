import geocodeAddress from "./geocodeAddress";

// Similarly, for Instagram and Twitter
const prepareData = async (apiData) => {
  const geocodedFacebookData = await Promise.all(
    (apiData?.facebook || []).map(async (item) => {
      const coordinates = await geocodeAddress(item?.dimension_values[0]);
      return coordinates ? { ...item, ...coordinates } : null;
    })
  );
  const valueSumFacebook = (apiData?.facebook || []).reduce((acc, curr) => {
    return acc + curr.value;
  }, 0);

  const valueSumInstagram = (apiData?.instagram || []).reduce((acc, curr) => {
    return acc + curr.value;
  }, 0);

  const geocodedInstagramData = await Promise.all(
    (apiData?.instagram || []).map(async (item) => {
      const coordinates = await geocodeAddress(item?.dimension_values[0]);
      return coordinates ? { ...item, ...coordinates } : null;
    })
  );

  // Handle Twitter similarly if you have Twitter data

  return {
    facebook: geocodedFacebookData.filter((item) => item),
    instagram: geocodedInstagramData.filter((item) => item),
    valueSumFacebook,
    valueSumInstagram
    // twitter: geocodedTwitterData.filter(item => item)
  };
};

export default prepareData;
