import { Link } from "react-router-dom";
import "../../../Styles/socialMedia.css";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const formatNumber = (num) => {
  if (num === null || num === undefined) return "-";
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
};

const formattedCount = (num) => {
  if (num === null || num === undefined) return "-";
  if (num >= 10000000) {
    return (num / 10000000).toFixed(2) + " Cr";
  } else if (num >= 100000) {
    return (num / 100000).toFixed(2) + " L";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2) + "K";
  } else {
    return num.toString();
  }
};

const StatCard = ({ icon, platform, data = {} }) => {
  const { t } = useTranslation();
  const basicInfo = data.basicInfo || {};
  const growth = data.growth || {};

  return (
    <Link to={`/socialmedia/details/${platform}`}>
      <div className='col-span-1 media-wrapper p-4 rounded-xl shadow'>
        <div className='flex items-center justify-between mb-4'>
          <div className='flex items-center'>
            <div className='mr-4'>
              <img src={icon} alt='icon' className='w-12 h-12' />
            </div>
            <div className='mx-4'>
              <h2 className='text-2xl font-bold text-[--primary-color]'>
                {basicInfo.followers !== undefined &&
                basicInfo.followers !== null
                  ? formatNumber(basicInfo.followers)
                  : "-"}
              </h2>
              <h1 className='text-[--stat-text] font-semibold text-base'>
                {t("Followers")}
              </h1>
            </div>
            <Tooltip title='Yearly growth'>
              <span
                className={`px-3 py-1 rounded-full ${
                  growth.followersGrowth !== undefined &&
                  growth.followersGrowth !== null
                    ? growth.followersGrowth >= 0
                      ? "green-growth"
                      : "red-growth"
                    : "green-growth"
                }`}
              >
                {growth.followersGrowth !== undefined &&
                growth.followersGrowth !== null
                  ? growth.followersGrowth.toFixed(2) + "%"
                  : "NA"}
              </span>
            </Tooltip>
          </div>
        </div>
        <div className='flex text-center justify-between'>
          <div className='w-1/2 pr-2'>
            <h1 className='text-lg font-semibold text-[--primary-color]'>
              {basicInfo.avgLikes !== undefined && basicInfo.avgLikes !== null
                ? formattedCount(parseInt(basicInfo.avgLikes))
                : "-"}
            </h1>
            <Tooltip title='Engagement is a measure of how users interact with your content.'>
              <h2 className='text-[--dark-color] text-sm'>{t("Engagement")}</h2>
            </Tooltip>
          </div>
          <div className='pl-2 border-l-2 border-gray-200'></div>
          <div className='w-1/2 pl-2'>
            <h1 className='text-[--primary-color] text-lg font-semibold'>
              {basicInfo.reach !== undefined && basicInfo.reach !== null
                ? formattedCount(parseFloat(basicInfo.reach))
                : "-"}
            </h1>
            <Tooltip title='Reach is a measure of how many people are interested in your content.'>
              <h2 className='text-[--dark-color] text-sm'>{t("Reach")}</h2>
            </Tooltip>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StatCard;
