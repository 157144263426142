import React from 'react'
import { Modal, Table } from "antd";
import Column from 'antd/es/table/Column';
import supervisorImg from "../../../assets/svg/supervisorImg.svg"
import managerImg from "../../../assets/svg/managerImg.svg"
import cadreImg from "../../../assets/svg/cadreImg.svg"

const ViewOffices = ({ viewOffice, setViewOffice, viewDetails, viewOfficeName }) => {
    const handleCancel = () => {
        setViewOffice(false)
    }

    const convertRole = (role) => {
        const words = role.split('_');
        const capitalizedWords = words.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const convertedRole = capitalizedWords.join(' ');
        return convertedRole;
    };

    return (

        <>
            {viewOffice && (
                <Modal className='view-office-modal'
                    open={viewOffice}
                    width={960}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <div className='user-info-modal'>
                        <div className='modal-header'>
                            <h3>{viewOfficeName}</h3>
                        </div>

                        <div className='modal-body-office'>
                            <div className='office-stat px-5 py-3 mt-5'>
                                <div>
                                    <img src={supervisorImg} alt="" />
                                    <p className="pt-2">{viewDetails?.count?.office_manager}</p>
                                    <p className="pt-2">Office Manager</p>
                                </div>
                                <div>
                                    <img src={managerImg} alt="" />
                                    <p>{viewDetails?.count?.data_entry_operator}</p>
                                    <p className="pt-2">Data Entry Operator</p>
                                </div>
                                <div className="">
                                    <img src={cadreImg} alt="" />
                                    <p>{viewDetails?.count?.cadre}</p>
                                    <p className="pt-2">Cadre</p>
                                </div>
                            </div>
                            <div className='office-user-info pb-4'>
                                <Table dataSource={viewDetails?.users} rowKey={record => record._id} scroll={{ y: 240, }}>
                                    <Column title="User Id" dataIndex="_id" />
                                    <Column title="User Name" dataIndex="users" className='font-semibold w-44'
                                        render={(text, record) => `${record.firstName} ${record.lastName}`}
                                    />
                                    <Column title="ROLE" dataIndex="role"
                                        render={(text) => (
                                            <span>
                                                {convertRole(text)}
                                            </span>
                                        )} />
                                    <Column title="Status" dataIndex="status" className="user-status-text"
                                        render={(text) => (
                                            <span style={{ color: text === 'active' ? '#27AE60' : '#E84C3B', fontWeight: "700" }}>
                                                {text}
                                            </span>
                                        )}
                                    />
                                </Table>
                            </div>
                        </div>
                    </div>


                </Modal>
            )}
        </>
    )
}

export default ViewOffices