import React from "react";

import { useTranslation } from "react-i18next";

const formatNumber = (num) => {
  if (num === null || num === undefined) return "-";
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
};

const TotalPost = ({ icon, data = [] }) => {
  const { t } = useTranslation();
  const tweetGrowth = () => {
    if (data?.growth?.tweetsGrowth !== undefined) {
      return data?.growth?.tweetsGrowth >= 0 ? "green-growth" : "red-growth";
    } else {
      return "white-growth";
    }
  };
  return (
    <div className='col-span-1 bg-white p-4  shadow statitics-wrapper'>
      {/* <div className='mr-4'>
        <img src={icon} alt='icon' />
      </div> */}

      <div className='flex mt-2'>
        <div className='w-1/2 pl-2'>
          <div className='mr-2'>
            <img src={icon} alt='icon' />
          </div>
        </div>
        <div className='w-1/2 pr-2'>
          <div className='flex items-center gap-2'>
            <h1 className='text-lg font-semibold text-[--dark-color]'>
              {formatNumber(data?.basicInfo?.mediaUploads) || "-"}
            </h1>
            {/* <span
              className={`px-3 py-1 rounded-full ${data?.growth?.tweetsGrowth !== undefined
                  ? data.growth.tweetsGrowth >= 0
                    ? "green-growth"
                    : "red-growth"
                  : "white-growth"
                }`}
            >
              {data?.growth?.tweetsGrowth !== undefined
                ? data.growth.tweetsGrowth.toFixed(2) + "%"
                : "-"}
            </span> */}
          </div>
          <h2 className='text-gray-600 mt-2'>{t("Total Posts")}</h2>
        </div>
      </div>
      <div className='flex mt-4 pl-2'>
        <div className='w-1/2 pr-2'>
          <h1 className='text-lg font-semibold text-[--dark-color]'>
            {data?.basicInfo?.followers
              ? formatNumber(data.basicInfo.followers)
              : "-"}
          </h1>
          <h2 className='text-gray-600'>{t("Total Followers")}</h2>
        </div>
        <div className='w-1/2'>
          <h1 className='text-lg text-[--dark-color] font-semibold'>
            {" "}
            {data?.basicInfo?.following
              ? formatNumber(data.basicInfo.following)
              : "-"}
          </h1>
          <h2 className='text-gray-600'>{t("Total Followings")}</h2>
        </div>
      </div>
    </div>
  );
};
export default TotalPost;
