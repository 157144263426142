import React from "react";
import SurveyContext from "../../context/surveyContext";
import { ToastContainer } from "react-toastify";
import PageHeading from "../PageHeading";
import RequestSurveyForm from "./RequestSurveyForm";
import { useContext } from "react";
import { MdOutlineUpload } from "react-icons/md";
import { useTranslation } from "react-i18next";
import SurveyTabs from "./SurveyTabs";

const Result = () => {
  const { t } = useTranslation();
  const surveyContext = useContext(SurveyContext);

  const handleModal = () => {
    surveyContext.updateOpenModal(true);
  };
  return (
    <>
      <ToastContainer />
      <div className='reports-section'>
        <PageHeading
          pageTitle={t("Survey")}
          icon={<MdOutlineUpload />}
          pageModalTitle={t("Request New Survey")}
          pageModalOpen={handleModal}
        />

        <RequestSurveyForm
          isOpen={surveyContext.isOpenModal}
          isAddMode={surveyContext.isAddMode}
        />
        <div className='reports-table-wrapper'>
          {/* <SurveyData /> */}
          <SurveyTabs />
        </div>
      </div>
    </>
  );
};

export default Result;
