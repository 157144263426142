import React, { useState } from 'react'
import { Button, Pagination, Select, Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { useQuery } from 'react-query';
import callBackImg from '../../../assets/svg/callBack.svg'
import useAxiosInstance from '../../../lib/useAxiosInstance';
import SearchFilter from '../../SearchFilter';
import { t } from "i18next";

const SurveyRequestedList = () => {
    const axiosInstance = useAxiosInstance();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    // eslint-disable-next-line
    const [sort, setSort] = useState("DES");
    const [searchKeyword, setSearchKeyword] = useState("");

    // GET ALL REPORTS REQUESTED  DATA
    const fetchSurveyRequested = async () => {
        const response = await axiosInstance.get(
            `api/survey/client/getRequestedSurvey?page=${currentPage}&limit=${limit}`,
        );
        setTotal(response.data.totalpages)
        return response
    };
    const surveyRequestedData = useQuery(
        ["survey-requested-list", currentPage, limit, sort],
        () => fetchSurveyRequested(currentPage, limit, sort),
        {
            refetchOnWindowFocus: false,
            retry: 1
        }
    );

    // GET ALL SEARCH REQUESTED REPORT DATA
    const fetchSearchRequestedSurvey = async () => {
        let response;
        if (searchKeyword) {
            response = await axiosInstance.get(
                `api/survey/client/searchRequestedSurvey?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
            );
            setTotal(response?.data?.totalpages);
        } else {
            response = await axiosInstance.get(
                `api/survey/client/getRequestedSurvey?page=${currentPage}&limit=${limit}&sort=${sort}`
            );
            setTotal(response?.data?.totalpages);
        }
        return response;
    };

    const requestedSearchSurveyData = useQuery(
        ["search-survey-requested", searchKeyword, currentPage, limit],
        () => fetchSearchRequestedSurvey(),
        {
            refetchOnWindowFocus: false,
            retry: 1
        }
    );

    const SearchRequestedSurvey = (value) => {
        setCurrentPage(1);
        setSearchKeyword(value);
    };

    // FOR PAGINATION
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setLimit(pageSize);
    };

    const pagination = {
        total: total * limit,
        pageSize: limit,
        current: currentPage,
        onChange: handlePageChange,
    };

    // DOWNLOAD REPORT FILE
    const handleView = (record) => {
        axiosInstance
            .get(`api/survey/client/viewRequestedSurvey/${record._id}`, {
                headers: {
                    subscription: "advanced"
                }
            })
            .then((res) => {
                const publicUrl = res.data.data.publicUrl;
                const fileName = getFileNameFromUrl(publicUrl);
                const link = document.createElement("a");
                link.href = publicUrl;
                link.setAttribute("download", fileName);
                link.setAttribute("target", "_blank");
                link.click();
            });
    };

    const getFileNameFromUrl = (url) => {
        const urlParts = url.split("/");
        const fileName = urlParts[urlParts.length - 1];
        const fileExtension = fileName.split(".").pop();
        return fileExtension;
    };
    return (
        <>
            <div className='report-page-section report-requested'>
                <div className='report-table-wrapper'>
                    <div className="reports-table">
                        <SearchFilter handleSearch={SearchRequestedSurvey} />

                        <Table
                            rowKey={(record) => record._id}
                            dataSource={requestedSearchSurveyData?.data?.data?.data || surveyRequestedData?.data?.data?.data}
                            className="report-tableRows"
                            pagination={pagination}
                        >
                            <Column
                                title="" dataIndex="quickCall" className='w-16'
                                render={(title, record) => (
                                    <>
                                        <div className="flex pl-3">
                                            {record.quickCall === 'true' && (
                                                <img
                                                    src={callBackImg}
                                                    alt="pin icon"
                                                    className="mr-4"
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            />
                            <Column title={t("surveyName")} dataIndex="surveyName" className='font-semibold' />
                            <Column title={t("description")} dataIndex="description" className='report-name-tooltip' />
                            <Column title={t("DATE REQUESTED")} dataIndex="createdAt"
                                sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
                                defaultSortOrder="descend"
                                render={(text, record) => (
                                    <span>
                                        {new Date(record.createdAt).toLocaleDateString("en-GB")}
                                    </span>
                                )}
                            />
                            <Column title={t("STATUS")} dataIndex="status"
                                render={(text, record) => {
                                    let statusLabel;
                                    let fontWeight;
                                    let fontColor
                                    if (record.status === "open") {
                                        statusLabel = "Requested";
                                        fontWeight = "4000";
                                        fontColor = "var(--dark-color)"
                                    } else if (record.status === "close") {
                                        statusLabel = "Request Closed";
                                        fontWeight = "600";;
                                        fontColor = "#2B9FFF"
                                    } else if (record.status === "decline") {
                                        statusLabel = "Request Declined";
                                        fontWeight = "600";
                                        fontColor = "#E84C3B";
                                    }
                                    return (
                                        <span style={{ color: fontColor, fontWeight: fontWeight }}>
                                            {statusLabel}
                                        </span>
                                    );
                                }}
                            />
                            <Column
                                key='action' render={(text, record) => (
                                    <>
                                        {record.status !== "open" && record?.status !== "decline" && (
                                            <Space>
                                                <Button type="button" onClick={() => handleView(record)}>
                                                    View
                                                </Button>
                                            </Space>
                                        )}
                                    </>
                                )}
                            />
                        </Table>

                        <div className='client-pagination'>
                            <div className='pagination-text '>
                                {t("showing")}

                                <Select
                                    className='mx-2'
                                    value={limit.toString()}
                                    style={{ width: 56 }}
                                    onChange={(value) => {
                                        setLimit(parseInt(value)); setCurrentPage(1);
                                    }}
                                    options={[
                                        { value: "10", label: "10" },
                                        { value: "20", label: "20" },
                                        { value: "50", label: "50" },
                                        { value: "100", label: "100" },


                                    ]}
                                />
                                <span className='pl-1'>{t("entriesPerPage")}</span>
                            </div>
                            <div className='report-selection'>

                                <Pagination
                                    current={currentPage}
                                    pageSize={limit}
                                    total={total * limit}
                                    onChange={(page, pageSize) => {
                                        setCurrentPage(page);
                                        setLimit(pageSize);
                                    }}
                                    className="pagination"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SurveyRequestedList