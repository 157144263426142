import React, { useState, useEffect } from "react";
import { Button, Col, DatePicker, Input, Row, Select } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosInstance from "../../lib/useAxiosInstance";
import AssignDetails from "./AssignedDetails";
import { useNavigate } from "react-router-dom";
import formatRole from "../../utils/formatRole";
import { useTranslation } from "react-i18next";

const AssignTo = ({ taskId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const [selectedUser, setselectedUser] = useState(null);
  const [singleUserData, setSingleUserData] = useState(null);
  const [assignToData, setAssignToData] = useState();
  const [assignSuccess, setAssignSuccess] = useState(false);
  const [dueDate, setDueDate] = useState(null);

  //fetch all users-->
  const fetchUsers = async () => {
    return axiosInstance.get("/api/clients/info?page=1&limit=1000");
  };
  const usersData = useQuery("client-users-list", fetchUsers, {
    refetchOnWindowFocus: false
  });
  // <--

  //fetch single user-->
  const fetchSingleUser = async () => {
    if (selectedUser != null)
      return axiosInstance
        .get(`/api/clients/clientUser/${selectedUser}`)
        .then((response) => {
          setSingleUserData(response);
        })
        .catch((err) => {
          console.log(err);
        });
  };
  // <--

  //Assign task to user -->
  const assignToUser = async () => {
    return axiosInstance.put(`api/task/updateTask/${taskId}`, assignToData);
  };

  const mutateAssignUser = useMutation(assignToUser, {
    onSuccess: (data) => {
      toast.success(`${data?.data?.message}`);
      //invalidate cache and refresh
      queryClient.invalidateQueries("tasklist");
      setAssignSuccess(true);
    },
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });
  // <--

  useEffect(() => {
    fetchSingleUser();
  }, [selectedUser]);

  useEffect(() => {
    setAssignToData({
      assignedToUserId: selectedUser,
      assignedToUserName:
        singleUserData?.data?.data?.firstName +
        " " +
        singleUserData?.data?.data?.lastName,
      assignedByUserRole: singleUserData?.data?.data?.role,
      status: "assigned",
      dueDate: dueDate
    });
  }, [dueDate, selectedUser, singleUserData]);

  const handleSubmit = () => {
    mutateAssignUser.mutateAsync().then(() => {
      navigate("/tms");
      toast.success("Assigned Successfully");
    });
  };

  return (
    <>
      <ToastContainer />

      <div className='assign-form'>
        {assignSuccess ? (
          <AssignDetails singleTaskData={assignToData} />
        ) : (
          <>
            <div>
              <Row className='row'>
                {/* <Col span={8}>
              <label htmlFor="">Assign To</label>
              <br></br>
              <Select
                defaultValue="Please select"
                value="please select"
                size="large"
                style={{ height: "48", marginTop: "10px" }}
                name=""
                onChange={(value) => setselectedUser(value)}
                options={usersData?.data?.data?.data?.results?.map((user) => ({
                  value: user?._id,
                  label: `${user?.firstName} ${user?.lastName}`,
                }))}
              />
            </Col> */}

                <label htmlFor=''>{t("Assign To")}</label>
                <br></br>
                <Select
                  defaultValue='Please select'
                  size='large'
                  style={{ height: "48", marginTop: "10px" }}
                  name=''
                  onChange={(value) => setselectedUser(value)}
                  options={usersData?.data?.data?.data?.results?.map(
                    (user) => ({
                      value: user?._id,
                      label: `${user?.firstName} ${user?.lastName}`
                    })
                  )}
                />
              </Row>
              <Row className='row'>
                <label htmlFor=''>{t("Role")}</label>
                <Input
                  type='text'
                  name='role'
                  placeholder='Enter Role'
                  style={{ marginTop: "10px" }}
                  value={formatRole(singleUserData?.data?.data?.role)}
                />
              </Row>
              <Row className='row'>
                <label htmlFor=''>{t("Email")}</label>
                <Input
                  type='email'
                  id='email'
                  name='email'
                  value={singleUserData?.data?.data?.email}
                  placeholder='john.smith@abc.com'
                  className='mt2'
                />
              </Row>
              <Row className='row'>
                <label htmlFor=''>{t("Phone")}</label>
                <Input
                  type='tel'
                  addonBefore='+91'
                  id='phone'
                  name='phone'
                  placeholder='9876543210'
                  className='mt2 '
                  value={singleUserData?.data?.data?.phone}
                />
              </Row>

              <Row className='row'>
                <Col span={24}>
                  <label className='block mb-3'>{t("dueDate")}</label>
                  <DatePicker
                    placeholder='DD/MM/YY'
                    style={{ width: "100%" }}
                    name={dueDate}
                    value={dueDate}
                    onChange={(date) => setDueDate(date)}
                  />
                </Col>
              </Row>
            </div>
            <div className='gms-details-btn '>
              <Button className='assign-btn' onClick={() => handleSubmit()}>
                {t("Assign")}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default AssignTo;
