import React, { useState, useEffect } from "react";
import durationFormatDetailsPage from "../utils/durationFormatDetailsPage";
import { useParams } from "react-router-dom";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useQuery } from "react-query";
import formatDate from "../../../utils/formatDate";
import FollowerGrowth from "./FollowerSection/Growth/FollowerGrowth";
import PostGrowth from "./FollowerSection/Growth/PostGrowth";
import TotalPost from "../TotalPost";

import fbIcon from "../../../assets/svg/fbSocial.svg";
import instaIcon from "../../../assets/svg/instaSocial.svg";
import twitterIcon from "../../../assets/svg/twitterSocial.svg";
import GenderPie from "./FollowerSection/GenderPie";
import EngagementSection from "./EngagementSection";
import { useTranslation } from "react-i18next";


const socialIconMapping = {
  facebook: fbIcon,
  instagram: instaIcon,
  twitter: twitterIcon
};

const PlatformDetails = () => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  const [selectedFollowerPeriod, setSelectedFollowerPeriod] =
    useState("monthly");
  const [selectedPostPeriod, setSelectedPostPeriod] = useState("monthly");
  const [followerGrowthData, setFollowerGrowthData] = useState({});
  const [postGrowthData, setPostGrowthData] = useState({});

  const { platfrom } = useParams();

  const { data, isLoading } = useQuery(
    ["socialMedia"],
    async () => {
      const response = await axiosInstance.get("api/users/social_data");
      return response?.data;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const basicData = Array.isArray(data)
    ? data.reduce((acc, itm) => {
        acc[itm?.platform] = {
          basicInfo: itm?.platformData?.BasicInfo || {},
          growth: itm?.platformData?.ProfileGrowth || {},
          genderData: itm?.apiData?.genderDemographics
        };
        return acc;
      }, {})
    : {};

  const engagedData = Array.isArray(data)
    ? data.reduce((acc, itm) => {
        acc[itm?.platform] = itm?.apiData;
        return acc;
      }, {})
    : {};

  useEffect(() => {
    if (data && data.length > 0) {
      const transformedData = data.reduce((acc, itm) => {
        const { social, data } = itm?.platformData;

        const formattedData =
          data.map((point) => ({
            x: formatDate(new Date(point.date)),
            y: point.followers || point?.likes
          })) || [];
        const dataToUse = durationFormatDetailsPage(
          formattedData,
          selectedFollowerPeriod
        );
        acc[social] = dataToUse;
        return acc;
      }, {});

      setFollowerGrowthData(transformedData);
    }
  }, [data, selectedFollowerPeriod]);

  useEffect(() => {
    if (data && data.length > 0) {
      const transformedData = data.reduce((acc, itm) => {
        const { social, data } = itm?.platformData;

        const formattedData =
          data.map((point) => ({
            x: formatDate(new Date(point.date)),
            y: point.media || point?.tweets || point?.talking_about
          })) || [];
        const dataToUse = durationFormatDetailsPage(
          formattedData,
          selectedPostPeriod
        );

        acc[social] = dataToUse;
        return acc;
      }, {});

      setPostGrowthData(transformedData);
    }
  }, [data, selectedPostPeriod]);

  if (isLoading) {
    return (
      <div className='flex items-center justify-center h-screen'>
        Loading...
      </div>
    );
  }

  return (
    <div className='p-4'>
      <div className='flex items-center mb-6'>
        <img
          src={socialIconMapping[platfrom]}
          alt='icon'
          className='w-10 h-10 mr-4'
        />
        <h1 className='text-2xl font-semibold text-gray-800'>
          {platfrom.charAt(0).toUpperCase() + platfrom.slice(1)}
        </h1>
      </div>

      <div
        className={`grid gap-4 mb-4 ${
          platfrom === "instagram"
            ? "grid-cols-1 lg:grid-cols-2"
            : "grid-cols-1"
        }`}
      >
        <div className='bg-white p-6 rounded-lg shadow-md lg:col-span-2'>
          <h1 className='text-lg font-semibold mb-4'>{t("Statistics")}</h1>
          <TotalPost
            icon={socialIconMapping[platfrom]}
            data={basicData[platfrom]}
          />
        </div>

        {(platfrom === "instagram" || platfrom === "facebook") && (
          <div className='bg-white p-6 rounded-lg shadow-md'>
            <EngagementSection
              engagedData={engagedData[platfrom]}
              platfrom={platfrom}
            />
          </div>
        )}

        {platfrom === "instagram" && (
          <div className='bg-white p-6 rounded-lg shadow-md'>
            <GenderPie genderData={basicData[platfrom]?.genderData} />
          </div>
        )}
      </div>

      <div className='lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4'>
        <div className='bg-white p-6 rounded-lg shadow-md'>
          <FollowerGrowth
            data={followerGrowthData[platfrom]}
            platfrom={platfrom}
            setSelectedFollowerPeriod={setSelectedFollowerPeriod}
            selectedFollowerPeriod={selectedFollowerPeriod}
          />
        </div>

        <div className='bg-white p-6 rounded-lg shadow-md'>
          <PostGrowth
            data={postGrowthData[platfrom]}
            platfrom={platfrom}
            setSelectedPostPeriod={setSelectedPostPeriod}
            selectedPostPeriod={selectedPostPeriod}
          />
        </div>
      </div>
    </div>
  );
};

export default PlatformDetails;
