import React, { useContext } from "react";
import { Modal, Row, Col, Input, Checkbox } from "antd";
import SurveyContext from "../../context/surveyContext";
import { useMutation, useQueryClient } from "react-query";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ErrorMessage from "../Forms/ErrorMessage";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

const RequestSurveyForm = ({ isOpen }) => {
  const { t } = useTranslation();

  const surveyContext = useContext(SurveyContext);
  const queryClient = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const FormSchema = Yup.object().shape({
    surveyName: Yup.string()
      .trim()
      .required("Survey Name is required")
      .min(3, "First Name is minimum 3 character")
      .max(50, "First Name is maximum 50 character"),
    description: Yup.string()
      .trim()
      .required("Description is required")
      .min(3, "Last Name is minimum 3 character")
      .max(200, "Description is maximum 200 character")
  });

  const formik = useFormik({
    initialValues: {
      surveyName: "",
      description: "",
      quickCall: false
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const closeModal = () => {
    surveyContext.updateOpenModal(false);
    surveyContext.updateAddMode(true);
    formik.resetForm();
  };

  // <--
  //handle submit event
  const handleSubmit = async (value) => {
    const data = {
      ...value,
      quickCall: value.quickCall.toString()
    };
    handleCreateSurveyRequest.mutate(data);
  };

  const handleCreateSurveyRequest = useMutation(
    (formData) =>
      axiosInstance
        .post(`api/survey/client/requestSurvey`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );
  const handleSuccess = (data) => {
    queryClient.invalidateQueries("survey-requested-list");
    queryClient.invalidateQueries("search-survey-requested");
    closeModal();
    toast.success(`${data?.message}`);
  };

  const handleError = (error) => {
    toast.error("Something went wrong.");
    closeModal();
  };

  return (
    <>
      <div className='reports-section'>
        {isOpen && (
          <Modal
            className='reports-modal-wrapper'
            open={isOpen}
            maskClosable={false}
            onSubmit={handleSubmit}
            onCancel={closeModal}
            width={960}
            footer={[
              <button
                key='request-survey'
                form='request-survey-form'
                type='submit'
                className='modal-footer-button'
              >
                {t("Request Survey")}
              </button>
            ]}
          >
            <form id='request-survey-form' onSubmit={formik.handleSubmit}>
              <div className='reports-info-modal'>
                <div className='reports-modal-header'>
                  <h3>{t("Request New Survey")}</h3>
                </div>
                <div className='reports-modal-body'>
                  <div className='request-report'>
                    <h5>{t("Survey Information")}</h5>
                    <Row className='reports-modal-row mb-8'>
                      <Col span={10} className='mr-9'>
                        <label>{t("Survey Name")}</label>
                        <Input
                          type='text'
                          placeholder=''
                          name='surveyName'
                          value={formik.values.surveyName}
                          onChange={formik.handleChange}
                          showCount
                          maxLength={50}
                        />
                        <ErrorMessage
                          hasError={Boolean(
                            formik.errors.surveyName &&
                              formik.touched.surveyName
                          )}
                          message={formik.errors.surveyName}
                        />
                      </Col>
                    </Row>
                    <Row className='reports-modal-row'>
                      <Col span={22}>
                        <label>{t("Description")}</label>
                        <TextArea
                          rows={6}
                          placeholder={t("Description")}
                          showCount
                          maxLength={200}
                          name='description'
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        />
                        <ErrorMessage
                          hasError={Boolean(
                            formik.errors.description &&
                              formik.touched.description
                          )}
                          message={formik.errors.description}
                        />
                      </Col>
                      <Checkbox
                        className='req-callback ml-1'
                        name='quickCall'
                        checked={formik.values.quickCall}
                        onChange={formik.handleChange}
                      >
                        {t("Request a callback")}
                      </Checkbox>
                    </Row>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </div>
    </>
  );
};

export default RequestSurveyForm;
