import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import AssemblyMap from '../AssemblyMap'
import ExpandIcon from "../../../assets/svg/expandIcon.svg";
// import AssemblyMap from "../AssemblyMap";
import VillageDataTable from "./VillageDataTable";

import GraphModal from "../GraphModal";
// import NoDataIcon from "../../../../assets/svg/no-data.svg";
import sampleVillageData from "./sampleVillageData.json";

import { getGraphData } from "../utils/getFromattedValue";
import ConstituencyMap from "../ConstituencyMap";
import { renderCharts } from "../utils/renderCharts";
import { useTranslation } from "react-i18next";

const VillageData = ({
  constituencyData,
  loading,
  summaryData,
  polygonData,
  constituencyDataList,
  center,
  setSelectedMarker = () => {},
  villageFetchedData,
  isDataAvailable,
  selectedMarker,
  isLoading
}) => {
  // const isDataAvailable = true;
  // const isDataAvailable =
  //   ["YES", "Yes"].includes(summaryData["Form 20"]) &&
  //   ["YES", "Yes"].includes(summaryData["Village Data"]);

  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("age");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  // const data = sampleVillageData?.electoralData;

  //graphData for age VS Gender
  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  // const graphData = getGraphData(selectedOption, sampleVillageData);

  const graphData = getGraphData(
    selectedOption,
    sampleVillageData,
    villageFetchedData
  );

  return (
    <>
      <div className='constituency-data mt-5'>
        <h4 className='flex justify-center mb-2'>{t("villageData")}</h4>

        <div className='w-full grid grid-cols-12 gap-4'>
          <div className='col-span-12 sm:col-span-7 relative'>
            <div className='village-data-tooltip absolute top-0 right-0 z-1'>
              <div className='village-data-tooltip mb-4 ml-3'>
                <h3>{t("villageType")} </h3>
                <div className='flex justify-between village-type'>
                  <p
                    style={{
                      background: selectedMarker?.winnerParty?.colorCode,
                      marginRight: "5px"
                    }}
                  >
                    {selectedMarker?.winnerParty?.name}
                  </p>
                  <p
                    style={{
                      background: selectedMarker?.runnerUpParty?.colorCode,
                      marginRight: "5px"
                    }}
                  >
                    {selectedMarker?.runnerUpParty?.name}
                  </p>
                  <p
                    style={{
                      background: selectedMarker?.firstRunnerUpParty?.colorCode
                    }}
                  >
                    {selectedMarker?.firstRunnerUpParty?.name}
                  </p>
                </div>
                {/* <div className='flex flex-col justify-around tooltip-options mt-3'>
                  <div className='flex justify-between tooltip-content'>
                    <p>Strong Hold </p>
                    <div className='flex items-center'>
                      <FaChevronRight />
                      <p>10</p>
                      <span>Margin</span>
                    </div>
                  </div>
                  <div className='flex justify-between tooltip-content'>
                    <p>Battlefield </p>
                    <div className='flex items-center'>
                      <p>5-10 %</p>
                      <span>Margin</span>
                    </div>
                  </div>
                  <div className='flex justify-between tooltip-content'>
                    <p>Swing </p>
                    <div className='flex items-center'>
                      <FaChevronLeft />
                      <p>5 %</p>
                      <span>Margin</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <AssemblyMap
              // constituencyData={constituencyData}
              // loading={loading}
              // polygonData={polygonData}
              // constituencyDataList={constituencyDataList}
              // center={center}
              // setSelectedMarker={setSelectedMarker}
              // selectedMarker={selectedMarker}

              polygonData={constituencyData}
              // constituencyData={constituencyDataList}
              center={center}
              setSelectedMarker={setSelectedMarker}
              selectedMarker={selectedMarker}
              isDataAvailable={isDataAvailable}
            ></AssemblyMap>
          </div>
          <div className='col-span-12 sm:col-span-5 village-data'>
            <VillageDataTable
              isDataAvailable={isDataAvailable}
              data={villageFetchedData?.electoralData}
              selectedMarker={selectedMarker}
              villageLoading={isLoading}
            />

            <div className='col-span-12 sm:col-span-5 mt-4 constituenct-data-table'>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <h1 className='mt-2 ml-6'>{t("Village Electoral Data")}</h1>
                <div className='flex-grow' />
                <div className='ml-auto pr-1'>
                  <Select
                    className='mr-2'
                    placeholder='Select'
                    value={selectedOption}
                    onChange={handleSelectChange}
                    disabled={!isDataAvailable} 
                  >
                    <Select.Option value='age'>{t("age")}</Select.Option>
                    <Select.Option value='gender'>{t("gender")}</Select.Option>
                    {/* <Select.Option value='community'>
                      {t("community")}
                    </Select.Option> */}
                    <Select.Option value='ageGender'>
                      {t("ageVsGender")}
                    </Select.Option>
                    {/* <Select.Option value='ageCommunity'>
                      {t("ageVsCommunity")}
                    </Select.Option>
                    <Select.Option value='ganderCommunity'>
                      {t("genderVsCommunity")}
                    </Select.Option> */}
                  </Select>
                </div>

                <img
                  className='cursor-pointer'
                  src={ExpandIcon}
                  alt=''
                  onClick={openModal}
                />
              </div>

              {renderCharts(
                selectedOption,
                isDataAvailable,
                graphData,
                isLoading
              )}
            </div>
          </div>
        </div>
      </div>
      <GraphModal
        isDataAvailable={isDataAvailable}
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        graphData={graphData}
        selectedOption={selectedOption}
      />
    </>
  );
};

export default VillageData;
