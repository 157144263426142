import React from "react";
import "../../Styles/cdm.css";
import { ToastContainer } from "react-toastify";
import PageHeading from "../PageHeading";
// import { useContext } from 'react'
// import ConstituencyContext from '../../context/constituencyContext'
import Constituency from "./Constituency";
import useConstituencyData from "./hooks/useConstituencyData";
import { useTranslation } from "react-i18next";
const Result = () => {
  const { t } = useTranslation();

  const { constituencyData, loading } = useConstituencyData(1);
  // const constituencyContext = useContext(ConstituencyContext)
  return (
    <>
      <div className='constituency-section'>
        <ToastContainer />
        <PageHeading pageTitle={t("constituencyDataManagement")} />
        <Constituency constituencyData={constituencyData} loading={loading} />
      </div>
    </>
  );
};

export default Result;
