import React, { useState } from 'react'



const CustSupportContext = React.createContext({

    isOpenModal: false,
    updateOpenModal: () => {
    },

    isStatusModal: false,
    updateStatusModal: () => {
    },

    isAddMode: true,
    updateAddMode: () => {
    },

    editData: {},
    updateEditData: () => {
    },
});

export const CustSupportContextProvider = ({ children }) => {

    const [isOpenModalState, setIsOpenModalState] = useState(false);
    const [isStatusModalState, setIsStatusModalState] = useState(false);
    const [isAddModeState, setIsAddModeState] = useState(true);
    const [editDataState, setEditDataState] = useState({});

    const contextValue = {
        isOpenModal: isOpenModalState,
        updateOpenModal: setIsOpenModalState,

        isStatusModal: isStatusModalState,
        updateStatusModal: setIsStatusModalState,

        isAddMode: isAddModeState,
        updateAddMode: setIsAddModeState,

        editData: editDataState,
        updateEditData: setEditDataState,
    };

    return <CustSupportContext.Provider value={contextValue}>{children}</CustSupportContext.Provider>
}

export default CustSupportContext