import React, { useState } from "react";
import "../../Styles/reports.css";
import { Button, Modal, Row, Col, Form } from "antd";
import Multiselect from "multiselect-react-dropdown";
import { useTranslation } from "react-i18next";

const ShareSurvey = ({ moreDetails, setMoreDetails }) => {
  const { t } = useTranslation();
  const handleSubmit = () => {
    setMoreDetails();
  };

  const handleDelete = () => {
    setMoreDetails();
  };
  const [selectValues, setSelectValues] = useState({});
  const handleChange = (value, name) => {
    setSelectValues({ ...selectValues, [name]: value });
  };

  const [options, setOptions] = useState([
    "Ronit Shaw",
    "Rohini Shaw",
    "Jane Doe3",
    "Sayli Patil"
  ]);

  return (
    <div>
      <div className='share-record'>
        {moreDetails && (
          <Modal
            className='reports-modal-wrapper'
            open={moreDetails}
            onSubmit={handleSubmit}
            onCancel={handleDelete}
            width={600}
            maskClosable={false}
            footer={[
              <Button
                key='shareReport'
                type='button'
                className='modal-footer-button'
                onClick={handleSubmit}
              >
                {t("Share via email")}
              </Button>
            ]}
          >
            <Form>
              <div className='reports-info-modal'>
                <div className='reports-modal-header'>
                  <h3>{t("More Details")}</h3>
                </div>
                <div className='reports-modal-body'>
                  <div className='request-report'>
                    {/* <div className='share-record'>
                                        <FilePdfOutlined />
                                        <p>Economic Advisory Council to the Prime Minister’s
                                            Report: The State of Inequality in India</p>
                                    </div> */}
                    <Row className='share-modal-row'>
                      <Col span={11} className='tags mr-9'>
                        <label>{t("Tags")}</label>
                        <span>tag 1 </span>
                        <span>tag 2 </span>
                      </Col>
                    </Row>
                    <Row className='share-modal-row' style={{ height: "auto" }}>
                      <Col span={24}>
                        <label>{t("Share Survey")}</label>
                        <span className='chief-of-staff pt-3 pb-1'>
                          Chief of Staff
                        </span>
                        {/* <Select
                                                    mode="multiple"
                                                    size='large' placeholder="Please Select"
                                                    style={{ width: 336, height: '48' }}
                                                    name='staffSelection'
                                                    value={selectValues?.staffSelection}
                                                    onChange={(value) => handleChange(value, "staffSelection")}
                                                    showCheckbox
                                                    options={[
                                                        {
                                                            value: 'Jane Doe',
                                                            label: 'Jane Doe',
                                                        },
                                                        {
                                                            value: 'Jane Doe1',
                                                            label: 'Jane Doe1',
                                                        },
                                                        {
                                                            value: 'Jane Doe3',
                                                            label: 'Jane Doe3',
                                                        },
                                                    ]}
                                                /> */}
                        <Multiselect
                          isObject={false}
                          onRemove={(event) => {
                            // console.log(event);
                          }}
                          onSelect={(event) => {
                            // console.log(event);
                          }}
                          options={options}
                          showCheckbox
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ShareSurvey;
