import React, { useState, useEffect } from "react";
import { Button, Col, DatePicker, Input, Row, Select, Form, Radio } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import AssignDetails from "./AssignDetails";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import formatRole from "../../../utils/formatRole.js";

const { TextArea } = Input;

const AssignTo = ({ handleCancel, grievanceId }) => {
  const { t } = useTranslation();

  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const [selectedUser, setSelectedUser] = useState(null);
  const [singleUserData, setSingleUserData] = useState(null);
  const [assignToData, setAssignToData] = useState(null);
  const [assignSuccess, setAssignSuccess] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [showImages, setShowImages] = useState(true);
  const [dueDate, setDueDate] = useState(null);
  const navigate = useNavigate();

  //fetch all users-->
  const fetchUsers = async () => {
    return axiosInstance.get("/api/clients/info?page=1&limit=1000");
  };
  const usersData = useQuery("client-users", fetchUsers);
  // <--

  //fetch single user-->
  const fetchSingleUser = async () => {
    if (selectedUser != null)
      return axiosInstance
        .get(`/api/clients/clientUser/${selectedUser}`)
        .then((response) => {
          setSingleUserData(response);
        })
        .catch((err) => {
          console.log(err);
        });
  };
  // <--

  //Assign to user in grievance -->
  const assignToUser = async () => {
    return axiosInstance.put(
      `/api/grievances/updateGrievance/${grievanceId}`,
      assignToData
    );
  };

  const mutateAssignUser = useMutation(assignToUser, {
    onSuccess: (data) => {
      toast.success(`${data?.data?.message}`);
      //invalidate cache and refresh
      queryClient.invalidateQueries("GMS-List_Data");
      setAssignSuccess(true);
      // handleCancel();
    },
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });
  // <--

  useEffect(() => {
    fetchSingleUser();
  }, [selectedUser]);

  useEffect(() => {
    setAssignToData({
      assignedToUserId: selectedUser,
      assignedToUserName: singleUserData?.data?.data?.firstName,
      assignedByUserRole: singleUserData?.data?.data?.role,
      status: "assigned",
      instructions: instructions,
      showImages: showImages,
      dueDate: dueDate
    });
  }, [singleUserData, instructions, showImages, selectedUser, dueDate]);


  const handleSubmit = () => {
    mutateAssignUser.mutateAsync().then(() => {
      navigate("/gms");
    });
  };

  return (
    <Form>
      <div className='assign-form'>
        {assignSuccess ? (
          <AssignDetails singleGrievanceData={assignToData} />
        ) : (
          <>
            <div>
              <Row className='row'>
                <label htmlFor=''>{t("assignTo")}</label>
                <br></br>
                <Select
                  defaultValue='Please select'
                  size='large'
                  style={{ height: "48", marginTop: "10px" }}
                  name=''
                  onChange={(value) => setSelectedUser(value)}
                  options={usersData?.data?.data?.data?.results?.map(
                    (user) => ({
                      value: user?._id,
                      label: `${user?.firstName} ${user?.lastName}`
                    })
                  )}
                />
              </Row>

              <Row className='row'>
                <label htmlFor=''>{t("role")}</label>
                <Input
                  type='text'
                  name=''
                  placeholder=''
                  value={formatRole(singleUserData?.data?.data?.role)}
                  style={{ marginTop: "10px" }}
                />
              </Row>
              <Row className='row'>
                <label htmlFor=''>{t("email")}</label>
                <Input
                  type='email'
                  id='email'
                  name='email'
                  placeholder='john.smith@abc.com'
                  className='mt2'
                  value={singleUserData?.data?.data?.email}
                />
              </Row>
              <Row className='row'>
                <label htmlFor=''>{t("phone")}</label>
                <Input
                  type='tel'
                  addonBefore='+91'
                  id='phone'
                  name='phone'
                  placeholder='9876543210'
                  className='mt2 '
                  value={singleUserData?.data?.data?.phone}
                />
              </Row>
              <Row className='row'>
                <Col span={24}>
                  <label className='block mb-3'>{t("dueDate")}</label>
                  <DatePicker
                    placeholder='DD/MM/YY'
                    name='dueDate'
                    style={{ width: "100%" }}
                    onChange={(date) => setDueDate(date)}
                  />
                </Col>
              </Row>

              <Row className='row'>
                <Col span={24}>
                  <label htmlFor=''>{t("addInstructions")}</label>
                  <TextArea
                    rows={4}
                    id='instructions'
                    name='instructions'
                    style={{ marginTop: "8px", width: "100%" }}
                    value={instructions}
                    onChange={(e) => setInstructions(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <label htmlFor=''>{t("showImagesToAssignee")} ?</label>
                  <Radio.Group
                    style={{
                      display: "block",
                      marginTop: "25px",
                      marginBottom: "20px"
                    }}
                    name='showImages'
                    value={showImages ? "true" : "false"}
                    onChange={(e) => setShowImages(e.target.value === "true")}
                  >
                    <Radio value='true'>{t("yes")}</Radio>
                    <Radio value='false'>{t("no")}</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </div>
            <div className='gms-details-btn '>
              <Button className='assign-btn' onClick={() => handleSubmit()}>
                {t("assign")}
              </Button>
            </div>
          </>
        )}
      </div>
    </Form>
  );
};

export default AssignTo;
