import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Circle,
  InfoWindow
} from "@react-google-maps/api";
import prepareData from "../utils/prepareData";
import indiaShape from "../../../assets/geoJsonData/india-composite.json";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const mapContainerStyle = {
  width: "100%",
  height: "100%"
};

const center = {
  lat: 28.6139,
  lng: 77.209
};

const getColorForPlatform = (platform) => {
  switch (platform.toLowerCase()) {
    case "facebook":
      return "rgb(57,117,234)";
    case "instagram":
      return "rgb(178,65,30)";
    case "twitter":
      return "black";
    default:
      return "black";
  }
};

const libraries = ["places"];

const HeatMap = ({ apiData = {}, title }) => {
  const { t } = useTranslation();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries
  });

  const [data, setData] = useState({
    facebook: [],
    instagram: [],
    twitter: [],
    valueSumFacebook: 0,
    valueSumInstagram: 0
  });

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [platformData, setPlatformData] = useState([]);
  const [platformSumData, setPlatformSumData] = useState(1);
  const [selectedPlatform, setSelectedPlatform] = useState("facebook");

  useEffect(() => {
    const fetchData = async () => {
      const preparedData = await prepareData(apiData);
      setData(preparedData);
    };

    fetchData();
  }, [apiData]);

  useEffect(() => {
    const platformMapping = {
      facebook: data.facebook,
      instagram: data.instagram
    };
    const platformSumMapping = {
      facebook: data?.valueSumFacebook,
      instagram: data?.valueSumInstagram
    };

    setPlatformData(platformMapping[selectedPlatform] || []);
    setPlatformSumData(platformSumMapping[selectedPlatform] || 1);
  }, [selectedPlatform, data]);

  const handlePlatformChange = (value) => {
    setSelectedPlatform(value);
  };
  const mapRef = useRef(null);
  const onLoad = (map) => {
    mapRef.current = map;
    map.data.addGeoJson(indiaShape);
    map.data.setStyle((feature) => {
      return {
        fillColor: "grey",
        fillOpacity: 0.5,
        strokeColor: "black",
        strokeWeight: 2,
        strokeOpacity: 0.3
      };
    });
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div className='h-96'>
      <div className='overview-heading mb-4 flex justify-between items-center'>
        <h4 className='overview-text'>
          {title} {t("Demography")}
        </h4>
        <div className='flex'>
          <Select
            value={selectedPlatform}
            style={{ width: "120px" }}
            onChange={handlePlatformChange}
            className='ml-4 p-2 rounded'
          >
            <Select.Option value='facebook'>Facebook</Select.Option>
            <Select.Option value='instagram'>Instagram</Select.Option>
          </Select>
        </div>
      </div>
      <div className='p-2 h-[90%]'>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={4}
          center={center}
          onLoad={onLoad}
          ref={mapRef}
          options={{
            disableDefaultUI: true,
            fullscreenControl: true,
            zoomControl: true
          }}
        >
          {platformData.map((location, index) => (
            <Circle
              key={`${title}${selectedPlatform}${index}`}
              center={{ lat: location.lat, lng: location.lng }}
              radius={50000}
              options={{
                fillColor: getColorForPlatform(selectedPlatform),
                strokeColor: getColorForPlatform(selectedPlatform),
                fillOpacity: location.value / 10,
                strokeOpacity: location.value / 100
              }}
              onMouseOver={() =>
                setSelectedLocation({ ...location, platform: selectedPlatform })
              }
              onClick={() =>
                setSelectedLocation({ ...location, platform: selectedPlatform })
              }
              onMouseOut={() => setSelectedLocation(null)}
            />
          ))}
          {selectedLocation && (
            <InfoWindow
              position={{
                lat: selectedLocation?.lat,
                lng: selectedLocation?.lng
              }}
              onCloseClick={() => setSelectedLocation(null)}
            >
              <div className='p-2 flex flex-col gap-2'>
                <h5
                  style={{
                    color: getColorForPlatform(selectedLocation?.platform),
                    textTransform: "capitalize"
                  }}
                >
                  {selectedLocation.platform}
                </h5>
                <p>{selectedLocation?.dimension_values}</p>
                <p>
                  {title}: {selectedLocation?.value}
                </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </div>
  );
};

export default HeatMap;
