import React, { useState } from "react";
import PageHeading from "../../PageHeading";
import "../../../Styles/userinfo.css";
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MdKeyboardArrowRight } from "react-icons/md";
import Grievances from "./Grievances";
import Tasks from "./Tasks";
import SearchFilter from "../../SearchFilter";
import SummaryPieChart from "./SummaryPieChart";
import { useEffect } from "react";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useQuery } from "react-query";


const UserInfo = () => {
  const axiosInstance = useAxiosInstance();
  const location = useLocation();
  const params = useParams();
  const userId = params.userId;

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState();
  useEffect(() => {
    const path = location.pathname;
    if (path.includes('grievances')) {
      setActiveTab('grievances');
    } else if (path.includes('tasks')) {
      setActiveTab('tasks');
    }
  }, [location.pathname]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    navigate(`../${tabName}`);
  };


  const renderTabContent = () => {
    switch (activeTab) {
      case "grievances":
        return <Grievances />;
      case "tasks":
        return <Tasks userId={userId} />;
      default:
        return <Grievances />;
    }
  };

  //fetch all clientuser info -->
  const fetchClient = async () => {
    const response = await axiosInstance.get(`api/clients/clientUser/${userId}`);
    return response.data.data;
  };
  const singleClientUser = useQuery("single_clientuser_data", fetchClient);
  return (
    <div className="user-info-container">
      <PageHeading pageTitle="User Info" />
      <div className="userinfo-breadcrumbs">
        <div className="flex">
          <NavLink to="/assets-management/user">
            <h1>Assets Management
              <MdKeyboardArrowRight className="inline-block" />
            </h1>
          </NavLink>
          <span>User Info<MdKeyboardArrowRight className="inline-block" /></span>
          
          <span className="capitalize disabled">
            {`${singleClientUser?.data?.firstName} ${singleClientUser?.data?.lastName}`}
          </span>
        </div>
      </div>

      <div className="middle-section">
        <SummaryPieChart userId={userId} />

        <div className="table-section">
          <div className="tab-change">
            <nav className="user-info-tabs">
              <button
                className={activeTab === "grievances" ? "active" : ""}
                onClick={() => handleTabClick("grievances")} >
                Grievances
              </button>
              <button
                className={activeTab === "tasks" ? "active" : ""}
                onClick={() => handleTabClick("tasks")}>
                Tasks
              </button>
            </nav>
          </div>
          {/* <SearchFilter /> */}


          <div className="tms-tab-content">{renderTabContent()}</div>

        </div>
      </div>

    </div>

  )
}

export default UserInfo;