import React, { useState } from "react";
import { Button, Modal } from "antd";
// import mapSvg from "../../assets/svg/mapSvg.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useQuery } from "react-query";
import highPriority1 from "../../../assets/images/icon/high.png";
import mediumPriority1 from "../../../assets/images/icon/medium.png";
import lowPriority1 from "../../../assets/images/icon/low.png";
import Completed from "../../../assets/images/icon/completed.png";
import PendingIcon from "../../../assets/images/icon/pending.png";
import AssignedIcon from "../../../assets/images/icon/assigned.png";
import Unassigned from "../../../assets/images/icon/Unassigned.png";
import Inprogess from "../../../assets/images/icon/inpgress.png";
import AssignDetails from "./AssignDetails";
import AssignTo from "./AssignTo";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ViewOnMap from "../../../utils/ViewOnMap";
import formatRole from "../../../utils/formatRole";

const IssueDetails = () => {
  const { t } = useTranslation();
  const { grievanceId } = useParams();

  const user = JSON.parse(localStorage.getItem("user"));

  const axiosInstance = useAxiosInstance();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  //fetch Grievance By Id-->
  //   const encodedGrievanceId = encodeURIComponent(selectedGrievanceId);

  const fetchUsers = async () => {
    return axiosInstance.get(`/api/grievances/getGrievance/${grievanceId}`);
  };

  const singleGrievanceData = useQuery("single-grievance", fetchUsers);
  const isUserAssignee =
    user?._id === singleGrievanceData?.data?.data?.data?.assignedToUserId;
  // <--
  const priority = singleGrievanceData?.data?.data?.data?.priority;
  const status = singleGrievanceData?.data?.data?.data?.status;

  const grievanceTitle = singleGrievanceData?.data?.data?.data?.grievanceTitle;
  const grievanceStatus = singleGrievanceData?.data?.data?.data?.status;

  // const priority = singleGrievanceData?.priority;

  function getPriorityIcon(priority) {
    switch (priority) {
      case "high":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#E84C3B", border: "0.5px solid #E84C3B" }}
          >
            <img src={highPriority1} alt='priority icon' />
            <span>{t("highPriority")}</span>
          </div>
        );
      case "medium":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#E67E22", border: "0.5px solid #E67E22" }}
          >
            <img src={mediumPriority1} alt='priority icon' />
            <span>{t("mediumPriority")}</span>
          </div>
        );
      case "low":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#3BBEE8", border: "0.5px solid #3BBEE8" }}
          >
            <img src={lowPriority1} alt='priority icon' />
            <span>{t("lowPriority")}</span>
          </div>
        );
      default:
        return null;
    }
  }
  function getStatusIcon(status) {
    switch (status) {
      case "unassigned":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#E84C3B", border: "0.5px solid #E84C3B" }}
          >
            <img src={Unassigned} alt='unassigned icon' />
            <span>{t("unassigned")}</span>
          </div>
        );
      case "assigned":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#E67E22", border: "0.5px solid #E67E22" }}
          >
            <img src={AssignedIcon} alt='assigned icon' />
            <span>{t("assigned")}</span>
          </div>
        );
      case "inProgress":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#3BBEE8", border: "0.5px solid #3BBEE8" }}
          >
            <img src={Inprogess} alt='inprogress icon' />
            <span>{t("inProgress")}</span>
          </div>
        );
      case "pendingApproval":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#your-color", border: "0.5px solid #your-color" }}
          >
            <img src={PendingIcon} alt='pending Approval icon' />
            <span>{t("approvalPending")}</span>
          </div>
        );
      case "complete":
        return (
          <div
            className='priority-wrapper'
            style={{ color: "#your-color", border: "0.5px solid #your-color" }}
          >
            <img src={Completed} alt='complete icon' />
            <span>{t("completed")}</span>
          </div>
        );
      default:
        return null;
    }
  }

  const createdAt = moment(singleGrievanceData?.data?.data?.data?.createdAt);

  const formattedDate = createdAt.format("DD-MM-YYYY");

  // Check if singleGrievanceData is undefined
  if (!singleGrievanceData) {
    return <div>{t("loading")}...</div>;
  }

  const mapLocation = {
    lat:
      singleGrievanceData?.data?.data?.data?.locationCoordinates?.lat ||
      singleGrievanceData?.data?.data?.data?.location?.lat,
    lng:
      singleGrievanceData?.data?.data?.data?.locationCoordinates?.lng ||
      singleGrievanceData?.data?.data?.data?.location?.lng
  };


  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedImage("");
  };

  return (
    <>
      <div className='assign-details-wrapper'>
        <div className='breadcrumbs'>
          <h1 className='flex'>
            <p>
              {t("grievanceManagementSystem")}{" "}
              <MdKeyboardArrowRight style={{ marginTop: "4px" }} />{" "}
              <span>{t("issueDetails")}</span>{" "}
            </p>
          </h1>
        </div>
        <div className='grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-6'>
          <div className='gms-all-details col-span-2'>
            <div className='issue-details'>
              <div className='details-heading flex'>
                <span>{getPriorityIcon(priority)}</span>
                <span>{getStatusIcon(status)}</span>
                <h4 className='pr-4'>{grievanceTitle}</h4>
              </div>
              <div className='details-form-wrapper'>
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 '>
                  <div className='gms-container col-span-2'>
                    <p>
                      <span>{t("description")} </span>
                      <span style={{ fontWeight: 700 }}>
                        {singleGrievanceData?.data?.data?.data?.description ||
                          "NA"}
                      </span>
                    </p>
                    <p>
                      <span>{t("office")} </span>
                      <span style={{ fontWeight: 700 }}>
                        {" "}
                        {singleGrievanceData?.data?.data?.data?.officeName ||
                          "NA"}
                      </span>
                    </p>

                    <h2>{t("reportedBy")}</h2>

                    <p>
                      <span>{t("visitorType")} </span>
                      <span>
                        {singleGrievanceData?.data?.data?.data?.visitorType} +
                        {singleGrievanceData?.data?.data?.data?.numberOfPeople}
                      </span>
                    </p>
                    {/* <p>
                      <span>{t("numberOfPeople")} </span>
                      <span>
                        {singleGrievanceData?.data?.data?.data?.numberOfPeople}
                      </span>
                    </p> */}
                    <p>
                      <span>{t("name")} </span>
                      <span>
                        {singleGrievanceData?.data?.data?.data?.firstName}{" "}
                        {singleGrievanceData?.data?.data?.data?.lastName}
                      </span>
                    </p>
                    <p>
                      <span>{t("gender")} </span>
                      <span>
                        {singleGrievanceData?.data?.data?.data?.gender}
                      </span>
                    </p>
                    <p>
                      {" "}
                      <span>{t("email")} </span>
                      <span style={{ textTransform: "lowercase" }}>
                        {singleGrievanceData?.data?.data?.data?.email}
                      </span>
                    </p>
                    <p>
                      <span>{t("phone")} </span>
                      <span>
                        {singleGrievanceData?.data?.data?.data?.phone}
                      </span>
                    </p>

                    <h2>{t("createdBy")}</h2>
                    <p>
                      <span>{t("name")} </span>
                      <span>
                        {
                          singleGrievanceData?.data?.data?.data
                            ?.createdByUserName
                        }
                        &nbsp; (
                        {formatRole(
                          singleGrievanceData?.data?.data?.data
                            ?.createdByUserRole
                        )}
                        )
                      </span>
                    </p>
                    {/* <p>
                      <span>{t("role")} </span>
                      <span>
                        {formatRole(
                          singleGrievanceData?.data?.data?.data
                            ?.createdByUserRole
                        )}
                      </span>
                    </p> */}
                    <p>
                      <span>{t("creationDate")} </span>
                      <span>{formattedDate}</span>
                    </p>
                    <h2>{t("photos")}</h2>
                    {singleGrievanceData?.data?.data?.data?.showImages ||
                      !isUserAssignee ? (
                      <div className='gms-details-img grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-4'>
                        {/* {singleGrievanceData?.data?.data?.data?.uploadImages?.map(
                          (image, index) => (
                            <img
                              src={image?.publicUrl}
                              alt={image?.name}
                              key={index}
                            />
                          )
                        )} */}
                        {singleGrievanceData?.data?.data?.data?.uploadImages?.map(
                          (image, index) => (
                            <img
                              key={index}
                              src={image?.publicUrl}
                              alt={`image?.name ${index + 1}`}
                              onClick={() => handleImageClick(image)}
                              style={{ cursor: "pointer", marginRight: 10 }}
                              className="image-preview"
                            />
                          )
                        )}
                      </div>
                    ) : (
                      <p>{t("youCantSeeImages")}</p>
                    )}
                  </div>

                  <div className='m-2 h-64'>
                    <ViewOnMap mapLocation={mapLocation}></ViewOnMap>
                    <div className='mt-3 flex justify-center'>
                      <Button>
                        <a
                          href={`https://maps.google.com/?q=${parseFloat(
                            mapLocation?.lat
                          )},${parseFloat(mapLocation?.lng)}`}
                          target='_blank'
                        >
                          <div className='flex'>
                            {/* <img src={mapSvg} alt="Map Icon" /> */}
                            <span className='pl-2 pt-1'>
                              {t("View On Map")}
                            </span>
                          </div>
                        </a>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ASSIGN TASK */}
          {user?.role !== "data_entry_operator" && (
            <div className='col-span-1'>
              <div className='details-heading'>
                <h4>{t("assignTask")}</h4>
              </div>
              <div className='details-form-wrapper'>
                {grievanceStatus === "unassigned" ? (
                  <AssignTo
                    grievanceId={singleGrievanceData?.data?.data?.data?._id}
                  />
                ) : (
                  <AssignDetails
                    singleGrievanceData={singleGrievanceData?.data?.data?.data}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="50%"
        className="image-modal"
      >
        <img src={selectedImage?.publicUrl} alt="Selected" style={{ width: "100%" }} className="p-10" />
      </Modal>
    </>
  );
};
export default IssueDetails;
