import React, { useState, useEffect, useContext } from "react";
import Logo from "../assets/images/logo/ps-logo.png";
// import { HiOutlineSearch } from "react-icons/hi";
import { IoIosNotifications } from "react-icons/io";
import { roleMapping } from "../utils/roleMapping";
// import { InfoCircleOutlined } from "react-icons";

import { NavLink } from "react-router-dom";
import { notification } from "antd";

// import { IoIosNotifications } from "react-icons/io"
import NotificationsWindow from "./NotificationsWindow";
// import Notifications from '../Pages/notifications/Notifications'
import { AppContext } from "../context/AppContextProvider";
import { Switch } from "antd";
import io from "socket.io-client";
import { AiOutlineArrowRight } from "react-icons/ai";
import profile from "../assets/images/placeholder-icon.png";
// import Sun from "../assets/images/icon/sun.svg";
import sunImg from "../assets/svg/sunImg.svg";
import moonImg from "../assets/svg/moonImg.svg";
import { useLocation } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";

// import { NavLink } from "react-router-dom";
// import { FaUserCircle } from "react-icons/fa";
// import NotificationsWindow from "./NotificationsWindow";
// import Notifications from '../Pages/notifications/Notifications'
// import { AppContext } from "../context/AppContextProvider";
// import { AiOutlineArrowRight } from "react-icons/ai";
// import profile from "../assets/images/placeholder-icon.png";
import LanguageOption from "../utils/language-dropdown";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useAxiosInstance from "../lib/useAxiosInstance";

function Navbar() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const { isOpen, themes, setThemes } = useContext(AppContext);
  const [showNotificationWindow, setShowNotificationWindow] = useState(false);
  const [photoUrl, setPhotoUrl] = useState();
  // user data
  const userString = localStorage.getItem("user");
  const user = JSON.parse(userString);

  // FOR UNREAD MESSAGE
  const bgColor = "var(--notification-unread-background)";
  const [seeAllLink, setSeeAllLink] = useState("var(--dark-color)");
  const [unreadNotifications, setUnreadNotifications] = useState();
  const [showNotificationDot, setShowNotificationDot] = useState(false);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL, {
      transports: ["websocket", "polling", "flashsocket"]
    });

    socket.on(user?._id, (data) => {
      notification.open({
        placement: "top",
        message: data.title + " Notification",
        description:
          data?.content.charAt(0).toUpperCase() + data?.content.slice(1),
        duration: 400,
        icon: <IoIosNotifications color='#004877' />
      });
      setShowNotificationDot(true);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const notificationIcon = document.querySelector(".notification-icon");
      const isNotificationIconClicked =
        notificationIcon && notificationIcon.contains(event.target);

      if (isNotificationIconClicked) {
        setShowNotificationWindow((prevState) => !prevState); // toggle notification window
      } else {
        const notificationWindow = document.querySelector(
          ".notification-window"
        );
        const isNotificationWindowClicked =
          notificationWindow && notificationWindow.contains(event.target);

        if (!isNotificationWindowClicked) {
          setShowNotificationWindow(false);
        }
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await axiosInstance.get(
          `api/clients/info/getProfileImage/${user?._id}`
        );
        const url = response?.data?.data?.profileImageLink?.publicUrl;
        setPhotoUrl(url);
      } catch (error) {
        console.error("Error fetching profile image:", error);
      }
    };

    fetchProfileImage();
  }, []);

  // USER PROFILE DROPDOWN
  const [showTooltip, setShowTooltip] = useState(false);

  // Toggle tooltip directly when clicking user profile icon
  const toggleTooltipDirectly = () => {
    setShowTooltip((prevShowTooltip) => !prevShowTooltip);
  };

  // Function to close the tooltip
  const closeTooltip = () => {
    setShowTooltip(false);
  };

  const handleOutsideClick = (event) => {
    if (event.target && !event.target.closest(".tooltip-container")) {
      closeTooltip();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleMarkAllRead = async () => {
    try {
      await markAllReadMutation.mutateAsync();
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const markAllReadMutation = useMutation(
    async () => {
      await axiosInstance.post("/api/notification/markAsReadAll");
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notifications");
        setSeeAllLink("var(--stat-text)");
        setShowNotificationDot(false);
      }
    }
  );
  // Change Navbar Background Color On Scrolling
  const [navBg, setNavBg] = useState(false);
  const changeNavBg = () => {
    if (window.scrollY >= 10) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
  };
  window.addEventListener("scroll", changeNavBg);

  const handleClick = (value) => {
    localStorage.setItem("selectedLanguage", value);
    i18next.changeLanguage(value);
  };

  const toggleTheme = () => {
    const newThemes = themes === "darkMode" ? "lightMode" : "darkMode";
    setThemes(newThemes);
    // Save theme preference to local storage
    localStorage.setItem("themes", newThemes);
  };

  // bg change in dashboard

  const location = useLocation();
  const [isDashboard, setIsDashboard] = useState(false);

  useEffect(() => {
    setIsDashboard(location.pathname === "/dashboard");
  }, [location]);
  return (
    <div className='b'>
      {/* {isLoggedIn && ( */}

      <nav
        className={`nav ${isOpen ? "navbar-bg" : ""} ${
          navBg ? "nav-bg-color" : ""
        }  ${isDashboard ? "dashboard-page-bg" : ""}`}
      >
        <div className='nav-left'>
          {/* <FiMenu className='menubar' onClick={toggle} size="1.3em" /> */}
          <div className='logo'>
            <img src={Logo} alt='Logo' />
          </div>
        </div>

        <div className='nav-right'>
          <div className='nav-right-item custom-switch toggle-btn'>
            {/* <img src={Sun} alt="toggle button" className="toggle-btn" /> */}

            <Switch
              onClick={toggleTheme}
              checkedChildren={
                <img src={moonImg} alt='moon' style={{ height: "2.3em" }} />
              }
              unCheckedChildren={
                <img
                  src={sunImg}
                  alt='sun'
                  style={{ height: "1.3em", marginLeft: "4px" }}
                />
              }
              checked={themes === "darkMode"}
            />
          </div>
          <div className='nav-right-item'>
            <LanguageOption onChange={handleClick} />
          </div>
          {/* <div className='nav-right-item'>
            <HiOutlineSearch size='1.3em' />
          </div> */}
          <div className='nav-right-item notification-icon'>
            {/* <img src={bellIcon} alt="bell icon"/> */}
            <IoIosNotifications size='1.3em' />
            <div
              className='notification-dot'
              style={{
                display: showNotificationDot ? "block" : "none",
                marginRight: "20px"
              }}
            />
          </div>
          {unreadNotifications > 0 && (
            <div className='notification-count'>{unreadNotifications}</div>
          )}
          {/* <NavLink to='/settings'><div className='nav-right-item'><FaUserCircle size="1.3em" /><span className='username'>
          {user?.firstName + " " + user?.lastName}</span></div></NavLink> */}
          {/* <NavLink to='/settings'><div className='nav-right-item'><FaUserCircle size="1.3em" /></div></NavLink> */}
          <div className='nav-right-item user-profile'>
            <div className='tooltip-container mr-2'>
              <img
                src={photoUrl || profile}
                alt='User'
                style={{ height: "30px", width: "30px" }}
                onClick={toggleTooltipDirectly}
              />
              {/* <FaUserCircle size='1.3em' onClick={toggleTooltipDirectly} /> */}
              {showTooltip && (
                <div className='tooltip1'>
                  <div className='flex'>
                    <div>
                      <img src={photoUrl || profile} alt='user profile' />
                    </div>
                    <div className='user-name'>
                      <span className='username'>
                        {user?.firstName + " " + user?.lastName}
                      </span>
                      <span>{roleMapping[user?.role] || user?.role}</span>
                      <span>
                        <NavLink
                          to='/settings/profile'
                          className='flex'
                          onClick={closeTooltip}
                        >
                          <span className='mb-2'> {t("View Profile")}</span>{" "}
                          <AiOutlineArrowRight className='ml-1' />
                        </NavLink>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      {/* )} */}

      {showNotificationWindow && (
        <NotificationsWindow
          setShowNotificationDot={setShowNotificationDot}
          setShowNotificationWindow={setShowNotificationWindow}
          seeAllLink={seeAllLink}
          bgColor={bgColor}
          handleMarkAllRead={handleMarkAllRead}
        />
      )}
    </div>
  );
}

export default Navbar;
