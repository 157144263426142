import React, { useState, useEffect, useRef, useMemo } from "react";
import "../../../Styles/overView.css";
import { Input, Tooltip, DatePicker, Skeleton, Empty } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { EllipsisOutlined } from "@ant-design/icons";
import DeleteModal from "../../../Components/DeleteModal";
import EditModal from "../EditModal.js";
import GrievanceQuery from "./grievanceQuery";
import { priorityIcons } from "../../../utils/priorityIcons";
import "../../../Styles/gms.css";
import { Table, Select, Space, Pagination } from "antd";
import Column from "antd/es/table/Column";
import pinIcon from "../../../assets/images/icon/pinIcon.png";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import SearchLine from "../../../assets/svg/search-line.svg";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useTranslation } from "react-i18next";

const GmsListView = ({
  inputArr,
  setInputArr = () => {},
  priorityClick,
  statusClick,
  newGrievanceData
}) => {
  const { t } = useTranslation();
  const categoryName = newGrievanceData?.categoryName || "";
  const subCategoryName = newGrievanceData?.subCategoryName || "";
  const duration = newGrievanceData?.duration || "";
  const startDate = newGrievanceData?.dateRange?.[0] || "";
  const endDate = newGrievanceData?.dateRange?.[1] || "";

  const { useGMSListData, useGMSListDataByStatus, useGMSListDataByKeyword } =
    GrievanceQuery();
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const [grievanceStatus, setGrievanceStatus] = useState(null);
  const [grievanceId, setGrievanceId] = useState(null);
  const [assignToData, setAssignToData] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pendingApproval, setPendingApproval] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));

  const [openDropdown, setOpenDropdown] = useState([]);

  const dropdownRefs = useRef([]);

  const handleClick = (index) => {
    setOpenDropdown((prevOpenDropdown) => {
      const updatedOpenDropdown = [...prevOpenDropdown];
      updatedOpenDropdown[index] = !updatedOpenDropdown[index];
      return updatedOpenDropdown;
    });
  };

  const handleOutsideClick = (event) => {
    for (let i = 0; i < dropdownRefs.current.length; i++) {
      if (
        dropdownRefs.current[i] &&
        !dropdownRefs.current[i].contains(event.target)
      ) {
        setOpenDropdown((prevOpenDropdown) => {
          const updatedOpenDropdown = [...prevOpenDropdown];
          updatedOpenDropdown[i] = false;
          return updatedOpenDropdown;
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // edit Modal
  const [editModal, setEditModal] = useState(false);
  const showEditModal = (record) => {
    setSelectedRecord(record);
    setEditModal(!editModal);
  };

  // delete grievences single record using grievence id data
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const showModalDelete = (record) => {
    setSelectedRecord(record);
    setIsDeleteModal(!isDeleteModal);
  };

  // pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const pagination = {
    pageSize: limit,
    totalPages: totalPages * limit,
    current: currentPage,
    onChange: (page, pageSize) => {
      setCurrentPage(page);
      setLimit(pageSize);
    }
  };

  //fetch all users-->
  const fetchUsers = async () => {
    return axiosInstance.get("/api/clients/info?page=1&limit=1000");
  };
  const usersData = useQuery("client-users", fetchUsers, {
    refetchOnWindowFocus: false
  });
  // <--

  // const onStatusChange = () => {
  //   setPendingApproval(!pendingApproval);
  // };

  const SearchCategory = (value) => {
    setSearchKeyword(value);
    setCurrentPage(1);
  };

  const fetchByFilter = async () => {
    try {
      const response = await axiosInstance.post(
        `api/grievances/filterGrievance?page=${currentPage}&limit=${limit}&sort=${"DES"}`,
        {
          priority: priorityClick,
          status: statusClick,
          categoryName: categoryName,
          duration: duration,
          startDate: startDate,
          subCategoryName: subCategoryName,
          endDate: endDate
        }
      );
      return response?.data?.message;
    } catch (error) {
      return null;
    }
  };

  const gmsDataByFilter = useQuery(
    [
      "gmsList",
      priorityClick,
      statusClick,
      currentPage,
      limit,
      categoryName,
      subCategoryName,
      duration,
      startDate,
      endDate
    ],
    fetchByFilter,
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const GMS_ListData = useGMSListData(currentPage, limit);
  const GMS_ListData_By_Status = useGMSListDataByStatus(
    currentPage,
    limit,
    pendingApproval
  );
  const GMS_ListData_By_Keyword = useGMSListDataByKeyword(
    searchKeyword,
    currentPage,
    limit
  );

  const listToUse = useMemo(() => {
    if (pendingApproval) {
      return GMS_ListData_By_Status;
    } else if (searchKeyword) {
      return GMS_ListData_By_Keyword;
    } else if (
      statusClick ||
      priorityClick ||
      categoryName ||
      duration ||
      startDate ||
      endDate ||
      subCategoryName
    ) {
      return gmsDataByFilter;
    } else {
      return GMS_ListData;
    }
  }, [
    pendingApproval,
    searchKeyword,
    GMS_ListData_By_Status,
    GMS_ListData_By_Keyword,
    GMS_ListData
  ]);

  function calculateTotalPages(
    listToUse,
    pendingApproval,
    searchKeyword,
    statusClick,
    priorityClick,
    categoryName,
    duration,
    startDate,
    endDate,
    subCategoryName
  ) {
    if (pendingApproval) {
      return listToUse?.data?.data?.length || 0;
    } else if (searchKeyword) {
      return listToUse?.data?.totalGrievance || 0;
    } else if (
      statusClick ||
      priorityClick ||
      categoryName ||
      duration ||
      startDate ||
      endDate ||
      subCategoryName
    ) {
      return listToUse?.data?.totalGrievance || 0;
    } else {
      return listToUse?.data?.grievanceCount || 0;
    }
  }
  useEffect(() => {
    const calculatedTotalPages = calculateTotalPages(
      listToUse,
      pendingApproval,
      searchKeyword,
      statusClick,
      priorityClick,
      categoryName,
      duration,
      startDate,
      endDate,
      subCategoryName
    );
    setTotalPages(calculatedTotalPages);
  }, [
    listToUse,
    pendingApproval,
    searchKeyword,
    statusClick,
    priorityClick,
    categoryName,
    duration,
    startDate,
    endDate,
    subCategoryName
  ]);

  const DeleteData = async () => {
    try {
      await axiosInstance.delete(`/api/grievances/${selectedRecord._id}`);

      const newUserData = inputArr.filter(
        (item) => item._id !== selectedRecord._id
      );
      setInputArr(newUserData);
    } catch (error) {
      // console.error(error);
    }
  };

  const mutateDeleteGrievance = useMutation(DeleteData, {
    onSuccess: (data) => {
      toast.success("Deleted Successfully");
      //invalidate cache and refresh
      queryClient.invalidateQueries("GMS-List_Data");
      queryClient.invalidateQueries("gmsList");
      queryClient.invalidateQueries("gmsSummary");
      queryClient.invalidateQueries("gmsStatus");
      setIsDeleteModal(false);
    },
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });

  const handleDelete = (e) => {
    mutateDeleteGrievance.mutateAsync();
  };

  //Assign to user in grievance -->
  const assignToUser = async () => {
    return axiosInstance.put(
      `/api/grievances/updateGrievance/${grievanceId}`,
      assignToData
    );
  };

  const mutateAssignUser = useMutation(assignToUser, {
    onSuccess: (data) => {
      toast.success(`${data?.data?.message}`);
      //invalidate cache and refresh
      queryClient.invalidateQueries("GMS-List_Data");
      queryClient.invalidateQueries("gmsList");
      queryClient.invalidateQueries("gmsStatus");
    },
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });
  // <--

  const [selectedGrievanceId, setSelectedGrievanceId] = useState(null);

  const handleAssignTo = (user, status) => {
    setAssignToData({
      assignedToUserId: user?._id,
      assignedToUserName: user?.firstName,
      assignedByUserRole: user?.role,
      status: status,
      showImages: true
    });
    queryClient.invalidateQueries("GMS-List_Data");
    queryClient.invalidateQueries("gmsList");
  };

  const handleDueDateChange = (dueDate) => {
    setAssignToData({
      dueDate: dueDate
    });
    queryClient.invalidateQueries("GMS-List_Data");
    queryClient.invalidateQueries("gmsList");
  };

  const approveGrievances = async (record) => {
    return axiosInstance.put(
      `api/grievances/updategrievancestatus/${record?._id}?status=${"complete"}`
    );
  };
  const mutateUpdateGrievance = useMutation(approveGrievances, {
    onSuccess: (data) => {
      toast.success(`${data?.data?.message}`);
      queryClient.invalidateQueries("GMS-List_Data");
      queryClient.invalidateQueries("gmsList");
      queryClient.invalidateQueries("gmsSummary");
      queryClient.invalidateQueries("gmsStatus");
      window.location.reload();
    },
    onError: (error) => toast.error(`something went wrong: ${error.message}`)
  });

  const handleApprove = (record) => {
    mutateUpdateGrievance.mutateAsync(record);
  };

  useEffect(() => {
    if (assignToData != null) {
      mutateAssignUser.mutateAsync();
    }
  }, [assignToData]);

  const filteredData = (listToUse?.data?.data || []).filter(
    (item) => item.status //!== "pendingApproval"
  );

  const handleRowClick = (record) => {
    window.location.href = `#/gms/issuedetails/${record?._id}`;
  };

  const handleCellClick = (e) => {
    e.stopPropagation(); // Prevent row click from being triggered
  };

  const isLoading = listToUse?.isLoading;

  useEffect(() => {
    setCurrentPage(1);
  }, [priorityClick, statusClick]);
  return (
    <React.Fragment>
      <div className='search-filter'>
        <div className='search-option'>
          <Input
            size='small'
            placeholder={t("search")}
            disabled={pendingApproval || statusClick || priorityClick}
            onChange={(e) => SearchCategory(e.target.value)}
          />
          <img src={SearchLine} alt='' />
        </div>

        {/* <Checkbox
          onChange={onStatusChange}
          className='checkbox'
          disabled={searchKeyword}
        >
          Pending Approval
        </Checkbox> */}
      </div>
      <ToastContainer />
      <div className=''>
        <div className='gms-table'>
          <Table
            rowKey={(record) => record?._id}
            dataSource={filteredData}
            style={{ border: "none !important" }}
            pagination={pagination}
            locale={{
              emptyText: isLoading ? (
                <div
                  style={{
                    marginLeft: "20px",
                    width: "95%"
                  }}
                >
                  {" "}
                  {/* Container with 90% width */}
                  <Skeleton
                    title={false}
                    active
                    paragraph={{
                      rows: 6,
                      width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                    }}
                  />
                </div>
              ) : (
                <Empty />
              )
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record)
            })}
          >
            <Column
              title={t("trackID")}
              dataIndex='_id'
              render={(title, record) => (
                <>
                  <div className='flex'>
                    <span className='pinIcon'>
                      {record &&
                      record?.uploadImages &&
                      record?.uploadImages.length ? (
                        <img
                          src={pinIcon}
                          alt='pin icon'
                          className='mr-4'
                          height='20px'
                          width='10px'
                        />
                      ) : null}
                    </span>
                    <Tooltip title={title}>
                      <span>{title.slice(0, 10)}...</span>
                    </Tooltip>
                  </div>
                </>
              )}
            ></Column>

            <Column
              title={t("issueName")}
              dataIndex='grievanceTitle'
              render={(title, record) => (
                <>
                  <span className='flex'>
                    <span className='priorityIcon'>
                      <img
                        src={priorityIcons[record?.priority]}
                        alt='priority icon'
                        style={{
                          maxWidth: "18px",
                          maxHeight: "18px",
                          marginRight: "5px"
                        }}
                      />
                    </span>
                    <span className='pl-1'>{title}</span>
                  </span>
                </>
              )}
            ></Column>
            <Column
              title={t("reportedBy")}
              dataIndex='Reported_By'
              render={(text, record) => (
                <span>
                  {`${record?.firstName} ${record?.lastName}`}
                  {record?.numberOfPeople > 0 && ` +${record?.numberOfPeople}`}
                </span>
              )}
            ></Column>

            <Column title={t("category")} dataIndex='categoryName'></Column>

            <Column
              title={t("subCategory")}
              dataIndex='subCategoryName'
            ></Column>

            {!["data_entry_operator", "office_manager"].includes(
              userData?.role
            ) && (
              <Column
                title={t("dueDate")}
                dataIndex='dueDate'
                render={(text, record) => (
                  <span onClick={handleCellClick}>
                    {record?.dueDate ? (
                      <div
                        className={`${
                          record?.status === "complete"
                            ? "completed-date"
                            : "date-assign"
                        }`}
                      >
                        <span>
                          {new Date(record?.dueDate).toLocaleDateString()}
                        </span>
                        {record?.status !== "complete" && (
                          <CloseOutlined
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            onClick={() => {
                              setGrievanceId(record?._id);
                              handleDueDateChange(null); // Pass null to handle clearing the date
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <DatePicker
                        placeholder='DD/MM/YY'
                        name='dueDate'
                        style={{ width: "120px" }}
                        value={record?.dueDate ? moment(record?.dueDate) : null}
                        onClick={() => setGrievanceId(record?._id)}
                        onChange={(date) => handleDueDateChange(date)}
                        disabledDate={(current) =>
                          current && current < moment().startOf("day")
                        }
                      />
                    )}
                  </span>
                )}
              />
            )}
            {!["data_entry_operator", "office_manager"].includes(
              userData?.role
            ) && (
              <Column
                title={t("assignedTo")}
                dataIndex='assignedToCadre'
                render={(text, record) => (
                  <span onClick={handleCellClick}>
                    {record?.status && record?.status !== "unassigned" ? (
                      <div
                        className={`${
                          record?.status === "assigned"
                            ? "date-assign"
                            : "completed-date"
                        }`}
                      >
                        <span>{record?.assignedToUserName}</span>
                        {record?.status === "assigned" && (
                          <CloseOutlined
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            onClick={() => {
                              setGrievanceId(record?._id);
                              handleAssignTo({}, "unassigned");
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <Select
                        className='gms-select'
                        style={{ width: "120px", height: "32px!important" }}
                        value={
                          record?.assignedToUserName ||
                          record?.status === "unassigned"
                            ? t("unassigned")
                            : record?.assignedToUserName
                        }
                        onClick={() => setGrievanceId(record?._id)}
                        onChange={(value, option) =>
                          handleAssignTo(option.data.user, "assigned")
                        }
                      >
                        {usersData?.data?.data?.data?.results?.map((user) => (
                          <Select.Option
                            key={user?._id}
                            value={user?._id}
                            data={{ user }}
                          >
                            {user?.firstName + " " + user?.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </span>
                )}
              ></Column>
            )}

            {!["data_entry_operator", "office_manager"].includes(
              userData?.role
            ) && (
              <Column
                title={t("status")}
                dataIndex='status'
                render={(status) => {
                  if (status === "pendingApproval") {
                    return "Pending Approval";
                  } else if (status === "inProgress") {
                    return "In Progress";
                  } else if (status === "complete") {
                    return "Completed";
                  } else {
                    return status;
                  }
                }}
              ></Column>
            )}
            {!["data_entry_operator", "office_manager"].includes(
              userData?.role
            ) && (
              <Column
                title={t("action")}
                key='action'
                render={(text, record, index) => (
                  <div onClick={handleCellClick}>
                    <Space
                      className='select-option'
                      size='middle'
                      onClick={() => {
                        setSelectedGrievanceId(record?._id);
                        setGrievanceStatus(record?.status);
                      }}
                    >
                      <div
                        className='dropdown'
                        ref={(el) => (dropdownRefs.current[index] = el)}
                      >
                        <span onClick={() => handleClick(index)}>
                          <EllipsisOutlined />
                        </span>
                        <div
                          className='dropdown-content'
                          style={{
                            display: openDropdown[index] ? "block" : "none"
                          }}
                        >
                          {/* {record?.status === "pendingApproval" && (
                          <div onClick={() => handleApprove(record)}>
                            Approve
                          </div>
                        )} */}
                          <Link to={`/gms/issuedetails/${record?._id}`}>
                            <div>Issue details</div>
                          </Link>

                          <div onClick={() => showEditModal(record)}>Edit</div>
                          <div onClick={() => showModalDelete(record)}>
                            Delete
                          </div>
                          {record?.status === "assigned" && (
                            <div
                              onClick={() => {
                                setGrievanceId(record?._id);
                                handleAssignTo({}, "unassigned");
                              }}
                            >
                              Unassigned
                            </div>
                          )}
                        </div>
                      </div>
                    </Space>
                  </div>
                )}
              />
            )}
          </Table>
        </div>

        <div className='client-pagination '>
          <div className='pagination-text'>
            {t("showing")}{" "}
            <span className='px-2'>
              <Select
                value={limit.toString()}
                style={{ width: 56 }}
                onChange={(value) => {
                  setLimit(parseInt(value));
                  setCurrentPage(1);
                }}
                options={[
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "50", label: "50" },
                  { value: "100", label: "100" }
                ]}
              />
            </span>
            <span className='pl-1'>{t("entriesPerPage")}</span>
          </div>
          <div className='gms-selection'>
            <Pagination
              current={currentPage}
              pageSize={limit}
              total={totalPages}
              onChange={(page, limit) => {
                setCurrentPage(page);
                setLimit(limit);
              }}
              className='pagination'
            />
          </div>
        </div>
      </div>

      {isDeleteModal && (
        <DeleteModal
          deleteBtn='Delete GMS'
          deleteTitle='Delete Gms'
          isDeleteModal={isDeleteModal}
          showModalDelete={showModalDelete}
          record={selectedRecord}
          handleDelete={handleDelete}
        />
      )}

      {editModal && (
        <EditModal
          selectedRecord={selectedRecord}
          editModal={editModal}
          setEditModal={setEditModal}
        />
      )}
    </React.Fragment>
  );
};
export default GmsListView;
