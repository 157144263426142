const formatGenderCommunityValues = (genderVsCommunityData) => {

  return Object.entries(genderVsCommunityData).map(([gender, communities]) => {
    const totalPeople = communities.reduce(
      (sum, { noOfPeople }) => sum + noOfPeople,
      0
    );

    const dataEntry = { gender };

    communities.forEach(({ name, noOfPeople }) => {
      const percentage = ((noOfPeople / totalPeople) * 100).toFixed(2);
      dataEntry[name] = parseFloat(percentage);
    });

    return dataEntry;
  });
};

export default formatGenderCommunityValues;
