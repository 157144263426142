import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "antd";
import reports from "../assets/svg/reports.svg";
// import insights from "../assets/svg/insights.svg"
import survey from "../assets/svg/survey.svg";
import cdm from "../assets/svg/cdm.svg";
import tms from "../assets/svg/tms.svg";
// import electoral from "../assets/svg/electoral.svg"
import { AppContext } from "../context/AppContextProvider";
import overview from "../assets/svg/overview.svg";
import assetsManagement from "../assets/svg/assetsManagement.svg";
import gms from "../assets/svg/gms.svg";
import customerSupport from "../assets/svg/customer-support.svg";
import setting from "../assets/svg/setting.svg";
import help from "../assets/svg/help.svg";
import logout from "../assets/svg/logout.svg";
import socialMedia from "../assets/svg/socialMedia.svg";
import sidebarToggle from "../assets/svg/sidebar-toggle.svg";
// import toggleBtn from "../assets/svg/toggleBtn.svg"
import Logo from "../assets/images/logo/ps-logo.png";
import WhiteLogo from "../assets/images/logo/polstrat-logo.png";
import Logout from "./services/Logout";
import { useTranslation } from "react-i18next";

const Sidebar = ({ role }) => {
  const { t } = useTranslation();

  const { isOpen, toggle } = useContext(AppContext);
  const [logoutModal, setLogoutModal] = useState(false);
  const sideBarOptions = {
    generalUser: [
      {
        id: 0,
        path: "/dashboard",
        name: t("Overview"),
        icon: <img src={overview} alt='' />
      },
      {
        id: 1,
        path: "/cdm",
        name: t("Constituency"),
        icon: <img src={cdm} alt='' />
      },

      {
        id: 2,
        path: "/gms",
        name: isOpen ? t("Grievance") : t("Grievance"),
        icon: <img src={gms} alt='' />
      },
      {
        id: 3,
        path: "/tms",
        name: isOpen ? t("Task") : t("Task"),
        icon: <img src={tms} alt='' className='colored-icon filter-bright' />
      },
      // {
      //     id: 3,
      //     path: "/electoral-management",
      //     name: isOpen ? "EDM" : "Electoral Data Management",
      //     icon: <img src={electoral} alt="" className="colored-icon filter-bright" />
      // },
      {
        id: 4,
        path: "/reports",
        name: t("Reports"),
        icon: <img src={reports} alt='' className='colored-icon' />
      },
      {
        id: 5,
        path: "/survey",
        name: t("Surveys"),
        icon: <img src={survey} alt='' className='colored-icon' />
      },
      // {
      //     id: 7,
      //     path: "/insights",
      //     name: "Insights",
      //     icon: <img src={insights} alt="" className="colored-icon" />
      // },
      // {
      //     id: 8,
      //     path: "/perception",
      //     name: "Constituency Data Management",
      //     icon: <img src={perception} alt="" className="colored-icon" />
      // },
      {
        id: 6,
        path: "/assets-management",
        name: t("Asset"),
        icon: <img src={assetsManagement} alt='' />
      },
      {
        id: 7,
        path: "/socialmedia",
        name: t("Social Media"),
        icon: <img src={socialMedia} alt='' />
      },
      {
        id: 8,
        path: "/customersupport",
        name: t("Customer Support"),
        icon: <img src={customerSupport} alt='' />
      },
      {
        id: 9,
        path: "/settings",
        name: t("Settings"),
        icon: <img src={setting} alt='' />
      },

      {
        id: 10,
        path: "/helpcenter",
        name: t("Help Center"),
        icon: <img src={help} alt='' className='bottom-links' />
      }
    ],
    data_entry_operator: [
      {
        id: 0,
        path: "/gms",
        name: isOpen ? t("Grievance") : t("Grievance"),
        icon: <img src={gms} alt='' />
      },

      {
        id: 1,
        path: "/settings",
        name: t("Settings"),
        icon: <img src={setting} alt='' />
      }
    ],
    office_manager: [
      {
        id: 2,
        path: "/gms",
        name: isOpen ? t("Grievance") : t("Grievance"),
        icon: <img src={gms} alt='' />
      },
      {
        id: 3,
        path: "/tms",
        name: isOpen ? t("Task") : t("Task"),
        icon: <img src={tms} alt='' className='colored-icon filter-bright' />
      },
      {
        id: 2,
        path: "/settings",
        name: t("Settings"),
        icon: <img src={setting} alt='' />
      }
    ]
  };
  const menuItem = sideBarOptions[role] || sideBarOptions.generalUser;

  return (
    // <div style={{ width: isOpen ? "284px" : "80px" }} className="container">

    //     <div style={{ width: isOpen ? "284px" : "80px" }} className="sidebar">
    //         <div className={`navLeft ${isOpen ? 'sidebarLeft' : ''}`} >
    //             <img src={isOpen ? toggleBtn : sidebarToggle} className={`menubar ${isOpen ? 'sidebarToggle' : ''}`} alt="" onClick={toggle} size="1.3em" />
    //             <div className='logo'>
    //                 <img src={Logo} alt="Logo" />
    //             </div>
    //         </div>
    //         {
    //             menuItem.map((item) => (
    //                 <Tooltip placement="right" key={item.id} title={item.name}>
    //                 <NavLink to={item.path} className={`link ${isOpen ? 'sidebarLeft' : ''}`} activeclassname="active">
    //                         <div className="icon">{item.icon}</div>
    //                         <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
    //                     </NavLink>
    //                 </Tooltip>

    //             ))
    //         }
    //         <Tooltip placement="right" title="Logout">
    //             <div className="link logout" onClick={() => setLogoutModal(true)}>
    //                 <div className="icon">
    //                     <img src={logout} alt="" />
    //                 </div>
    //                 <div style={{ display: isOpen ? "block" : "none" }} className="link_text">
    //                     Logout
    //                 </div>
    //             </div>
    //         </Tooltip>
    //     </div>
    //     <Logout logoutModal={logoutModal} setLogoutModal={setLogoutModal} />

    // </div>

    <div style={{ width: isOpen ? "284px" : "90px" }} className='container'>
      <div style={{ width: isOpen ? "284px" : "80px" }} className='sidebar'>
        <div className='sidebar-content'>
          <div className={`navLeft ${isOpen ? "sidebarLeft" : ""}`}>
            <img
              src={sidebarToggle}
              className={`menubar ${isOpen ? "sidebarToggle" : ""}`}
              alt=''
              onClick={toggle}
              size='1.3em'
            />
            <div className='logo'>
              <img src={isOpen ? WhiteLogo : Logo} alt='Logo' />
            </div>
          </div>
          {menuItem.map((item) => (
            <Tooltip placement='right' key={item.id} title={item.name}>
              <NavLink
                to={item.path}
                className={`link ${isOpen ? "sidebarLeft" : ""}`}
                activeclassname='active'
              >
                <div className='icon'>{item.icon}</div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className='link_text'
                >
                  {item.name}
                </div>
              </NavLink>
            </Tooltip>
          ))}
          <Tooltip placement='right' title='Logout'>
            <div
              className={`link logout ${isOpen ? "sidebarLeft" : ""}`}
              onClick={() => setLogoutModal(true)}
            >
              <div className='icon'>
                <img src={logout} alt='' />
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className='link_text'
              >
                {t("Logout")}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <Logout logoutModal={logoutModal} setLogoutModal={setLogoutModal} />
    </div>
  );
};

export default Sidebar;
