import React, { useEffect } from "react";
import { Button, Input, Form, Row, Col } from "antd";

import useAxiosInstance from "../../lib/useAxiosInstance";
import { toast } from "react-toastify";
import ProfilePicUpload from "./ProfilePicUpload";
import { useTranslation } from "react-i18next";

const Profile = ({
  profileData,
  setIsProfileUpdated = () => {},
  setActiveTab = () => {}
}) => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const handleSubmit = async (values) => {
    try {
      const updatedUserData = {
        ...profileData,
        password: values?.clientPassword,
        bio: values?.bio,
        email: values?.email
      };

      await axiosInstance
        .put(`api/clients/clientUser/info/${profileData._id}`, updatedUserData)
        .then(() => {
          setIsProfileUpdated((prev) => !prev);
          toast.success("Profile updated successfully");
        });
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile");
    }
  };

  useEffect(() => {
    form.setFieldsValue(profileData);
  }, [profileData, form]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className='SecondTab bg-white'>
      <div className='SecondTab-body'>
        <ProfilePicUpload user={user} />
        <Form form={form} onFinish={handleSubmit}>
          <div className='px-5'>
            <div className='profile-form pb-8'>
              {/* <div className='username-password-fields'> */}
              <Row>
                <Col span={8}>
                  <div className='username-field'>
                    <label>{t("Username")}</label>
                    <Form.Item name='email'>
                      <Input disabled
                        placeholder='Username'
                        style={{ height: 48 }}
                        onKeyPress={handleKeyPress}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={8} offset={1}>
                  <div className='username-field'>
                    <label>{t("Password")}</label>
                    <Form.Item name='clientPassword'>
                      <Input.Password
                        placeholder='Password'
                        style={{ height: 48 }}
                        onKeyPress={handleKeyPress}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            <Row>
              <Col span={17}>
                <label>{t("Bio")}</label>
                <Form.Item name='bio'>
                  <Input.TextArea
                    style={{ height: 120, resize: "none" }}
                    placeholder='This is my Bio Text'
                    onKeyPress={handleKeyPress}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className='button-bottom-right bg-white'>
            <Button className='cancle-btn' ghost>
              {t("Cancel")}
            </Button>
            <Button type='primary' htmlType='submit' className='primary-btn'>
              {t("Save Changes")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
