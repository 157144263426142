import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Input, Button } from "antd";
import {
  TwitterOutlined,
  InstagramOutlined,
  FacebookOutlined
} from "@ant-design/icons";
import { useMutation, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const AddSocialModal = ({ showModal, handleModal, usernameData }) => {
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isLinking = useRef(false); // Ref to manage the linking state

  useEffect(() => {
    const formatData = () => {
      const formatted = usernameData.reduce((acc, curr) => {
        acc[curr.platform] = curr.username;
        return acc;
      }, {});
      setFormattedData(formatted);
    };
    formatData();
  }, [usernameData]);

  const axiosInstance = useAxiosInstance();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const linkUser = async (userData) => {
    const response = await axiosInstance.post("/api/users/link", userData);
    return response.data;
  };

  const deleteUser = async (userData) => {
    const response = await axiosInstance.post(
      "/api/users/delete_socials",
      userData
    );
    return response.data;
  };

  const linkMutation = useMutation(linkUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("socialMedia");
      toast.success("User linked successfully!");
      handleModal();
      isLinking.current = false; // Reset the flag after success
    },
    onError: (error) => {
      console.log("error", error);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again."
      );
      isLinking.current = false; // Reset the flag after error
    }
  });

  const deleteMutation = useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("socialMedia");
      queryClient.invalidateQueries("fetchUsernames");

      toast.success("User deleted successfully!");
    },
    onError: (error) => {
      console.log("error", error);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    }
  });

  const handleLinkUser = (platform, username) => {
    if (!username) {
      toast.error("Please input the User ID");
      return;
    }

    if (isLinking.current) return; // Prevent further execution if already linking
    isLinking.current = true; // Set the flag to true to prevent multiple clicks

    linkMutation.mutate(
      { platform, username },
      {
        onSuccess: (data) => {
          if (platform === "instagram" || platform === "facebook") {
            if (data.url) {
              window.open(data.url, "_blank");
            }
          } else if (platform === "twitter") {
            toast.success("User linked successfully");
          }
        },
        onError: (error) => {
          toast.error("Error linking user");
        }
      }
    );
  };

  const handleDeleteUser = (platform, username) => {
    if (!username) {
      toast.error("User ID not present");
      return;
    }
    deleteMutation.mutate({ platform, username });
    form.setFieldValue(`${platform}UserId`, "");
  };

  const handleTwitterLinkClick = () => {
    const twitterUserId = form.getFieldValue("twitterUserId");
    handleLinkUser("twitter", twitterUserId);
  };

  return (
    <Modal
      open={showModal}
      onCancel={handleModal}
      className='logout-Modal'
      footer={false}
      width={500}
    >
      <h3>{t("Connect Social Media")}</h3>
      <div className='modal-body p-2'>
        <Form
          form={form}
          layout='vertical'
          initialValues={{
            twitterUserId: formattedData?.twitter,
            instagramUserId: formattedData?.instagram,
            facebookUserId: formattedData?.facebook
          }}
        >
          <div className='mx-2 mb-4'>
            <div className='grid grid-cols-5 gap-2'>
              <div className='col-span-3'>
                <Form.Item
                  label={
                    <span className='flex items-center text-lg text-[--dark-color]'>
                      <TwitterOutlined style={{ marginRight: "8px" }} />
                      {t("Twitter User ID")}:
                    </span>
                  }
                  name='twitterUserId'
                  rules={[
                    { required: true, message: "Please input Twitter User ID" }
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className='col-span-2 mt-9 flex space-x-2'>
                <Button
                  className='social-media-btn'
                  type='button'
                  onClick={handleTwitterLinkClick}
                  disabled={isButtonDisabled}
                >
                  {t("Link")}
                </Button>
                <Button
                  type='danger'
                  className='remove-btn'
                  onClick={() => {
                    const twitterUserId = form.getFieldValue("twitterUserId");
                    handleDeleteUser("twitter", twitterUserId);
                  }}
                >
                  {t("Remove")}
                </Button>
              </div>
            </div>

            <div className='grid grid-cols-5 gap-2'>
              <div className='col-span-3'>
                <Form.Item
                  label={
                    <span className='flex items-center text-lg text-[--dark-color]'>
                      <InstagramOutlined style={{ marginRight: "8px" }} />
                      {t("Instagram User ID")}:
                    </span>
                  }
                  name='instagramUserId'
                  rules={[
                    {
                      required: true,
                      message: "Please input Instagram User ID"
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className='col-span-2 mt-9 flex space-x-2'>
                <Button
                  type='button'
                  className='social-media-btn'
                  onClick={() => {
                    const instagramUserId =
                      form.getFieldValue("instagramUserId");
                    handleLinkUser("instagram", instagramUserId);
                  }}
                  disabled={!!formattedData?.instagram}
                >
                  {t("Link")}
                </Button>
                <Button
                  type='danger'
                  className='remove-btn'
                  onClick={() => {
                    const instagramUserId =
                      form.getFieldValue("instagramUserId");
                    handleDeleteUser("instagram", instagramUserId);
                  }}
                >
                  {t("Remove")}
                </Button>
              </div>
            </div>

            <div className='grid grid-cols-5 gap-2'>
              <div className='col-span-3'>
                <Form.Item
                  label={
                    <span className='flex items-center text-lg text-[--dark-color]'>
                      <FacebookOutlined style={{ marginRight: "8px" }} />
                      {t("Facebook User ID")}:
                    </span>
                  }
                  name='facebookUserId'
                  rules={[
                    { required: true, message: "Please input Facebook User ID" }
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className='col-span-2 mt-9 flex space-x-2'>
                <Button
                  type='button'
                  className='social-media-btn'
                  onClick={() => {
                    const facebookUserId = form.getFieldValue("facebookUserId");
                    handleLinkUser("facebook", facebookUserId);
                  }}
                  disabled={!!formattedData?.facebook}
                >
                  {t("Link")}
                </Button>
                <Button
                  type='danger'
                  className='remove-btn'
                  onClick={() => {
                    const facebookUserId = form.getFieldValue("facebookUserId");
                    handleDeleteUser("facebook", facebookUserId);
                  }}
                >
                  {t("Remove")}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddSocialModal;
