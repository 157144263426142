import React from 'react'
import { SurveyContextProvider } from '../../context/surveyContext';
import Result from '../../Components/survey/Result';

const Survey = () => {

  
  return (
    <>
    <SurveyContextProvider>
      <Result/>
    </SurveyContextProvider>
    </>
  )
}

export default Survey