import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { mapStyles } from "./mapStyles";

const lib = ["places"];

const ViewOnMap = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: lib
  });

  if (loadError) return <div>Error loading Google Maps</div>;
  if (!isLoaded) return <div>Loading Google Maps...</div>;

  const { lat, lng } = props?.mapLocation;

  return (
    <div className='h-full'>
      <GoogleMap
        mapContainerClassName='map-container'
        options={{
          styles: mapStyles,
          disableDefaultUI: true
        }}
        center={{ lat: parseFloat(lat), lng: parseFloat(lng) }} // Convert string lat/lng to numbers
        zoom={12} // Set zoom level if you have it
      >
        {lat && lng && (
          <Marker position={{ lat: parseFloat(lat), lng: parseFloat(lng) }} />
        )}
      </GoogleMap>
    </div>
  );
};

export default ViewOnMap;
