import React, { useState } from "react";
import { Table, Select, Pagination, Space} from "antd";
import { useQuery } from "react-query";
import { useParams, Link } from 'react-router-dom';
import useAxiosInstance from "../../../lib/useAxiosInstance";
import highPriority from "../../../assets/svg/highPriority.svg"
import mediumPriority from "../../../assets/svg/mediumPriority.svg"
import lowPriority from "../../../assets/svg/lowPriority.svg"
const { Column } = Table;


const Tasks = () => {
  const axiosInstance = useAxiosInstance();
  const { userId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total] = useState(0);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //fetch all task list by assigned to user-->
  const fetchTasks = async () => {
    return axiosInstance.get(
      `api/userDetails/getTasksByAssignedToUserId/${userId}`,
    );
  };
  const tasksData = useQuery("tasks_By_AssignedToUser", fetchTasks);
  // <--
  const filteredTmsData = tasksData?.data?.data?.data.filter(record => record.status !== 'unassigned');
  
  const pagination = {
    total: total,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange,
  };

  function getPriorityIcon(priority) {
    switch (priority) {
      case "high":
        return <img src={highPriority} alt="icon" />;
      case "medium":
        return <img src={mediumPriority} alt="icon" />;
      case "low":
        return <img src={lowPriority} alt="icon" />;
      default:
        return null;
    }
  }

  return (
    <div>

      <div className="gms-page">
        <div className="gms-table">
          <Table
            className="tms-list"
            rowKey={record => record._id}
            dataSource={filteredTmsData}
            style={{ border: "none !important" }}
            pagination={pagination}
          >
            <Column title="Track Id" dataIndex="_id" />
            <Column title="Task Name" dataIndex="title"
              render={(text, record) => (
                <Space>
                  {getPriorityIcon(record.priority)}
                  {text}
                </Space>
              )}
            />
            <Column title="Due Date" dataIndex=""
              render={(text, record) => (
                <span>
                -
                </span>
              )}
             />
            <Column title="Status" dataIndex="status" />
            <Column title="Action" dataIndex=""
              render={(text, record) => (
                <Space className="view-more-details">
                  <Link to={`/assets-management/user/userInfo/taskuserinfo/${record._id}`}><span>View</span></Link>
                </Space>
              )}
            />
          </Table>
        </div>
      </div>

      <div className="client-pagination flex justify-justify">
        <div>
          <Select
            defaultValue={5}
            value={limit.toString()}
            style={{ width: 56 }}
            onChange={(value) => setLimit(parseInt(value))}
            options={[
              { value: "5", label: "5" },
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "50", label: "50" },
              { value: "100", label: "100" },
            ]}
          />{" "}
          Entries per pages
        </div>
        <div>
          <Pagination
            current={currentPage}
            pageSize={limit}
            total={total}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setLimit(pageSize);
            }}
            className="pagination"

          />
        </div>
      </div>

    </div>
  )
}

export default Tasks; 