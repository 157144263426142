import React from "react";
import { ConstituencyContextProvider } from "../../context/constituencyContext";
import Result from "../../Components/cdm/Result";


const ConstituencyManagement = () => {
    return(
        <>
            <ConstituencyContextProvider>
                <Result />
            </ConstituencyContextProvider>
        </>
    )
}

export default ConstituencyManagement;