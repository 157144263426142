import React, { useState, useEffect } from "react";

import NoDataIcon from "../../assets/svg/no-data.svg";
import NotificationItem from "./NotificationItem";
import "../../Styles/notificationsPage.css";
import { Pagination, Select, Skeleton } from "antd";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useQueryClient, useMutation } from "react-query";

import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  //these objects are just for testing this should be replaced with the backend notification array of objects

  // FOR UNREAD MESSAGE
  const bgColor = "var(--notification-unread-background)";
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [seeAllLink, setSeeAllLink] = useState("var(--dark-color)");

  const [notifications, setNotifications] = useState([]);
  const axiosInstance = useAxiosInstance();
  const fetchNotifications = async () => {
    const response = await axiosInstance.get(
      `api/notification?&page=${currentPage}&limit=${limit}`
    );
    return response.data;
  };

  const { data, isLoading } = useQuery(
    ["notifications", currentPage, limit],
    fetchNotifications,
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        console.error("Error fetching notifications:", error);
      }
    }
  );

  const handleMarkAllRead = async () => {
    try {
      await markAllReadMutation.mutateAsync();
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const markAllReadMutation = useMutation(
    async () => {
      await axiosInstance.post("/api/notification/markAsReadAll");
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notifications");
        setSeeAllLink("var(--stat-text)");
      }
    }
  );
  // const pagination = {
  //   total: total * limit,
  //   pageSize: limit,
  //   current: currentPage,
  //   onChange: (page, pageSize) => {
  //     setCurrentPage(page);
  //     setLimit(pageSize);
  //   }
  // };
  useEffect(() => {
    if (data?.message?.data) {
      setNotifications(data?.message?.data);
      setTotal(data?.message?.notificationCount);
    }
  }, [data]);

  // const handleMarkAllRead = () => {
  //   if (!unreadNotifications) {
  //     setbgColor("#fff");
  //     setSeeAllLink("rgba(0, 0, 0, 0.65)");
  //     setUnreadNotifications(
  //       notifications.filter((notification) => notification.read === "false")
  //     );
  //   } else {
  //     setbgColor("#F6FCFF");
  //     setSeeAllLink("#000");
  //     setUnreadNotifications(undefined);
  //   }
  // };
  return (
    <div className='see-all-notifications'>
      <div className='heading-text-div'>
        <p className='title'>{t("Notifications")}</p>

        <p
          className='read-all-text'
          onClick={handleMarkAllRead}
          style={{ color: seeAllLink }}
        >
          {t("Mark all as Read")}
        </p>
      </div>
      <div
        className='allNotifications'
        style={{ backgroundColor: "var(--table-wrapper)" }}
      >
        {isLoading ? (
          <Skeleton
            title={false}
            className='mt-4 mb-4 p-8 '
            active
            paragraph={{
              rows: 6,
              width: [
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%"
              ],
              height: ["30px", "30px", "30px", "30px", "30px"]
            }}
          />
        ) : notifications && notifications.length > 0 ? (
          notifications.map((item) => {
            return (
              <NotificationItem
                key={item?._id}
                url={item?.profileURL}
                title={item?.title}
                content={item?.content}
                time={item?.createdAt}
                bgColor={
                  item?.isViewed
                    ? "var(--notification-read-background)"
                    : bgColor
                }
              />
            );
          })
        ) : (
          <div className='no-data-icon m-20'>
            <img src={NoDataIcon} alt='' />
            <h1>{t("No Notification Available")}</h1>
          </div>
        )}

        <div className='client-pagination pb-5 '>
          <div className='pagination-text'>
            {t("Showing")}{" "}
            <span className='px-2'>
              {" "}
              <Select
                value={limit.toString()}
                style={{ width: 56 }}
                onChange={(value) => setLimit(parseInt(value))}
                options={[
                  { value: "5", label: "5" },
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "50", label: "50" },
                  { value: "100", label: "100" }
                ]}
              />{" "}
            </span>
            <span className='pl-1'>{t("entriesPerPage")}</span>
          </div>
          <div className='gms-selection'>
            <Pagination
              current={currentPage}
              pageSize={limit}
              total={total}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setLimit(pageSize);
              }}
              className='pagination'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
