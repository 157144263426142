import React, { useEffect, useState } from "react";
import { Table, Space, Pagination, Select } from "antd";
import { useQuery } from "react-query";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { EllipsisOutlined } from "@ant-design/icons";
import ShareReports from "./ShareReports";
import SearchFilter from "../SearchFilter";
import ReportDownload from "./ReportDownload";
import { useNavigate } from "react-router-dom";

import { t } from "i18next";

import pdfImg from "../../assets/images/icon/pdf.png";

const Column = Table;

const ReportsData = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState("DES");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportType, setReportType] = useState("all");

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };

  // DROPDOWN
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleClick = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
    }
  };

  const handleMouseLeave = () => {
    setOpenDropdown(null);
  };

  const isDropdownOpen = (index) => {
    return openDropdown === index;
  };
  const axiosInstance = useAxiosInstance();

  //fetch all REPORTS-->
  const allReportsData = async () => {
    const response = await axiosInstance.get(
      `api/reports/client/getAllReports?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}&type=${reportType}`
    );
    setTotal(response.data.totalpages);
    return response;
  };
  const reportsData = useQuery(
    ["ReportsClient", currentPage, limit, sort, searchKeyword, reportType],
    () => allReportsData(currentPage, limit, sort, searchKeyword, reportType)
  );

  const fetchSearchReport = async () => {
    let response;
    let url;

    if (searchKeyword) {
      url = `api/reports/client/getAllReports?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}&type=${reportType}`;
    } else if (reportType !== "all") {
      url = `api/reports/client/getAllReports?page=1&limit=${limit}&type=${reportType}`;
    } else {
      url = `api/reports/client/getAllReports?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}&type=${reportType}`;
    }

    response = await axiosInstance.get(url);
    setTotal(response?.data?.totalpages);

    return response;
  };

  const reportSearchData = useQuery(
    ["search-report", searchKeyword, currentPage, limit, reportType],
    () => fetchSearchReport(),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const SearchReports = (value) => {
    setCurrentPage(1);
    setSearchKeyword(value);
  };

  // Filter Report
  const handleFilterReportType = (value) => {
    setCurrentPage(1);
    setReportType(value);
  };

  // DOWNLOAD REPORT FILE
  const handleView = (record) => {
    if (record.status === "close") {
      axiosInstance
        .get(`api/reports/client/viewRequestedReport/${record._id}`, {
          headers: {
            subscription: "advanced"
          }
        })
        .then((res) => {
          const publicUrl = res.data.data.publicUrl;
          const fileName = getFileNameFromUrl(publicUrl);
          const link = document.createElement("a");
          link.href = publicUrl;
          link.setAttribute("download", fileName);
          link.setAttribute("target", "_blank");
          link.click();
        });
    } else {
      axiosInstance
        .get(`api/reports/${record._id}`, {
          headers: {
            subscription: "advanced"
          }
        })
        .then((res) => {
          const publicUrl = res.data.data.resourceLocation[0].publicUrl;
          const fileName = getFileNameFromUrl(publicUrl);
          const link = document.createElement("a");
          link.href = publicUrl;
          link.setAttribute("download", fileName);
          link.click();
        });
    }
  };

  const getFileNameFromUrl = (url) => {
    const urlParts = url.split("/");
    const fileName = urlParts[urlParts.length - 1];
    const fileExtension = fileName.split(".").pop();
    return fileExtension;
  };

  // VIEW MORE DETAILS
  const [moreDetails, setMoreDetails] = useState(false);

  const viewDetails = (record) => {
    setMoreDetails(true);
  };

  // Function to handle the click on reportName
  const handleReportNameClick = (record) => {
    // console.log("record", record);
    const { _id } = record;
    navigate(`../uploadedReport/${_id}`, { state: record });
    // setSelectedReport(record);
  };
  useEffect(() => {}, [searchKeyword]);
  return (
    <>
      <div className='report-filter flex justify-between'>
        <SearchFilter className='mt-5' handleSearch={SearchReports} />
        <div className='report-filter-select flex flex-col pr-4'>
          <span>{t("filter")}</span>
          <Select
            className=''
            placeholder='Select'
            onChange={handleFilterReportType}
            defaultValue='all'
          >
            <Select.Option value='all'>{t("all")}</Select.Option>
            <Select.Option value='free'>{t("free")}</Select.Option>
            <Select.Option value='premium'>{t("Premium")}</Select.Option>
          </Select>
        </div>
      </div>
      <div className='report-data-modal'>
        <Table
          rowKey={(record) => record._id}
          pagination={pagination}
          dataSource={
            reportSearchData?.data?.data?.data || reportsData?.data?.data?.data
          }
        >
          <Column
            title={t("REPORT TYPE")}
            dataIndex='type'
            className='report-type w-40'
            render={(text, record) => (
              <span
                style={{
                  color:
                    record.status === "close" && !text
                      ? "#0066ff"
                      : text === "premium"
                      ? "#0066ff"
                      : "",
                  backgroundColor:
                    record.status === "close" && !text
                      ? "var(--tag-type-bg)"
                      : text === "premium"
                      ? "var(--tag-type-bg)"
                      : "",
                  border:
                    record.status === "close" && !text
                      ? "1px solid #0066ff"
                      : text === "premium"
                      ? "1px solid #0066ff"
                      : "none"
                }}
              >
                {record.status === "close" && !text
                  ? "Premium"
                  : text === "premium"
                  ? "Premium"
                  : ""}
              </span>
            )}
          />
          <Column
            title={t("UPLOAD FILE")}
            className='report-type w-40'
            render={(text, record) => (
              <div className='px-4'>
                <img
                  src={record?.resourceLocation?.publicUrl || pdfImg}
                  alt='Uploaded File'
                  style={{ width: "50px", height: "auto" }}
                  onError={(e) => {
                    e.target.src = pdfImg;
                  }}
                />
              </div>
            )}
          />

          <Column
            title={t("reportName")}
            dataIndex='reportName'
            className='font-semibold cursor-pointer'
            render={(text, record) => (
              <span
                onClick={() => {
                  handleReportNameClick(record);
                }}
                style={{ textDecoration: "underline" }}
              >
                {record?.reportName}
              </span>
            )}
          />
          <Column
            title={t("DESCRIPTION")}
            render={(text, record) => {
              const description =
                record.reportDescription || record.description;
              return <span>{description}</span>;
            }}
          />
          <Column
            title={t("uploadDate")}
            dataIndex='createdAt'
            sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
            defaultSortOrder='descend'
            render={(text, record) => (
              <span>
                {new Date(record.createdAt).toLocaleDateString("en-GB")}
              </span>
            )}
          />
          <Column
            className='report-action'
            title={t("action")}
            key='action'
            render={(text, record, index) => (
              <Space className='' size='small' style={{}}>
                <div
                  className='dropdown '
                  onClick={() => handleClick(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <span>
                    <EllipsisOutlined />
                  </span>
                  {isDropdownOpen(index) && (
                    <div className='dropdown-content'>
                      <div onClick={() => handleView(record)}> {t("view")}</div>
                      {/* <div onClick={() => viewDetails(record)}>
                        {" "}
                        {t("moreDetails")}
                      </div> */}
                    </div>
                  )}
                </div>
              </Space>
            )}
          />
        </Table>

        <ShareReports
          moreDetails={moreDetails}
          setMoreDetails={setMoreDetails}
        />
        {selectedReport && (
          <ReportDownload
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
          />
        )}
      </div>
      <div className='client-pagination'>
        <div className='pagination-text'>
          {t("showing")}
          <Select
            className='mx-2'
            value={limit.toString()}
            style={{ width: 56 }}
            onChange={(value) => {
              setLimit(parseInt(value));
              setCurrentPage(1);
            }}
            options={[
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "50", label: "50" },
              { value: "100", label: "100" }
            ]}
          />
          <span className='pl-1'>{t("entriesPerPage")}</span>
        </div>
        <div>
          <Pagination
            current={currentPage}
            pageSize={limit}
            total={total * limit}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setLimit(pageSize);
            }}
            className='pagination'
          />
        </div>
      </div>
    </>
  );
};

export default ReportsData;
