
import React from "react";
import "../../Styles/CustomerSupport.css"
import { CustSupportContextProvider } from "../../context/custSupportContext";
import Result from "../../Components/CustomerSupport/Result";



const CustomerSupport = () => {


  return (
    <>
      <CustSupportContextProvider>
        <Result />
      </CustSupportContextProvider>
    </>
  );
};
export default CustomerSupport;