import React, { useState } from "react";
import { Table, Space, Pagination, Select } from "antd";
import SearchFilter from "../../Components/SearchFilter";
import { EllipsisOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Column = Table;

const Billing = ({ user }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };

  // Dropdown

  const [hoveredDropdown, setHoveredDropdown] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredDropdown(index);
  };

  const handleMouseLeave = () => {
    setHoveredDropdown(null);
  };

  const isDropdownOpen = (index) => {
    return hoveredDropdown === index;
  };

  return (
    <div className='bg-white'>
      <div className='report-data-modal'>
        <div>
          <div className='w-full grid grid-col-2 gap-12overview-top pb-6'>
            <div className='subscription'>
              <h1>{t("Current Subscription")}</h1>
              <div className='pt-6 wrapper flex'>
                <div className='right-side'>
                  <p className='basic'>
                    <span className='span-container'>Plan</span>
                    <span>
                      {user?.subscription.map((item) => item?.name).join(", ")}
                    </span>
                  </p>
                  <p className='basic'>
                    <span>{t("Duration")}</span>
                    <span>-</span>
                  </p>
                  <p className='basic'>
                    <span>{t("Amount")}</span>
                    <span>-</span>
                  </p>
                </div>
                <div className='left-side'>
                  <p className='basic'>
                    <span>{t("Start Date")}</span>
                    <span>-</span>
                  </p>
                  <p className='basic'>
                    <span>{t("End Date")}</span>
                    <span>-</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='setting-search-container'>
          <SearchFilter />
        </div>
        <div className="billing-table">
          <Table
            rowKey={(record) => record._id}
            // dataSource={reportsData?.data?.data?.data}
            pagination={pagination}
          >
            <Column
              title={t("Invoice Date")}
              dataIndex='createdAt'
              render={(text, record) => (
                <span>
                  {new Date(record.createdAt).toLocaleDateString("en-GB")}
                </span>
              )}
            />
            <Column title={t("Mod. Subscribed")} dataIndex='reportName' />
            <Column title={t("Status")} dataIndex='reportDescription' />
            <Column title={t("Transaction ID")} dataIndex='reportDescription' />
            <Column title={t("Amount")} dataIndex='reportDescription' />
            <Column title={t("Payment Date")} dataIndex='reportDescription' />

            <Column
              className='Duration'
              title={t("ACTION")}
              key='action'
              render={(text, record, index) => (
                <Space className='select-option w-1.5' size='small' style={{}}>
                  <div
                    className='dropdown '
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span>
                      <EllipsisOutlined />
                    </span>
                    {isDropdownOpen(index) && (
                      <div className='dropdown-content -ml-24'>
                        {/* <div onClick={() => handleView(record)}>View</div> */}
                        {/* <div onClick={() => viewDetails(record)}>More Details</div> */}
                      </div>
                    )}
                  </div>
                </Space>
              )}
            />
          </Table>

          {/* <ShareReports moreDetails={moreDetails} setMoreDetails={setMoreDetails} /> */}

          <div className='client-pagination rounded-none'>
            <div className='pagination-text'>
              {t("Showing")}
              <Select
                className='mx-2'
                value={limit.toString()}
                style={{ width: 56 }}
                onChange={(value) => {
                  setLimit(parseInt(value));
                  setCurrentPage(1);
                }}
                options={[
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "50", label: "50" },
                  { value: "100", label: "100" }
                ]}
              />
              <span className='pl-1'>{t("Entries per pages")}</span>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={limit}
                total={total * limit}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setLimit(pageSize);
                }}
                className='pagination'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
