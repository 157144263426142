import React from "react";
import "../../Styles/notificationItem.css";
import formatTimeDifference from "../../utils/formatTime";
import icon from "../../assets/images/placeholder-icon.png";
import notificationMapping from "../../utils/notificationMapping";

const NotificationItem = ({ url, title, content, time, bgColor }) => {
  return (
    <div
      className='notification-item'
      style={{
        backgroundColor: bgColor,
        padding: "10px",
        borderBottom: "1px solid #CCDAE4",
        cursor: "pointer"
      }}
      onClick={() => {
        window.open(`#/${notificationMapping[title]}`, "");
      }}
    >
      <div className='child1 icon-div'>
        <img className='icon' src={url || icon} alt='icon' />
      </div>
      <div className='child2 notification-content'>
        <div className='flex justify-between'>
          <p className='notification-item-title'>{title}</p>
          <div className='child3 time-div'>
            <p>{formatTimeDifference(time)}</p>
          </div>
        </div>
        <p className='notification-content-item'>
          {content.charAt(0).toUpperCase() + content.slice(1)}
        </p>
      </div>
    </div>
  );
};

export default NotificationItem;
