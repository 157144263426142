import React from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
const DeleteModal = (props) => {
  const { t } = useTranslation();

  const handleOk = () => {
    props.handleDelete();
    props.showModalDelete();
  };

  const handleCancel = () => {
    props.showModalDelete();
  };

  const handleDelete = () => {
    props.handleDelete();
    props.showModalDelete();
  };

  return (
    <>
      <Modal
        open={props.isDeleteModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <div className='delete-modal-header'>
            <h3>
              {t("Delete")} {props.textheading}
            </h3>
          </div>
          <div className='delete-modal-body'>
            <h6>
              {t("Are you sure you want to delete this")}{" "}
              <span>{props.deleteTitle}</span> {t("permanently?")}
            </h6>
          </div>
          <div className='modal-footer p-5'>
            <div className='flex justify-between'>
              <div></div>
              {/* <Checkbox className='pt2'><p className='checkbox-text'>Don’t show this again.</p></Checkbox> */}
              <Button className='delete-btn' onClick={handleDelete}>
                {props.deleteBtn}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
