import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import CustSupportForm from "./CustSupportForm";
import CustSupportContext from "../../context/custSupportContext";
import { useContext } from "react";
import CustTabs from "./CustTabs";

const Result = () => {
  const custSupportContext = useContext(CustSupportContext);
  
  const [activeTab, setActiveTab] = useState("all-tickets");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="user-section">
      <ToastContainer />

      {activeTab === "all-tickets" || activeTab === "open-tickets" || activeTab === "resolved-tickets" ? (
        <CustSupportForm
          isOpen={custSupportContext.isOpenModal}
          isAddMode={custSupportContext.isAddMode}
        />
        ) : null}


      <div>
        <CustTabs activeTab={activeTab} onTabChange={handleTabChange} />
      </div>
    </div>
  );
};

export default Result;
