import React from 'react'
import { Button } from 'antd'
import useAxiosInstance from '../../../lib/useAxiosInstance';
import { useQuery } from "react-query";
import { NavLink, useParams } from 'react-router-dom';
import { MdKeyboardArrowRight } from "react-icons/md";
import mapSvg2 from '../../../assets/svg/mapSvg2.svg'
import highPriority1 from "../../../assets/svg/highPriority1.svg"
import mediumPriority1 from "../../../assets/svg/mediumPriority1.svg"
import lowPriority1 from "../../../assets/svg/lowPriority1.svg"
import Completed from "../../../assets/images/icon/completed.png";
import PendingIcon from "../../../assets/images/icon/pending.png";
import AssignedIcon from "../../../assets/images/icon/assigned.png";
import Unassigned from "../../../assets/images/icon/Unassigned.png";
import Inprogess from "../../../assets/images/icon/inpgress.png";
import AssignDetails from "../../../Pages/tms/AssignedDetails"
import { useTranslation } from "react-i18next";

const TaskUserInfo = () => {
    const { t } = useTranslation();
    const { tmsUserId } = useParams();
    const axiosInstance = useAxiosInstance();
    const fetchTaskUser = async () => {
        return axiosInstance.get(
            `api/task/getTask/${tmsUserId}`,
        );
    };
    const taskUser = useQuery("task_User_Info", fetchTaskUser);
    const singleTaskData = taskUser?.data?.data?.data
    const taskStatus = taskUser?.data?.data?.data?.status;


    // Priority
    const priority = singleTaskData?.priority;
    const status = singleTaskData?.status;
    function getPriorityIcon(priority) {
        switch (priority) {
            case "high":
                return <div className="user-priority" style={{ color: "#E84C3B", border: "1px solid #E84C3B" }}>
                    <img className='mr-1' src={highPriority1} alt='' />
                    <span >High</span>
                </div>;
            case "medium":
                return <div className="user-priority" style={{ color: "#E67E22", border: "1px solid #E67E22" }}>
                    <img className='mr-1' src={mediumPriority1} alt='' />
                    <span >Medium</span>
                </div>;
            case "low":
                return <div className="user-priority" style={{ color: "#3BBEE8", border: "1px solid #3BBEE8" }}>
                    <img className='mr-1' src={lowPriority1} alt='' />
                    <span >Low</span>
                </div>;
            default:
                return null;
        }
    }
    function getStatusIcon(status) {
        switch (status) {
            case "unassigned":
                return (
                    <div
                        className='priority-wrapper'
                    >
                        <img src={Unassigned} alt='unassigned icon' />
                        <span>{t("unassigned")}</span>
                    </div>
                );
            case "assigned":
                return (
                    <div
                        className='priority-wrapper'
                    >
                        <img src={AssignedIcon} alt='assigned icon' />
                        <span>{t("assigned")}</span>
                    </div>
                );
            case "inProgress":
                return (
                    <div
                        className='priority-wrapper'
                    >
                        <img src={Inprogess} alt='inprogress icon' />
                        <span>{t("inProgress")}</span>
                    </div>
                );
            case "pendingApproval":
                return (
                    <div
                        className='priority-wrapper'
                    >
                        <img src={PendingIcon} alt='pending Approval icon' />
                        <span>{t("approvalPending")}</span>
                    </div>
                );
            case "complete":
                return (
                    <div
                        className='priority-wrapper'
                    >
                        <img src={Completed} alt='complete icon' />
                        <span>{t("completed")}</span>
                    </div>
                );
            default:
                return null;
        }
    }
    const handleBack = () => {
        window.history.back();
    };
    return (
        <>
            <div className='tms-more-details'>
                <div className="userinfo-breadcrumbs"> <div className="flex">
                    <NavLink to="/assets-management/user">
                        <h1>Assets Management
                            <MdKeyboardArrowRight className='inline-block' />
                        </h1>
                    </NavLink>
                    {/* <NavLink to="/assetsManagement/user/userInfo"> */}
                    <h1 onClick={handleBack}>
                        User Info <MdKeyboardArrowRight className='inline-block' />
                    </h1>
                    {/* </NavLink> */}
                    <span>More Details</span></div></div>
                <div className='more-details-wrapper grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-6'>
                    <div className='gms-all-details  col-span-2'>
                        <div className='details-heading'>
                            {getPriorityIcon(priority)}
                            <div className='approval'>
                                <span>{getStatusIcon(status)}</span>
                            </div>
                            <h3>{singleTaskData?.description}</h3>
                        </div>
                        <div className="issue-details">
                            <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6'>
                                <div className='gms-container col-span-2'>
                                    <p><span>Description  </span><span>{singleTaskData?.description}</span> </p>
                                    <p><span>Office  </span><span>{ }</span> </p>
                                </div>
                                <div className='gms-details-btn col-span-1'>
                                    <Button>
                                        <a href={`https://maps.google.com/?q=${singleTaskData?.locationCoordinates?.lat},${singleTaskData?.locationCoordinates?.lng}`} target="_blank">
                                            <div className="flex">
                                                <img src={mapSvg2} alt='Map Icon' />
                                                <span className="pl-2 pt-1">View On Map</span>
                                            </div>
                                        </a>
                                    </Button>
                                </div>

                            </div>
                            <h2>Created by</h2>
                            <p><span> Name   </span><span>{singleTaskData?.createdByUserName}</span></p>
                            <p><span>Role  </span><span>{singleTaskData?.createdByUserRole}</span></p>
                            <p><span>Creation Date </span><span>{singleTaskData?.createdAt}</span></p>
                            <h2>Photos</h2>
                            <div className="gms-details-img grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-4">
                                {singleTaskData?.uploadImages?.map((image, index) => (
                                    <img src={image?.publicUrl} alt={image?.name} key={index} />
                                ))}
                            </div>
                        </div>

                    </div>
                    {/* ASSIGN TASK */}
                    <div className='gms-assign-task  col-span-1'>
                        <div className='details-heading'>
                            <h3>Assign Task</h3>
                        </div>
                        
                        {taskStatus &&
                            <AssignDetails
                                singleTaskData={taskUser?.data?.data?.data}
                            />
                        }
                    </div>
                </div>
            </div>
        </>

    )
}

export default TaskUserInfo