import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SurveyData from './SurveyData';
import SurveyRequestedList from './SurveyRequested/SurveyRequestedList';


const SurveyTabs = () => {
    const [activeTab, setActiveTab] = useState('survey-database');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`../${tab}`);
    };
  return (
    <>
    <div className='user-table-wrapper'>
                <div className='tabs1'>
                    <div className='user-table-tabs'>
                        <button
                            className={`tab-button tab-btn1 ${activeTab === 'survey-database' ? 'active' : ''}`}
                            onClick={() => handleTabChange('survey-database')}
                        >
                            {t("All Surveys")}
                        </button>
                        <button
                            className={`tab-button  ${activeTab === 'survey-requested' ? 'active' : ''}`}
                            onClick={() => handleTabChange('survey-requested')}
                        >
                            {t("Requested Surveys")}
                        </button>
                    </div>
                </div>
                <div className='tab-content'>
                    {activeTab === "survey-database" && <SurveyData />}
                    {activeTab === "survey-requested" && <SurveyRequestedList />}
                </div>
            </div>
    </>
  )
}

export default SurveyTabs