import React, { useState, useEffect } from "react";
import { Input } from "antd";
import "../Styles/searchFilter.css";
import FilterContainer from "./FilterContainer";
import { DownOutlined } from "@ant-design/icons";
import SearchIcon from "../assets/svg/search-icon.svg";
import { useTranslation } from "react-i18next";

const SearchFilter = (props) => {
  const [isSearch, setIsSearchOpen] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const searchIcon = document.querySelector(".search-icon");
      const isSearchIconClicked =
        searchIcon && searchIcon.contains(event.target);

      if (isSearchIconClicked) {
        setIsSearchOpen((prevState) => !prevState); // toggle search window
      } else {
        const searchWindow = document.querySelector(".filter-container-body");
        const isSearchWindowClicked =
          searchWindow && searchWindow.contains(event.target);

        if (!isSearchWindowClicked) {
          setIsSearchOpen(false);
        }
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <div className=''>
        {/* <h1 className='pl-4 py-5 text-lg font-bold'>{props.searchTitle}</h1> */}

        <div className='search-filter'>
          <div className='search-option'>
            <Input
              size='small'
              placeholder={t("search")}
              onChange={(e) => props.handleSearch(e.target.value)}
            />
            <img src={SearchIcon} alt='' />
            {/* <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.4996 11H11.7096L11.4296 10.73C12.6296 9.33002 13.2496 7.42002 12.9096 5.39002C12.4396 2.61002 10.1196 0.390015 7.31965 0.0500152C3.08965 -0.469985 -0.470351 3.09001 0.049649 7.32001C0.389649 10.12 2.60965 12.44 5.38965 12.91C7.41965 13.25 9.32965 12.63 10.7296 11.43L10.9996 11.71V12.5L15.2496 16.75C15.6596 17.16 16.3296 17.16 16.7396 16.75C17.1496 16.34 17.1496 15.67 16.7396 15.26L12.4996 11ZM6.49965 11C4.00965 11 1.99965 8.99002 1.99965 6.50002C1.99965 4.01002 4.00965 2.00002 6.49965 2.00002C8.98965 2.00002 10.9996 4.01002 10.9996 6.50002C10.9996 8.99002 8.98965 11 6.49965 11Z" fill="black" />
                        </svg> */}
          </div>
          <div className='filter-option search-icon'>
            {/* <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path d="M10.0003 9V16.88C10.0403 17.18 9.94035 17.5 9.71035 17.71C9.32035 18.1 8.69035 18.1 8.30035 17.71L6.29035 15.7C6.06035 15.47 5.96035 15.16 6.00035 14.87V9H5.97035L0.210346 1.62C-0.129654 1.19 -0.049654 0.56 0.380346 0.22C0.570346 0.08 0.780346 0 1.00035 0H15.0003C15.2203 0 15.4303 0.08 15.6203 0.22C16.0503 0.56 16.1303 1.19 15.7903 1.62L10.0303 9H10.0003Z" fill="black" />
                        </svg> */}
            <span> Reset Filters</span>
            <DownOutlined />
            {isSearch && <FilterContainer />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
