import React from 'react'
import '../Styles/pageHeading.css'
import { Button } from 'antd'


const PageHeading = (props) => {
    return (
        <>
            <div className='page-heading'>
                <h3>{props.pageTitle}</h3>
                <Button  onClick={props.pageModalOpen} icon={props.icon}
                className={props.activeTab === 'organizational-hierarchy' ? 'hide-button' : ''}
                >{props.pageModalTitle}</Button>
            </div>
        </>
    );

}

export default PageHeading;