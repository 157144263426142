import React from "react";
import { priorityIcons } from "../../../utils/priorityIcons";
import cdn from "../../../assets/images/icon/location.png";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const MarkerInfoContent = ({ activeMarker }) => {
  const { t } = useTranslation();
  return (
    <div className='info-window-content'>
      <div className='flex'>
        <img
          src={priorityIcons[activeMarker?.properties?.priority]}
          alt='priority icon'
          style={{ maxWidth: "18px", maxHeight: "18px", marginRight: "5px" }}
        />
        <span className='pl-1' style={{ fontSize: "18px", fontWeight: "bold" }}>
          {activeMarker?.properties?.title}
        </span>
      </div>

      <span className='flex-container'>
        <img src={cdn} alt='' className='cdn-icon' />
        <h5 className='bold-text'>
          {t("Address")}: {activeMarker?.properties?.st_name || t("NA")}
        </h5>
      </span>
      <h5>
        {t("Assigned To")}: {activeMarker?.properties?.assigned_to || t("N/A")}
      </h5>
      <h5>
        {t("Category Name")}: {activeMarker?.properties?.cat_name || t("N/A")}
      </h5>
      <h5>
        {t("Sub Category Name")}:{" "}
        {activeMarker?.properties?.sub_cat_name || t("N/A")}
      </h5>
      <h5>
        {t("Reported By")}: {activeMarker?.properties?.reported_by || t("N/A")}
      </h5>
      <h5>
        {t("Status")}: {activeMarker?.properties?.status || t("N/A")}
      </h5>

      <Link
        to={`/gms/issuedetails/${activeMarker?.properties?.g_id}`}
        target='_blank'
      >
        <div className='button-link'>{t("More details")}</div>
      </Link>
    </div>
  );
};

export default MarkerInfoContent;
