import React, { createContext } from 'react'
import { useState } from 'react'

export const userContext = createContext();


const UserContextProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState('')
  const [showModal, setShowModal] = useState(false);
  const [addNewOffice, setAddNewOffice] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange,
  };


  const openModal = () => {
    setShowModal(true);
  };
  const handleCancel = () => {
    setShowModal(false);
    setInputValue('')
    setErrors("");
    setIsLoading(false);
  };

  // // VIEW OFFICE
  // const [viewOffice, setViewOffice] = useState(false)
  // const openViewOffice = () => {
  //     setViewOffice(!viewOffice)
  // }
  // Dropdown
  const [openDropdown, setOpenDropdown] = useState([]);

  const handleClick = (index) => {
    setOpenDropdown((prevOpenDropdown) => {
      const updatedOpenDropdown = [...prevOpenDropdown];
      updatedOpenDropdown[index] = !updatedOpenDropdown[index];
      return updatedOpenDropdown;
    });
  }


  const userValues = {
    inputValue, setInputValue,
    showModal, setShowModal,
    addNewOffice, setAddNewOffice, openModal, handleCancel, errors, setErrors,
    currentPage, setCurrentPage, limit, setLimit, total, setTotal, handlePageChange, pagination,
    isLoading, setIsLoading, openDropdown, handleClick,
    // viewOffice,setViewOffice,openViewOffice
  }
  return (
    <userContext.Provider value={userValues} >
      {children}
    </userContext.Provider>
  )
}

export default UserContextProvider