import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";

import useAxiosInstance from "../../lib/useAxiosInstance";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const General = ({
  profileData = {},
  setIsProfileUpdated = () => {},
  setActiveTab = () => {}
}) => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  // const user = JSON.parse(localStorage.getItem("user"));

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (profileData) {
      setFirstName(profileData.firstName);
      setLastName(profileData.lastName);
      setEmail(profileData.email);
      setPhone(profileData.phone);
    }
  }, [profileData]);

  const handleSubmit = async () => {
    try {
      const updatedUserData = {
        ...profileData,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        password: profileData?.clientPassword
      };

      await axiosInstance
        .put(`api/clients/clientUser/info/${profileData._id}`, updatedUserData)
        .then(() => {
          setIsProfileUpdated((prev) => !prev);
          toast.success("Profile updated successfully");
        });
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile");
    }
  };

  return (
    <div className='FirstTab'>
      <ToastContainer></ToastContainer>
      <div className='general-form'>
        <div>
          <p className='text-heading-general-info'>
            {t("General Information")}
          </p>
        </div>
        <div className='firstname-lastname-fields'>
          <div className='firstname-field'>
            <label>{t("First Name")}</label>
            <Input
              placeholder='First Name'
              value={firstName} disabled
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </div>
          <div className='lastname-field'>
            <label>{t("Last Name")}</label>
            <Input
              placeholder='Last Name'
              value={lastName} disabled
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>
        </div>

        <div className='firstname-lastname-fields'>
          <div className='firstname-field'>
            <label>{t("Email")}</label>
            <Input
              placeholder='Email'
              value={email} disabled
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='lastname-field'>
            <label>{t("Phone")}</label>
            <Input.Group compact>
              <Input
                style={{
                  width: "15%"
                }}
                value='+91' disabled
              />
              <Input
                type='text'
                style={{
                  width: "85%",
                  borderLeft: "1px solid var(--clr-divider) "
                }}
                value={phone} disabled
                onChange={(e) => setPhone(e.target.value)}
              />
            </Input.Group>
          </div>
        </div>
        {/* <div className='alternate-email-text'>
            <p><AiOutlinePlusCircle style={{ float:"left", height: 18, width: 25,paddingRight:2, color:"#004877"}}/>Add Alternate Email</p>
          </div> */}

        <div className='firstname-lastname-fields'>
          <div className='firstname-field'>
            <label>{t("Role")}</label>
            <Input
              placeholder='ABC Company'
              value={profileData?.role}
              disabled
            />
            {/* <Select
                size='large'
                value={role}
                style={{
                  width: '100%',
                  marginTop: '8px'
                }}
                onChange={handleChange}
                options={[
                  {
                    value: 'SuperAdmin',
                    label: 'Super Admin',
                  },
                  {
                    value: 'Admin',
                    label: 'Admin',
                  },
                  {
                    value: 'Manager',
                    label: 'Manager',
                  },
                  {
                    value: 'Client',
                    label: 'Client',
                  },
                ]}
              /> */}
          </div>
          <div className='lastname-field'>
            <label>{t("State")}</label>
            <Input
              placeholder=''
              value={profileData?.constituency?.state}
              disabled
            />
          </div>
        </div>

        <div className='firstname-lastname-fields'>
          <div className='firstname-field'>
            <label>{t("Type of Constituency")}</label>
            <Input placeholder='' value='Assembly' disabled />
          </div>
          <div className='lastname-field'>
            <label>{t("Constiuency Name")}</label>
            <Input
              placeholder='ABC Company'
              value={profileData?.constituency?.name}
              disabled
            />
          </div>
        </div>
      </div>
      {/* <div className='button-bottom-right'>
        <Button
          className='cancel-btn'
          style={{
            width: "120px !important",
            border: "1px solid var(--primary-color)",
            color: "var(--primary-color)",
            fontWeight: "600"
          }}
        >
          {t("Cancel")}
        </Button>
        <Button type='primary' className='primary-btn' onClick={handleSubmit}>
          {t("Save Changes")}
        </Button>
      </div> */}
    </div>
  );
};

export default General;
