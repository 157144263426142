import React, { useState } from "react";
import UserDataTable from "./UserDataTable";
import { useQuery, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useContext } from "react";
import { userContext } from "../../../context/UserContextProvider";
import { Pagination, Select } from "antd";
import { toast } from "react-toastify";

import SearchFilter from "../../SearchFilter";
import { useTranslation } from "react-i18next";

const UserDataList = () => {
  const { t } = useTranslation();
  const { currentPage, setCurrentPage, limit, setLimit, total, setTotal } =
    useContext(userContext);
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const [searchKeyword, setSearchKeyword] = useState("");

  const fetchUsers = async () => {
    const response = await axiosInstance.get(
      `api/clients/info?page=${currentPage}&limit=${limit}`
    );
    setTotal(response.data.data.totalPages);
    return response;
  };
  const usersData = useQuery(["client-users", currentPage, limit], fetchUsers);

  // GET ALL SEARCH USER DATA
  const SearchUser = (value) => {
    setCurrentPage(1);
    setSearchKeyword(value);
  };

  const fetchSearchUsers = async () => {
    let response;
    if (searchKeyword) {
      response = await axiosInstance.get(
        `api/clients/search?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
      );

      setTotal(response?.data?.totalpages);
    } else {
      const response = await axiosInstance.get(
        `api/clients/info?page=${currentPage}&limit=${limit}`
      );
      setTotal(response.data.data.totalPages);
    }
    return response;
  };

  const userSearchData = useQuery(
    ["allSearchUserData", searchKeyword, currentPage, limit],
    () => fetchSearchUsers(),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  // DELETE USER
  const handleDelete = async (record) => {
    try {
      const response = await axiosInstance.delete(`api/clients/${record._id}`);
      const message = response?.data?.message;
      toast.success(`${message}`);
      queryClient.invalidateQueries("client-users");
      queryClient.invalidateQueries("allSearchUserData");
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };
  return (
    <>
      <SearchFilter handleSearch={SearchUser} />
      <UserDataTable
        usersData={usersData?.data?.data?.data?.results}
        userSearchData={userSearchData?.data}
        handleDelete={handleDelete}
      />
      <div className='client-pagination mx-5 '>
        <div className='pagination-text'>
          {t("Showing")}
          <Select
            className='mx-2'
            value={limit.toString()}
            style={{ width: 56 }}
            onChange={(value) => {
              setLimit(parseInt(value));
              setCurrentPage(1);
            }}
            options={[
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "50", label: "50" },
              { value: "100", label: "100" }
            ]}
          />
          {t("Entries per pages")}
        </div>
        <div>
          <Pagination
            current={currentPage}
            pageSize={limit}
            total={total * limit}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setLimit(pageSize);
            }}
            className='pagination'
          />
        </div>
      </div>
    </>
  );
};

export default UserDataList;
