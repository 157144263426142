import React from 'react'
import { useState } from 'react'

const SurveyContext = React.createContext({
    isOpenModal: false,
    updateOpenModal: () => { },

    isAddMode: true,
    updateAddMode: () => { }
})

export const SurveyContextProvider = ({ children }) => {
    const [isOpenModalState, setIsOpenModalState] = useState(false)
    const [isAddModeState, setIsAddModeState] = useState(true)

    const contextValue = {
        isOpenModal: isOpenModalState,
        updateOpenModal:setIsOpenModalState,

        isAddMode:isAddModeState,
        updateAddMode:setIsAddModeState
    }
    return <SurveyContext.Provider value={contextValue}>{children}</SurveyContext.Provider>
}
export default SurveyContext