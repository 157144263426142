import React, { useContext } from "react";
import { Button, Modal } from "antd";
import { AppContext } from "../../context/AppContextProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Logout = ({ logoutModal, setLogoutModal }) => {
  const { t } = useTranslation();
  const { handleLogout } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <>
      <div className='logout-wrapper'>
        {logoutModal && (
          <Modal
            className='logout-Modal'
            open={logoutModal}
            onOk={() => {
              handleLogout();
              navigate("/");
              setLogoutModal(false);
            }}
            onCancel={() => setLogoutModal(false)}
            width={600}
            footer={false}
          >
            <h3> {t("Logout")}</h3>
            <p>{t("wantLogout")}</p>
            <div className='logout-Btns'>
              <Button
                type='button'
                className='logout-cancel mr-5'
                onClick={() => setLogoutModal(false)}
              >
                {t("Cancel")}
              </Button>
              <Button
                type='button'
                className='logout-button'
                onClick={() => {
                  handleLogout();
                  navigate("/");
                  setLogoutModal(false);
                }}
              >
                {t("Logout")}
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Logout;
