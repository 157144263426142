// grievanceQuery.js

import { useQuery } from "react-query";
import useGrievanceApi from "./useGrievanceApi";
const GrievanceQuery = () => {
  const grievanceApi = useGrievanceApi();
  const useGMSListData = (currentPage, limit) => {
    return useQuery(
      ["GMS-List_Data", currentPage, limit],
      () => grievanceApi.fetchGMS(currentPage, limit),
      {
        refetchOnWindowFocus: false
      }
    );
  };

  const useGMSListDataByStatus = (currentPage, limit, pendingApproval) => {
    return useQuery(
      ["GMS-List_Data", pendingApproval, currentPage, limit],
      () => grievanceApi.fetchByStatus(currentPage, limit, pendingApproval)
    );
  };
  const useGMSListDataByKeyword = (searchKeyword, currentPage, limit) => {
    return useQuery(
      ["GMS-List_Data", searchKeyword, currentPage, limit],
      () => grievanceApi.fetchSearch(searchKeyword, currentPage, limit),
      {
        refetchOnWindowFocus: false
      }
    );
  };
  return { useGMSListData, useGMSListDataByStatus, useGMSListDataByKeyword };
};

export default GrievanceQuery;
