import React, { useContext } from 'react'
import useAxiosInstance from '../../../lib/useAxiosInstance';
import { useMutation, useQueryClient } from 'react-query';
import AssetsManagementContext from '../../../context/assetsManagementContext';
import { Button, Modal } from 'antd';
import { toast } from 'react-toastify';

const DeactivateClientUser = ({ isStatusModal, editUserData }) => {
    const assetsContext = useContext(AssetsManagementContext)

    const axiosInstance = useAxiosInstance();
    const queryClient = useQueryClient();
    const toggleReportStatusMutation = useMutation(
        async () => {
            // Destructure and exclude _id, status, and clientPassword
            const { _id, status, clientPassword, ...otherFields } = editUserData;

            const updatedClient = {
                ...otherFields,
                status: status === 'deactivate' ? 'active' : 'deactivate',
            };

            const response = await axiosInstance.put(`api/clients/clientUser/${_id}`, updatedClient);

            return response.data;
        },
        {
            onSuccess: (data) => {
                handleCancel();
                queryClient.invalidateQueries("client-users");
                queryClient.invalidateQueries("allSearchUserData");
                // toast.success(`Client ${data.data.status} successfully`);
                const msg = data.data.status === 'active' ? 'activated' : 'deactivated';
                toast.success(`User ${msg} successfully`);
            },
            onError: (error) => {
                toast.error('An error occurred while updating report status');
            },
        }
    );
    const handleToggleStatus = () => {
        toggleReportStatusMutation.mutate();
    };

    const handleCancel = () => {
        assetsContext.updateStatusModal(false);
    };

    return (
        <>

            <Modal open={isStatusModal}
                onSumbit={handleToggleStatus}
                onCancel={handleCancel}
                footer={null}
            >
                <div>
                    <div className='delete-modal-header'>
                        <h3>{editUserData.status === 'deactivate' ? 'Activate' : 'Deactivate'} User</h3>
                    </div>
                    <div className='delete-modal-body'>
                        <h6>Are you sure you want to {editUserData.status === 'deactivate' ? 'activate' : 'deactivate'} this user?</h6>
                    </div>

                    <div className='modal-footer p-5'>
                        <div className='flex justify-end'>
                            <Button className='delete-btn' onClick={handleToggleStatus}>
                                {editUserData.status === 'deactivate' ? 'Activate' : 'Deactivate'} User
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default DeactivateClientUser