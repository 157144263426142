import React, { createContext, useState, useEffect } from "react";


import { useContext } from "react";
import UserContextProvider, { userContext } from "./UserContextProvider";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  // const [authToken, setAuthToken] = useState(localStorage.getItem('client-token'));

  // for navbar toggle
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  // const setToken = (response) => {
  //   localStorage.setItem("client-token", response.data.data.token);
  // };

  // const getToken = () => {
  //   return localStorage.getItem("client-token");
  // };
  const deleteToken = () => {
    localStorage.removeItem("client-token");
    localStorage.removeItem("user");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    deleteToken();
    const themes = localStorage.getItem("themes"); // Retrieve the theme preference
    localStorage.clear(); // Clear all data from local storage
    if (themes) {
      localStorage.setItem("themes", themes); // Set the theme preference again
    }
  };
  // LIGHT DARK THEME
  const [themes, setThemes] = useState(() => {
    // Retrieve theme preference from local storage on component mount
    const storedThemes = localStorage.getItem("themes");
    return storedThemes || "lightMode";
  });

  // Set theme directly on component mount
  document.body.className = themes;


  // user profile api
  const user = JSON.parse(localStorage.getItem("user"));

  // const [userName, setUsername] = useState();
  // const [firstName, setFirstname] = useState(user?.firstName);
  // const [lastName, setLastname] = useState(user?.lastName);
  // const [email, setEmail] = useState(user?.email);
  // const [phone, setPhone] = useState(user?.phone);
  // const [role, setRole] = useState(user?.role);


  const [password, setPassword] = useState("");
  const [bio, setBio] = useState("");
  const [deactivateAccount, setDeactivateAccount] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [fileInput, setFileInput] = useState(null);

  const handleDelete = () => {
    setAvatar(null);
  };

  const handleChange = (event) => {
    setAvatar(event.target.files[0]);
  };

  const handleClick = () => {
    fileInput.click();
  };

  const onChange = (e) => {
    setDeactivateAccount(e.target.value === 1);
  };





  // // grievences management

  // const [inputArr, setInputArr] = useState(() => {
  //   const storedInputArr = localStorage.getItem("inputArr");
  //   return storedInputArr ? JSON.parse(storedInputArr) : [];
  // });

  // // pagination
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limit, setLimit] = useState(5);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const token = getToken();

  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/api/grievances`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },

  //           params: {
  //             page: currentPage,
  //             perPage: limit,
  //           },
  //         }
  //       );

  //       setInputArr(response.data.data.results);
  //       localStorage.setItem(
  //         "grievences",
  //         JSON.stringify(response.data.data.results)
  //       );
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchData();
  // }, [currentPage, limit]);

  // const grievences = JSON.parse(localStorage.getItem("grievences"));
  // const [gmsId, setgmsId] = useState(grievences._id);
  // console.log("gms", grievences[1]);
  // console.log("gmsid ******", gmsId);


  // USER CONTEXT
  const userContextvalue = useContext(userContext)
  const userValues = {
    ...userContextvalue
  }

  return (
    <AppContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        handleLogout,
        isOpen,
        toggle,
        // firstName,
        // lastName,
        // setFirstname,
        // setLastname,
        // userName,
        // setUsername,
        // email,
        // setEmail,
        // phone,
        // setPhone,
        // role,
        // setRole,
        password,
        setPassword,
        bio,
        setBio,
        deactivateAccount,
        setDeactivateAccount,
        avatar,
        setAvatar,
        handleDelete,
        handleChange,
        handleClick,
        onChange,
        setFileInput,
        fileInput,
        // getToken,
        // authToken, setAuthToken,
        user, themes, setThemes
      }}
    >
      {/* {children} */}
      <UserContextProvider value={userValues}>{children}</UserContextProvider>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
