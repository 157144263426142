import ProfilePic from "../../../assets/images/icon/placeholder-icon.png";

import React, { useState, useEffect } from "react";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useQuery } from "react-query";
import formatDate from "../../../utils/formatDate";

import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import formatRole from "../../../utils/formatRole";

const AssignDetails = ({ singleGrievanceData }) => {
  const { t } = useTranslation();

  const axiosInstance = useAxiosInstance();
  const userId = singleGrievanceData?.assignedToUserId;

  // const grievanceId = singleGrievanceData?._id;

  const [instructions, setInstructions] = useState("");
  const [showImages, setShowImages] = useState(true);

  useEffect(() => {
    // Set initial values from singleGrievanceData when component mounts
    setInstructions(singleGrievanceData?.instructions || "");
    setShowImages(
      singleGrievanceData?.showImages !== undefined
        ? singleGrievanceData?.showImages
        : false
    );
  }, [singleGrievanceData]);
  //fetch client user by Id-->
  const fetchClientUser = async () => {
    return axiosInstance.get(`/api/clients/clientUser/${userId}`);
  };
  const fetchClientUserProfileImage = async () => {
    return axiosInstance.get(`/api/clients/info/getProfileImage/${userId}`);
  };
  const clientUserData = useQuery(
    ["client-user-by-id", userId],
    fetchClientUser,
    {
      refetchOnWindowFocus: false,
      enabled: !!userId
    }
  );

  const clientUserProfileImage = useQuery(
    ["client-user-profile-image", userId],
    fetchClientUserProfileImage,
    {
      refetchOnWindowFocus: false,
      enabled: !!userId
    }
  );

  const UserProfilePic =
    clientUserProfileImage?.data?.data?.data?.profileImageLink;

  // const queryClient = useQueryClient();

  // Update assign details in the API
  // const updateAssignDetails = async () => {
  //   const data = {
  //     instructions: instructions,
  //     showImages: showImages
  //   };

  //   return axiosInstance.put(
  //     `/api/grievances/updateGrievance/${grievanceId}`,
  //     data
  //   );
  // };

  // const mutateUpdateAssignDetails = useMutation(updateAssignDetails, {
  //   onSuccess: (data) => {
  //     toast.success(`${data?.data?.message}`);

  //     // Invalidate cache and refetch grievance data
  //     queryClient.invalidateQueries("single-grievance");
  //   },
  //   // Handle error
  //   onError: (error) => toast.error(`something went wrong: ${error.message}`)
  // });

  // const handleSubmit = () => {
  //   mutateUpdateAssignDetails.mutate();
  // };

  return (
    <React.Fragment>
      <div className='issue-details mt2'>
        {/* getting this name data from props data  */}
        <div className='profile-pic'>
          <img
            className=''
            src={UserProfilePic?.publicUrl || ProfilePic}
            alt={"No Profile Picture"}
          />{" "}
        </div>
        <p>
          <span>{t("assignTo")} </span>{" "}
          <span>{singleGrievanceData?.assignedToUserName}</span>{" "}
        </p>
        <p>
          <span>{t("role")} </span>{" "}
          <span>{formatRole(clientUserData?.data?.data?.data?.role)}</span>{" "}
        </p>
        <p>
          <span>{t("dueDate")} </span>
          {/* {t("noDataForDueDate")} */}
          <span>
            {singleGrievanceData?.dueDate
              ? formatDate(singleGrievanceData?.dueDate)
              : "-"}
          </span>
        </p>
        <p>
          <span>{t("email")}</span>
          <span>{clientUserData?.data?.data?.data?.email}</span>
        </p>
        <p>
          <span> {t("phone")} </span>{" "}
          <span>{clientUserData?.data?.data?.data?.phone}</span>
        </p>
        <p>
          <span> {t("instructions")} </span>{" "}
          <span>{singleGrievanceData?.instructions}</span>
        </p>
        <div>
          <h2 style={{ color: "#004877", fontSize: "16px" }}>
            {t("submittedPhotos")}
          </h2>

          {singleGrievanceData?.postCompletionImages &&
          singleGrievanceData.postCompletionImages.length > 0 ? (
            <div className='gms-details-img pb-5'>
              {singleGrievanceData.postCompletionImages.map((image, index) => (
                <img src={image.publicUrl} alt={image.name} key={index} />
              ))}
            </div>
          ) : (
            <span>{t("noImagesFound")}</span>
          )}
        </div>

        {/*

                        {/* <Row className="row new-field">
                            <Col span={16} className='mt-6'>
                                <label htmlFor="">Add Instructions</label>
                                <TextArea
                                    rows={4}
                                    id="instructions"
                                    name="instructions"
                                    style={{ marginTop: "10px", width: "97%" }}
                                    value={instructions}
                                    onChange={(e) => setInstructions(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className='new-field'>
                            <Col span={16}>
                                <label htmlFor="">Show Images To Assignee ?</label>


                                <Radio.Group
                                    style={{ display: "block", marginTop: "20px", marginBottom: "20px" }}
                                    name="showImages"
                                    onChange={(e) => setShowImages(e.target.value === true)}
                                    value={showImages ? true : false}
                                >
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>

                            </Col>

                        </Row> */}
      </div>
    </React.Fragment>
  );
};

export default AssignDetails;
