import React, { useRef, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow
} from "@react-google-maps/api";
import useGetAllTask from "./useGetAllTask";
import indiaShape from "../../../../src/assets/geoJsonData/india-composite.json";
import { mapStyles } from "../../../utils/mapStyles";
import { priorityIcons } from "../../../utils/priorityIcons";
import { statusIcons } from "../../../utils/statusIcons";
import MarkerInfoContent from "./MarkerInfoContent";
import {
  lib,
  indiaCenter,
  initialZoom,
  indiaBounds
} from "../../../utils/mapConstants";
import { useTranslation } from "react-i18next";
const TmsMapBox = (props) => {
  const { priorityClick, statusClick, newGrievanceData } = props;
  const { t } = useTranslation();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: lib
  });
  const { geojsonData, loading } = useGetAllTask(
    isLoaded,
    priorityClick,
    statusClick,
    newGrievanceData
  );
  const [activeMarker, setActiveMarker] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  const mapRef = useRef(null);
  const onCloseInfoWindow = () => {
    setActiveMarker(null);
    setShowingInfoWindow(false);
  };

  const onMarkerClick = (point) => {
    setActiveMarker((prevMarker) => point);
    setShowingInfoWindow(true);

    const markerLatLng = new window.google.maps.LatLng(
      point.geometry.coordinates[1],
      point.geometry.coordinates[0]
    );
    if (mapRef.current) {
      const targetZoom = 7;
      const currentZoom = mapRef.current.getZoom();
      const numSteps = 10;
      const step = (targetZoom - currentZoom) / numSteps;

      // Smoothly zoom in
      for (let i = 1; i <= numSteps; i++) {
        setTimeout(() => {
          const newZoom = currentZoom + step * i;
          mapRef.current.setZoom(newZoom);
          mapRef.current.panTo(markerLatLng);
        }, 80 * i);
      }
    }
  };

  const onLoad = (map) => {
    mapRef.current = map;
    map.data.addGeoJson(indiaShape);
    map.data.setStyle((feature) => {
      return {
        fillColor: "grey",
        fillOpacity: 0.3,
        strokeColor: "black",
        strokeWeight: 1,
        strokeOpacity: 0.1
      };
    });
  };
  const renderInfoWindow = () => {
    if (activeMarker && showingInfoWindow)
      return (
        <InfoWindow
          position={{
            lat: activeMarker?.geometry?.coordinates[1],
            lng: activeMarker?.geometry?.coordinates[0]
          }}
          visible={showingInfoWindow}
          onCloseClick={onCloseInfoWindow}
        >
          <MarkerInfoContent activeMarker={activeMarker} />
        </InfoWindow>
      );
  };

  const renderTaskMarkers = () => {
    return geojsonData?.map((point, index) => {
      const coordinates = point?.geometry?.coordinates;
      if (
        coordinates &&
        coordinates.length === 2 &&
        typeof coordinates[0] === "number" &&
        typeof coordinates[1] === "number" &&
        point?.properties?.status !== "complete"
      ) {
        return (
          <Marker
            // icon={priorityIcons[point?.properties?.priority]}
            // icon={flag}

            icon={{
              url: statusIcons[point?.properties?.status],
              scaledSize: new window.google.maps.Size(30, 30),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15)
            }}
            key={index}
            title={point?.properties?.title}
            position={{
              lat: coordinates[1],
              lng: coordinates[0]
            }}
            onClick={() => {
              onMarkerClick(point);
            }}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (loadError) {
    return <div className='loader'>{t("errorLoadingMap")}</div>;
  }
  if (isLoaded) {
    return (
      <>
        {loading && (
          <div className='loader'>
            <div>{t("loading")}</div>
          </div>
        )}
        <div className='map-container1'>
          <GoogleMap
            google={props.google}
            zoom={initialZoom}
            mapContainerClassName='map-container'
            center={indiaCenter}
            options={{
              styles: mapStyles,
              restriction: {
                latLngBounds: indiaBounds,
                strictBounds: true
              }
            }}
            onLoad={onLoad}
            ref={mapRef}
          >
            {renderTaskMarkers()}
            {renderInfoWindow()}
          </GoogleMap>
        </div>
      </>
    );
  } else {
    return <div className='loader'> {t("loading")}</div>;
  }
};
export default TmsMapBox;
