const formatAgeCommunityValues = (ageVsCommunityData) => {
  
  return Object.entries(ageVsCommunityData).map(([ageGroup, communities]) => {
    const totalPeople = communities.reduce(
      (sum, { noOfPeople }) => sum + noOfPeople,
      0
    );

    const dataEntry = { age: ageGroup };

    communities.forEach(({ name, noOfPeople }) => {
      const percentage = ((noOfPeople / totalPeople) * 100).toFixed(2);
      dataEntry[name] = parseFloat(percentage);
    });

    return dataEntry;
  });
};

export default formatAgeCommunityValues;
