import React from 'react'
import '../../Styles/insights.css'
import Study from '../../assets/images/insights/study.png'
import Roads from '../../assets/images/insights/roads.png'
import Cook from '../../assets/images/insights/cook.png'
import Talk from '../../assets/images/insights/talk.png'
import Photo from '../../assets/images/insights/photo.png'
import Relax from '../../assets/images/insights/relax.png'
import Goan from '../../assets/images/insights/goan.png'
import River from '../../assets/images/insights/river.png'
import Lava from '../../assets/images/insights/lava.png'
import Home from '../../assets/images/insights/home.png'
import Group from '../../assets/images/insights/group.png'
import Laser from '../../assets/images/insights/laser.png'
import Temp from '../../assets/images/insights/temp.png'
import Pass from '../../assets/images/insights/pass.png'
import Boat from '../../assets/images/insights/boat.png'
import PageHeading from '../../Components/PageHeading'






const Insights = () => {
  return (
    <>
      <div className='insight-page'>
        <PageHeading
          pageTitle='Insights'
        />
        <div className='insight-box'>

          <div className="flex insight-button-bar py-6 gap-4">
            <div className='insight-button w-12 h-8 ml-4'><button className="btn-active py-2 px-4">
              All
            </button></div>
            <div className='insight-button w-20 h-8'><button className="py-2 px-4">
              World
            </button></div>
            <div className='insight-button w-24 h-8'><button className="py-2 px-4">
              Politics
            </button></div>
            <div className='insight-button w-28 h-8'><button className="py-2 px-4">
              Education
            </button></div>
            <div className='insight-button w-20 h-8'><button className="py-2 px-4">
              Sport
            </button></div>
            <div className='insight-button w-36 h-8'><button className="py-2 px-4">
              Entertainment
            </button></div>
            <div className='insight-button w-20 h-8'><button className="py-2 px-4">
              Media
            </button></div>
            <div className='insight-button w-32 h-8'><button className="py-2 px-4">
              Technology
            </button></div>
          </div>


          {/* Cards Section */}

          <div className='grid grid-cols-4 gap-8 mx-8'>
            {/* LEFT SECTION */}
            <div className='grid grid-cols-2  gap-8 mt-6 col-span-3'>
              <div className='insight-card grid grid-cols-3'>
                <img src={Study} alt=""></img>
                <div className='col-span-2 p-5'>
                  <h1 className='card-heading'>Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit.</h1>
                  <p className='card-para'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                  <div className='flex justify-between items-center'>
                    <button className='text-base underline underline-offset-2'>Read More</button>
                    <p className='card-date'>18 Dec, 2022</p>
                  </div>
                </div>
              </div>

              <div className='insight-card grid grid-cols-3'>
                <img src={Cook} alt=""></img>
                <div className='col-span-2 p-5'>
                  <h1 className='card-heading'>Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit.</h1>
                  <p className='card-para'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                  <div className='flex justify-between items-center'>
                    <button className='text-base underline underline-offset-2'>Read More</button>
                    <p className='card-date'>18 Dec, 2022</p>
                  </div>
                </div>
              </div>

              <div className='insight-card grid grid-cols-3'>
                <img src={Talk} alt=""></img>
                <div className='col-span-2 p-5'>
                  <h1 className='card-heading'>Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit.</h1>
                  <p className='card-para'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                  <div className='flex justify-between items-center'>
                    <button className='text-base underline underline-offset-2'>Read More</button>
                    <p className='card-date'>18 Dec, 2022</p>
                  </div>
                </div>
              </div>



              <div className='insight-card grid grid-cols-3'>
                <img src={Photo} alt=""></img>
                <div className='col-span-2 p-5'>
                  <h1 className='card-heading'>Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit.</h1>
                  <p className='card-para'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                  <div className='flex justify-between items-center'>
                    <button className='text-base underline underline-offset-2'>Read More</button>
                    <p className='card-date'>18 Dec, 2022</p>
                  </div>
                </div>
              </div>

              <div className='insight-card grid grid-cols-3'>
                <img src={Relax} alt=""></img>
                <div className='col-span-2 p-5'>
                  <h1 className='card-heading'>Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit.</h1>
                  <p className='card-para'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                  <div className='flex justify-between items-center'>
                    <button className='text-base underline underline-offset-2'>Read More</button>
                    <p className='card-date'>18 Dec, 2022</p>
                  </div>
                </div>
              </div>

              <div className='insight-card grid grid-cols-3'>
                <img src={Roads} alt=""></img>
                <div className='col-span-2 p-5'>
                  <h1 className='card-heading'>Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit.</h1>
                  <p className='card-para'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                  <div className='flex justify-between items-center'>
                    <button className='text-base underline underline-offset-2'>Read More</button>
                    <p className='card-date'>18 Dec, 2022</p>
                  </div>
                </div>
              </div>

            </div>
            {/* RIGHT SECTION */}
            <div className='grid grid-cols-1 gap-y-4 col-span-1 mt-6'>
              {/* <div className=''> */}
                <div className='insight-big-card'>
                  <h1 className='sidebar-heading'>Most Popular</h1>
                  <div className='flex pt-4 pl-4'>
                    <img src={Goan} alt=""></img>
                    <div className='sidebar-para'>
                      <p className='pb-3'>In hac habitasse plates dictumst vestibulum rhoncus ...</p>
                      <a className='underline underline-offset-2' href=''>Read More</a>
                    </div>
                  </div>

                  <div className='flex pt-4 pl-4'>
                    <img src={River} alt=""></img>
                    <div className='sidebar-para'>
                      <p className='pb-3'>In hac habitasse plates dictumst vestibulum rhoncus ...</p>
                      <a className='underline underline-offset-2' href=''>Read More</a>
                    </div>
                  </div>

                  <div className='flex pt-4 pl-4 pb-4'>
                    <img src={Lava} alt=""></img>
                    <div className='sidebar-para'>
                      <p className='pb-3'>In hac habitasse plates dictumst vestibulum rhoncus ...</p>
                      <a className='underline underline-offset-2' href=''>Read More</a>
                    </div>
                  </div>
                </div>
                <div className='insight-big-card'>
                  <h1 className='sidebar-heading'>Trending</h1>
                  <div className='flex pt-4 pl-4'>
                    <img src={Home} alt=""></img>
                    <div className='sidebar-para'>
                      <p className='pb-3'>In hac habitasse plates dictumst vestibulum rhoncus ...</p>
                      <a className='underline underline-offset-2' href=''>Read More</a>
                    </div>
                  </div>

                  <div className='flex pt-4 pl-4'>
                    <img src={Group} alt=""></img>
                    <div className='sidebar-para'>
                      <p className='pb-3'>In hac habitasse plates dictumst vestibulum rhoncus ...</p>
                      <a className='underline underline-offset-2' href=''>Read More</a>
                    </div>
                  </div>

                  <div className='flex pt-4 pl-4 pb-4'>
                    <img src={Laser} alt=""></img>
                    <div className='sidebar-para'>
                      <p className='pb-3'>In hac habitasse plates dictumst vestibulum rhoncus ...</p>
                      <a className='underline underline-offset-2' href=''>Read More</a>
                    </div>
                  </div>

                </div>
                <div className='insight-big-card'>
                  <h1 className='sidebar-heading'>Recent</h1>
                  <div className='flex pt-4 pl-4'>
                    <img src={Temp} alt=""></img>
                    <div className='sidebar-para'>
                      <p className='pb-3'>In hac habitasse plates dictumst vestibulum rhoncus ...</p>
                      <a className='underline underline-offset-2' href=''>Read More</a>
                    </div>
                  </div>

                  <div className='flex pt-4 pl-4'>
                    <img src={Pass} alt=""></img>
                    <div className='sidebar-para'>
                      <p className='pb-3'>In hac habitasse plates dictumst vestibulum rhoncus ...</p>
                      <a className='underline underline-offset-2' href=''>Read More</a>
                    </div>
                  </div>

                  <div className='flex pt-4 pl-4 pb-4'>
                    <img src={Boat} alt=""></img>
                    <div className='sidebar-para'>
                      <p className='pb-3'>In hac habitasse plates dictumst vestibulum rhoncus ...</p>
                      <a className='underline underline-offset-2' href=''>Read More</a>
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>

          {/* <div className='show-more'>
            <button className="text-black-700 font-semibold py-2 px-4 border border-black rounded-lg">
              Show More
            </button>

          </div> */}
        </div>
      </div>
    </>

  )
}

export default Insights