import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportsData from "./ReportsData";

import ReportRequestedList from "./ReportRequested/ReportRequestedList";
import { useTranslation } from "react-i18next";

const ReportTabs = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("report-database");
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`../${tab}`);
  };
  return (
    <>
      <div className='user-table-wrapper'>
        <div className='tabs1'>
          <div className='user-table-tabs'>
            <button
              className={`tab-button tab-btn1 ${
                activeTab === "report-database" ? "active" : ""
              }`}
              onClick={() => handleTabChange("report-database")}
            >
              {t("All Reports")}
            </button>
            <button
              className={`tab-button  ${
                activeTab === "report-requested" ? "active" : ""
              }`}
              onClick={() => handleTabChange("report-requested")}
            >
              {t("Requested Reports")}
            </button>
          </div>
        </div>
        <div className='tab-content'>
          {activeTab === "report-database" && <ReportsData />}
          {activeTab === "report-requested" && <ReportRequestedList />}
        </div>
      </div>
    </>
  );
};

export default ReportTabs;
