import React, { useState, useEffect } from "react";
import { Button } from "antd";

import useAxiosInstance from "../../lib/useAxiosInstance";
import { MdOutlineDownload } from "react-icons/md";
import pdfImg from "../../assets/images/icon/pdf.png";
import "../../Styles/survey.css";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SurveyDownload = ({
  selectedSurvey = {},
  setSelectedSurvey = () => {}
}) => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  const [fileUrl, setFileUrl] = useState("");
  const location = useLocation();
  const state = location?.state || {};

  useEffect(() => {
    if (state) {
      handleDownload(state);
    }
  }, [state]);
  const handleBack = () => {
    window.history.back();
  };

  const handleDownload = (record) => {
    if (record.status === "close") {
      axiosInstance
        .get(`api/survey/client/viewRequestedSurvey/${record._id}`, {
          headers: {
            subscription: "advanced"
          }
        })
        .then((res) => {
          const publicUrl = res.data.data.publicUrl;
          setFileUrl(publicUrl);
        });
    } else {
      axiosInstance
        .get(`api/survey/${record._id}`, {
          headers: {
            subscription: "advanced"
          }
        })
        .then((res) => {
          const publicUrl = res.data.data.resourceLocation[0].publicUrl;
          setFileUrl(publicUrl);
        });
    }
  };

  const handleDownloadButtonClick = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "");
    link.setAttribute("target", "_blank");
    link.click();
  };
  return (
    <div className='logout-wrapper'>
      <div className='userinfo-breadcrumbs'>
        {" "}
        <div className='flex'>
          <NavLink to='/survey/report-database'>
            <h1>
              {t("Survey")}
              <MdKeyboardArrowRight className='inline-block' />
            </h1>
          </NavLink>
          {/* <NavLink to="/assetsManagement/user/userInfo"> */}
          <h1 onClick={handleBack}>
            {t("Survey Details")}{" "}
            <MdKeyboardArrowRight className='inline-block' />
          </h1>
          {/* </NavLink> */}
        </div>
      </div>
      <div className='flex justify-center'>
        <div className='client-details w-[90vw]'>
          <div className='tab-heading flex items-end justify-between'>
            <h4 className='mb-2'>{t("Survey Details")}</h4>
            <div className='download-Btn'>
              <Button
                type='button'
                className='download-button'
                onClick={handleDownloadButtonClick}
              >
                <MdOutlineDownload className='mt-1' />
                <span className='pl-1'>{t("Download")}</span>
              </Button>
            </div>
          </div>
          <div className='flex flex-col mt-4'>
            
            
            <div className='firstname-field'>
              <p>
                <span>{t("SURVEY NAME")}</span>
                <span>{state?.surveyName}</span>
              </p>
            </div>


            <div className='firstname-field'>
              <p>
                <span>{t("DESCRIPTION")}</span>
                <span>{state?.description}</span>
              </p>
            </div>

            <div className='firstname-field'>
              <p>
                <span>{t("UPLOAD DATE")}</span>
                <span>{new Date(state?.updatedAt).toLocaleDateString()}</span>
              </p>
            </div>


          </div>
        </div>
      </div>
      {fileUrl && (
        <div>
          <hr />
          <embed
            src={fileUrl || pdfImg}
            style={{
              width: "100%",
              height: "800px",
              border: "none",
              padding: "20px",
              margin: "10px"
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SurveyDownload;
