const engagementHelper = (data, platform) => {
  const totalImpressions = data?.reach?.total ?? 0;
  const totalLikes = data?.engagement?.postInteractions?.likes ?? 0;
  const totalComments = data?.engagement?.postInteractions?.comments ?? 0;
  const totalEngagedUsers = totalLikes + totalComments;
  const totalFollowers = data?.followers?.total ?? 0;

  // Calculate the total engagement rate based on total impressions
  const totalEngagementRate = totalImpressions
    ? (totalEngagedUsers / totalImpressions) * 100
    : 0;

  // Calculate the follower engagement rate based on total followers
  const followerEngagementRate = totalFollowers
    ? (totalEngagedUsers / totalFollowers) * 100
    : 0;

  // Ensure the follower engagement rate does not exceed 100%
  const validFollowerEngagementRate = Math.min(followerEngagementRate, 100);

  // Calculate the non-follower engagement rate
  const nonFollowerEngagementRate = Math.max(
    0,
    totalEngagementRate - validFollowerEngagementRate
  );

  return {
    followerEngagement: validFollowerEngagementRate.toFixed(2),
    nonFollowerEngagement: nonFollowerEngagementRate.toFixed(2),
    totalLikes,
    totalEngagedUsers
  };
};
export default engagementHelper;
